import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Typography, Link as DSLink } from '@olaisaac/design-system'

import IsaacLogo from '@/shared/assets/isaac-logo.svg'
import Graphism from '@/shared/assets/graphism.svg'

import { Logo } from './styles'

export const NotFound = () => {
  return (
    <Box
      position="relative"
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      overflow="hidden"
    >
      <Box mt="14rem" display="flex" flexDirection="column" alignItems="center">
        <Box mb="3.5rem">
          <img src={IsaacLogo} alt="isaac" />
        </Box>

        <Typography variation="headlineDesktopSmall" color="accent">
          Página não encontrada
        </Typography>

        <Typography variation="bodySmall">
          Não foi possível encontrar a página solicitada.
        </Typography>

        <Link to="/" component={DSLink}>
          Voltar para o início
        </Link>
      </Box>

      <Logo src={Graphism} alt="Graphism" />
    </Box>
  )
}
