import { APIResponse } from '@/shared/interfaces'

export type GracePeriodDate = {
  label: string
  value: string
}

export type GracePeriod = {
  CP: Date[]
  CPCR: Date[]
}

export type Eligibility = {
  eligibility?: {
    eligible: {
      CP: boolean
      CPCR: boolean
    }
    pa: {
      CP: number
      CPCR: number
    }
  }
  eligible: boolean
  eligibleForGracePeriod: boolean
  gracePeriod: GracePeriod
  pa: number
  renovationPa?: number
}

export type EligibilityResponse = APIResponse<Eligibility>

export enum CreditType {
  CP = 'cp',
  CPCR = 'cp-cr',
}

export type CreateCreditSimulationRequest = {
  creditTypes?: CreditType[]
  firstDiscountDate?: string
  gracePeriodDate: GracePeriodDate
  gracePeriodDates: GracePeriodDate[]
  note: string
  reason: string
  requestedValue: number

  schoolId: string
}

export type Offer = {
  annualFee: string
  category: CreditType
  chosenOfferDate: string
  firstDiscountDate: string
  id: string
  installmentValue: string
  monthlyFee: string
  totalAmount: string
  totalInstallments: number
}

export type CreditSimulation = {
  creditValue: string
  offers: Offer[]
  orderId: string
}

export type CreditSimulationResponse = APIResponse<CreditSimulation>

export type CreditReason = {
  label: string
  requiredNote: boolean
  value: string
}

export type CreditReasonsResponse = APIResponse<CreditReason[]>

export type ChooseOfferRequest = {
  chooseOfferId: string
  orderId: string
}

export type StepperMap = {
  finished: boolean
  step: number
}

export type GetOrderStatus = {
  chosenOffer: Offer
  creditValue: string
  orderId: string
  status: string
  stepperMapper: StepperMap
}

export type DropoutType = 'cancel' | 'abandonment'

export type OrderDropoutRequest = {
  creditOrderId: string
  message?: string
  type: DropoutType
}

export type RequestSimulation = {
  gracePeriodDate: GracePeriodDate
  gracePeriodDates: GracePeriodDate[]
  note: string
  reason: string
}

export type GetOrderStatusResponse = APIResponse<GetOrderStatus>

export type CreditOrderLockResponse = {
  chosenOffer?: Offer
  creditValue: string
  id: string
  status: string
}

export type GetLockResponse = {
  isLocked: boolean
  order?: CreditOrderLockResponse
}

export enum ReasonForChosenPolicyType {
  becauseOfTheGracePeriod = 'becauseOfTheGracePeriod',
  becauseOfTheInstallment = 'becauseOfTheInstallment',
  becauseOfTheInstallmentValue = 'becauseOfTheInstallmentValue',
}

export type DataReasonForChosenPolicyType = {
  chosenGracePeriod: string
  fromTheseInstallment: number
  maxPaCP: number
  reason?: ReasonForChosenPolicyType
}
