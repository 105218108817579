import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  UnleashFlags,
  useApi,
  useJWT,
  useSnackbar,
  useUnleashFlag,
  useUnleashFlag as unleashFlag,
} from '@/shared/hooks'
import { setToClipboard } from '@/shared/utils'
import { LiquidationSource } from '@/shared/interfaces'
import { FeatureFlags, useFeatureFlag } from '@/shared/hooks/useFeatureFlag'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'
import { useSendManualLiquidationEvent } from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useSendManualLiquidationEvents'
import useManualLiquidationNavigation from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/hooks/useManualLiquidationNavigation'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { useQuery } from '@/shared/hooks/useQuery'
import { useCanAddDiscountQuery } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/hooks/useCanAddDiscount'
import { InstallmentType, NegotiationType } from '@/modules/guardians/GuardianDetails/constants'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import { useSendGuardianDetailsInvoiceEvent } from '@/modules/guardians/GuardianDetailsInvoicesTab/hooks/useSendGuardianDetailsInvoiceEvent'

export interface useDrawerActionsInstallmentReturn {
  handleClickAddDiscounts: () => void
  handleClickChangePaymentMethod: () => void
  handleClickEditEnrollmentDueDate: () => void
  handleClickEditManualLiquidation: () => void
  handleClickManualLiquidation: () => void
  handleClickPrintReceipt: () => void
  handleClickRenegotiation: () => void
  handleClickSharePaymentLink: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleSuccessChangePaymentMethod: () => void
  isDisabledAddDiscounts: boolean
  isDisabledChangePaymentMethod: boolean
  isDisabledManualLiquidation: boolean
  isDisabledRenegotiation: boolean
  isHiddenAddDiscounts: boolean
  isHiddenChangePaymentMethod: boolean
  isHiddenEditDueDate: boolean
  isHiddenEditManualLiquidation: boolean
  isHiddenManualLiquidation: boolean
  isHiddenRenegotiation: boolean
  setShowChangePaymentModal: (show: boolean) => void
}

export const useDrawerActionsInstallment = (
  onCloseDrawer: () => void
): useDrawerActionsInstallmentReturn => {
  const { api } = useApi()
  const { isAdmin } = useJWT()
  const { setOnQueryParam } = useQuery()
  const { school } = useSelectedSchool()
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { search: currentPageQueryParams } = useLocation()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const sendManualLiquidationEvent = useSendManualLiquidationEvent()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { sendHotjarEvent } = useHotjar()
  const {
    installmentDrawerInfo: {
      type,
      status,
      is_from_credit_card_flow,
      urn,
      contract_id,
      invoice,
      negotiation,
      paid,
      receivable_type,
    },
    setSelectedDrawer,
    setShowChangePaymentModal,
  } = useInstallmentDrawerContext()
  const { setPageToReturn } = useGuardianNavigation()

  const { data: enableCanAddDiscount } = useCanAddDiscountQuery({
    receivable_id: urn?.replace(/urn:receivable:|urn:down-payment:/, ''),
    discounts: [],
    reason: '',
    schoolId: school.id,
  })

  const { goToManualLiquidation, goToEditManualLiquidation } = useManualLiquidationNavigation({
    urn,
    callbackParams: currentPageQueryParams,
  })

  const sendGuardianDetailsInvoiceEvent = useSendGuardianDetailsInvoiceEvent()

  const isManualLiquidationBlockedForSchool = unleashFlag(
    UnleashFlags.B2CB_984_DISABLE_MANUAL_LIQUIDATION
  )
  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )
  const isEditEnrollmentDueDateEnabled = useUnleashFlag(
    UnleashFlags.PAS_734_EDIT_ENROLLMENT_DUEDATE
  )

  const {
    value: manualLiquidationFeatureFlagValue,
    config: manualLiquidationConfig,
  } = useFeatureFlag(FeatureFlags.MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG)

  const IsManualLiquidationFeatureFlagEnabled =
    !isAdmin && manualLiquidationFeatureFlagValue && manualLiquidationConfig.includes(school.id)

  const isNegotiation = type === InstallmentType.NEGOTIATION
  const isDownPayment = type === InstallmentType.DOWN_PAYMENT
  const isNegotiationV2 = negotiation?.type === NegotiationType.NEGOTIATION_API_V2

  const isIsaacPayReceivable = receivable_type === 'ISAAC_PAY'
  const isEnrollmentReceivable = receivable_type === 'ENROLLMENT'
  const isOpenReceivable = ['OPEN', 'OVERDUE'].includes(status)
  const isOverdueReceivable = status === 'OVERDUE'
  const isFromCreditCardFlow = is_from_credit_card_flow

  const isManualLiquidation = [
    LiquidationSource.ISAAC_ACCOUNT,
    LiquidationSource.SCHOOL_ACCOUNT,
  ].includes(paid?.payment_source as LiquidationSource)

  const isHiddenManualLiquidation =
    (isDownPayment && isNegotiationV2 && isOverdueReceivable) ||
    (isManualLiquidationBlockedForSchool && !isAdmin) ||
    !isOpenReceivable ||
    isFromCreditCardFlow
  const isDisabledManualLiquidation = isHiddenManualLiquidation || !isOpenReceivable

  const isEditable =
    !isOpenReceivable &&
    !isFromCreditCardFlow &&
    isManualLiquidation &&
    !(isManualLiquidationBlockedForSchool && !isAdmin)

  const canEditManualLiquidation = IsManualLiquidationFeatureFlagEnabled
    ? isEditable && paid?.payment_source === LiquidationSource.ISAAC_ACCOUNT
    : isEditable

  const isHiddenEditManualLiquidation = !canEditManualLiquidation

  const isHiddenRenegotiation =
    isDownPayment ||
    !isAdmin ||
    !isRenegotiateAndAgglutinateButtonEnabled ||
    isFromCreditCardFlow ||
    !isOpenReceivable

  const isDisabledRenegotiation =
    isHiddenRenegotiation || !isOpenReceivable || isEnrollmentReceivable

  const isHiddenAddDiscounts =
    !isAdmin ||
    isEnrollmentReceivable ||
    isNegotiation ||
    isDownPayment ||
    !isOpenReceivable ||
    isFromCreditCardFlow

  const isDisabledAddDiscounts = isHiddenAddDiscounts || !isOpenReceivable || !enableCanAddDiscount

  const isHiddenEditDueDate =
    !isAdmin ||
    !isIsaacPayReceivable ||
    isDownPayment ||
    !isEditEnrollmentDueDateEnabled ||
    !isOpenReceivable ||
    isFromCreditCardFlow

  const isHiddenChangePaymentMethod =
    !isOpenReceivable || !isOutsourcedProvider || isFromCreditCardFlow

  const isDisabledChangePaymentMethod = isHiddenChangePaymentMethod || isOverdueReceivable

  const handleClickManualLiquidation = () => {
    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION,
      undefined,
      {
        name: 'Registrar recebimento',
        receivable_id: urn,
        selected_date: dayjs().format(),
        contract_id: contract_id,
      }
    )
    onCloseDrawer()
    sendHotjarEvent(HotjarEvents.MANUAL_LIQUIDATION)
    setPageToReturn()
    goToManualLiquidation()
  }

  const handleClickRenegotiation = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.INSTALLMENT_DRAWER,
        entity: EventDispatcherEntities.RENEGOTIATE,
        action: 'click',
        customProperties: {
          $name: 'Renegociar',
          $receivable_id: urn,
        },
      })

    sendHotjarEvent(HotjarEvents.RENEGOTIATE_INSTALLMENT)
    setSelectedDrawer('renegotiation')
  }

  const handleClickAddDiscounts = () => {
    sendHotjarEvent(HotjarEvents.ADD_INSTALLMENT_DISCOUNT)
    setSelectedDrawer('addDiscount')
  }

  const handleClickEditEnrollmentDueDate = () => {
    sendHotjarEvent(HotjarEvents.EDIT_ENROLLMENT_DUEDATE)
    setSelectedDrawer('contractEditEnrollmentDueDay')
  }

  const handleClickChangePaymentMethod = () => {
    setShowChangePaymentModal(true)
  }

  const handleClickSharePaymentLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendGuardianDetailsInvoiceEvent('button', {
      name: 'Copiar link de cobrança',
      receivable_id: urn,
      contract_id,
    })

    setToClipboard(invoice?.payment_link)

    setSnackbarIsOpen(true, {
      variation: 'success',
      title: 'Link copiado com sucesso',
      description: '',
    })
  }

  const handleClickPrintReceipt = async () => {
    sendGuardianDetailsInvoiceEvent('button', {
      name: 'Gerar comprovante',
      receivable_id: urn,
      contract_id: contract_id,
    })

    const response = await api.receivables.printReceipt(urn.replace('urn:receivable:', ''))
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleClickEditManualLiquidation = () => {
    onCloseDrawer()
    sendHotjarEvent(HotjarEvents.EDIT_MANUAL_LIQUIDATION)
    setPageToReturn()
    goToEditManualLiquidation()
  }

  const handleSuccessChangePaymentMethod = () => {
    setShowChangePaymentModal(false)
    setOnQueryParam('true', 'refetch')
    onCloseDrawer()
  }

  return {
    isDisabledManualLiquidation,
    isHiddenManualLiquidation,
    isDisabledRenegotiation,
    isHiddenRenegotiation,
    isDisabledAddDiscounts,
    isHiddenAddDiscounts,
    isHiddenEditDueDate,
    isDisabledChangePaymentMethod,
    isHiddenChangePaymentMethod,
    isHiddenEditManualLiquidation,
    setShowChangePaymentModal,
    handleSuccessChangePaymentMethod,
    handleClickManualLiquidation,
    handleClickRenegotiation,
    handleClickAddDiscounts,
    handleClickEditEnrollmentDueDate,
    handleClickChangePaymentMethod,
    handleClickSharePaymentLink,
    handleClickPrintReceipt,
    handleClickEditManualLiquidation,
  }
}
