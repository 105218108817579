import { FC } from 'react'

import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { CheckoutStatus, FormOfPayment } from 'src/shared/interfaces'
import { useAgreement } from 'src/escolas/contexts/agreementContext'

import AgreementConclusionStep from '../../steps/AgreementConclusionStep'
import Conclusion from '../../steps/Conclusion'
import { NegotiationData } from '../../EntryNegotiation/hooks/useEntryNegotiation'
import { CheckoutFailStep } from '../../steps/CheckoutFailStep'

const SelectConclusion: FC<StepComponentProps> = ({
  id,
  goBack,
  goToPayAgreementStep,
  isNegotiationDownPayment,
}) => {
  const { selectedFormOfPayment, checkoutData } = useAgreement()
  const isCheckoutFallbackEnabled = useUnleashFlag(UnleashFlags.BOT_1590_ENABLE_CHECKOUT_FALLBACK)

  const checkoutFailed = checkoutData?.status === CheckoutStatus.FAILURE_TO_CONNECT
  if (checkoutFailed && isCheckoutFallbackEnabled) {
    return <CheckoutFailStep id={id} goBack={goBack} onFinish={goBack} />
  }

  if (
    selectedFormOfPayment === FormOfPayment.POS ||
    selectedFormOfPayment === FormOfPayment.POS_V2
  ) {
    const conclusionInfo: NegotiationData = {
      receivableId: checkoutData?.receivables[0].id,
      amount: checkoutData?.amount,
      paymentMethod: checkoutData?.payment_methods[0],
      id: checkoutData?.id,
      installmentsNumberSelected: checkoutData?.installments_count,
    }
    return <Conclusion id={id} goBack={goBack} onFinish={goBack} conclusionInfo={conclusionInfo} />
  }

  return (
    <AgreementConclusionStep
      id={id}
      goToPayAgreementStep={goToPayAgreementStep}
      isNegotiationDownPayment={isNegotiationDownPayment}
    />
  )
}

export default SelectConclusion
