import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useLayout } from '@/shared/hooks/useLayout'
import { useJWT } from '@/shared/hooks'
import { useSchoolGroupManagementQuery } from './hooks/useSchoolGroupManagementQuery'
import { useSchoolManagementQuery } from './hooks/useSchoolManagementQuery'
import { useHasGroupManagerAccess } from '@/modules/app/hooks/useHasGroupManagerAccess'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { DASHBOARDS_TABS, MANAGEMENT_DASHBOARDS_TABS } from './constants'
import { ManagementDashboardsTab } from './types'

import * as Styled from './styles'
import { DashboardEmbedded } from './components/DashboardEmbedded'
import { CircularLoadingPlaceholder } from '@/modules/app/components/CircularLoadingPlaceholder'

export const ManagementDashboards = () => {
  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Painel de gestão',
  })

  const { hasPayoutReportAccess, isGroupManager } = useJWT()
  const { status, isLoading } = useHasGroupManagerAccess({ enabled: isGroupManager })
  const isUnitManagement = (!isGroupManager && hasPayoutReportAccess) || status === 'error'

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    data: dataGroupManagement,
    status: statusGroupManagement,
    refetch: refetchGroupManagement,
  } = useSchoolGroupManagementQuery()

  const {
    data: dataSchoolManagement,
    status: statusSchoolManagement,
    refetch: refetchSchoolManagement,
  } = useSchoolManagementQuery()

  const [activeTab, setActiveTab] = useState<MANAGEMENT_DASHBOARDS_TABS>(
    MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT
  )

  const sendTabEvent = (tabName: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.MANAGEMENT_DASHBOARD,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $tab_name: tabName,
        },
      })
  }

  const handleTabChange = (tab: ManagementDashboardsTab) => {
    setActiveTab(tab.value)
    sendTabEvent(tab.label)
  }

  if (isGroupManager && isLoading) {
    return <CircularLoadingPlaceholder />
  }

  if (isUnitManagement) {
    return (
      <DashboardEmbedded
        id="unit-management-dashboard"
        url={dataSchoolManagement?.url}
        status={statusSchoolManagement}
        refetch={refetchSchoolManagement}
        style={{ padding: '2.5rem 4rem 0' }}
        isVisible
      />
    )
  }

  return (
    <Styled.Container>
      <Styled.Tabs variant="scrollable" value={activeTab} aria-label="Escolha a visualização">
        {DASHBOARDS_TABS.map(tab => (
          <Styled.Tab
            key={tab.label}
            label={tab.label}
            value={tab.value}
            onClick={() => handleTabChange(tab)}
          />
        ))}
      </Styled.Tabs>

      <DashboardEmbedded
        id="unit-management-dashboard"
        url={dataSchoolManagement?.url}
        status={statusSchoolManagement}
        refetch={refetchSchoolManagement}
        isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT}
      />
      <DashboardEmbedded
        id="group-management-dashboard"
        url={dataGroupManagement?.url}
        status={statusGroupManagement}
        refetch={refetchGroupManagement}
        isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP}
      />
    </Styled.Container>
  )
}
