import { useGuardian } from 'src/escolas/hooks/useGuardian'
import { useParams, useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { PathParams } from 'src/shared/interfaces'
import { useSchool } from './useSchool'
import { useJWT } from '@/shared/hooks'

type NavigationParams = Partial<{
  path: string
  slug: string
}>

/**
 * @deprecated
 * - To get selected school data use useSelectedSchool
 * - prefer useHistory to redirects instead `navigateTo`, `hardNavigateTo` and `newTabNavigateTo`
 */
const useNavigation = () => {
  const history = useHistory()
  const { schoolSlug, guardianId, urn } = useParams<PathParams>()
  const { guardian } = useGuardian(guardianId)
  const location = useLocation()
  const { school } = useSchool()
  const { isAdmin } = useJWT()

  const schoolId = school?.id

  /**
   * @deprecated
   */
  const getNavigationUrl = ({ slug, path }: NavigationParams): url => {
    if (!slug) {
      // TROCANDO DE PATH NA MESMA ESCOLA
      return `/${schoolSlug}/${path}`.replace(/\/\//g, '/')
    }
    if (!path) {
      if (schoolSlug) {
        // MUDANDO CONTEXTO DE UMA ESCOLA PARA OUTRA

        // REGEX match do primeiro uuid pra frente
        // eslint-disable-next-line security/detect-unsafe-regex
        const regexUUID = /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}.*/

        // REGEX match tudo entre as duas primeiras /
        // eslint-disable-next-line security/detect-unsafe-regex
        const regexFirst2Slashes = /^\/(.*?)(\/.*)?$/

        return location.pathname.replace(regexFirst2Slashes, `/${slug}$2`).replace(regexUUID, '')
      } else {
        // MUDANDO DE UM CONTEXTO SEM ESCOLA PARA UM COM ESCOLA
        return `/${slug}`
      }
    } else {
      return `/${slug}/${path}`.replace(/\/\//g, '/')
    }
  }

  /**
   * @deprecated
   */
  const navigateTo = ({ slug, path }: NavigationParams) => {
    history.push(getNavigationUrl({ slug, path }))
  }

  /**
   * @deprecated
   */
  const newTabNavigateTo = ({ slug, path }: NavigationParams) => {
    if (isAdmin) {
      history.push(getNavigationUrl({ slug, path }))
      return
    }

    window.open(getNavigationUrl({ slug, path }), '_blank')
  }

  /**
   * @deprecated
   */
  const hardNavigateTo = ({ slug, path }: NavigationParams) => {
    window.location.href = getNavigationUrl({ slug, path })
    // HARD NAVIGATE ON PURPOSE TO FORCE FULL RELOAD DUE TO CHANGE OF CONTEXT
  }

  return {
    getNavigationUrl,
    navigateTo,
    hardNavigateTo,
    newTabNavigateTo,
    school,
    schoolId,
    schoolSlug,
    guardian,
    urn,
  }
}

export default useNavigation
