import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import { MenuItem, ListItemText } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'

import { useApi, useJWT } from '@/shared/hooks'
import { downloadFile } from '@/shared/utils'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import useInstallments from '@/escolas/hooks/useInstallments'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useContract } from '@/escolas/hooks'
import { ContractStatus } from '@/shared/interfaces'
import ChangeContractOwnershipButton from '@/escolas/pages/ChangeGuardian/components/ChangeContractOwnershipButton'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import CancelContractMenuItem from '../GuardianCard/CancelContractMenuItem'
import {
  getAgglutinatedInstallment,
  getDueTodayInstallment,
  getOpenInstallments,
  getOverdueInstallment,
  getPaidEnrollmentInstallment,
  getRenegotiatedInstallment,
  getTuitionInstallmentsPaid,
} from '../utils'
import { FailureFeedbackContent, FailureFeedbackDialog } from '../../modal/FeedbackCarneDialog'
import { PUBLIC_DOMAIN } from 'src/shared/api'
import { Loader } from '@/modules/account-reports/components/Loader'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

export type ContractCheckoutActionsProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  changeContractOwnership?: () => void
  clickPaidAmountStatement: (value: boolean) => void
  contractId: string
  hasInvoicesWithError?: boolean
  isPreContract?: boolean
  isReenrollable?: boolean
  openCancellationDrawer: () => void
  queryLoading: boolean
  setShowInvoicesNotGeneratedDialog: (status: boolean) => void
  showAnnualPaidAmountsStatement: boolean
}
export const ContractCheckoutActions = ({
  addContract,
  changeContractOwnership,
  clickPaidAmountStatement,
  contractId,
  hasInvoicesWithError,
  isPreContract,
  isReenrollable,
  openCancellationDrawer,
  queryLoading,
  setShowInvoicesNotGeneratedDialog,
  showAnnualPaidAmountsStatement,
}: ContractCheckoutActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { api } = useApi()
  const [isLoadingCarne, setIsLoadingCarne] = useState(false)
  const [carneErrorMessage, setCarneErrorMessage] = useState<string>('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const printContractURL = `${PUBLIC_DOMAIN}/contract/${contractId}/print-slips`
  const { school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const { processedInstallments } = useInstallments()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const cancelPreContractFeatureFlagValue = useUnleashFlag(
    UnleashFlags.ENGAJAMENTO_PAS392_CANCELLATION_PRECONTRACT
  )

  const isNewFormToExportCarneEnabled = useUnleashFlag(
    UnleashFlags.PEX_447_CHANGE_FORM_TO_EXPORT_CARNE_PI
  )

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const useUnifiedContractEditing = useUnleashFlag(UnleashFlags.ENABLE_UNIFIED_CONTRACT_EDITING)

  const { isAdmin } = useJWT()
  const { contract } = useContract()

  const isOpen = contract?.status === ContractStatus.OPEN
  const isPending = contract?.status === ContractStatus.PENDING

  const isCancelPreContractFeatureFlagEnabled = !isAdmin && cancelPreContractFeatureFlagValue

  /**
   * Caso o usuário não seja admin mas a escola tem permissão para cancelar contratos.
   */
  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  const showCancellationOption =
    isAdmin || isCancelContractEnabled || isCancelPreContractFeatureFlagEnabled

  const { sendHotjarEvent } = useHotjar()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const close = () => {
    setAnchorEl(null)
  }

  const getHandleClick = (callback: () => void) => () => {
    if (hasInvoicesWithError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      callback()
      close()
    }
  }

  const openInstallments = getOpenInstallments(processedInstallments)
  const hasInstallments = contract?.installments?.length > 0

  const tuitionInstallmentsPaid = getTuitionInstallmentsPaid(processedInstallments)
  const hasInstallmentsTuitionPaid = tuitionInstallmentsPaid?.length > 0

  const hasPaidEnrollmentInstallments = hasInstallments
    ? getPaidEnrollmentInstallment(processedInstallments)
    : false

  const hasOpenInstallments = openInstallments?.length > 0

  const hasRenegotiatedInstallment = Boolean(getRenegotiatedInstallment(processedInstallments))
  const hasAgglutinatedInstallment = Boolean(getAgglutinatedInstallment(processedInstallments))
  const hasOverdueInstallment = Boolean(getOverdueInstallment(processedInstallments))
  const hasDueTodayInstallment = Boolean(getDueTodayInstallment(processedInstallments))

  const hasDebt = hasRenegotiatedInstallment || hasAgglutinatedInstallment || hasOverdueInstallment

  const canChangeContractOwnership =
    hasOpenInstallments &&
    !hasDueTodayInstallment &&
    !hasDebt &&
    !isPreContract &&
    !isIntegratedSchool

  const canCancelPreContract =
    isPreContract && !hasPaidEnrollmentInstallments && isCancelPreContractFeatureFlagEnabled

  const canCancelPreContractPaid =
    !hasInstallmentsTuitionPaid &&
    hasPaidEnrollmentInstallments &&
    isCancelPreContractFeatureFlagEnabled

  const canCancelContract = !isPreContract && hasOpenInstallments && isCancelContractEnabled

  const canEnableCancellationOption =
    isAdmin || canCancelPreContract || canCancelContract || canCancelPreContractPaid

  const isQueryLoaded = isPreContract && !hasInstallments ? true : queryLoading

  useEffect(() => {
    if (carneErrorMessage !== '') {
      setShowErrorModal(true)
    }
  }, [carneErrorMessage])

  const useDownloadCarne = async (contractId: string) => {
    const carneFileDownload = async (contractId: string) => {
      await api.contracts.downloadCarne(contractId).then(async data => {
        if (data.status > 200) {
          const error = await data.data.text()
          setCarneErrorMessage(error)
        } else {
          downloadFile(`carne-${contractId}`, 'pdf', async () => await data.data)
        }
      })
    }

    try {
      setCarneErrorMessage('')
      await carneFileDownload(contractId)
    } finally {
      setIsLoadingCarne(false)
    }
  }

  const printCarne = () => {
    if (hasInvoicesWithError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      if (isInitialized)
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.BUTTON_CLICKED,
          scope: EventDispatcherEventScopes.CONTRACT_PAGE,
          action: 'click',
          entity: EventDispatcherEntities.PRINT_BANKSLIP,
          customProperties: {
            $name: 'Imprimir carnê',
            $contract_id: contractId,
          },
        })
      if (isNewFormToExportCarneEnabled) {
        setIsLoadingCarne(true)
        useDownloadCarne(contract?.id)
      } else {
        window.open(printContractURL)
      }
    }
  }

  const sendContractDrawerEvent = (button_name: string) => {
    if (isInitialized) {
      eventDispatcherClient
        .sendEvent({
          scope: EventDispatcherEventScopes.CONTRACT_DRAWER,
          name: EventDispatcherEvents.BUTTON_CLICK,
          action: 'click',
          customProperties: {
            $button_name: button_name,
            $contract_type: isPreContract ? 'NOT EFFECTED' : 'EFFECTED',
          },
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const sendGenerateStatementEvent = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_PAGE,
        action: 'click',
        entity: EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
        customProperties: {
          $name: 'Gerar demonstrativo de pagamento',
          $contract_id: contract?.id,
        },
      })
  }

  return (
    <>
      <FailureFeedbackDialog
        isVisible={showErrorModal}
        buttonLabel="Entendi"
        onClose={() => setShowErrorModal(false)}
        submitHandler={() => setShowErrorModal(false)}
        closeIcon
      >
        <FailureFeedbackContent err={carneErrorMessage} />
      </FailureFeedbackDialog>

      {isPending ? (
        showCancellationOption &&
        canEnableCancellationOption && (
          <Button
            disabled={!isQueryLoaded}
            onClick={getHandleClick(openCancellationDrawer)}
            variation="ghost"
          >
            Cancelar contrato
          </Button>
        )
      ) : (
        <>
          <Button
            data-testid="contract-actions-button"
            variation="ghost"
            aria-label="mais ações"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Outras opções
          </Button>
          <Menu
            id="menu-imprimir"
            data-testid="contract-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={close}
          >
            {showAnnualPaidAmountsStatement && (
              <MenuItem
                onClick={() => {
                  sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT_CHECKOUT)
                  clickPaidAmountStatement(true)
                  sendGenerateStatementEvent()
                }}
                data-testid="generate-statement-button"
              >
                Gerar demonstrativo de pagamento
              </MenuItem>
            )}

            <ChangeContractOwnershipButton
              canChangeContractOwnership={canChangeContractOwnership}
              changeContractOwnership={changeContractOwnership}
            />
            {isLoadingCarne ? (
              <Loader />
            ) : (
              useUnifiedContractEditing &&
              !isOutsourcedProvider && (
                <MenuItem onClick={() => printCarne()} component="a" target="blank">
                  <ListItemText>Imprimir carnê</ListItemText>
                </MenuItem>
              )
            )}
            {showCancellationOption && (
              <CancelContractMenuItem
                disabled={!isOpen || !canEnableCancellationOption || !isQueryLoaded}
                onClick={close}
                openCancellationDrawer={() => {
                  sendContractDrawerEvent('Cancelar contrato')
                  getHandleClick(openCancellationDrawer)()
                }}
              />
            )}
            {isReenrollable && <MenuItem onClick={() => addContract(true)}>Rematricular</MenuItem>}
          </Menu>
        </>
      )}
    </>
  )
}
