import { useApi } from '@/shared/hooks'
import { creditService } from '../../services'
import { useQuery } from '@tanstack/react-query'
import { GetOrderStatusResponse } from '../../services/types'

export const creditOrderStatusQueryKey = 'credit-order-status'

export const useGetOrderStatus = (schoolId: string) => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<GetOrderStatusResponse>([creditOrderStatusQueryKey, schoolId], async () => {
    return await service.getOrderStatus(schoolId)
  })
}
