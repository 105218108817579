import { CommonMetadata } from './metadata'
import { Installment } from './installment'
import { Invoice } from './invoice'
import { PaymentMethod } from './paymentMethod'
import { PaginationRequest } from '../hooks/queries/schools/types'

export type Discount = {
  amount: cents
  days_before_due_date: number
  description: string
  id?: uuid
  type?: string
}

export enum ReceivableStatuses {
  AGGLUTINATED = 'AGGLUTINATED',
  CANCELED = 'CANCELED',
  CHECKOUTED = 'CHECKOUTED',
  DUE_TODAY = 'DUE_TODAY',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PAID_AND_CANCELED = 'PAID_AND_CANCELED',
  RENEGOTIATED = 'RENEGOTIATED',
}

export type AddDiscountsReqBody = {
  discounts: Discount[]
  reason: string
  receivable_id: uuid
}

export type RenegotiationResponseBody = {
  created: Array<Receivable>
  renegotiated: Receivable
}

export type Receivable = CommonMetadata & {
  base_amount: number
  credit_card_fee?: number
  current_additional_discount: number
  current_amount: number
  current_discount: number
  current_due_payment_discount?: number
  current_early_payment_discount: number
  current_fine: number
  current_interest: number
  current_perpetual_discount: number
  description: string
  discounts: Discount[]
  due_date: datestring
  installment?: Installment
  installment_id: uuid
  invoice?: Invoice
  invoice_id: uuid
  lost_due_payment_discount?: number
  lost_early_payment_discount?: number
  original_amount: number
  original_receivables?: Receivable[]
  paid_date?: datestring
  payment_method?: PaymentMethod
  payment_source?: LiquidationSource
  status: ReceivableStatuses
  total_amount_paid?: number
  type: string
}

export type Negotiation = {
  amount: number
  due_date: datestring
}
export type NegotiationDiscount = {
  discount_amount?: cents
  due_date: datestring
}

export type Renegotiation = {
  channel: string
  from: uuid
  max_installments_value: number
  payment_methods: string[]
  to: Array<Negotiation>
}

export type Agglutination = {
  channel: string
  from: uuid[]
  payment_methods: string[]
  to: NegotiationDiscount
}

export enum LiquidationSource {
  ISAAC_ACCOUNT = 'ISAAC_ACCOUNT',
  IUGU = 'IUGU',
  PAGARME_CHECKOUT = 'PAGARME_CHECKOUT',
  SCHOOL_ACCOUNT = 'SCHOOL_ACCOUNT',
  STONE_CHECKOUT = 'STONE_CHECKOUT',
}

export enum LiquidationPaymentMethod {
  BANK_CHECK = 'BANK_CHECK',
  BANK_DEPOSIT = 'BANK_DEPOSIT',
  BANK_SLIP = 'BANK_SLIP',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  OTHER = 'OTHER',
  PIX = 'PIX',
  SCHOOL_PORTFOLIO_REPURCHASE = 'SCHOOL_PORTFOLIO_REPURCHASE',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum LiquidationPaymentMethodLabel {
  BANK_CHECK = 'Cheque',
  BANK_DEPOSIT = 'Depósito bancário',
  BANK_SLIP = 'Boleto bancário',
  BANK_TRANSFER = 'Transferência para escola',
  CASH = 'Dinheiro',
  CREDIT_CARD = 'Crédito na maquininha da escola',
  DEBIT_CARD = 'Débito na maquininha da escola',
  DIRECT_DEBIT = 'Recorrência - Débito automático',
  OTHER = 'Outro',
  PIX = 'Pix',
  SUBSCRIPTION = 'Recorrência - Crédito',
}
export enum LiquidationPaymentMethodAdminLabel {
  BANK_CHECK = 'Cheque',
  BANK_DEPOSIT = 'Depósito bancário',
  BANK_SLIP = 'Boleto bancário',
  BANK_TRANSFER = 'Transferência para escola',
  CASH = 'Dinheiro',
  CREDIT_CARD = 'Crédito na maquininha da escola',
  DEBIT_CARD = 'Débito na maquininha da escola',
  DIRECT_DEBIT = 'Recorrência - Débito automático',
  OTHER = 'Outro',
  PIX = 'Pix',
  SCHOOL_PORTFOLIO_REPURCHASE = 'Recompra de carteira',
  SUBSCRIPTION = 'Recorrência - Crédito',
}

export enum LiquidationPaymentMethodAdminLabelSourceAccoutIsaac {
  CREDIT_CARD = 'Crédito na maquininha isaac',
  DEBIT_CARD = 'Débito na maquininha isaac',
}

export enum DiscountType {
  ADDITIONAL_ON_MANUAL_LIQUIDATION = 'ADDITIONAL_ON_MANUAL_LIQUIDATION',
  DUE_PAYMENT = 'DUE_PAYMENT',
  EARLY_PAYMENT = 'EARLY_PAYMENT_DISCOUNT',
  KEPT_ON_MANUAL_LIQUIDATION = 'KEPT_ON_MANUAL_LIQUIDATION',
  PERPETUAL = 'PERPETUAL',
}

export type LiquidationPostParams = LiquidationGetParams & {
  edit_reason?: string
  payment_method: LiquidationPaymentMethod
  source: LiquidationSource
  triggered_by?: string
}

export type LiquidationInfoResponse = {
  final_amount: cents
  final_base_amount: cents
  final_discount: cents
  final_fine_and_interest: cents
  fine_and_interest: cents
  lost_due_payment_discount: cents
  lost_early_payment_discount: cents
  payment_method: LiquidationPaymentMethod
  previous_due_payment_discount: cents
  previous_early_payment_discount: cents
  previous_fines_and_interest: cents
}

export type LiquidationGetParams = {
  additional_discount_amount: cents
  apply_lost_due_payment_discount: boolean
  apply_lost_early_payment_discount: boolean
  is_edition?: boolean
  paid_date: datestring
  remove_fines_and_interest: boolean
  remove_fines_and_interest_change: boolean
}

export type ExternalPaymentParams = {
  end_date: datestring
  filter?: Partial<{ student_name: string }>
  pagination?: PaginationRequest
  schoolId: uuid
  start_date: datestring
}
