import styled from 'styled-components'

import theme from '@/shared/theme'

const { colors } = theme.primitiveTokens

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.gravity.spacing[4]};
`

export const Content = styled.div`
  text-align: right;
`

type AvatarProps = {
  variation: 'primary' | 'secondary'
}

export const Avatar = styled.button<AvatarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: ${props => (props.variation === 'primary' ? colors.gray[0] : colors.gray[80])};
  background-color: ${props =>
    props.variation === 'primary' ? colors.isaacBlue[60] : colors.isaacBlue[10]};
  border: 0;
  cursor: pointer;
`
