import { Box } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { Button, Typography } from '@olaisaac/design-system'

type ErrorStateProps = {
  onRetry: () => void
  title: string
}

/**
 * Error state for dashboard card component
 *
 * @param props
 * @param props.title Title
 * @param props.onRetry Callback function for retry button
 */
export const ErrorState = ({ title, onRetry }: ErrorStateProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      height="18.625rem"
      px="2rem"
      py="3rem"
      border="1px solid #DADADA"
      borderRadius="0.5rem"
      data-testid="error-state"
    >
      <Typography variation="headlineDesktopXsmall" color="primary" data-testid="title-element">
        {title}
      </Typography>

      <Typography variation="bodySmall" style={{ whiteSpace: 'pre-line' }}>
        Ocorreu um erro inesperado ao carregar as informações do seu repasse. {'\n'}Tente novamente
        mais tarde.
      </Typography>

      <Button startIcon={<Refresh />} onClick={onRetry}>
        Recarregar
      </Button>
    </Box>
  )
}
