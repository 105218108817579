import type { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import theme from '@/shared/theme'

import { Content } from './styles'

type InfoCardRootProps = {
  children: ReactNode
  isLoading: boolean
}

const BORDER_COLOR = theme.primitiveTokens.colors.gray[10]

export const InfoCardRoot = ({ children, isLoading }: InfoCardRootProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1rem"
      border={`1px solid ${BORDER_COLOR}`}
      borderRadius="0.5rem"
    >
      {isLoading ? (
        <Box p="1rem" data-testid="loading">
          <Skeleton
            variant="rect"
            width="8.875rem"
            height="2rem"
            style={{ borderRadius: '0.5rem', marginBottom: '1rem' }}
          />

          <Skeleton variant="rect" width="100%" height="1rem" style={{ borderRadius: '0.5rem' }} />
        </Box>
      ) : (
        <Content data-testid="content">{children}</Content>
      )}
    </Box>
  )
}
