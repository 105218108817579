import { Box } from '@material-ui/core'
import { Chip, Link, Typography } from '@olaisaac/design-system'

import { LoadingState } from './components/LoadingState'

import { formatCurrencyValue } from '@/shared/utils/numberFormatters'
import { formatDate } from '@/shared/utils/dateFormatters'

import theme from '@/shared/theme'

import { Amount } from './styles'
import { getPayoutAmountLabel } from '../../utils/getPayoutAmountLabel'

type PayoutReportSubHeaderProps = {
  isHourIncluded?: boolean
  isLoading: boolean
  isNewVersionPayout?: boolean
  isPayoutOpen: boolean
  linkButtonText: string
  onClickAtLinkButton: () => void
  payoutReportPeriod: {
    endDate?: Date
    startDate?: Date
  }
  payoutSubtitleLabel?: string
  payoutValue: number
}

const POSITIVE_VALUE_COLOR = theme.primitiveTokens.colors.green[90]
const NEGATIVE_VALUE_COLOR = theme.primitiveTokens.colors.coral[90]

/**
 * Component used to display payout report summary data on payout report pages
 *
 * @param props
 * @param props.payoutValue Payout value in decimal
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.isNewVersionPayout Indicates if payout is in new version
 * @param props.payoutReportPeriod Object containing start and end date for the payout
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.linkButtonText Label to be displayed at link button
 * @param props.onClickAtLinkButton Callback function triggered on click at link button
 * @param props.isHourIncluded Indicates if the date should include hour
 * @param props.payoutSubtitleLabel Payout label value for open and close instances
 */
export const PayoutReportSubHeader = ({
  payoutValue,
  isPayoutOpen,
  isNewVersionPayout = true,
  payoutReportPeriod,
  isLoading,
  linkButtonText,
  isHourIncluded,
  payoutSubtitleLabel,
  onClickAtLinkButton,
}: PayoutReportSubHeaderProps) => {
  const startDateTemplate = isHourIncluded ? 'D [de] MMMM[,] HH[h]mm' : 'D [de] MMMM'
  const endDateTemplate = isHourIncluded ? 'D [de] MMMM[,] HH[h]mm' : 'D [de] MMMM [de] YYYY'

  const formattedPayoutReportStartPeriodDate =
    payoutReportPeriod?.startDate && formatDate(payoutReportPeriod.startDate, startDateTemplate)

  const formattedPayoutReportEndPeriodDate =
    payoutReportPeriod?.endDate && formatDate(payoutReportPeriod.endDate, endDateTemplate)

  const valueLabel = payoutSubtitleLabel ?? getPayoutAmountLabel(payoutValue < 0, isPayoutOpen)

  if (isLoading) {
    return <LoadingState />
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Box display="flex" alignItems="center">
          {isPayoutOpen ? (
            <Chip variation="yellow" label="Aberto" />
          ) : (
            <Chip variation="green" label="Fechado" />
          )}

          <Box ml="0.5rem">
            <Typography variation="subtitleDesktopLarge" data-testid="payout-value-label">
              {valueLabel}
            </Typography>
          </Box>
        </Box>

        <Amount
          color={payoutValue >= 0 ? POSITIVE_VALUE_COLOR : NEGATIVE_VALUE_COLOR}
          data-testid="payout-report-value"
        >
          {formatCurrencyValue(payoutValue)}
        </Amount>
      </Box>

      {isNewVersionPayout && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          data-testid="right-content"
        >
          <Typography
            variation="bodyLarge"
            color="secondary"
            style={{ whiteSpace: 'pre-wrap', textAlign: 'right' }}
          >
            Período de atualizações:{'\n'}
            {formattedPayoutReportStartPeriodDate} até {formattedPayoutReportEndPeriodDate}
          </Typography>

          <Box mt="0.25rem">
            <Link onClick={onClickAtLinkButton}>{linkButtonText}</Link>
          </Box>
        </Box>
      )}
    </Box>
  )
}
