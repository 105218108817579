import type { RouteDefinition } from '@/modules/core/router'
import { EditContractPage } from './pages/EditContract'

export const routes: RouteDefinition[] = [
  {
    key: 'EDIT_CONTRACT',
    path: '/contratos/:contractId/editar',
    component: EditContractPage,
    exact: true,
  },
]
