import { Typography } from '@olaisaac/design-system'
import { Skeleton } from '@material-ui/lab'

import styled from 'styled-components'
import theme from 'src/shared/theme'

const { colors, spacing } = theme.primitiveTokens
const { color, variation } = theme.humanizedTokens.components.typography

export const Container = styled.div<{ backgroundColor?: string }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  grid-gap: ${spacing[8]};
  padding: ${spacing[8]} ${spacing[24]};
  width: 100%;
  border: 1px solid ${colors.gray[10]};
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || '#FFFFFF00'};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiButtonBase-root {
    width: fit-content;
  }
`

export const Title = styled.h2<{ color?: string }>`
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: 0;
  margin-bottom: ${spacing[4]};
  ${props => props.color && `color: ${props.color};`}
`

export const TitlePlaceholder = styled(Skeleton)`
  font-size: 1.5rem;
  line-height: 1.15;
  margin-bottom: ${spacing[4]};
`

export const Explanation = styled(Typography)<{ $color?: string }>`
  ${variation.bodyLarge};
  ${color.secondary}
  line-height: 1.5;
  margin-bottom: ${spacing[8]};
  ${props => props.$color && `color: ${props.$color};`};
`

export const ExplanationPlaceholder = styled(Skeleton)`
  font-size: ${variation.bodyLarge.fontSize};
  line-height: 1.5;
  margin-bottom: ${spacing[8]};
`
