import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Table } from '@gravity/table'
import { spacing } from '@gravity/tokens'

import { Contract, ErrorDialog } from '@/modules/guardians/GuardianDetails/types'
import { debtStatusVariant } from '@/modules/guardians/GuardianDetails/components/GuardianDetailsCard/components/DebtStatus/types'
import { DebtStatus } from '@/shared/interfaces'
import { GuardianDetailsContractsTableProps } from './types'
import { contractStatusDict } from '../ContractStatus/constants'
import { contractStatusDict as contractStatusBadge } from '../ContractStatusBadge/constants'
import { GuardianDetailsContractsTableActions } from '../GuardianDetailsContractsTableActions'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/FeedbackCarneDialog'
import * as Styled from './styles'

export const GuardianDetailsContractsNewTable = ({
  contracts,
  contractsData,
  isLoading,
  openContractDetails,
  openCancelContractDrawer,
  onChangeContractOwnership,
  onGeneratePaymentStatement,
}: GuardianDetailsContractsTableProps) => {
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>({ visible: false })

  const handleClickViewConditions = (contract: Contract) => {
    if (contract.debt_status !== DebtStatus.PENDING) openContractDetails(contract.id)
  }

  return (
    <Styled.Wrapper>
      <Table.Root style={{ marginBottom: spacing[16] }}>
        <Table.Head>
          <Table.HeaderCell name="contract" minWidth={80}>
            Ano
          </Table.HeaderCell>
          <Table.HeaderCell name="status" minWidth={80}>
            Status
          </Table.HeaderCell>
          <Table.HeaderCell name="student">Aluno</Table.HeaderCell>
          <Table.HeaderCell name="product" minWidth={140}>
            Produto
          </Table.HeaderCell>
          <Table.HeaderCell name="installments">Situação do contrato</Table.HeaderCell>
          <Table.HeaderCell name="total">Situação financeira</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <Table.Row>
              {Array.from({ length: 6 }).map(() => (
                <Table.LoadingCell key={uuidv4()} />
              ))}
            </Table.Row>
          ) : (
            contracts.map(contract => (
              <Styled.TableRow
                key={contract.id}
                onClick={() => handleClickViewConditions(contract)}
              >
                <Table.TextCell>{contract.reference_year}</Table.TextCell>
                <Table.TextCell>{contractStatusDict[contract.status]?.text}</Table.TextCell>
                <Table.TextCell>{contract.student.name}</Table.TextCell>
                <Table.TextCell>{contract.product.name}</Table.TextCell>
                <Table.TextCell>
                  {contractStatusBadge[contract.pre_contract_status]?.text}
                </Table.TextCell>
                <Table.BadgeCell
                  badgeColor={debtStatusVariant[contract.debt_status].color}
                  badgeVariant="soft"
                >
                  {debtStatusVariant[contract.debt_status].text}
                </Table.BadgeCell>
                <GuardianDetailsContractsTableActions
                  contract={contract}
                  contractData={contractsData.find(c => c.id === contract.id)}
                  openCancelContractDrawer={openCancelContractDrawer}
                  onChangeContractOwnership={onChangeContractOwnership}
                  onGeneratePaymentStatement={onGeneratePaymentStatement}
                  setErrorDialog={setErrorDialog}
                />
              </Styled.TableRow>
            ))
          )}
        </Table.Body>
      </Table.Root>
      <FailureFeedbackDialog
        isVisible={errorDialog.visible}
        buttonLabel="Entendi"
        onClose={() => setErrorDialog({ visible: false })}
        submitHandler={() => setErrorDialog({ visible: false })}
        closeIcon
      >
        <FailureFeedbackContent err={errorDialog.message} />
      </FailureFeedbackDialog>
    </Styled.Wrapper>
  )
}
