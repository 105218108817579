import styled from 'styled-components'

type AmountProps = {
  color: string
}

export const Amount = styled.p<AmountProps>`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.5rem;
  color: ${props => props.color};
`
