import { z } from 'zod'

export const createUserAccessSchema = z
  .object({
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    school: z.object({
      name: z.string(),
      id: z.string(),
    }),
    group: z.enum(['gestor', 'mantenedor', 'secretaria']),
    email: z.string().email('Formato de e-mail inválido.'),
  })
  .required()

export type CreateUserAccessForm = z.infer<typeof createUserAccessSchema>
