import type { AgreementSimulationCombined } from 'src/shared/interfaces'

type Receivable = {
  amount: number
  id: string
}

export const getReceivablesByAgreementSimulation = (agreements: AgreementSimulationCombined[]) => {
  const receivablesByAgreementSimulationId = new Map<string, Receivable[]>()

  const filteredAgreements = agreements.filter(agreement => agreement.agreement_simulation_id)

  filteredAgreements.forEach(agreement => {
    const { receivables, agreement_simulation_id } = agreement
    const receivable = receivables.map(({ amount, id }) => ({ amount, id }))
    receivablesByAgreementSimulationId.set(agreement_simulation_id, receivable)
  })

  return receivablesByAgreementSimulationId
}
