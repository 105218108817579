import { Link } from '@gravity/link'
import { dateToFormattedString } from './formatDate'

const getDataByCPCRFeatureFlag = (isCPCREnabled: boolean) => {
  return {
    creditSummaryCardTitle: (chosenOfferDate: string) => {
      if (isCPCREnabled) {
        const date = chosenOfferDate ?? new Date().toLocaleDateString('pt-BR', { timeZone: 'UTC' })
        return <>Resumo da oferta solicitada em {date}</>
      }
      return <>Resumo da oferta solicitada</>
    },
    subtitle: () => {
      if (isCPCREnabled) {
        return (
          <>
            As parcelas de crédito são condicionadas à duração do contrato vigente da escola com o
            isaac. Créditos que excedem a vigência atual só podem ser liberados mediante renovação
            ou extensão do contrato.
            <br />
            <br />
            Faça uma simulação e veja as possibilidades da sua oferta.
          </>
        )
      } else {
        return (
          <>
            As parcelas de crédito são condicionadas à duração do contrato vigente da escola com o
            isaac. Créditos que excedem a vigência atual só podem ser liberados mediante renovação
            ou extensão do contrato.
            <br />
            <br />
            Entre em contato com seu consultor para entender novas possibilidades.
          </>
        )
      }
    },
    description: () => {
      if (isCPCREnabled) {
        return (
          <>
            Leia:&nbsp;
            <Link
              rel="noreferrer"
              target="_blank"
              href="https://centraldeajuda.olaisaac.io/credito-isaac/entenda-como-funciona-o-parcelamento-de-credito-no-isaac"
            >
              Entenda como funciona o parcelamento de crédito no isaac
            </Link>
          </>
        )
      } else {
        return <>Novas condições das ofertas estão sujeitas a análise.</>
      }
    },
    keyTextChoosenOfferCard: () => {
      if (isCPCREnabled) {
        return <>Comece a pagar em</>
      } else {
        return <>Primeiro desconto</>
      }
    },
    firstDiscountDateChoosenOffercard: (firstDiscountDate: string) => {
      if (isCPCREnabled) {
        return dateToFormattedString(firstDiscountDate)
      } else {
        return firstDiscountDate
      }
    },

    commentTextChoosenOfferCard: () => {
      if (isCPCREnabled) {
        return (
          <>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta válidas para os próximos 7 dias a partir da data da solicitação.
          </>
        )
      } else {
        return (
          <>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta válidas para a data de hoje.
          </>
        )
      }
    },

    keyTextSummaryCard: () => {
      if (isCPCREnabled) {
        return <>Comece a pagar em</>
      } else {
        return <>Primeiro desconto</>
      }
    },

    firstDiscountDateSummaryCard: (firstDiscountDate: string) => {
      if (isCPCREnabled) {
        return dateToFormattedString(firstDiscountDate)
      } else {
        return firstDiscountDate
      }
    },

    commentTextSummaryCard: () => {
      if (isCPCREnabled) {
        return (
          <>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta simulada válidas para a data de hoje.
          </>
        )
      } else {
        return (
          <>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta válidas para a data de hoje.
          </>
        )
      }
    },
  }
}

export default getDataByCPCRFeatureFlag
