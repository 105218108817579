import { Status } from '@/escolas/services/enrollment/types'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Pagination } from '@olaisaac/design-system'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import LoadingProgress from '@/escolas/components/CircularProgress/CircularProgress'
import { useNavigation } from '@/escolas/hooks'
import { useContractsByStudents } from '@/escolas/hooks/queries/contracts'
import { usePagination } from '@/shared/hooks/usePagination'
import { Contract, InstallmentType } from '@/shared/interfaces'
import { ErrorDialog } from '../../components/ErrorDialog'
import { SearchWithDebounce } from '../../components/SearchWithDebounce'
import { useRefreshSituation } from '../../hooks/useRefreshSituation'
import { ContractsTable } from './components/ContractsTable'
import { EnrollButton } from './components/EnrollButton'
import { GuardianInfo } from './components/GuardianInfo'
import { StatusBanner } from './components/StatusBanner'
import { StudentInfo } from './components/StudentInfo'
import { paginateInMemory } from './utils'
import { PageBreadcrumbs } from './components/PageBreadcrumbs'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { BreadcrumbsProvider } from '@/escolas/contexts/BreadcrumbsContext'
import { PageWrapper } from '@/escolas/components/PageWrapper'

import { useGetStudentEnrollmentCycle } from '../../hooks/queries/useGetStudentEnrollmentCycle'
import { useSelectCycle } from './hooks/useSelectCycle'
import { useGetMatriculaContracts } from './hooks/useGetMatriculaContracts'
import * as Styled from './styles'

const NO_YEAR_SELECTED_OPTION = 'Todos'

export const StudentEnrollment = () => {
  const [productName, setProductName] = useState('')
  const [referenceYear, setReferenceYear] = useState(NO_YEAR_SELECTED_OPTION)
  const { schoolId } = useNavigation()
  const { studentId } = useParams<{ studentId: string }>()
  const { pagination, updatePaginationValue } = usePagination()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const studentEnrollmentCycleQuery = useGetStudentEnrollmentCycle({ schoolId, studentId })
  const studentData = studentEnrollmentCycleQuery.data?.data
  const enabledCycles = studentData?.enabled_enrollment_cycles ?? []
  const studentEnrollmentData = studentEnrollmentCycleQuery.data?.data
  const hasEnrollmentCycleEnabled = studentEnrollmentData?.enabled_enrollment_cycles.length > 0

  const { selectedCycle, setSelectedCycleYear, shouldShowSelectCycleYearDropdown } = useSelectCycle(
    enabledCycles
  )

  const pageTitle = studentData ? `${studentData.student_name} - isaac` : 'Matrículas - isaac'
  useSetPageTitle(pageTitle)

  const matriculaContractsQuery = useGetMatriculaContracts(studentId, schoolId)
  const matriculaContracts = matriculaContractsQuery.data

  const contractsByStudentsQuery = useContractsByStudents({
    schoolId,
    filter: {
      studentId: studentId,
    },
    pagination: {
      page: 1,
      per_page: 100,
    },
  })
  const contractsByStudents = contractsByStudentsQuery.data?.data ?? []

  const refreshSituation = useRefreshSituation(() => contractsByStudentsQuery.refetch())
  const handleRefresh = () =>
    refreshSituation(
      schoolId,
      studentData?.student_id,
      selectedCycle?.reference_year,
      selectedCycle?.student_status,
      studentData?.student_name
    )

  const contractsFiltered = [...contractsByStudents, ...matriculaContracts]
    .filter(
      i => i.product.name.toLowerCase().includes(productName.toLowerCase()) || productName === ''
    )
    .filter(i => i.reference_year === referenceYear || referenceYear === NO_YEAR_SELECTED_OPTION)

  const isPreContractMap = matriculaContracts.reduce((result, curr) => {
    const contract = curr as Contract
    const installmentsOpen = contract.pre_contract_installments.filter(
      c => c.status === Status.OPEN && c.type === InstallmentType.TUITION
    )

    return {
      ...result,
      [curr.id]: installmentsOpen.length === 0,
    }
  }, {})

  const availableYears = Array.from(new Set(contractsFiltered.map(c => c.reference_year))).sort()

  const isLoadingContractsData =
    contractsByStudentsQuery.isLoading || matriculaContractsQuery.isLoading

  if (
    studentEnrollmentCycleQuery.isError ||
    contractsByStudentsQuery.isError ||
    matriculaContractsQuery.isError
  ) {
    return <ErrorDialog isError />
  }

  if (
    studentEnrollmentCycleQuery.isLoading ||
    contractsByStudentsQuery.isLoading ||
    matriculaContractsQuery.isLoading
  ) {
    return <LoadingProgress />
  }

  return (
    <BreadcrumbsProvider>
      <PageWrapper>
        <Styled.PageContainer>
          <PageBreadcrumbs studentName={studentData?.student_name} />
          <Styled.InfoContainer>
            <StudentInfo studentName={studentData?.student_name} />
            <GuardianInfo
              mainGuardian={studentData?.main_guardian}
              otherGuardians={studentData?.other_guardians}
              studentName={studentData?.student_name}
            />

            {selectedCycle && (
              <EnrollButton
                enrollmentYear={selectedCycle.reference_year}
                isReEnrollable={selectedCycle.is_reenrollable}
                enrollmentStatus={selectedCycle.student_status}
                startedEnrollment={selectedCycle.started_enrollment}
                studentId={studentId}
                studentName={studentData.student_name}
              />
            )}
          </Styled.InfoContainer>
          <StatusBanner
            mainGuardianId={studentData?.main_guardian?.id}
            studentId={studentId}
            data={selectedCycle}
            hasEnrollmentCycleEnabled={hasEnrollmentCycleEnabled}
            onClickRefresh={handleRefresh}
            renderCycleSelector={
              shouldShowSelectCycleYearDropdown && (
                <Styled.SelectYear
                  IconComponent={props => <KeyboardArrowDown {...props} />}
                  value={selectedCycle.reference_year}
                  onChange={evt => setSelectedCycleYear(Number(evt.target.value))}
                >
                  {enabledCycles.map(cycle => (
                    <MenuItem key={cycle.reference_year} value={cycle.reference_year}>
                      {`Ano letivo ${cycle.reference_year}`}
                    </MenuItem>
                  ))}
                </Styled.SelectYear>
              )
            }
          />
          <Styled.TableFiltersContainer>
            <Styled.SearchContainer>
              <SearchWithDebounce
                value={productName}
                setValue={setProductName}
                placeholder="Pesquisar por produto"
              />
            </Styled.SearchContainer>
            <FormControl style={{ width: 265 }} variant="outlined">
              <InputLabel id="selectYearLabel">Período letivo</InputLabel>
              <Select
                labelId="selectYearLabel"
                id="selectYearLabel"
                value={referenceYear}
                onChange={e => setReferenceYear(e.target.value.toString())}
                label={`Turmas/Cursos ${referenceYear}`}
              >
                {[...availableYears, NO_YEAR_SELECTED_OPTION].map(year => (
                  <MenuItem key={year} value={year}>
                    {year || 'Todos'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Styled.TableFiltersContainer>
          <ContractsTable
            data={paginateInMemory(contractsFiltered, pagination)}
            isLoading={isLoadingContractsData}
            isPreContractMap={isPreContractMap}
            enrollmentStatus={selectedCycle?.student_status}
          />
          <Box my={2} px={2}>
            <Pagination
              currentPage={pagination.page}
              itensPerPage={pagination.itemsPerPage}
              itensPerPageOptions={[10, 15, 25, 50]}
              totalItens={isLoadingContractsData ? 0 : contractsFiltered.length}
              onPageChange={newPage => {
                updatePaginationValue('page', newPage)
                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
                    name: EnrollmentEventDispatcherEvents.CLICKED,
                    action: 'click',
                    customProperties: {
                      $Button_name: 'NEXT_PAGE',
                      $table_name: 'LISTA_DE_PRODUTOS',
                    },
                  })
              }}
              onItensPerChangeChange={newItensPerPage => {
                updatePaginationValue('itemsPerPage', newItensPerPage)
              }}
            />
          </Box>
        </Styled.PageContainer>
      </PageWrapper>
    </BreadcrumbsProvider>
  )
}
