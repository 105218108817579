import styled from 'styled-components'
import theme from 'src/shared/theme'

const { spacing } = theme.primitiveTokens
const { color } = theme.humanizedTokens.components.typography

export const CssStyles = styled.div`
  --swiper-theme-color: ${color.accent.color};
  --swiper-navigation-size: 16px;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-horizontal-gap: ${spacing[1]};

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      content: '';
    }

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  .swiper-pagination-bullets {
    height: 16px;
  }
`

export type SliderContainerProps = {
  $hasPadding: boolean
}

export const SlideContainer = styled.div<SliderContainerProps>`
  padding: ${props => (props.$hasPadding ? `0 ${spacing[6]} ${spacing[4]} ${spacing[6]}` : 0)};
  margin-bottom: ${spacing[8]};
`
