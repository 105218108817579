import { Card } from '@olaisaac/design-system'
import { useEffect, useRef, useState } from 'react'
import {
  ColumnContainer,
  CommentText,
  ContainerCard,
  KeyText,
  RowContainer,
  SmallValueText,
  StyledSelect,
  StyledSkeleton,
  ValueText,
} from './styles'
import { CreditSummaryProps } from './types'
import { Option } from '@gravity/select'
import { Skeleton } from '@gravity/skeleton'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { SeparatorLine } from '../SeparatorLine'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import getDataByCPCRFeatureFlag from '../../utils/featureFlag'
import { Callout } from '@gravity/callout'
import { CreditType } from '../../services/types'

import { useReasonForChosenPolicy } from '../../hooks/useReasonForChosenPolicy'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'

const CreditSummaryCard = ({
  creditValue,
  offers,
  chosenOffer,
  setChosenOffer,
  reasonForChosenPolicyType,
}: CreditSummaryProps) => {
  const isEnabledCPCRWithFF = useUnleashFlag(UnleashFlags.CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE)
  const { getCalloutMessage } = useReasonForChosenPolicy()
  /**
   * //TODO: remove condition and filter after handing in the cpcr
   */
  if (!isEnabledCPCRWithFF) {
    offers = offers.filter(o => o.category === CreditType.CP)
  }

  const dropdownOptions: Option[] = offers.map(offer => ({
    label: `${
      offer.totalInstallments === 1 ? '1 parcela' : `${offer.totalInstallments} parcelas`
    } de ${offer.installmentValue}`,
    value: offer.totalInstallments.toString(),
  }))

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const getIndexInitializeOption = () => {
    const primaryIndexCP = offers.findIndex(e => e.category === CreditType.CP)
    const optionSelected = dropdownOptions[primaryIndexCP > 0 ? primaryIndexCP : 0]
    return optionSelected
  }

  const [installment, setInstallment] = useState<Option>(getIndexInitializeOption())
  const [showSkeleton, setShowSkeleton] = useState(false)
  const isCPCREnabled = useUnleashFlag(UnleashFlags.CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE)
  const { keyTextSummaryCard, commentTextSummaryCard } = getDataByCPCRFeatureFlag(isCPCREnabled)
  const isCategoryCPCR = useRef(false)

  useEffect(() => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
    const offer = offers.find(e => e.totalInstallments.toString() === installment.value)
    const isOfferCPCR = offer.category === CreditType.CPCR
    setChosenOffer(offer)
    isCategoryCPCR.current = isOfferCPCR
  }, [installment])

  const handleDropdownValueChange = (value: string) => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.NUMBER_OF_INSTALLMENTS,
    })
    setInstallment(dropdownOptions.find(e => e.value === value))
  }

  const enabledCallout = isCategoryCPCR.current && isEnabledCPCRWithFF

  return (
    <RowContainer>
      <Card>
        <ContainerCard>
          <KeyText>Valor simulado</KeyText>
          <ValueText>{creditValue}</ValueText>

          <SeparatorLine />

          <RowContainer>
            <KeyText>Número de parcelas</KeyText>
          </RowContainer>

          <StyledSelect
            $fullWith={isEnabledCPCRWithFF}
            fullWidth={isEnabledCPCRWithFF}
            size={3}
            variant="surface"
            options={dropdownOptions}
            value={installment.value}
            onValueChange={value => handleDropdownValueChange(value)}
          />

          {enabledCallout && (
            <Callout
              text={getCalloutMessage(reasonForChosenPolicyType)}
              href="https://centraldeajuda.olaisaac.io/credito-isaac/entenda-como-funciona-o-parcelamento-de-credito-no-isaac"
              color="gray"
            />
          )}

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              <KeyText>Forma de pagamento</KeyText>
              <SmallValueText>Desconto no repasse</SmallValueText>
            </ColumnContainer>
            <ColumnContainer>
              <KeyText>{keyTextSummaryCard()}</KeyText>
              <SmallValueText>{chosenOffer.firstDiscountDate}</SmallValueText>
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              {showSkeleton ? (
                <>
                  <StyledSkeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </>
              ) : (
                <>
                  <KeyText>Taxa de juros mensal</KeyText>
                  <SmallValueText>{chosenOffer.monthlyFee + '% a.m'} </SmallValueText>
                </>
              )}
            </ColumnContainer>
            <ColumnContainer>
              {showSkeleton ? (
                <>
                  <StyledSkeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </>
              ) : (
                <>
                  <KeyText>Juros e IOF anuais (CET)</KeyText>
                  <SmallValueText>{chosenOffer.annualFee + '% a.a'} </SmallValueText>
                </>
              )}
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          {showSkeleton ? (
            <>
              <StyledSkeleton radius={8} fullWidth />
              <StyledSkeleton radius={8} fullWidth />
            </>
          ) : (
            <>
              <KeyText>Total a pagar</KeyText>
              <SmallValueText>{chosenOffer.totalAmount}</SmallValueText>
            </>
          )}

          <CommentText>{commentTextSummaryCard()}</CommentText>
        </ContainerCard>
      </Card>
    </RowContainer>
  )
}

export default CreditSummaryCard
