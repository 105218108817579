import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import * as Styled from './styles'

import { StatusBadge } from '../StatusBadge'
import { StatusDetailsBadge } from '../StatusDetailsBadge'
import { Tooltip } from '@material-ui/core'
import { SyncRounded } from '@material-ui/icons'
import { TooltipButton, IconButton } from '@olaisaac/design-system'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { ReenrollmentStatus } from '@/shared/interfaces'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export const showRefreshButton = (status: ReenrollmentStatus): boolean => {
  return status !== ReenrollmentStatus.ENROLLED
}

export const Situation = ({ status, details, refreshSituation, enrollmentCycle }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleRefresh = async () => {
    setIsLoading(true)

    try {
      await refreshSituation()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Styled.SituationContainer>
      <Styled.StatusContainer>
        <StatusBadge status={status} />
        {status === ReenrollmentStatus.GATE_ERROR && (
          <Tooltip title="Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde.">
            <TooltipButton>
              <InfoOutlinedIcon color="action" fontSize="small" />
            </TooltipButton>
          </Tooltip>
        )}

        {showRefreshButton(status) && (
          <Tooltip title="Utilize caso tenha recebido algum pagamento que pode alterar a situação de matrícula do aluno. Para pagamentos em boleto, o tempo de compensação pode ser até 3 dias.">
            <IconButton
              size="small"
              variation="ghost"
              loading={isLoading}
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation()
                handleRefresh()
                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                    name: EventDispatcherEvents.BUTTON_CLICKED,
                    action: 'click',
                    customProperties: {
                      $Button_name: 'ATUALIZAR_SITUAÇÃO',
                      $table_name: 'LISTA_DE_ALUNOS',
                      $value: status,
                    },
                  })
              }}
            >
              <SyncRounded />
            </IconButton>
          </Tooltip>
        )}
      </Styled.StatusContainer>
      {status !== ReenrollmentStatus.GATE_ERROR && (
        <StatusDetailsBadge status={status} details={details} currentYear={enrollmentCycle} />
      )}
    </Styled.SituationContainer>
  )
}
