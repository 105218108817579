import { z } from 'zod'

export const updateUserAccessSchema = z.object({
  userId: z.string(),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  school: z.object({
    name: z.string(),
    id: z.string(),
  }),
  group: z.enum(['gestor', 'mantenedor', 'secretaria']),
  email: z.string(),
})

export type UpdateUserAccessForm = z.infer<typeof updateUserAccessSchema>
