import { RouteDefinition } from '@/modules/core/router'

import { NotificationsBoard } from '../pages/NotificationsBoard'

export const routes: RouteDefinition[] = [
  {
    key: 'QUADRO_DE_AVISOS',
    path: '/avisos',
    component: NotificationsBoard,
    exact: true,
  },
]
