import { useRef } from 'react'

// Import Swiper React components
import { Navigation, Pagination, Autoplay, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'

import type { CarouselProps } from './types'

import { CssStyles, SlideContainer } from './styles'

const AUTOPLAY_DELAY = 5000

export const Carousel = (props: CarouselProps) => {
  const { items } = props

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  return (
    <CssStyles>
      <Swiper
        modules={[A11y, Navigation, Pagination, Autoplay]}
        onInit={swiper => {
          if (typeof swiper.params.navigation !== 'boolean') {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current

            swiper.navigation.update()
          }
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: AUTOPLAY_DELAY,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <SlideContainer $hasPadding={items.length > 1}>{item}</SlideContainer>
          </SwiperSlide>
        ))}
        <div
          className="swiper-button-prev"
          ref={prevRef}
          role="button"
          tabIndex={0}
          aria-label="Slide anterior"
        >
          <ArrowBackIosRoundedIcon />
        </div>
        <div
          className="swiper-button-next"
          ref={nextRef}
          role="button"
          tabIndex={0}
          aria-label="Próximo slide"
        >
          <ArrowForwardIosRoundedIcon />
        </div>
      </Swiper>
    </CssStyles>
  )
}
