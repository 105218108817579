import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'

import { NotificationsActive as NotificationsActiveIcon } from '@material-ui/icons'

import { MenuHeader } from './MenuHeader'
import { NotificationCard, NotificationCardPlaceholder } from '../NotificationCard'
import { MenuFooter } from './MenuFooter'
import { NotificationError } from '../NotificationError'

import { useNavigation } from '@/escolas/hooks'
import { useJWT } from '@/shared/hooks'
import { getSegmentFilters } from '@/shared/hooks/useBeamer'
import { useMenuPopover } from './useMenuPopover'
import { useLoadNotifications } from './useLoadNotifications'
import { useTrackNotificationMenuEvents } from './useTrackNotificationMenuEvents'

import type { NotificationResponse } from '../../services/types'

import { Menu, MenuItem, colors, spacing } from './styles'

const MAX_NOTIFICATIONS_COUNT = 3

export const NotificationMenu = () => {
  const history = useHistory()
  const { schoolSlug } = useNavigation()

  const jwtResponse = useJWT()
  const { isOpen, anchorEl, handleOpen, handleClose: onClose } = useMenuPopover()
  const { data, isLoading, isError, refetch } = useLoadNotifications(
    {
      userId: jwtResponse.userId,
      filter: getSegmentFilters(jwtResponse),
      pagination: {
        count: MAX_NOTIFICATIONS_COUNT,
        page: 0,
      },
    },
    isOpen
  )
  const isNotificationsRoute = window.location.pathname.includes('/avisos')

  const {
    trackModalButtonClick,
    trackModalClose,
    trackTitleLinkClick,
    trackFooterLinkClick,
    trackActionButtonClick,
  } = useTrackNotificationMenuEvents()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isNotificationsRoute) {
      return
    }

    trackModalButtonClick()
    handleOpen(event)
  }

  const handleClose = () => {
    onClose()
    trackModalClose()
  }

  const handleActionButtonClick = (notifictionTitle: string) => {
    onClose()
    trackActionButtonClick(notifictionTitle)
  }

  const handleActionLinkClick = (notification: NotificationResponse) => {
    onClose()
    trackTitleLinkClick(notification)
  }

  const handleFooterLinkClick = () => {
    onClose()
    history.push(`/${schoolSlug}/avisos`)
    trackFooterLinkClick()
  }

  return (
    <>
      {isNotificationsRoute ? (
        <Box display="flex" alignItems="center" height={spacing[10]} paddingX={spacing[2]}>
          <NotificationsActiveIcon width="24" htmlColor={colors.isaacBlue[60]} />
        </Box>
      ) : (
        <Button
          id="beamerButton"
          variation="ghost"
          onClick={handleClick}
          data-beamer-click="false"
          aria-label="Visualizar menu de avisos"
          disabled={isNotificationsRoute}
        >
          <NotificationsActiveIcon />
        </Button>
      )}
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 32,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuHeader onClose={handleClose} />

        {isError && <NotificationError onRetry={refetch} />}

        <ul>
          {isLoading &&
            [1, 2].map(value => (
              <MenuItem key={value}>
                <NotificationCardPlaceholder />
              </MenuItem>
            ))}

          {!isLoading &&
            !isError &&
            data.notifications?.map(notification => (
              <MenuItem key={notification.title}>
                <NotificationCard
                  path={`/${schoolSlug}/avisos?id=${notification.id}`}
                  onActionButtonClick={handleActionButtonClick}
                  onTitleLinkClick={handleActionLinkClick}
                  notification={notification}
                />
              </MenuItem>
            ))}
        </ul>

        <MenuFooter
          isError={isError}
          isLoading={isLoading}
          notificationsCount={data.remainingNotificationsCount}
          onClick={handleFooterLinkClick}
        />
      </Menu>
    </>
  )
}
