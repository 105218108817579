import { useRouteMatch } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { Section, SectionPlaceholder } from '../Section'
import { NumericInfo, NumericInfoPlaceholder } from '../NumericInfo'
import { Subsection, SubsectionPlaceholder } from '../Subsection'
import { Error } from '../Error'

import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import { useLoadFinancialInfo } from './hooks/useLoadFinancialInfo'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import { ErrorContainer } from './styles'

export const MainInfoSection = () => {
  const { url } = useRouteMatch()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    info: financialInfo,
    isError: isErrorFinancial,
    isLoading: isLoadingFinancial,
    refetch: refetchFinancialInfo,
  } = useLoadFinancialInfo(school?.id, !!school?.id)

  const handleRetry = () => {
    refetchFinancialInfo()
  }

  const handleClickInfo = (buttonName: string) => {
    if (buttonName && isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.HOME,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $button: buttonName },
      })
    }
  }

  if (isLoadingFinancial) {
    return (
      <SectionPlaceholder variation="secondary">
        <SubsectionPlaceholder>
          {[3, 4, 5].map(item => (
            <li key={item}>
              <NumericInfoPlaceholder />
            </li>
          ))}
        </SubsectionPlaceholder>
      </SectionPlaceholder>
    )
  }

  if (isErrorFinancial) {
    return (
      <Section variation="secondary">
        <ErrorContainer>
          <Error
            title="Não foi possível carregar suas principais informações"
            onRetry={handleRetry}
          />
        </ErrorContainer>
      </Section>
    )
  }

  const infos = Object.values(financialInfo).filter(info => Number.isFinite(info.value))

  return (
    <Section
      title="Principais informações da escola"
      description="Um resumo dos números mais importantes que você precisa saber"
      variation="secondary"
    >
      <Subsection title="Financeiro" icon={<AttachMoneyIcon />} columnsCount={infos.length}>
        {infos.map(info => (
          <li key={`${info.path}`}>
            <NumericInfo
              href={`${url}/${info.path}`}
              title={info.title}
              value={info.value}
              isCurrency
              onClick={() => handleClickInfo(info.title)}
            />
          </li>
        ))}
      </Subsection>
    </Section>
  )
}
