import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { WeekPicker } from '@/escolas/components/WeekPicker'
import { DatePicker } from '@/escolas/components/DatePicker'
import { PayoutReportPageHeader } from '@/modules/report/components/PayoutReportPageHeader'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'

import { enrollmentPayoutReportDataMapper } from '../../../PayoutReportDashboard/hooks/usePayoutReportDashboardData/utils/enrollmentPayoutReportDataMapper'

import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { TileDisabledFunctionArgs } from '@olaisaac/design-system'

type EnrollmentPayoutReportHeaderProps = {
  enrollmentPayoutReportList: UnguaranteedReport[]
  isLoadingEnrollmentPayoutReportList: boolean
  isOnboardingDrawerOpen: boolean
  onChangeEnrollmentPayout: () => void
  onRequestOnboarding: () => void
}

/**
 * Specialized header component for enrollment payout report page
 *
 * @param props
 * @param props.enrollmentPayoutReportList List of payout reports
 * @param props.isLoadingEnrollmentPayoutReportList Indicates if loading state should be displayed for the picker
 * @param props.isOnboardingDrawerOpen Indicates if onboarding drawer is open
 * @param props.onRequestOnboarding Callback function called on request drawer open
 * @param props.onChangeEnrollmentPayout Callback function called on change payout
 */
export const EnrollmentPayoutReportHeader = ({
  enrollmentPayoutReportList,
  isLoadingEnrollmentPayoutReportList,
  isOnboardingDrawerOpen,
  onRequestOnboarding,
  onChangeEnrollmentPayout,
}: EnrollmentPayoutReportHeaderProps) => {
  const { query, setOnQueryParam, setMultipleQueryParams } = useQuery()
  const { school } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [selectedEnrollmentPayoutReportId, setSelectedEnrollmentPayoutReportId] = useState<string>()

  const urlPayoutId = query.get('id')

  useEffect(() => {
    if (enrollmentPayoutReportList.length === 0) return

    let currentPayout = enrollmentPayoutReportList.find(item => item.payout_id === urlPayoutId)

    if (!currentPayout) {
      currentPayout = enrollmentPayoutReportList[enrollmentPayoutReportList.length - 1]
    }

    setSelectedEnrollmentPayoutReportId(currentPayout.payout_id)

    if (urlPayoutId !== currentPayout.payout_id) {
      setOnQueryParam(currentPayout.payout_id, 'id', 'replace')
    }
  }, [urlPayoutId, enrollmentPayoutReportList])

  const findEnrollmentPayoutByDate = (date: Date) => {
    return enrollmentPayoutReportList.find(
      item => dayjs(item.ended_at).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    )
  }

  const handleChangeEnrollmentPayoutReportById = (id: string) => {
    const newCurrentEnrollmentPayoutReport = enrollmentPayoutReportList.find(
      item => item.payout_id === id
    )

    if (!newCurrentEnrollmentPayoutReport) return

    setMultipleQueryParams(
      [
        { name: 'id', value: newCurrentEnrollmentPayoutReport.payout_id },
        { name: 'events' },
        { name: 'amount_signs' },
        { name: 'products' },
        { name: 'reference_years' },
      ],
      'replace'
    )
    onChangeEnrollmentPayout()

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DATE_CHANGE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
        },
      })
  }

  const handleChangeEnrollmentPayoutReportByDate = (date: Date) => {
    const payout = findEnrollmentPayoutByDate(date)
    if (!payout) return
    const id = payout.payout_id
    handleChangeEnrollmentPayoutReportById(id)
  }

  const checkIfDateShouldBeDisabled = ({ view, date }: TileDisabledFunctionArgs) => {
    if (view !== 'month') return false

    const payout = findEnrollmentPayoutByDate(date)

    return !payout
  }

  const options = enrollmentPayoutReportList.map(item => enrollmentPayoutReportDataMapper(item))

  const currentOption =
    options.find(item => item.id === selectedEnrollmentPayoutReportId) ??
    options[options.length - 1]

  const enrollmentMinDate = enrollmentPayoutReportList[0]?.started_at

  const enrollmentMaxDate =
    enrollmentPayoutReportList[enrollmentPayoutReportList.length - 1]?.ended_at

  return (
    <PayoutReportPageHeader
      title="Repasse de matrículas"
      picker={
        isIsaacPay ? (
          <DatePicker
            valueTemplate="DD [de] MMMM [de] YYYY"
            variation="ghost"
            maxDetail="month"
            minDetail="decade"
            minDate={enrollmentMinDate}
            maxDate={enrollmentMaxDate}
            onChange={handleChangeEnrollmentPayoutReportByDate}
            tileDisabled={checkIfDateShouldBeDisabled}
            value={dayjs(currentOption?.ended_at).toDate()}
          />
        ) : (
          <WeekPicker<{ ended_at: Date; id: string; started_at: Date }>
            variation="ghost"
            keyExtractor={item => item.id}
            maxDetail="year"
            minDate={enrollmentMinDate}
            maxDate={enrollmentMaxDate}
            value={currentOption}
            options={options}
            onChange={({ id }) => handleChangeEnrollmentPayoutReportById(id)}
          />
        )
      }
      backButtonLink={`/${school?.slug}/repasses`}
      isLoading={isLoadingEnrollmentPayoutReportList}
      isOnboardingDrawerOpen={isOnboardingDrawerOpen}
      onRequestOnboarding={() => {
        onRequestOnboarding()

        isInitialized &&
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
            name: EventDispatcherEvents.ENROLLMENT_PAYOUT_HELP,
            action: 'click',
            customProperties: {
              $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
            },
          })
      }}
    />
  )
}
