import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Contract, ContractStatus, PreContractStatuses } from 'src/shared/interfaces'
import { Button, ButtonDocker } from '@olaisaac/design-system'
import { PUBLIC_DOMAIN } from 'src/shared/api'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { ContractCheckoutActions } from '../ContractActionsCheckout/ContractActionsCheckout'
import { useNavigation } from 'src/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { MATRICULAS_API_FIRST_YEAR } from '../constants'
import { Box } from '@material-ui/core'
import theme from '@/shared/theme'
import { downloadFile } from '@/shared/utils'
import { useApi } from '@/shared/hooks'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { FailureFeedbackContent, FailureFeedbackDialog } from '../../modal/FeedbackCarneDialog'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

export type ContractDetailsDrawerFooterProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  cancelContractQueryLoading?: boolean
  changeContractOwnership?: () => void
  contract: Contract
  hasInvoicesWithError?: boolean
  isCheckoutSchool?: boolean
  isReenrollable?: boolean
  openCancellationDrawer: () => void
  setIsConfirmationDialogVisible: (value: boolean) => void
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  showAnnualPaidAmountsStatement: boolean
}

const ContractDetailsDrawerFooter = ({
  contract,
  isCheckoutSchool,
  isReenrollable,
  setIsConfirmationDialogVisible,
  addContract,
  changeContractOwnership,
  openCancellationDrawer,
  cancelContractQueryLoading,
  showAnnualPaidAmountsStatement,
  hasInvoicesWithError,
  setShowInvoicesNotGeneratedDialog,
}: ContractDetailsDrawerFooterProps) => {
  const { sendHotjarEvent } = useHotjar()
  const { schoolSlug, navigateTo } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const editPreContractFeatureToggle = useUnleashFlag(
    UnleashFlags.ENGAJAMENTO_PAS444_EDIT_PRECONTRACT
  )
  const isNewFormToExportCarneEnabled = useUnleashFlag(
    UnleashFlags.PEX_447_CHANGE_FORM_TO_EXPORT_CARNE_PI
  )
  const useUnifiedContractEditing = useUnleashFlag(UnleashFlags.ENABLE_UNIFIED_CONTRACT_EDITING)

  const { api } = useApi()
  const [isLoadingCarne, setIsLoadingCarne] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [carneErrorMessage, setCarneErrorMessage] = useState<string>('')

  const printContractURL = `${PUBLIC_DOMAIN}/contract/${contract?.id}/print-slips`
  const { school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const useDownloadCarne = async (contractId: string) => {
    const carneFileDownload = async (contractId: string) => {
      await api.contracts.downloadCarne(contractId).then(async data => {
        if (data.status > 200) {
          const error = await data.data.text()
          setCarneErrorMessage(error)
        } else {
          downloadFile(`carne-${contractId}`, 'pdf', async () => await data.data)
        }
      })
    }

    try {
      setCarneErrorMessage('')
      await carneFileDownload(contractId)
    } finally {
      setIsLoadingCarne(false)
    }
  }

  const printCarne = () => {
    if (hasInvoicesWithError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      if (isInitialized)
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.BUTTON_CLICKED,
          scope: EventDispatcherEventScopes.CONTRACT_PAGE,
          action: 'click',
          entity: EventDispatcherEntities.PRINT_BANKSLIP,
          customProperties: {
            $name: 'Imprimir carnê',
            $contract_id: contract?.id,
          },
        })
      if (isNewFormToExportCarneEnabled) {
        setIsLoadingCarne(true)
        useDownloadCarne(contract?.id)
      } else {
        window.open(printContractURL)
      }
    }
  }

  const sendGenerateStatementEvent = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_PAGE,
        action: 'click',
        entity: EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
        customProperties: {
          $name: 'Gerar demonstrativo de pagamento',
          $contract_id: contract?.id,
        },
      })
  }

  useEffect(() => {
    if (carneErrorMessage !== '') {
      setShowErrorModal(true)
    }
  }, [carneErrorMessage])

  const isPreContract = contract?.pre_contract_status !== PreContractStatuses.EFFECTED_PCS
  const isCanceledContract = contract?.status === ContractStatus.CANCELED
  const isCanceledPreContract = isPreContract && isCanceledContract

  const isMatriculasPreContract =
    Number(contract?.reference_year) >= MATRICULAS_API_FIRST_YEAR && isPreContract

  const goToContractEdit = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_DRAWER,
        action: 'click',
        customProperties: {
          $button_name: 'Editar contrato',
          $contract_type: isPreContract ? 'PRE_CONTRACT' : 'EFFECTED_CONTRACT',
        },
      })

    const path =
      isMatriculasPreContract || useUnifiedContractEditing
        ? `/contratos/${contract?.id}/editar`
        : `/contratos/${contract?.reference_year}/${contract?.id}/editar-contrato`

    navigateTo({ slug: schoolSlug, path })
  }

  if (isCanceledPreContract)
    return (
      <ButtonDocker>
        <Button
          variation="primary"
          fullWidth
          onClick={() => {
            sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT)
            setIsConfirmationDialogVisible(true)
            sendGenerateStatementEvent()
          }}
          data-testid="generate-statement-button"
        >
          Gerar demonstrativo de pagamento
        </Button>
      </ButtonDocker>
    )

  return (
    <>
      <FailureFeedbackDialog
        isVisible={showErrorModal}
        buttonLabel="Entendi"
        onClose={() => setShowErrorModal(false)}
        submitHandler={() => setShowErrorModal(false)}
        closeIcon
      >
        <FailureFeedbackContent err={carneErrorMessage} />
      </FailureFeedbackDialog>
      {!isCheckoutSchool && showAnnualPaidAmountsStatement && (
        <ButtonDocker>
          <Button
            variation="primary"
            fullWidth
            onClick={() => {
              sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT)
              setIsConfirmationDialogVisible(true)
              sendGenerateStatementEvent()
            }}
            data-testid="generate-statement-button"
          >
            Gerar demonstrativo de pagamento
          </Button>
        </ButtonDocker>
      )}
      {isCheckoutSchool && (
        <ButtonDocker>
          {editPreContractFeatureToggle && (useUnifiedContractEditing || isPreContract) ? (
            <Box sx={{ width: '100%' }}>
              <Button
                variation="primary"
                onClick={goToContractEdit}
                fullWidth
                style={{
                  opacity: 1,
                  backgroundColor: theme.primitiveTokens.colors.isaacBlue[60],
                  color: 'white',
                }}
              >
                Editar Contrato
              </Button>
            </Box>
          ) : (
            !isOutsourcedProvider && (
              <Button variation="primary" onClick={() => printCarne()} loading={isLoadingCarne}>
                Imprimir carnê
              </Button>
            )
          )}

          <ContractCheckoutActions
            contractId={contract?.id}
            hasInvoicesWithError={hasInvoicesWithError}
            setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
            addContract={addContract}
            isReenrollable={isReenrollable}
            changeContractOwnership={changeContractOwnership}
            showAnnualPaidAmountsStatement={showAnnualPaidAmountsStatement}
            clickPaidAmountStatement={value => setIsConfirmationDialogVisible(value)}
            openCancellationDrawer={openCancellationDrawer}
            queryLoading={cancelContractQueryLoading}
            isPreContract={isPreContract}
          />
        </ButtonDocker>
      )}
    </>
  )
}

export default ContractDetailsDrawerFooter
