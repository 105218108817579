import { useApi } from '@/shared/hooks'
import { creditService } from '../../services'
import { useMutation } from '@tanstack/react-query'
import { OrderDropoutRequest } from '../../services/types'

export const orderDropoutQueryKey = 'order-dropout'

export const useOrderDropout = () => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  return useMutation([orderDropoutQueryKey], async (body: OrderDropoutRequest) =>
    service.orderDropout(body)
  )
}
