import styled from 'styled-components'
import { Table } from '@gravity/table'

export const Wrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`

export const TableRow = styled(Table.Row)`
  :hover {
    cursor: pointer;
  }
`
