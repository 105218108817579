import styled from 'styled-components'
import { Controller, UseFormReturn, FieldArrayWithId } from 'react-hook-form'
import { Button, IconButton } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import useTheme from '@material-ui/core/styles/useTheme'
import { Discount } from 'src/shared/interfaces'
import DiscountFormFields from 'src/escolas/components/DiscountFormFields'
import {
  formatCentsToReal,
  leftZeroPadding,
  formatNumberToTwoFractionDigits,
} from 'src/shared/utils'
import { EnrollmentFormFields } from '../contract/create/types'
import { ContractEditDiscountsForm } from '../contract/ContractEditDrawer/ContractEditDiscountsDrawer'

const StyledTypography = styled(Typography)`
  font-weight: 700;
`

const StyledButton = styled(Button)`
  text-transform: unset;
  font-weight: 700;
  align-self: flex-end;
`

const StyledPaper = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export type AddDiscountFormType = Discount & {
  isFinished: boolean
  label_days_before_due_date: string
}

export type AddDiscountsFormType = {
  discounts: AddDiscountFormType[]
  reason?: string
}

export type DiscountFormProps = {
  data: FieldArrayWithId<EnrollmentFormFields, 'discounts', 'id'>
  form: UseFormReturn<EnrollmentFormFields | AddDiscountsFormType | ContractEditDiscountsForm>
  idPrefix: string
  index: number
  removeDiscount: (index: number) => void
  totalAmount: number
}

const DiscountForm = ({
  form,
  idPrefix,
  totalAmount,
  data,
  index,
  removeDiscount,
}: DiscountFormProps) => {
  const theme = useTheme()
  const { control, watch, trigger } = form

  const [
    amount,
    description,
    days_before_due_date,
    isFinished,
    label_days_before_due_date,
  ] = watch([
    `discounts.${index}.amount` as const,
    `discounts.${index}.description` as const,
    `discounts.${index}.days_before_due_date` as const,
    `discounts.${index}.isFinished` as const,
    `discounts.${index}.label_days_before_due_date` as const,
  ])

  const isValidDescription = Boolean(description)
  const daysBeforeDueDateOptionDescription =
    days_before_due_date > 0
      ? `${days_before_due_date} dias antes do vencimento`
      : label_days_before_due_date
  const isValidAmount = amount && amount <= totalAmount && amount > 0
  const isValidDaysBeforeDueDate =
    daysBeforeDueDateOptionDescription &&
    (daysBeforeDueDateOptionDescription === 'Antes do vencimento'
      ? Boolean(days_before_due_date > 0)
      : days_before_due_date >= -1)
  const isFinishButtonDisabled = !isValidAmount || !isValidDaysBeforeDueDate || !isValidDescription

  const percentage = formatNumberToTwoFractionDigits((amount / totalAmount) * 100)

  return (
    <>
      <StyledPaper
        key={data.id}
        variant="outlined"
        theme={theme as any}
        data-testid={`add-discount-${index}-content`}
      >
        <Box mb={2}>
          <Typography variant="caption" color="textSecondary">
            Desconto nº {leftZeroPadding(index + 1, 2)}
          </Typography>
          <Box position="absolute" top={14} right={16}>
            <IconButton
              variation="ghost"
              size="small"
              id={`${idPrefix}-delete-discount-icon-${index}`}
              aria-label="remover desconto"
              onClick={() => {
                removeDiscount(index)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        {isFinished && (
          <>
            <Box mb={1}>
              <Typography>{description}</Typography>
              <Typography display="inline" color="textSecondary">
                Valor:{' '}
              </Typography>
              <StyledTypography display="inline">{formatCentsToReal(amount)}</StyledTypography>
              <Typography display="inline" color="textSecondary">
                {' '}
                —
              </Typography>
              <StyledTypography display="inline">{` ${percentage}%`}</StyledTypography>
            </Box>
            <Box mb={2}>
              {days_before_due_date > 0 && (
                <Typography display="inline" color="textSecondary">
                  Válido até{' '}
                </Typography>
              )}
              <Typography display="inline" color="textSecondary">
                {daysBeforeDueDateOptionDescription}
              </Typography>
            </Box>
          </>
        )}
        <Box display={isFinished ? 'none' : 'block'}>
          <DiscountFormFields
            form={form}
            amountDefaultValue={data.amount}
            amountFieldName={`discounts.${index}.amount`}
            daysBeforeDueDateDefaultValue={data.days_before_due_date}
            daysBeforeDueDateFieldName={`discounts.${index}.days_before_due_date`}
            daysBeforeDueDateLabelFieldName={`discounts.${index}.label_days_before_due_date`}
            descriptionDefaultValue={data.description}
            descriptionFieldName={`discounts.${index}.description`}
            totalAmountPaid={totalAmount}
          />
        </Box>

        <Controller
          rules={{ required: true, validate: Boolean }}
          control={control}
          name={`discounts.${index}.isFinished` as const}
          defaultValue={isFinished}
          render={({ field: { onChange, value } }) => (
            <>
              <Box position="absolute" top={14} right={56} display={isFinished ? 'block' : 'none'}>
                <IconButton
                  variation="ghost"
                  size="small"
                  aria-label="editar desconto"
                  onClick={() => onChange(!value)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
              <StyledButton
                style={{ display: isFinished ? 'none' : 'block' }}
                variation="ghost"
                fullWidth
                onClick={() => {
                  onChange(!value)
                  trigger()
                }}
                disabled={isFinishButtonDisabled}
                data-testid="save-discount-button"
              >
                Salvar desconto
              </StyledButton>
            </>
          )}
        />
      </StyledPaper>
    </>
  )
}

export default DiscountForm
