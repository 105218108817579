import { FC, useState } from 'react'
import { SelectPaymentMethodProps } from './types'

import { Box } from '@material-ui/core'
import { Divider, Typography } from '@olaisaac/design-system'
import { RadioGroupStyled } from './styles'
import OptionCard from '../../../components/OptionCard/OptionCard'
import { SimulationPaymentMethod } from '@/shared/interfaces'
import CardForm from '../../../steps/PaymentOptionsStep/component/Forms/CardForm'
import { PaymentOptionsValue } from '../../../types'
import { formatCentsToReal } from '@/shared/utils'
import Footer from '../../../components/StepFooter/StepFooter'
import NotificationPaymentLink from '../../../components/NotificationPaymentLink'
import StepTitle from '../../../components/StepTitle/StepTitle'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { HelpButtonEntryProps } from '../../../components/HelpButton/types'

const SelectPaymentMethod: FC<SelectPaymentMethodProps> = ({
  isLoading,
  goBack,
  onFinish,
  entryInformation,
}) => {
  const [selectOption, setSelectOption] = useState<SimulationPaymentMethod>()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [chosenOption, setChosenOption] = useState<PaymentOptionsValue>(null)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleChosenOption = (value: PaymentOptionsValue) => {
    setChosenOption(value)
  }

  const helpButtonEntry: HelpButtonEntryProps = {
    productName: entryInformation.product[0].name,
    studentName: entryInformation.student.name,
    amount: entryInformation.value,
    orderReference: entryInformation.orderReference,
  }

  const handleValidate = (value: boolean) => setIsValid(value)

  const handleFinish = () => {
    const customParams =
      chosenOption.paymentOption === SimulationPaymentMethod.CREDIT_CARD
        ? {
            $Total_amount: entryInformation.value,
            $Payment_conditions:
              chosenOption.installments === 1
                ? 'à vista'
                : `parcelado em ${chosenOption.installments}x`,
          }
        : { $Total_amount: entryInformation.value }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
        entity: 'Pagamento',
        name:
          chosenOption.paymentOption === SimulationPaymentMethod.CREDIT_CARD
            ? EventDispatcherEvents.CLICKED_PAY_NEGOTIATION_2_ENTRY_CREDIT
            : EventDispatcherEvents.CLICKED_PAY_NEGOTIATION_2_ENTRY_DEBIT,
        action: 'click',
        customProperties: customParams,
      })
    }
    onFinish(chosenOption)
  }

  const oneSumpText = `${formatCentsToReal(entryInformation.value)} à vista sem juros`

  const creditInformationText =
    entryInformation.maxInstallment === 1
      ? oneSumpText
      : `Até ${entryInformation.maxInstallment}x de ${formatCentsToReal(
          entryInformation.value / entryInformation.maxInstallment
        )} sem juros`

  return (
    <Box display="flex" flexDirection="column" marginBottom={17}>
      <StepTitle
        ondueCount={1}
        overdueCount={0}
        productName={entryInformation.product[0].name}
        studentName={entryInformation.student.name}
        totalAmount={entryInformation.value}
      />
      <Box marginTop={6} marginBottom={3}>
        <Typography variation="subtitleDesktopLarge" withoutMargin color="primary">
          Selecione a forma de pagamento
        </Typography>
      </Box>
      <RadioGroupStyled
        style={{ display: 'flex' }}
        aria-label="form of payment"
        name="radio-buttons-form-of-payment"
      >
        <Box>
          <Box key={SimulationPaymentMethod.CREDIT_CARD} width="100%">
            <OptionCard
              informationText={{ info: creditInformationText }}
              isSelected={selectOption === SimulationPaymentMethod.CREDIT_CARD}
              onSelect={setSelectOption}
              type={SimulationPaymentMethod.CREDIT_CARD}
            >
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleChosenOption}
                paymentOption={SimulationPaymentMethod.CREDIT_CARD}
                maxInstallment={entryInformation.maxInstallment}
                totalAmount={entryInformation.value}
              />
            </OptionCard>
          </Box>
          <Divider style={{ margin: 0 }} />
          <Box key="DEBIT_CARD" width="100%">
            <OptionCard
              informationText={{ info: oneSumpText }}
              isSelected={selectOption === SimulationPaymentMethod.DEBIT_CARD}
              onSelect={setSelectOption}
              type={SimulationPaymentMethod.DEBIT_CARD}
            >
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleChosenOption}
                paymentOption={SimulationPaymentMethod.DEBIT_CARD}
                maxInstallment={1}
                totalAmount={entryInformation.value}
              />
            </OptionCard>
          </Box>
        </Box>
      </RadioGroupStyled>
      <Box marginTop={3}>
        <NotificationPaymentLink link={entryInformation.paymentLink} />
      </Box>
      <Footer
        goBack={goBack}
        showHelpButton
        isLoading={isLoading}
        onFinish={handleFinish}
        stepName="payment-method"
        disableConfirm={!isValid || isLoading}
        helpButtonEntry={helpButtonEntry}
      />
    </Box>
  )
}

export default SelectPaymentMethod
