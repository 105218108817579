import { PAYOUT_SPREADSHEET_FILE_VERSION } from '@/modules/report/constants/PAYOUT_SPREADSHEET_FILE_VERSION'

import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import type {
  APIResponse,
  BankTransfer,
  FinancialTransaction,
  FinancialTransactionProps,
  PaginationRequest,
  Payout,
  ReportAggregations,
  School,
} from '@/shared/interfaces'
import type { PayoutReportType } from '@/modules/report/models/PayoutReportType'
import type { Filter as TuitionPayoutReportFilter } from '@/modules/report/hooks/useTuitionPayoutReportFilter/types'
import type { Filter as EnrollmentPayoutReportFilter } from '@/modules/report/hooks/useEnrollmentPayoutReportFilter/types'
import type { Filter as IsaacPayPayoutReportFilter } from '@/modules/report/hooks/useIsaacPayPayoutReportFilter/types'
import type { ConstEnumValue } from '@/shared/models/utils'

export type FetchReportListRequestDTO = {
  payoutDomain?: string
  reportType: 'UNGUARANTEED' | 'isaac-pay'
  schoolId: uuid
}

export type FetchReportListResponseDTO = APIResponse<UnguaranteedReport[]>

export enum PayoutDomain {
  Enrollment = 'E',
  IsaacPay = 'IP',
}

type PayoutDetailsFilter = {
  enrollment: EnrollmentPayoutReportFilter
  'isaac-pay': IsaacPayPayoutReportFilter
  tuition: TuitionPayoutReportFilter
}

export type PayoutDetailsFilterOptions = {
  enrollment: Record<
    'amount_signs' | 'events' | 'products' | 'reference_years',
    Array<{ label: string; value: string }>
  >
  'isaac-pay': Record<
    'amount_signs' | 'events' | 'products' | 'reference_years',
    Array<{ label: string; value: string }>
  >
  tuition: Record<'amount_signs' | 'events' | 'products', Array<{ label: string; value: string }>>
}

export type FetchPayoutDetailsRequestDTO<T extends PayoutReportType> = {
  filter?: Partial<PayoutDetailsFilter[T] & { name: string }>
  pagination?: PaginationRequest
  payoutId: string
}

export type FetchPayoutMonthAggregationRequestDTO<T extends PayoutReportType> = {
  endDate: datestring
  filter?: Partial<PayoutDetailsFilter[T] & { name: string }>
  pagination?: PaginationRequest
  payoutDomain: PayoutDomain
  schoolID: string
  startDate: datestring
}

export type FetchPayoutDetailsResponseDTO<T extends PayoutReportType> = APIResponse<{
  agregations: ReportAggregations
  bank_transfers: BankTransfer[]
  ended_at: datestring | null
  enrollment_take_rate: number
  file_version?: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>
  filters_included?: PayoutDetailsFilterOptions[T] // INFO: Will be sended if filters feature-flags is enabled
  financial_transactions: FinancialTransactionProps[]
  payment_schedule_transactions: FinancialTransaction[] | null
  payout: Payout
  payout_id: string
  school: School
  school_id: string
  started_at: datestring | null
  take_rate: number
  type: string
}>

export type FetchUnguaranteedReportFileRequestDTO = {
  extension: 'pdf' | 'xlsx'
  id: uuid
  schoolId: uuid
}

export type FetchPayoutReportFileRequestDTO = {
  extension: 'pdf' | 'xlsx'
  id: string
  version: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>
}

export type GetEmbedReportRequestDTO = {
  id: uuid
}

export type GetEmbedReportResponseDTO = {
  url: string
}

export type GetAggregatedProvidersReportResponseDTO = {
  external_total: number
  external_total_base_amount: number
  external_total_fee: number
  payout_total: number
  payout_total_base_amount: number
  payout_total_fee: number
  total: number
  total_fee: number
}
