import React, { useState } from 'react'
import { Box, Step, Stepper } from '@material-ui/core'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { StatusStepperProps } from './types'
import {
  BaseStepContent,
  BaseStepLabel,
  CompletedIcon,
  CompletedIconMargin,
  StepDescription,
  StepLabelText,
  TitleStepLabel,
} from './styles'
import { useContextChosenOffer } from '../../contexts/CreditDataContext'
import { CreditType } from '../../services/types'

const chooseStep = (isCPCREnabled: boolean, creditType: CreditType) => {
  const commonSteps = [
    {
      label: 'Coleta de documentos',
      description:
        'Contrato social e suas alterações \n Documento com foto dos representantes legais',
      finishedDescription: 'Concluído',
    },
    {
      label: 'Assinatura de contrato',
      description: 'Aditivo de contrato \n CCB (Cédula de Crédito Bancário)',
      finishedDescription: 'Concluído',
    },
    {
      label: 'Transferência do crédito',
      description: 'Você irá receber a transferência na data combinada',
      finishedDescription: 'Concluído',
    },
  ]

  if (isCPCREnabled && creditType === CreditType.CPCR) {
    return [
      {
        label: 'Solicitação recebida \n com sucesso!',
        description:
          'Para prosseguir com a oferta, renove seu contrato com o isaac. Seu consultor entrará em contato para dar continuidade à contratação.',
        finishedDescription: 'Contratação iniciada',
      },
      {
        label: 'Renovação de contrato',
        description: 'Parceria da escola com o isaac',
        finishedDescription: 'Concluído',
      },
      ...commonSteps,
    ]
  } else {
    return [
      {
        label: 'Solicitação recebida \n com sucesso!',
        description:
          'Em até 2 dias úteis, a equipe de crédito entrará em \n contato para continuar a contratação.',
        finishedDescription: 'Contratação iniciada',
      },
      ...commonSteps,
    ]
  }
}

const StepIconCompleted = () => <CompletedIcon style={{ fontSize: 29 }} />
const StepIconMargin = () => <CompletedIconMargin style={{ fontSize: 29 }} />

export function StatusStepper({ statusStepper }: StatusStepperProps) {
  const [activeStep] = useState(statusStepper.step)
  const { chosenOffer } = useContextChosenOffer()

  const isStepCompleted = (currentStep: number) => {
    return activeStep > currentStep || (currentStep === activeStep && statusStepper.finished)
  }

  const isCPCREnabled = useUnleashFlag(UnleashFlags.CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE)

  return (
    <Box mb={4}>
      <Stepper style={{ padding: 0 }} activeStep={activeStep} orientation="vertical">
        {chooseStep(isCPCREnabled, chosenOffer.category).map((step, index) => {
          const isFirstStep = index === 0
          const isSecondStepCompleted = isStepCompleted(1)
          const showFinishedDescription =
            isStepCompleted(index) && (!isFirstStep || isSecondStepCompleted)
          return (
            <Step expanded key={step.label}>
              {isFirstStep ? (
                <TitleStepLabel StepIconComponent={StepIconMargin}>{step.label}</TitleStepLabel>
              ) : (
                <BaseStepLabel
                  StepIconComponent={isStepCompleted(index) ? StepIconCompleted : null}
                >
                  <StepLabelText>{step.label}</StepLabelText>
                </BaseStepLabel>
              )}
              <BaseStepContent isTitle={isFirstStep}>
                <StepDescription>
                  {showFinishedDescription ? step.finishedDescription : step.description}
                </StepDescription>
              </BaseStepContent>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
