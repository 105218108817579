import { FC, Fragment } from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { formatCentsToReal } from 'src/shared/utils'
import {
  CheckoutCombinableReceivable,
  CheckoutReceivables,
} from 'src/escolas/contexts/checkoutContext'
import { Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import { SimulationPaymentMethod } from '@/shared/interfaces'

export type NegotiationFinancialSummaryProps = {
  combinedReceivables: CheckoutReceivables[]
  creditCardFee?: number
}
export type TypographyType = {
  isDiscount?: boolean
  value: string
  variation: any
}
const NewTypography: FC<TypographyType> = ({ variation, value, isDiscount }) => {
  return (
    <Typography withoutMargin variation={variation} style={{ marginBottom: 10 }}>
      {isDiscount ? ' - ' : ''}
      {value}
    </Typography>
  )
}
const NegotiationFinancialSummary: FC<NegotiationFinancialSummaryProps> = ({
  combinedReceivables,
  creditCardFee,
}) => {
  const { selectedSimulationPaymentMethod, agreementSimulations } = useAgreement()

  const findCreditCardFeeByProduct = (combinableReceivables: CheckoutCombinableReceivable[]) => {
    if (!creditCardFee || selectedSimulationPaymentMethod !== SimulationPaymentMethod.CREDIT_CARD)
      return 0

    const simulations: Record<string, number> = combinableReceivables?.reduce((acc, { id }) => {
      const simulation = agreementSimulations?.find(simulation =>
        simulation?.receivable_ids.includes(id)
      )
      return { ...acc, [simulation?.agreement_simulation_id]: simulation?.credit_card_fee ?? 0 }
    }, {})

    return Object.values(simulations).reduce((acc, value) => acc + value, 0)
  }

  const findValues = (receivables: CheckoutCombinableReceivable[]) => {
    return receivables.reduce(
      (acc, curr) => {
        const duePaymentDiscount = acc.duePaymentDiscount + curr.lost_due_payment_discount

        const fineAndInterest =
          acc.finesAndInterest + (curr.current_fine ?? 0) + (curr.current_interest ?? 0)

        const baseAmount = acc.baseAmount + curr.amount

        return {
          finesAndInterest: fineAndInterest,
          baseAmount: baseAmount,
          duePaymentDiscount: duePaymentDiscount,
        }
      },
      {
        finesAndInterest: 0,
        duePaymentDiscount: 0,
        baseAmount: 0,
      }
    )
  }
  return (
    <>
      {combinedReceivables.map(student => (
        <Fragment key={student.student_id}>
          <Typography
            withoutMargin
            variation="bodySmall"
            color="secondary"
            style={{ marginBottom: 10 }}
          >
            {prettifyFullName(student.student_name)}
          </Typography>

          {student.products.map(product => {
            const values = findValues(product.receivables)
            const creditCardFeeByProduct = findCreditCardFeeByProduct(product.receivables)
            const subTotal = values?.baseAmount + creditCardFeeByProduct
            return (
              <Fragment key={product.product_id}>
                <NewTypography variation="subtitleDesktopLarge" value={product.product_name} />
                <Divider style={{ marginBottom: 20 }} />
                {product.receivables.map(receivable => {
                  const currentAmount =
                    receivable?.amount -
                    (receivable?.current_fine ?? 0) -
                    (receivable?.current_interest ?? 0) -
                    (receivable?.current_perpetual_discount ?? 0) -
                    (receivable?.current_early_payment_discount ?? 0) -
                    (receivable?.current_due_payment_discount ?? 0)
                  return (
                    <Fragment key={receivable.id}>
                      <Box pb={1} display="flex" justifyContent="space-between">
                        <NewTypography
                          variation="subtitleDesktopMedium"
                          value={dayjs(receivable?.competenceDate).utc().format('MMMM')}
                        />
                        <NewTypography
                          variation="subtitleDesktopMedium"
                          value={formatCentsToReal(currentAmount)}
                        />
                      </Box>
                    </Fragment>
                  )
                })}
                <Box pb={1} display="flex" justifyContent="space-between">
                  <NewTypography variation="subtitleDesktopMedium" value="Multa e Juros" />

                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value={formatCentsToReal(values?.finesAndInterest)}
                  />
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value="Desconto de pontualidade devolvido"
                  />

                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value={formatCentsToReal(values?.duePaymentDiscount)}
                    isDiscount
                  />
                </Box>

                {Boolean(creditCardFeeByProduct) && (
                  <Box pb={1} display="flex" justifyContent="space-between">
                    <NewTypography variation="subtitleDesktopMedium" value="Taxa do cartão" />

                    <NewTypography
                      variation="subtitleDesktopMedium"
                      value={formatCentsToReal(creditCardFeeByProduct)}
                    />
                  </Box>
                )}
                <Divider style={{ marginBottom: 20 }} />
                <Box pb={1} display="flex" justifyContent="space-between">
                  <NewTypography variation="subtitleDesktopMedium" value="Subtotal" />

                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value={formatCentsToReal(subTotal)}
                  />
                </Box>
              </Fragment>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

export default NegotiationFinancialSummary
