import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import Skeleton from '@material-ui/lab/Skeleton'

import theme from 'src/shared/theme'

const { spacing } = theme.primitiveTokens
const { variation } = theme.humanizedTokens.components.typography

export const Container = styled.div`
  display: flex;
  align-items: baseline;
`

export const Currency = styled(Typography)`
  ${variation.subtitleDesktopLarge};
  margin-right: ${spacing[1]};
  color: inherit;
`

export const Value = styled(Typography)`
  ${variation.headlineDesktopMedium};
  color: inherit;
`

export const ValuePlaceholder = styled(Skeleton)`
  ${variation.headlineDesktopMedium};
`
