import { Button } from '@olaisaac/design-system'
import RefreshIcon from '@material-ui/icons/Refresh'

import type { ErrorProps } from './types'

import { Container, Explanation, Title } from './styles'

export const Error = (props: ErrorProps) => {
  const { title, onRetry } = props

  return (
    <Container>
      <Title>{title}</Title>
      <Explanation>Ocorreu um erro inesperado. Tente novamente mais tarde.</Explanation>
      <Button startIcon={<RefreshIcon />} onClick={onRetry}>
        Recarregar
      </Button>
    </Container>
  )
}
