import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import theme from 'src/shared/theme'

const { spacing } = theme.primitiveTokens
const { variation } = theme.humanizedTokens.components.typography

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Title = styled(Typography)`
  ${variation.headlineDesktopSmall};
  margin-bottom: ${spacing[1]};
`
export const Explanation = styled(Typography)`
  ${variation.bodyLarge};
  line-height: 1.5;
  margin-bottom: ${spacing[8]};
`
