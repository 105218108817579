import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'

import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

import { enrollmentRequestService } from '../services/enrollment-request'

type UseHasPendingIrregularitiesQuery = {
  options?: UseQueryOptions<{ has_pending_irregularities: boolean }, XMLHttpRequest>
  referenceYear: number
  schoolId: string
}

export const useHasPendingIrregularitiesQuery = ({
  schoolId,
  referenceYear,
  options,
}: UseHasPendingIrregularitiesQuery) => {
  const { apiClient } = useApi()

  const service = enrollmentRequestService(apiClient.privateApi)

  const queryKey = ['enrollment-request', 'students', schoolId, referenceYear]
  return useQuery<{ has_pending_irregularities: boolean }, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchHasPendingIrregularities({
        referenceYear: referenceYear,
        schoolId: schoolId,
      })
    },
    { ...SOFTCACHE_OPTIONS_BASE, enabled: !!schoolId && !!referenceYear, ...options }
  )
}
