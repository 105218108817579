import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Typography } from '@olaisaac/design-system'
import { propEq } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useNavigation } from 'src/escolas/hooks'
import { useApi } from 'src/shared/hooks'
import { isProduct, Product } from 'src/shared/interfaces'
import { debounce } from 'throttle-debounce'
import { DEBOUNCE_MS, defaultProduct } from './constants'
import { SelectProductProps } from './types'
import { useUnleashClient } from '@unleash/proxy-client-react'
import { sortProducts } from './utils/sortProducts'

import { useProductValidation } from './hooks/useProductValidation'

const PRODUCTS_PER_PAGE = 100

const SelectProduct = ({ form, referenceYear }: SelectProductProps) => {
  const { api } = useApi()
  const { schoolId } = useNavigation()
  const [products, setProducts] = useState<Array<Product>>([])
  const [isSearchingProduct, setIsSearchingProduct] = useState<boolean>(false)
  const productYear = referenceYear
  const unleashClient = useUnleashClient()

  const { control, getValues, setValue, reset } = form
  const formValues = getValues()

  const { validateProductRequired, validateStudentIsInCampaign } = useProductValidation({
    form,
    productYear,
    schoolId,
  })

  useEffect(() => {
    async function fetchProducts() {
      let products: Array<Product> = []
      for (let page = 1, lastPage = 1; page <= lastPage; page++) {
        await api.products
          .getList({ school_id: schoolId, per_page: PRODUCTS_PER_PAGE, page: page })
          .then(({ data, pagination: { total } }) => {
            products = [...products, ...data]
            lastPage = total && total > 0 ? Math.ceil(total / PRODUCTS_PER_PAGE) : lastPage
          })
      }
      products.length && setProducts(sortProducts(products))
    }
    fetchProducts()
  }, [])

  const getNoOptionsText = () => {
    return isSearchingProduct
      ? 'Nenhum produto com esse nome foi encontrado'
      : 'Comece a digitar para buscar produtos'
  }

  const onProductSelectChange = (data: string | Product) => {
    if (!isProduct(data)) {
      return reset({ ...formValues, product: defaultProduct })
    }

    const product = products.find(propEq('id', data.id))
    setValue('product', product)
    unleashClient.setContextField('productId', data.id)
  }

  const inputDebounce = debounce(DEBOUNCE_MS, () => setIsSearchingProduct(true))

  return (
    <>
      <Box mb={3} mt="32px">
        <Typography variation="headlineDesktopXsmall">
          Selecione o produto{' '}
          <span style={{ fontWeight: 'normal' }}>(Ano letivo {productYear})</span>
        </Typography>
        <Typography variation="bodyLarge" color="secondary">
          O produto selecionado será para onde o aluno(a) será matriculado.
        </Typography>
      </Box>
      <FormControl fullWidth variant="outlined">
        <Controller
          rules={{ validate: { validateProductRequired, validateStudentIsInCampaign } }}
          control={control}
          name="product_id"
          render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
            <Autocomplete
              {...rest}
              id="product-input"
              value={value}
              forcePopupIcon={false}
              getOptionSelected={(option, value) => option?.name === value?.name}
              getOptionLabel={option => option?.name}
              renderOption={option => <Typography>{option?.name}</Typography>}
              options={products}
              onChange={(_, data, reason) => {
                if (!data || reason === 'clear') {
                  return reset(
                    {
                      ...formValues,
                      product: defaultProduct,
                      product_id: '',
                      custom_enrollment_monthly_amount: null,
                      custom_monthly_amount: null,
                    },
                    { keepErrors: true }
                  )
                }
                onChange(data)
                onProductSelectChange(data)
              }}
              onInputChange={inputDebounce}
              noOptionsText={getNoOptionsText()}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Produto"
                  variant="outlined"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </FormControl>
    </>
  )
}

export default SelectProduct
