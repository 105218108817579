import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@gravity/button'
import { DialogPrimitives } from '@gravity/dialog'
import { Select } from '@gravity/select'
import { Callout } from '@gravity/callout'
import { TextField } from '@gravity/text-field'
import { useToast } from '@gravity/toast'

import {
  useCheckCreateUserAccess,
  useMutateOnCreateUserAccess,
} from '@/modules/access-management/hooks/queries/user-access'

import { InputLoading } from './components/InputLoading'

import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'
import { getAvailableOptions } from './utils/getAvailableOptions'

import { CreateUserAccessForm, createUserAccessSchema } from './schema'

import type { AssignAccessRoles } from '@/modules/access-management/types/AssignAccessRoles'
import { USER_ACCESS_LEVEL_LABEL } from '@/modules/access-management/constants/USER_ACCESS_LEVEL_LABEL'
import type { School } from '@/shared/models/School'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useIsFirstRender } from '@/shared/hooks/useIsFirstRender'

import { INVALID_EMAIL_REASON_MESSAGE } from './constants/INVALID_EMAIL_REASON_MESSAGE'

import * as Styled from './styles'

interface Props {
  assignAccessRoles: AssignAccessRoles[]
  currentSchool?: Pick<School, 'id' | 'name'>
  isOpen: boolean
  onClose: () => void
}

export const AccessCreateDialog = ({
  assignAccessRoles,
  isOpen,
  onClose,
  currentSchool,
}: Props) => {
  const { toast } = useToast()

  const { eventDispatcherClient, isInitialized } = useEventDispatcher()

  const firstRender = useIsFirstRender()
  const [createAccessButtonClicked, setCreateAccessButtonClicked] = useState<boolean>(false)

  const {
    control,
    watch,
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    formState,
  } = useForm<CreateUserAccessForm>({
    mode: 'onBlur',
    resolver: zodResolver(createUserAccessSchema),
    defaultValues: {
      email: '',
      school: {
        name: currentSchool?.name ?? '',
        id: currentSchool?.id ?? '',
      },
    },
  })

  const sendToastEvent = (title: string) => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      name: EventDispatcherEvents.TOAST_VIEWED,
      scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
      action: 'component_view',
      customProperties: {
        $toast_name: title,
      },
    })
  }

  const sendModalViewEvent = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.MODAL_VIEWED,
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        action: 'component_view',
        customProperties: {
          $modal_name: 'Criar novo acesso',
        },
      })
  }

  const sendCancelEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      name: EventDispatcherEvents.BUTTON_CLICKED,
      scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
      action: 'click',
      customProperties: {
        $button_name: 'Cancelar',
      },
    })
  }

  const {
    mutateAsync: mutateOnCreateUserAccess,
    isLoading: isLoadingCreateAccess,
  } = useMutateOnCreateUserAccess({
    onSuccess: () => {
      queryClient.invalidateQueries(['access-management', 'users', currentSchool?.id])

      const title = 'Acesso criado'
      sendToastEvent(title)
      toast({ type: 'success', title })
    },
    onError: () => {
      const title = 'Erro na criação do acesso'
      sendToastEvent(title)
      toast({ type: 'error', title })
    },
  })

  const { errors, isValid } = formState
  const email = watch('email')

  const isEmailValid = createUserAccessSchema.shape.email.safeParse(email).success

  const { data, isLoading: isLoadingCheckCreate, isError } = useCheckCreateUserAccess(
    {
      schoolId: currentSchool?.id ?? '',
      email,
    },
    {
      onSuccess: data => {
        setValue('firstName', data?.first_name ?? '')
        setValue('lastName', data?.last_name ?? '')
      },
      enabled: isEmailValid,
    }
  )

  const shouldDisplayLoadingForNameInput = isLoadingCheckCreate && isEmailValid && !isError

  const shouldDisplayNameInput =
    !isLoadingCheckCreate && isEmailValid && !isError && data?.is_user_creation_allowed === true

  const availableAccessOptions = getAvailableOptions({
    assignAccessRoles,
  })

  const isCalloutMessageVisible = !!data?.first_name && !!email

  const emailInputErrorMessage = (() => {
    if (errors?.email?.message) {
      return errors.email.message
    }

    if (data?.reason) {
      return INVALID_EMAIL_REASON_MESSAGE[data.reason]
    }

    // fallback error
    if (isError) {
      return 'Erro ao tentar validar o e-mail. Tente novamente.'
    }
  })()

  const emailDebounce = debounce(300, input => {
    setValue('email', input)
  })

  const handleCloseDialog = () => {
    reset()
    onClose()
  }

  const handleOpenChange = (open: boolean, isLoading: boolean) => {
    if (open || isLoading) return

    handleCloseDialog()
  }

  const onSubmit: SubmitHandler<CreateUserAccessForm> = async ({
    email,
    firstName,
    lastName,
    group,
    school,
  }) => {
    await mutateOnCreateUserAccess({
      email,
      firstName,
      lastName,
      schoolId: school.id,
      group,
      username: email,
    })

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        action: 'click',
        customProperties: {
          $button_name: 'Criar novo acesso',
          $access_type: USER_ACCESS_LEVEL_LABEL[group],
        },
      })

    setCreateAccessButtonClicked(true)

    handleCloseDialog()
  }

  useEffect(() => {
    if (isOpen) {
      setCreateAccessButtonClicked(false)
      sendModalViewEvent()
    } else if (!firstRender && !createAccessButtonClicked) {
      sendCancelEvent()
    }
  }, [isOpen])

  useEffect(() => {
    if (data?.reason) {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.ERROR_VIEWED,
          scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
          action: 'component_view',
          customProperties: {
            $error_name: 'E-mail inválido',
            $error_description: INVALID_EMAIL_REASON_MESSAGE[data.reason],
          },
        })
    }
  }, [data])

  return (
    <DialogPrimitives.Root
      open={isOpen}
      onOpenChange={open => handleOpenChange(open, isLoadingCreateAccess)}
    >
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay backdrop />

        <div data-testid="access-create">
          <DialogPrimitives.Content
            style={{ width: '100%' }} // TODO: dialog components should have this style
            size={3}
            title="Criar novo acesso"
            aria-describedby={undefined}
            actionButton={
              <Button
                disabled={!isValid || isLoadingCheckCreate || isLoadingCreateAccess}
                loading={isLoadingCreateAccess}
                data-testid="submit-access"
                color="accent"
                variant="solid"
                onClick={handleSubmit(onSubmit)}
              >
                Criar novo acesso
              </Button>
            }
            cancelButton={
              <Button
                disabled={isLoadingCreateAccess}
                variant="ghost"
                onClick={() => sendCancelEvent()}
              >
                Cancelar
              </Button>
            }
          >
            <Styled.Content>
              <div className="email">
                <TextField
                  label="E-mail do usuário"
                  aria-label="E-mail do usuário"
                  placeholder="Digite o e-mail do novo usuário"
                  size={3}
                  data-testid="email"
                  autoComplete="off"
                  error={!!emailInputErrorMessage}
                  errorMessage={emailInputErrorMessage}
                  {...register('email')}
                  onChange={event => emailDebounce(event.target.value)}
                />
              </div>
              <div className={isCalloutMessageVisible ? 'access-type-with-callout' : 'access-type'}>
                <Controller
                  control={control}
                  name="group"
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="surface"
                      label="Nível de acesso"
                      data-testid="group"
                      size={3}
                      options={availableAccessOptions}
                      placeholder="Selecione o nível de acesso"
                      value={field.value}
                      onValueChange={value => {
                        field.onChange(value)
                        trigger()
                      }}
                    />
                  )}
                />
              </div>
              <div className={isCalloutMessageVisible ? 'school-with-callout' : 'school'}>
                <TextField
                  label="Unidade da escola"
                  aria-label="Unidade da escola"
                  placeholder="Nome da Unidade"
                  size={3}
                  data-testid="school"
                  disabled
                  {...register('school.name')}
                />
              </div>
              <div className={isCalloutMessageVisible ? 'first-name-with-callout' : 'first-name'}>
                {shouldDisplayNameInput && (
                  <TextField
                    label="Primeiro nome"
                    aria-label="Primeiro nome"
                    placeholder="Digite o nome"
                    size={3}
                    data-testid="firstName"
                    disabled={!!data?.first_name}
                    {...register('firstName')}
                  />
                )}
                {shouldDisplayLoadingForNameInput && <InputLoading />}
              </div>
              <div className={isCalloutMessageVisible ? 'last-name-with-callout' : 'last-name'}>
                {shouldDisplayNameInput && (
                  <TextField
                    label="Sobrenome"
                    aria-label="Sobrenome"
                    placeholder="Digite o sobrenome"
                    size={3}
                    data-testid="lastName"
                    disabled={!!data?.first_name}
                    {...register('lastName')}
                  />
                )}
                {shouldDisplayLoadingForNameInput && <InputLoading />}
              </div>
              {isCalloutMessageVisible && (
                <Callout
                  text="Este e-mail já está vinculado a um usuário de outra Unidade."
                  className="callout"
                />
              )}
            </Styled.Content>
          </DialogPrimitives.Content>
        </div>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  )
}
