import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { Installment } from '../../GuardianDetails/types'

export const useNegotiationFlow = () => {
  const isCheckoutWithoutRestrictionsEnabled = useUnleashFlag(
    UnleashFlags.REG_2285_ENABLED_CHECKOUT_WITHOUT_RESTRICTIONS
  )

  const { checkoutCart } = useCheckoutCart()

  const isDownPayment = (installment: Installment) =>
    (installment.type === 'DOWN_PAYMENT' || installment.is_from_credit_card_flow) &&
    installment.urn.includes('urn:down-payment')

  const downPayments = checkoutCart?.filter(installment => isDownPayment(installment))
  const receivables = checkoutCart?.filter(installment => !isDownPayment(installment))

  const isEnableCheckoutWithoutRestrictions = () => {
    if (isCheckoutWithoutRestrictionsEnabled)
      return !(downPayments?.length === 1 && receivables?.length === 0)

    return false
  }

  return {
    isEnableCheckoutWithoutRestrictions: isEnableCheckoutWithoutRestrictions(),
  }
}
