const brazilianLayout = 'pt-BR'
const monthYearFormatter = new Intl.DateTimeFormat(brazilianLayout, {
  month: 'long',
  year: 'numeric',
})
export function parseBrazilianDate(dateStr: string): Date | null {
  const parts = dateStr.split('/')
  if (parts.length === 3) {
    const [day, month, year] = parts.map(Number)
    return new Date(year, month - 1, day)
  }
  return null
}
export function dateToFormattedString(date: Date | string): string {
  let dateObj: Date
  if (typeof date === 'string') {
    dateObj = parseBrazilianDate(date) || new Date(date)
  } else {
    dateObj = date
  }
  const formattedDate = monthYearFormatter.format(dateObj)
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
}
export function dateToFormattedStrings(dates?: string[]): string[] {
  return dates == null ? [] : dates.map(dateToFormattedString)
}
