import styled from 'styled-components'

type CardContainerProps = {
  isSelected: boolean
}

export const CardContainer = styled.article<CardContainerProps>`
  padding: 16px;
  border: 1px solid;
  border-color: ${props =>
    props.isSelected
      ? props.theme.primitiveTokens.colors.isaacBlue[30]
      : props.theme.primitiveTokens.colors.gray[10]};
  background-color: ${props =>
    props.isSelected && props.theme.primitiveTokens.colors.isaacBlue[10]};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${props => !props.isSelected && props.theme.primitiveTokens.colors.gray[2]};
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const BigNumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export const BigNumber = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin-right: 8px;
  line-height: 36px;
`
