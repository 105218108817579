import { JWTProps } from '@/modules/app/contexts/GlobalProviders/Auth/types'
import { createContext, ReactNode } from 'react'

type JWTProviderProps = {
  children: ReactNode
  props: JWTProps
}

export const JWTContext = createContext({} as JWTProps)

export const JWTProvider = ({ props, children }: JWTProviderProps) => {
  return <JWTContext.Provider value={props}>{children}</JWTContext.Provider>
}
