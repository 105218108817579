import { useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Button } from '@gravity/button'
import Icon from '@mdi/react'
import { mdiArrowRight } from '@mdi/js'

import { ReportCard } from '../../components/ReportCard'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useProfileDetails } from '@/escolas/pages/Home/hooks/useProfileDetails'

import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { REPORT_NAME } from '../../constants'
import { PaymentReportCard } from '../../components/PaymentReportCard'
import { TicketsSummaryReportCard } from '../../components/TicketsSummaryReportCard'
import { DemonstrativeIRReportCard } from '../../components/DemonstrativeIRReportCard'

import { Container } from './styles'

export const Reports = () => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const { isMaintainer } = useProfileDetails()

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Relatórios',
  })

  const isDemonstrativeIRReportEnabled = useUnleashFlag(
    UnleashFlags.REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT
  )

  const isIsaacPay = isIsaacPaySchool(school)

  const handleNavigateToFIDCReport = () => {
    push(`/${school?.slug}/relatorios/validacao-de-contratos`)

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.FIDC_REPORT,
        name: EventDispatcherEvents.NAVIGATION_TO_FIDC_REPORT,
        action: 'click',
      })
    }
  }

  const goToFamilyRegularizationReport = () => {
    push(`/${school?.slug}/relatorio/${REPORT_NAME['regularizacao-das-familias']}`)

    if (isInitialized)
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.FAMILY_REGULARIZATION_REPORT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Acessar relatório de regularização das famílias' },
      })
  }

  const goToReceiptsReport = () => {
    push(`/${school?.slug}/relatorio/${REPORT_NAME['visao-de-recebimentos']}`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.ISAAC_PAY_INCOME_PANEL,
        action: 'click',
        customProperties: { $Button_name: 'Acessar painel de recebimentos isaac pay' },
      })
  }

  const canViewIsaacPayIncomePanel = isIsaacPay && isMaintainer

  return (
    <Container>
      {canViewIsaacPayIncomePanel && (
        <ReportCard
          title="Visão de recebimentos"
          description="As principais informações sobre recebimentos, atualizadas ao longo do dia"
        >
          <Button
            variant="ghost"
            iconEnd={<Icon path={mdiArrowRight} />}
            onClick={goToReceiptsReport}
          >
            Acessar
          </Button>
        </ReportCard>
      )}

      <PaymentReportCard />

      <ReportCard
        title="Relatório de regularização das famílias"
        description="As principais informações sobre a inadimplência, cobrança e regularização das famílias."
      >
        <Button
          variant="ghost"
          iconEnd={<Icon path={mdiArrowRight} />}
          onClick={goToFamilyRegularizationReport}
        >
          Acessar
        </Button>
      </ReportCard>

      {isMaintainer && <TicketsSummaryReportCard />}

      {isDemonstrativeIRReportEnabled && <DemonstrativeIRReportCard />}

      {!isIsaacPay && (
        <ReportCard
          title="Relatório de validação de contratos 2024"
          description="Relatório com a evolução de alunos com contratos 2024 validados no meu isaac."
        >
          <Button
            variant="ghost"
            iconEnd={<Icon path={mdiArrowRight} />}
            onClick={handleNavigateToFIDCReport}
          >
            Acessar
          </Button>
        </ReportCard>
      )}
    </Container>
  )
}
