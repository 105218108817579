import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import { TextField, Typography } from '@olaisaac/design-system'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useIsReenrollment } from './hooks/useIsReenrollment'
import { FormProps } from './types'
import { useEffect } from 'react'

type StudentFormProps = {
  form: UseFormReturn<FormProps>
}

export const StudentForm = ({ form }: StudentFormProps) => {
  const { control } = form
  const { isReenrollment, student } = useIsReenrollment()

  useEffect(() => {
    form.setValue('student', student)
  }, [student])

  return (
    <FormControl fullWidth variant="outlined">
      <Box mb={3}>
        <Typography variation="headlineDesktopXsmall">Aluno(a)</Typography>
        <Typography variation="bodyLarge" color="secondary">
          Este é o nome do aluno(a) que será matrículado ou rematrículado.
        </Typography>
      </Box>
      <Controller
        rules={{ required: true }}
        name="student.name"
        control={control}
        render={({ field: { ...rest }, fieldState: { error } }) => (
          <TextField
            {...rest}
            id="student"
            disabled={isReenrollment}
            label="Nome do aluno"
            error={Boolean(error?.type)}
            helperText={error?.type ? 'Insira um nome ou selecione um aluno cadastrado' : ''}
          />
        )}
      />
    </FormControl>
  )
}
