import { FC } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { Content } from './styles'
import EntryStatusDialog from './EntryStatusDialog'
import StatusCheckout from '../components/StatusCheckout/StatusCheckout'
import { useNavigation, useSelectPos } from '@/escolas/hooks'
import EntryDetails from './steps/EntryDetails'
import SelectPaymentMethod from './steps/SelectPaymentMethod'
import useStepsEntryFlow from './hooks/useEntryFlowSteps'
import useEntryNegotiation from './hooks/useEntryNegotiation'
import Stepper from '@/escolas/components/Stepper'
import { useParams } from 'react-router-dom'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import EntryConclusion from './steps/EntryConclusion'

export const EntryNegotiation: FC = () => {
  const { guardianId } = useParams<{ guardianId: uuid }>()

  const {
    steps,
    currentStep,
    goBack,
    goToNextStep,
    handleOnFinishStep,
    handleStepperItemClick,
  } = useStepsEntryFlow()

  const { checkoutCart } = useCheckoutCart()

  const urn = checkoutCart[0].urn

  const {
    checkoutValues,
    showStatusDialog,
    statusDialogContent,
    isError,
    closeErrorDialog,
    handleOnNegotiationStep,
    currentVariantDialog,
    isLoading,
    entryInformation,
    negotiationIsLoading,
    negotiationData,
  } = useEntryNegotiation(goToNextStep, urn, guardianId)

  const posName = statusDialogContent.pos?.pdv_name

  const { schoolId } = useNavigation()
  useSelectPos(schoolId)

  const stepComponents = [
    <EntryDetails
      entryInformation={entryInformation}
      key="entry_details_step"
      goBack={goBack}
      onFinish={goToNextStep}
    />,
    <SelectPaymentMethod
      key="select_payment_method_step"
      entryInformation={entryInformation}
      goBack={goBack}
      onFinish={handleOnNegotiationStep}
      isLoading={negotiationIsLoading}
    />,
    <EntryConclusion
      key="conclusion_step"
      goBack={goBack}
      onFinish={handleOnFinishStep}
      conclusionInfo={negotiationData}
      entryInformation={entryInformation}
    />,
  ]

  return (
    <Grid container justifyContent="center">
      <Grid item xs={4} sm={12}>
        <Content>
          <Stepper
            disabled={isLoading}
            steps={steps}
            activeStepIndex={currentStep}
            onItemClick={handleStepperItemClick}
          />
          {isLoading ? <CircularProgress /> : stepComponents[currentStep]}
          <EntryStatusDialog
            isOpen={showStatusDialog}
            showErrorDialog={isError}
            closeErrorDialog={closeErrorDialog}
            variant={currentVariantDialog}
            posName={posName}
            {...statusDialogContent}
          />
          <StatusCheckout
            closeErrorDialog={checkoutValues.closeCheckoutErrorDialog}
            {...checkoutValues}
            pos={statusDialogContent.pos}
            shortId={statusDialogContent.shortId}
          />
        </Content>
      </Grid>
    </Grid>
  )
}

export default EntryNegotiation
