import { BaseSyntheticEvent, useState } from 'react'
import { Table, TableColumns, TableRowParams, Typography } from '@olaisaac/design-system'

import { MoneyTypography } from '@/escolas/components/report/layout/Typography'
import { ChangesChip } from '@/escolas/components/report/Chips/ChangesChip'

import { formatDate, prettifyFullName } from '@/shared/utils'

import { TransactionEventType } from '@/shared/interfaces'
import type { FinancialTransactionProps, TransactionEvents } from '@/shared/interfaces'

import { Container } from './styles'

type TableData = {
  contract_reference_year: string
  events?: TransactionEvents
  id: string
  installment: string
  key: string
  product_name: string
  receipt_date: datestring
  student_name: string
  totalAmount: number
}

type IsaacPayReportTableProps = {
  isFilterEmpty?: boolean
  isLoading?: boolean
  isNewVersion: boolean
  onRowClick: (params: TableRowParams<TableData>, event: BaseSyntheticEvent) => void
  transactions: FinancialTransactionProps[]
}

export const IsaacPayReportTable = ({
  isFilterEmpty = false,
  isLoading,
  onRowClick,
  transactions,
}: IsaacPayReportTableProps) => {
  const [selectedRow, setSelectedRow] = useState<string>()

  const handleRowClick = (params: TableRowParams<TableData>, event: BaseSyntheticEvent) => {
    setSelectedRow(params.row.id)
    onRowClick(params, event)
  }

  const rows = transactions.map(item => {
    return {
      id: item.referrals.installment_id,
      key: item.referrals.installment_id,
      installment: item.referrals.installment_order_reference,
      student_name: prettifyFullName(item.referrals.student_name),
      product_name: item.referrals.product_name,
      contract_reference_year: item.referrals.contract_reference_year || '2023',
      totalAmount: item.financial_detail.total,
      events: item.financial_detail.events,
      receipt_date: item.referrals.last_receivable_paid_date,
      reference_month: item.reference_month,
    }
  }) as TableData[]

  const tableColumns: TableColumns = [
    {
      headerName: 'Aluno',
      field: 'student_name',
      enableSorting: true,
    },
    {
      headerName: 'Produto',
      field: 'product_name',
    },
    {
      headerName: 'Competência',
      field: 'reference_month',
      enableSorting: false,
      valueFormatter: (value: string) => formatDate(value, 'MMM YYYY'),
    },
    {
      headerName: 'Valor final',
      field: 'totalAmount',
      renderCell: (value: number) => <MoneyTypography amount={value} />,
    },
    {
      headerName: 'Mudanças',
      field: 'events',
      enableSorting: false,
      renderCell: (values: TransactionEvents = {}) => (
        <ChangesChip
          tags={Object.keys(values) as TransactionEventType[]}
          limit={2}
          style={{ margin: 4 }}
        />
      ),
    },
  ]

  return (
    <Container>
      <Table<TableData>
        columns={tableColumns}
        rows={rows}
        sorted={false}
        isLoading={isLoading}
        rowsSelected={selectedRow ? [selectedRow] : undefined}
        emptyRender={
          <Typography>
            {isFilterEmpty
              ? 'Não houveram pagamentos de matrículas neste período.'
              : 'Nenhum resultado encontrado'}
          </Typography>
        }
        onRowClick={handleRowClick}
      />
    </Container>
  )
}
