import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  FetchInstallmentsToUpdateRequest,
  FetchInstallmentsToUpdateResponse,
  UpdateInstallmentsRequest,
  CancelInstallmentsRequest,
  Installment,
  ContractDetailsRequest,
} from '@/modules/contract/services/types'
import { contractAPI } from '@/modules/contract/services'
import { sortInstallmentsToUpdate } from '../../utils/sortInstallmentsToUpdate'
import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'
import {
  ContractCancellationReason,
  InstallmentStatuses,
  InstallmentType,
} from '@/shared/interfaces'
import { canCancelInstallment } from '../../utils'

export const useInstallmentsToUpdateQuery = (
  api: contractAPI,
  { contractId, schoolId }: FetchInstallmentsToUpdateRequest,
  options?: UseQueryOptions<FetchInstallmentsToUpdateResponse>
) => {
  const queryKey = ['update-contract', contractId, schoolId]

  return useQuery<FetchInstallmentsToUpdateResponse>(
    queryKey,
    async () => {
      const response = await api.fetchInstallmentsToUpdate({
        contractId,
        schoolId,
      })

      return {
        ...response,
        data: {
          ...response.data,
          installments: sortInstallmentsToUpdate(response.data.installments),
        },
      }
    },
    options
  )
}

type UpdateInstallmentsMutationParams = {
  contractId: uuid
  correlationID: uuid
  schoolId: string
  updateInstallmentsRequest: UpdateInstallmentsRequest
}

export const useUpdateInstallmentsMutation = (api: contractAPI) => {
  return useMutation(
    (params: UpdateInstallmentsMutationParams) => {
      return api.updateInstallments(
        params.schoolId,
        params.contractId,
        params.updateInstallmentsRequest,
        params.correlationID
      )
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries(['update-contract'])
        return response
      },
    }
  )
}

export const useCancelInstallmentsMutation = (api: contractAPI) => {
  return useMutation(
    (params: CancelInstallmentsRequest) => {
      return api.cancelInstallments(params)
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries(['update-contract'])
        return response
      },
    }
  )
}

type HandleCancelContractParams = {
  contractId: uuid
  correlationID: uuid
  description?: string
  installments: Installment[]
  reason: ContractCancellationReason
  schoolId: uuid
}

/**
 * The cancel contract api does not cancel overdue installments for school users.
 * To work around this issue, we first cancel the overdue installments, then cancel the contract,
 * which also cancels the open installments.
 */
const handleCancelContract = async (
  api: contractAPI,
  {
    contractId,
    correlationID,
    schoolId,
    installments,
    description = 'empty',
    reason,
  }: HandleCancelContractParams
) => {
  const cancellableInstallments = installments.filter(i => canCancelInstallment(i))

  const overdueInstallmentIDs = cancellableInstallments
    .filter(i => i.status === InstallmentStatuses.OVERDUE)
    .map(i => i.backoffice_installment_id)

  if (overdueInstallmentIDs.length > 0) {
    await api.cancelInstallments({
      contractId: contractId,
      correlationID: correlationID,
      installmentIDs: overdueInstallmentIDs,
      schoolId: schoolId,
    })
  }

  const firstInstallmentId = cancellableInstallments.find(
    i =>
      i.receivable_type === InstallmentType.TUITION &&
      (i.status === InstallmentStatuses.OPEN || i.status === InstallmentStatuses.DUE_TODAY)
  )?.backoffice_installment_id

  await api.cancelContract({
    contractId: contractId,
    correlationID: correlationID,
    description: description,
    firstInstallmentId: firstInstallmentId,
    reason: reason,
    schoolId: schoolId,
    triggeredBy: 'EDIT_CONTRACT',
  })
}

export const useCancelContractMutation = (api: contractAPI) => {
  return useMutation(
    (params: HandleCancelContractParams) => {
      return handleCancelContract(api, params)
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries(['update-contract'])
        await queryClient.invalidateQueries(['guardian-details-contracts'])
        return response
      },
    }
  )
}

export const useContractDetailsQuery = (
  api: contractAPI,
  { contractId }: ContractDetailsRequest
) => {
  return useQuery(['contract-details', contractId], async () => {
    const response = await api.contractDetails({ contractId })

    return response.data
  })
}
