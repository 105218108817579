import { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'
import Icon from '@mdi/react'
import { mdiLoading } from '@mdi/js'
import { Table } from '@gravity/table'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { NotificationProps } from '@olaisaac/design-system'

import StatusBadge, { StatusBadgeColor } from '@/escolas/components/StatusBadge'
import { AgreementStatus, SimulationPaymentMethod } from '@/shared/interfaces'
import { formatCentsToReal, formatDate } from '@/shared/utils'
import { useSnackbar } from '@/shared/hooks'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'
import { useGuardianNegotiationQuery } from '@/modules/guardians/hooks/useGuardianNegotiationQuery'

import {
  Wrapper,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableCustomCell,
  TableBadge,
} from './styles'

type PaymentTableProps = {
  agreementStatus: AgreementStatus
}

export const PaymentTable = ({ agreementStatus }: PaymentTableProps) => {
  const [invoiceURL, setInvoiceURL] = useState<string>()

  const sendCheckoutEvent = useSendCheckoutEvent()
  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const { guardianNegotiation, refetchGuardianNegotiation } = useGuardianNegotiationQuery(
    {
      id: agreementStatus.negotiationId,
    },
    {
      enabled: false,
    }
  )

  const paymentMethod: Partial<Record<SimulationPaymentMethod, string>> = {
    [SimulationPaymentMethod.BANK_SLIP]: 'Boleto e Pix',
    [SimulationPaymentMethod.CREDIT_CARD]: 'Cartão de crédito',
    [SimulationPaymentMethod.DEBIT_CARD]: 'Cartão de débito',
    [SimulationPaymentMethod.PIX]: 'Pix',
  }

  const getStatusBadge = (status?: string): { children: string; color: StatusBadgeColor } => {
    if (status === 'waiting') {
      return {
        color: 'primary',
        children: 'A vencer',
      }
    }

    return {
      color: 'primary',
      children: 'Aguardando pagamento da entrada',
    }
  }

  const handleShowSnackbar = (variation: NotificationProps['variation'], message: string) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  const handleCopyInvoiceLink = (link: string) => {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_COPY_LINK, {
      name: 'Copiar link de cobrança',
    })

    copy(link)
    handleShowSnackbar('success', 'Link copiado com sucesso')
  }

  const handleNewTabInvoiceLink = (link: string) => {
    window.open(link, '_blank')
  }

  useEffect(() => {
    const interval = setInterval(() => {
      agreementStatus?.invoices?.forEach(invoice => {
        if (!invoice.payment_link) {
          refetchGuardianNegotiation()
          return
        }
        setInvoiceURL(invoice.payment_link)
      })
    }, 3000)
    return () => clearInterval(interval)
  }, [agreementStatus?.invoices])

  useEffect(() => {
    if (guardianNegotiation?.data) {
      const invoiceURL = guardianNegotiation?.data?.down_payment?.invoice_url
      setInvoiceURL(invoiceURL)
    }
  }, [guardianNegotiation?.data])

  return (
    <Wrapper>
      <Table.Root>
        <TableHead>
          <TableHeaderCell minWidth={126} name="installment">
            Parcelas
          </TableHeaderCell>
          <TableHeaderCell minWidth={185} name="payment_method">
            Meio de pagamento
          </TableHeaderCell>
          <TableHeaderCell minWidth={126} name="due_date">
            Vencimento
          </TableHeaderCell>
          <TableHeaderCell minWidth={126} align="end" name="amount">
            Valor
          </TableHeaderCell>
          <TableHeaderCell minWidth={126} name="status">
            Situação
          </TableHeaderCell>
          <TableHeaderCell name="payment_link">Link de pagamento</TableHeaderCell>
        </TableHead>
        <TableBody>
          {agreementStatus?.invoices?.map(invoice => (
            <Table.Row key={invoice?.id} highlighOnHover={false}>
              <Table.TextCell>Entrada</Table.TextCell>
              <Table.TextCell>{paymentMethod[invoice?.payment_method]}</Table.TextCell>
              <Table.TextCell>{formatDate(invoice?.due_date, 'DD/MM/YYYY')}</Table.TextCell>
              <Table.TextCell align="end">{formatCentsToReal(invoice?.amount)}</Table.TextCell>
              <TableCustomCell>
                <TableBadge>
                  <StatusBadge color={getStatusBadge('waiting').color} />
                  {getStatusBadge('waiting').children}
                </TableBadge>
              </TableCustomCell>
              {invoiceURL ? (
                <TableCustomCell>
                  <Button
                    variant="ghost"
                    size={1}
                    onClick={() => handleCopyInvoiceLink(invoiceURL)}
                  >
                    Copiar link
                  </Button>
                  <Button
                    variant="ghost"
                    size={1}
                    onClick={() => handleNewTabInvoiceLink(invoiceURL)}
                  >
                    Imprimir
                  </Button>
                </TableCustomCell>
              ) : (
                <TableCustomCell small>
                  <Icon path={mdiLoading} spin size={1} />
                  <Text variant="body-2-regular">Gerando sua fatura</Text>
                </TableCustomCell>
              )}
            </Table.Row>
          ))}
        </TableBody>
      </Table.Root>
    </Wrapper>
  )
}
