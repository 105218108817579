import { Table } from '@gravity/table'
import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import { CircularProgress } from '@material-ui/core'
import * as Styled from './styles'
import { TableRowProps, ColumnProps, RowActionTypes } from './types'
import { mdiTrashCan, mdiUndo } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { IconButton } from '@gravity/icon-button'

interface StudentsTableProps {
  disableActions: boolean
  disabledRows: string[]
  isLoading: boolean
  onActionRow: (rowKey: string, action: RowActionTypes) => void
  rows: TableRowProps[]
}

interface ActionButtonProps {
  icon: string
  onClick: () => void
}

const COLUMN_HEADERS = [
  { key: 'student_column', label: 'Aluno' },
  { key: 'main_guardian_column', label: 'Responsável' },
  { key: 'product_column', label: 'Produto de origem' },
  { key: 'student_status_column', label: 'Situação' },
  { key: 'actions_column', label: '' },
]

export const StudentsTable = ({
  rows,
  isLoading,
  disabledRows,
  disableActions,
  onActionRow,
}: StudentsTableProps) => {
  if (isLoading) {
    return (
      <Styled.LoadingContainer>
        <CircularProgress />
      </Styled.LoadingContainer>
    )
  }

  if (!rows?.length) {
    return <Styled.EmptyStateContainer>Nenhum aluno encontrado</Styled.EmptyStateContainer>
  }

  const [hoveredRow, setHoveredRow] = useState(null)

  const isRowDisabled = (rowKey: string) => disabledRows.includes(rowKey)

  const ActionButton = ({ onClick, icon }: ActionButtonProps) => (
    <IconButton onClick={onClick} variant="ghost">
      <Icon path={icon} />
    </IconButton>
  )

  return (
    <Table.Root>
      <Table.Head>
        {COLUMN_HEADERS.map(({ key, label }: ColumnProps) => (
          <Table.HeaderCell key={key} name={key}>
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>
      <Table.Body>
        {rows.map(row => {
          const disabled = isRowDisabled(row.key)
          return (
            <Table.Row
              key={row.key}
              onMouseEnter={() => setHoveredRow(row.key)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <Table.TextCell disabled={disabled}>{row.columns[0]}</Table.TextCell>
              <Table.TextCell disabled={disabled}>{row.columns[1]}</Table.TextCell>
              <Table.TextCell disabled={disabled}>{row.columns[2]}</Table.TextCell>
              <Table.CustomCell disabled={disabled}>
                <StatusBadge status={row.columns[3]} disabled={isRowDisabled(row.key)} />
              </Table.CustomCell>
              {disableActions ? (
                <Table.CustomCell disabled={disabled}>
                  <></>
                </Table.CustomCell>
              ) : (
                <Table.CustomCell disabled={disabled}>
                  <Styled.ActionContainer>
                    {hoveredRow === row.key && (
                      <ActionButton
                        onClick={() =>
                          onActionRow(
                            row.key,
                            isRowDisabled(row.key) ? RowActionTypes.UNDO : RowActionTypes.REMOVE
                          )
                        }
                        icon={isRowDisabled(row.key) ? mdiUndo : mdiTrashCan}
                      />
                    )}
                  </Styled.ActionContainer>
                </Table.CustomCell>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table.Root>
  )
}
