import { RouteDefinition } from '@/modules/core/router'
import { AccessManagement } from '../pages/AccessManagement'

export const routes: RouteDefinition[] = [
  {
    key: 'ACCESS_MANAGEMENT',
    path: '/acessos',
    component: AccessManagement,
    exact: true,
  },
]
