import { Button, Pagination, TableControlBar, Typography } from '@olaisaac/design-system'
import { Table, TableBody, TableHead, TableRow as MUITableRow, Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { VisibilityOffRounded } from '@material-ui/icons'

import dayjs from 'dayjs'

import { TableCell } from '../Table/TableCell'
import { TableColumnCell, TableRow, trimText } from '../Table'
import { StatusBadge } from '../StatusBadge'
import { CategoryTag } from '../CategoryTag'
import { NotificationModal } from '../NotificationModal'

import { useQuery } from '@/shared/hooks/useQuery'
import { useNotificationTable } from './useNotificationTable'

import type { Category, NotificationStatus } from '../../types'
import type { NotificationsSectionProps } from './types'

import {
  NOTIFICATION_MAX_COUNT,
  NOTIFICATION_MIN_COUNT,
  NOTIFICATION_STATUS,
} from '../../constants'

import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens
const { ghost } = theme.humanizedTokens.components.button

const getCellStyle = (status: NotificationStatus) =>
  status === NOTIFICATION_STATUS.UNREAD ? { fontWeight: 600 } : {}

const getStatusBadgeVariation = (status: NotificationStatus) =>
  status === NOTIFICATION_STATUS.UNREAD ? 'coral' : 'green'

const getStatusMessage = (category: Category, status: NotificationStatus) =>
  ({
    [NOTIFICATION_STATUS.READ]: category === 'tarefas' ? 'Lida' : NOTIFICATION_STATUS.READ,
    [NOTIFICATION_STATUS.UNREAD]: category === 'tarefas' ? 'Não lida' : NOTIFICATION_STATUS.UNREAD,
  }[status])

const ITENS_PER_PAGE_OPTIONS = [NOTIFICATION_MIN_COUNT, NOTIFICATION_MAX_COUNT]

export const NotificationsSection = (props: NotificationsSectionProps) => {
  const { notifications, isLoading, page, itensPerPage, totalItems, onNotificationRead } = props

  const { query } = useQuery()
  const {
    rows,
    selectedRow,
    selectedRowIndex,
    resetSelectedRow,
    handlePageChange,
    handleItensPerChangeChange,
    handleKeyDown,
    handleRowClick,
    handleUnreadFilterClick,
  } = useNotificationTable({ notifications, isLoading })

  return (
    <section>
      <Box display="grid">
        <Typography as="h2" variation="headlineDesktopSmall">
          Lista de avisos
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginTop={spacing[4]}
          marginBottom={spacing[8]}
        >
          <Button
            variation="ghost"
            startIcon={<VisibilityOffRounded />}
            onClick={handleUnreadFilterClick}
            style={{
              background:
                query.get('status') === NOTIFICATION_STATUS.UNREAD
                  ? ghost.press.background_color
                  : '',
            }}
          >
            Apenas não lidos
          </Button>
        </Box>

        <Table>
          <TableHead>
            <MUITableRow>
              <TableColumnCell width="148px">Categoria</TableColumnCell>
              <TableColumnCell width="148px">Data</TableColumnCell>
              <TableColumnCell width="148px">Status</TableColumnCell>
              <TableColumnCell>Assunto</TableColumnCell>
              <TableColumnCell>Aviso</TableColumnCell>
            </MUITableRow>
          </TableHead>

          <TableBody>
            {isLoading
              ? [1, 2, 3, 4, 5].map(i => (
                  <TableRow key={i}>
                    {[1, 2, 3, 4, 5].map(j => (
                      <TableCell key={j}>
                        <Skeleton width="100%" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    onRowClick={() => {
                      handleRowClick(index)
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        handleKeyDown(index)
                      }
                    }}
                  >
                    <TableCell width="148px">
                      <CategoryTag
                        variation={row.category}
                        inactive={row.status === NOTIFICATION_STATUS.READ}
                      >
                        <Typography
                          style={{ ...getCellStyle(row.status), textTransform: 'capitalize' }}
                        >
                          {row.category}
                        </Typography>
                      </CategoryTag>
                    </TableCell>
                    <TableCell width="148px">
                      <Typography style={getCellStyle(row.status)}>
                        {dayjs(row.date).format('DD/MM/YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell width="148px">
                      <StatusBadge variation={getStatusBadgeVariation(row.status)}>
                        <Typography style={getCellStyle(row.status)}>
                          {getStatusMessage(row.category, row.status)}
                        </Typography>
                      </StatusBadge>
                    </TableCell>
                    <TableCell>
                      <Typography style={getCellStyle(row.status)}>{row.title}</Typography>
                    </TableCell>
                    <TableCell>{trimText(row.content)}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {!isLoading && rows.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" marginTop={spacing[8]}>
            <Typography variation="bodySmall" color="secondary" withoutMargin>
              Nenhum aviso encontrado
            </Typography>
          </Box>
        )}
      </Box>
      {!isLoading && rows.length > 0 && (
        <TableControlBar>
          <Pagination
            currentPage={page}
            itensPerPage={itensPerPage}
            totalItens={totalItems}
            onPageChange={handlePageChange}
            onItensPerChangeChange={handleItensPerChangeChange}
            itensPerPageOptions={ITENS_PER_PAGE_OPTIONS}
          />
        </TableControlBar>
      )}
      {selectedRow && !isLoading && (
        <NotificationModal
          open={!!selectedRow}
          onClose={() => {
            !!query.get('id') && onNotificationRead(selectedRowIndex, query.get('id'))

            resetSelectedRow()
          }}
          notification={selectedRow}
        />
      )}
    </section>
  )
}
