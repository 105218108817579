import type { ReactNode } from 'react'

import { BigNumberPrimitives as BigNumber } from '@/modules/enrollment/components/BigNumber'

type Props = {
  description: string
  onClick?: () => void
  title: string | ReactNode
  value: string
}
export const View = ({ value, description, onClick, title }: Props) => {
  return (
    <BigNumber.Root onClick={onClick}>
      <BigNumber.Title>{title}</BigNumber.Title>
      <BigNumber.Value>{value}</BigNumber.Value>
      <BigNumber.Description>{description}</BigNumber.Description>
    </BigNumber.Root>
  )
}
