import { useHistory, useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { SelectPrimitives } from '@gravity/select'
import { Button } from '@gravity/button'
import Icon from '@mdi/react'
import { mdiChartLine, mdiPlus } from '@mdi/js'
import { useEffect, useMemo, useState } from 'react'

import { ErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { PageWrapper } from '@/escolas/components/PageWrapper'
import { useEnrollmentCampaignRedirect } from '@/modules/enrollment/hooks/useEnrollmentCampaignRedirect'
import { Pagination } from '@olaisaac/design-system'
import { usePagination } from '@/shared/hooks/usePagination'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { Callout } from '@gravity/callout'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { useSelectedYear } from './hooks/useSelectedYear'
import { useCampaignsList } from './hooks/useCampaignsList'
import { CampaignsTable } from './components/CampaignsTable'
import { CampaignsFilter } from './components/CampaignsFilter'
import type { SortableColumns } from './components/CampaignsTable/types'
import * as Styled from './styles'
import { useEnrollmentMixpanelEvents } from '@/modules/enrollment/hooks/useEnrollmentMixpanelEvents'

type URLParams = {
  year: string
}

export const CampaignsList = () => {
  useEnrollmentCampaignRedirect()

  useSetPageTitle('Campanhas de rematrícula: - isaac')

  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const { year: urlYearParam } = useParams<URLParams>()
  const {
    hasErrorOnFetchCycles,
    selectedEnrollmentCycle,
    selectedCycleYear,
    years,
    updateCycleYear,
    isFetchingCycles,
  } = useSelectedYear(urlYearParam)

  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const isCampaignReportEnabled = useUnleashFlag(
    UnleashFlags.MAT_367_ENABLE_CAMPAIGN_METABASE_REPORT
  )

  const isCampaignDetailsEnabled = useUnleashFlag(UnleashFlags.MAT_343_ENABLE_CAMPAIGN_DETAILS)

  const currentYear = new Date().getFullYear()
  const isAddNewCampaignBtnDisabled =
    selectedEnrollmentCycle?.enabled === false &&
    selectedEnrollmentCycle.reference_year < currentYear

  const { pagination, updatePaginationValue, updatePagination } = usePagination(1, 10)
  const { ordering, updateOrdering } = useOrdering<SortableColumns>({
    sortBy: 'created_at',
    sortOrder: 'DESC',
  })

  const defaultNoDataMessage = 'Nenhuma campanha foi criada até o momento.'

  const [campaignsStatusFilter, setCampaignsStatusFilter] = useState<string[]>([])
  const [noDataMessage, setNoDataMessage] = useState<string>(defaultNoDataMessage)

  const { campaigns, tablePagination, isSuccess, isLoading, refetch } = useCampaignsList({
    schoolId: selectedEnrollmentCycle?.school_id,
    schoolEnrollmentCycleId: selectedEnrollmentCycle?.school_enrollment_cycle_id,
    campaignsStatusFilter: campaignsStatusFilter,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
      sort_by: ordering.sortBy,
      sort_order: ordering.sortOrder,
    },
  })

  const tableRows = useMemo(() => {
    return campaigns.map(campaign => ({
      columns: [
        campaign.name,
        campaign.product_name,
        campaign.students_count.toString(),
        campaign.engaged_students_count.toString(),
        campaign.end_date,
        campaign.status,
        campaign.closed_type,
        String(campaign.total_of_campaign_emails_read ?? 0),
      ],
      hasPaymentPlan: campaign.has_payment_plan,
      key: campaign.id,
    }))
  }, [campaigns])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetch()
  }, [selectedCycleYear, campaignsStatusFilter, ordering])

  const handleFilterChange = (value: string) => {
    switch (value) {
      case 'OPEN':
        setCampaignsStatusFilter(['OPEN'])
        setNoDataMessage(defaultNoDataMessage)
        break
      case 'ENDED':
        setCampaignsStatusFilter(['CLOSED', 'CANCELED'])
        setNoDataMessage('Nenhuma campanha foi encerrada até o momento.')
        break
      default:
        setCampaignsStatusFilter([])
        setNoDataMessage(defaultNoDataMessage)
    }
    updatePagination({ page: 1, itemsPerPage: 10 })
  }

  const handleOnClickRow = (key: string) => {
    const truncateStr = (str: string, length: number) => {
      if (str.length <= length) return str
      if (length < 3) return str.substring(0, length)
      return `${str.substring(0, length - 3)}...`
    }

    const campaign = campaigns.find(c => c.id === key)
    const searchParams = new URLSearchParams()

    searchParams.append(
      'school_enrollment_cycle_id',
      selectedEnrollmentCycle?.school_enrollment_cycle_id
    )
    if (isCampaignDetailsEnabled) {
      return history.push({
        pathname: `/${schoolSlug}/campanha/${key}`,
        search: searchParams.toString(),
      })
    }
    searchParams.append('name', truncateStr(campaign.name, 200))
    searchParams.append('product_name', truncateStr(campaign.product_name, 200))
    searchParams.append('status', campaign.status)
    searchParams.append('end_date', campaign.end_date)
    searchParams.append('has_payment_plan', campaign.has_payment_plan ? '1' : '0')
    return history.push({
      pathname: `/${schoolSlug}/campanha/${key}`,
      search: searchParams.toString(),
    })
  }

  return (
    <PageWrapper>
      <Styled.PageContainer>
        <ErrorDialog isError={hasErrorOnFetchCycles || (!isFetchingCycles && years.length === 0)} />

        <Styled.ActionsContainer>
          <Box flexDirection="column" order="1">
            <Box display="flex" flexDirection="row">
              <Styled.SelectYearTitle>Campanhas de rematrícula:</Styled.SelectYearTitle>

              <Styled.SelectYearWrapper>
                <SelectPrimitives.Root
                  onValueChange={updateCycleYear}
                  value={String(selectedCycleYear)}
                  aria-label="Campanhas de matrícula"
                >
                  <Styled.SelectLabelWrapper>
                    <SelectPrimitives.Trigger aria-label="Campanhas de matrícula" />
                  </Styled.SelectLabelWrapper>
                  <SelectPrimitives.Content>
                    {years.map(year => (
                      <SelectPrimitives.Item key={year} value={String(year)}>
                        {year}
                      </SelectPrimitives.Item>
                    ))}
                  </SelectPrimitives.Content>
                </SelectPrimitives.Root>
              </Styled.SelectYearWrapper>
            </Box>
          </Box>

          <Styled.ActionButtonsWrapper>
            {isCampaignReportEnabled && (
              <Button
                variant="outline"
                iconStart={<Icon path={mdiChartLine} />}
                onClick={() => {
                  sendButtonClickEvent('ver_relatorio')
                  history.push({ pathname: `/${schoolSlug}/relatorio/campanha-de-rematricula` })
                }}
                disabled={isAddNewCampaignBtnDisabled}
              >
                Ver relatório
              </Button>
            )}

            <Button
              iconStart={<Icon path={mdiPlus} />}
              onClick={() => {
                sendButtonClickEvent('nova_campanha')
                history.push({ pathname: `/${schoolSlug}/campanha/novo` })
              }}
              disabled={isAddNewCampaignBtnDisabled}
            >
              Nova campanha
            </Button>
          </Styled.ActionButtonsWrapper>
        </Styled.ActionsContainer>

        <Box mb={4}>
          <CampaignsFilter onChange={handleFilterChange} />
        </Box>
        <Callout
          text="Atenção: A adição de mensalidades para alunos em campanha está em desenvolvimento. Em
          breve, será possível adicioná-las em lote ou individualmente através do detalhamento da
          campanha."
        />
        <CampaignsTable
          rows={isSuccess ? tableRows : []}
          isLoading={isLoading}
          ordering={ordering}
          onChangeOrdering={(sortBy, sortOrder) => {
            updatePaginationValue('page', 1)
            updateOrdering({ sortBy, sortOrder })
          }}
          noDataMessage={noDataMessage}
          onClickRow={handleOnClickRow}
        />
        <Box my={2} px={2} data-testid="campaign-table-pagination">
          <Pagination
            currentPage={pagination.page}
            itensPerPage={pagination.itemsPerPage}
            itensPerPageOptions={[10, 15, 25, 50]}
            totalItens={(isSuccess && tablePagination?.total) ?? 0}
            onPageChange={newPage => {
              updatePaginationValue('page', newPage)
            }}
            onItensPerChangeChange={newItensPerPage => {
              updatePaginationValue('itemsPerPage', newItensPerPage)
            }}
          />
        </Box>
      </Styled.PageContainer>
    </PageWrapper>
  )
}
