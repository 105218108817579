import { useEffect, useState } from 'react'
import { usePayoutDetailsQuery, usePayoutsQuery } from 'src/escolas/hooks/queries'

import { getElapsedPayouts, getPayoutByMonth } from '@/escolas/pages/Home/utils/payouts'

import {
  CURRENT_MONTH_NUMBER,
  CURRENT_YEAR,
  DECEMBER_MONTH_NUMBER,
  NEXT_MONTH_NUMBER,
} from '@/escolas/pages/Home/constants'

export const useLoadPayouts = (schoolId: string, enabled = false) => {
  const [nextPayoutId, setNextPayoutId] = useState<uuid>('')

  // Get general infos of ALL the avaialable payouts of the school
  const {
    data: payoutsData,
    isFetching: isLoadingPayouts,
    isError: isErrorPayouts,
    refetch: refetchPayouts,
  } = usePayoutsQuery({
    schoolId,
    options: {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  })

  useEffect(() => {
    if (payoutsData) {
      const hasToIncrementYear = CURRENT_MONTH_NUMBER === DECEMBER_MONTH_NUMBER
      const nextPayoutYear = hasToIncrementYear ? CURRENT_YEAR + 1 : CURRENT_YEAR
      const filteredPayouts = getElapsedPayouts(payoutsData, NEXT_MONTH_NUMBER, nextPayoutYear)
      const nextPayout = getPayoutByMonth(filteredPayouts, NEXT_MONTH_NUMBER, nextPayoutYear)

      if (nextPayout?.payout_id) {
        setNextPayoutId(nextPayout.payout_id)
      }
    }
  }, [payoutsData])

  // Get the NEXT month payout value
  const {
    data: nextPayoutData,
    isFetching: isLoadingNextPayout,
    isError: isErrorNextPayout,
    refetch: refetchNextPayout,
  } = usePayoutDetailsQuery(
    'tuition',
    {
      payoutId: nextPayoutId,
    },
    {
      enabled: enabled && !!nextPayoutId,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  // Refetch only requests with error to avoid unnecessary requests
  const refetch = () => {
    if (isErrorPayouts) {
      refetchPayouts()
    }

    if (isErrorNextPayout) {
      refetchNextPayout()
    }
  }

  return {
    data: {
      payouts: payoutsData,
      nextPayout: nextPayoutData,
    },
    isLoading: isLoadingPayouts || isLoadingNextPayout,
    isError: isErrorPayouts || isErrorNextPayout,
    refetch,
  }
}
