import styled from 'styled-components'
import { spacing, colors, borderRadius } from '@gravity/tokens'
import { Button } from '@gravity/button'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 308px;
  background-color: ${colors['colors-background-accent-3']};
  border-radius: ${borderRadius[2]};
  padding: ${spacing[5]};
`

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReturnButton = styled(Button)`
  justify-content: flex-start;
`
