import React from 'react'
import * as Styled from './styles'
import { Tooltip } from '@material-ui/core'
import { Chip } from '@olaisaac/design-system'

export const Product = ({ mainProductName, otherProductsNames }) => {
  const mainProductNameFormat = mainProductName || '-'
  const otherProductsNamesCount = otherProductsNames?.length || 0

  return (
    <Styled.ProductsContainer>
      {mainProductNameFormat}
      <Styled.OtherProductsContainer>
        {otherProductsNamesCount > 0 && (
          <Tooltip title={otherProductsNames.join(', ')}>
            <Chip label={`+${otherProductsNamesCount}`} />
          </Tooltip>
        )}
      </Styled.OtherProductsContainer>
    </Styled.ProductsContainer>
  )
}
