import { useFlag, useFlagsStatus, useVariant } from '@unleash/proxy-client-react'

export enum UnleashFlags {
  B2BCOR_193_BLOCK_CONTRACT_REVOKE = 'B2BCOR-193-BLOCK_CONTRACT_REVOKE',
  B2CB_984_DISABLE_MANUAL_LIQUIDATION = 'B2CB-984_DISABLE_MANUAL_LIQUIDATION',

  BOT_1590_ENABLE_CHECKOUT_FALLBACK = 'BOT_1590_ENABLE_CHECKOUT_FALLBACK',

  /**
   * Determines if credit module is enabled
   */
  CRED_883_ENABLE_GRACE_PERIOD_TO_CREDIT_MODULE = 'CRED_883_ENABLE_GRACE_PERIOD_TO_CREDIT_MODULE',
  CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE = 'CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE',
  EFI_168_CONTRACT_FORM_IMPROVEMENTS = 'EFI-168_CONTRACT_FORM_IMPROVEMENTS',
  ENABLE_CLASSAPP_CAMPAIGN_NOTIFICATION = 'MAT-94-ENABLE_CLASSAPP_CAMPAIGN_NOTIFICATION',
  ENABLE_MAINTENANCE_PAGE = 'ENABLE_MAINTENANCE_PAGE',
  ENABLE_MATRICULAS_CAMPAIGN = 'EEMAT-897-ENABLE_MATRICULAS_CAMPAIGN',
  ENABLE_MATRICULAS_SELECT_CYCLES = 'EEMAT-604-ENABLE_MATRICULAS_SELECT_CYCLES',
  ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS = 'GC-1784-ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS',
  ENABLE_STUDENT_SUBSTATUS_FILTER = 'EEMAT-852-ENABLE_STUDENT_SUBSTATUS_FILTER',
  ENABLE_UNIFIED_CONTRACT_EDITING = 'EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING',
  ENGAJAMENTO_PAS392_CANCELLATION_PRECONTRACT = 'ENGAJAMENTO_PAS-392_CANCELLATION_PRECONTRACT',
  ENGAJAMENTO_PAS444_EDIT_PRECONTRACT = 'ENGAJAMENTO_PAS-444_EDIT_PRECONTRACT',
  FII_321_ENABLE_CANCEL_INSTALLMENTS = 'FII-321_ENABLE_CANCEL_INSTALLMENTS',
  FII_329_ENABLE_EDIT_INSTALLMENT_AMOUNT = 'FII-329_ENABLE_EDIT_INSTALLMENT_AMOUNT',
  FII_394_ENABLE_CANCEL_CONTRACT_VIA_INSTALLMENTS = 'FII-394_ENABLE_CANCEL_CONTRACT_VIA_INSTALLMENTS',
  FII_97_ENABLE_INACTIVE_CONTRACT_CANCELLING = 'FII-97_ENABLE_INACTIVE_CONTRACT_CANCELLING',
  /**
   * Determines if the new RF pages are enabled
   */
  GC_973_B2BCORE_122_EDIT_RF = 'GC_973_B2BCORE_122_EDIT_RF',
  INADIMPLENCIA_PAGE_ENABLED = 'INADIMPLENCIA_PAGE_ENABLED',
  INT_43_ENABLE_CANCEL_IRREGULARITY_BUTTON = 'INT_43_ENABLE_CANCEL_IRREGULARITY_BUTTON',
  IS_INTEGRATED_SCHOOL = 'is_integrated_school',
  /**
   * Determines if current school is included at ActiveSoft schools list
   */
  IS_NOT_ACTIVESOFT_SCHOOL = 'IS_NOT_ACTIVESOFT_SCHOOL',
  ISAAC_PAY_102_ENABLE_EXTERNAL_PAYOUT_TABLE = 'ISAAC_PAY_102_ENABLE_EXTERNAL_PAYOUT_TABLE',
  ISAAC_PAY_99_ENABLE_CALENDAR_TOGGLE = 'ISAAC_PAY_99_ENABLE_CALENDAR_TOGGLE',
  MAT_304_MATRICULAS_PREVENT_CAMPAIGN_CONTRACT_CREATION = 'MAT-304-MATRICULAS_PREVENT_CAMPAIGN_CONTRACT_CREATION',

  MAT_343_ENABLE_CAMPAIGN_DETAILS = 'MAT-343-ENABLE_CAMPAIGN_DETAILS',
  MAT_367_ENABLE_CAMPAIGN_METABASE_REPORT = 'MAT-367-ENABLE_CAMPAIGN_METABASE_REPORT',
  MAT_957_PRODUCT_WITHOUT_TUITION = 'MAT-957-PRODUCT_WITHOUT_TUITION',
  PAS_561_ENABLE_REONBOARDING = 'PAS-561_ENABLE_REONBOARDING',
  PAS_734_EDIT_ENROLLMENT_DUEDATE = 'PAS-734-EDIT-ENROLLMENT-DUEDATE',
  PAYMENTS_PAYM227_ENABLE_V0_CHECKOUT_FLOW = 'PAYMENTS_PAYM-227_ENABLE_V0_CHECKOUT_FLOW',
  PAYMENTS_PAYM377_ENABLE_CHECKOUT_FOR_BACKOFFICE = 'PAYMENTS_PAYM-377_ENABLE_CHECKOUT_FOR_BACKOFFICE',
  PAYMENTS_PAYM_641_ENABLE_ERROR_DIALOG_WHEN_POLLING_FAILS = 'PAYMENTS_PAYM-641_ENABLE_ERROR_DIALOG_WHEN_POLLING_FAILS',
  PAYMENTS_PAYM_641_ENABLE_TIMEOUT_BEFORE_POLLING = 'PAYMENTS_PAYM-641_ENABLE_TIMEOUT_BEFORE_POLLING',
  PAYMENTS_PAYM_678_EXPERIMENT_DISPLAY_ACTION_LINKS = 'PAYMENTS_PAYM-678_EXPERIMENT_DISPLAY_ACTION_LINKS',
  PAYMENTS_PAYM_915_ENABLE_AGREEMENT_USERGUIDING = 'PAYM-915_ENABLE_AGREEMENT_USERGUIDING',
  PAYMENTS_PAYM_953_FEATURE_FLAG_ENABLE_VALIDATE_PAID_DATE_MANUAL_LIQUIDATE = 'PAYM-953_FEATURE_FLAG_ENABLE_VALIDATE_PAID_DATE_MANUAL_LIQUIDATE',
  PAYMENTS_PEX_237_ENABLE_PORTFOLIO_REPURCHASE_OPTION_ON_MANUAL_LIQUIDATION = 'PEX-237_ENABLE_PORTFOLIO_REPURCHASE_OPTION_ON_MANUAL_LIQUIDATION',
  PAYMENTS_PEX_273_ENABLE_MANUAL_LIQUIDATION_PAGE = 'PEX-273_ENABLE_MANUAL_LIQUIDATION_PAGE',
  PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE = 'PEX-273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE',
  PAYMENTS_PEX_288_ENABLE_EDIT_MANUAL_LIQUIDATION_PAGE = 'PEX-288_ENABLE_EDIT_MANUAL_LIQUIDATION_PAGE',
  PE_41_ENABLE_PAYMENT_STATUS_REPORT_BY_BFF = 'PE_41_ENABLE_PAYMENT_STATUS_REPORT_BY_BFF',
  PE_97_ENABLE_ACCESS_MANAGEMENT_PAGE = 'PE_97_ENABLE_ACCESS_MANAGEMENT_PAGE',
  PE_ENABLE_SCHOOL_GROUPS = 'PE_ENABLE_SCHOOL_GROUPS',
  PE_ENABLE_SCHOOL_GROUPS_MODULES = 'PE_173_ENABLE_SCHOOL_GROUPS_MODULES',
  PEX_447_CHANGE_FORM_TO_EXPORT_CARNE_PI = 'PEX-447_CHANGE_FORM_TO_EXPORT_CARNE_PI',
  /**
   * Determines if Negotiation V2 is enabled
   */
  REG_125_ENABLED_NEGOTIATIONS_V2 = 'REG_125_ENABLED_NEGOTIATIONS_V2',
  /** */
  REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT = 'REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT',
  REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS = 'REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS',
  /**
   * Determines if credit card fee is enabled
   */
  REG_2105_ENABLE_CREDIT_CARD_PAYMENT = 'REG_2105_ENABLE_CREDIT_CARD_PAYMENT',
  /**
   * Determines if checkout without restrictions is enabled
   */
  REG_2285_ENABLED_CHECKOUT_WITHOUT_RESTRICTIONS = 'REG_2285_ENABLED_CHECKOUT_WITHOUT_RESTRICTIONS',
  /**
   * Determines if unified invoice is enabled
   */
  REG_228_ENABLE_UNIFIED_INVOICE = 'REG_228_ENABLE_UNIFIED_INVOICE',
  /** Determines if the new contract page is enabled */
  REG_61_ENABLE_CONTRACT_PAGE = 'REG_61_ENABLE_CONTRACT_PAGE',
  /** */
  /** Determines if the new history page is enabled */
  REG_80_ENABLE_HISTORY_PAGE = 'REG_80_ENABLE_HISTORY_PAGE',

  /** */
  SHOW_DOWNLOAD_BUTTON_FOR_ENROLLMENT_SUMMARY = 'SHOW_DOWNLOAD_BUTTON_FOR_ENROLLMENT_SUMMARY',
}

export const useUnleashFlag = (feature: UnleashFlags) => useFlag(feature)
export const useUnleashFlagsStatus = useFlagsStatus
export const useUnleashVariant = useVariant
