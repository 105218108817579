import { ChevronRight } from '@material-ui/icons'
import * as Styled from './styles'
import { Status, StepItemProps } from './types'

export const StepItem = ({ label, status, active, onClick }: StepItemProps) => {
  return (
    <Styled.StepItem onClick={onClick} active={active} status={status}>
      {label}
      {status !== Status.DISABLED && <ChevronRight />}
    </Styled.StepItem>
  )
}
