export enum CreditEventComponentNames {
  CREDIT_SUMMARY_PAGE = 'Solicitação Recebida',
  FAQ = 'FAQ',
  HOME_PAGE = 'Home das escolas com crédito',
  LEARN_MORE_BUTTON = 'Saiba mais',
  LOADING_HOME_PAGE = 'Loading primário da simulação',
  NO_CREDIT_HOME = 'Home das escolas sem crédito',
  NUMBER_OF_INSTALLMENTS = 'Número de parcelas',
  REQUEST_CREDIT = 'Solicitar crédito',
  SIMULATE_NEW_OFFER = 'Simular nova oferta',
  SIMULATE_NOW = 'Simular agora',
  SIMULATION_PAGE = 'Formulário de simulação',
  SIMULATION_RESULT = 'Resultado da simulação',
  START_SIMULATION_BUTTON = 'Iniciar simulação',
}
