import { FC } from 'react'
import { NotificationPaymentLinkProps } from './types'
import * as S from './styles'
import { Link, NotificationProps } from '@olaisaac/design-system'
import copy from 'copy-to-clipboard'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useSnackbar } from '@/shared/hooks'

const NotificationPaymentLink: FC<NotificationPaymentLinkProps> = ({ link }) => {
  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const showSnackbar = (variation: NotificationProps['variation'], message: string) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  function copyInvoiceLink() {
    copy(link)
    showSnackbar('success', 'Link copiado com sucesso')
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
        entity: 'Parcela',
        name: EventDispatcherEvents.CLICKED_GET_CHARGE_LINK_NEGOTIATION_2_ENTRY,
        action: 'click',
      })
    }
  }

  return (
    <S.Wrapper data-testid={link}>
      <S.Icon />
      Para pagamentos com Boleto/pix,&nbsp;
      <Link data-testid={link + '-click'} onClick={copyInvoiceLink}>
        clique aqui
      </Link>
      &nbsp;para acessar a fatura.
    </S.Wrapper>
  )
}
export default NotificationPaymentLink
