import { mdiCheckCircle, mdiClock, mdiFolderOutline } from '@mdi/js'
import { FilterOption, FilterToggle } from '@/modules/enrollment/components/FilterToggle'

interface CampaignsFilterProps {
  onChange: (value: string) => void
}

export enum CampaignFilterValues {
  ALL = '',
  ENDED = 'ENDED',
  OPEN = 'OPEN',
}

export const CampaignsFilter = ({ onChange }: CampaignsFilterProps) => {
  const filterOptions: FilterOption[] = [
    {
      value: CampaignFilterValues.ALL,
      label: 'Todas',
      icon: mdiFolderOutline,
    },
    {
      value: CampaignFilterValues.OPEN,
      label: 'Iniciadas',
      icon: mdiCheckCircle,
    },
    {
      value: CampaignFilterValues.ENDED,
      label: 'Encerradas',
      icon: mdiClock,
    },
  ]

  return <FilterToggle filterOptions={filterOptions} onChange={onChange} />
}
