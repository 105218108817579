import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'

import { countSelectedFilters } from '@/modules/report/hooks/useEnrollmentPayoutReportFilter/utils/countSelectedFilters'

import {
  StudentEnrollmentsFilterObjectKeys,
  StudentEnrollmentsFilterQueryParams,
  StudentsEnrollmentsFilterType,
} from './types'

/**
 * Custom hook to manage students' enrollments page filter
 */
export const useStudentsEnrollmentsFilter = () => {
  const { query, setMultipleQueryParams } = useQuery()

  const getFiltersFromUrl = (): StudentsEnrollmentsFilterType => {
    const parseQueryParamArray = (paramName: StudentEnrollmentsFilterQueryParams) => {
      return query.get(paramName)?.split(',') ?? []
    }

    const data: Record<StudentEnrollmentsFilterObjectKeys, string[]> = {
      enrollmentStatus: parseQueryParamArray('enrollment_status'),
      enrollmentSubStatus: parseQueryParamArray('enrollment_sub_status'),
    }

    return (data as unknown) as StudentsEnrollmentsFilterType
  }

  const [
    studentsEnrollmentsFilter,
    setStudentsEnrollmentsFilter,
  ] = useState<StudentsEnrollmentsFilterType>(() => getFiltersFromUrl())

  useEffect(() => {
    const checkIfHasChanges = (
      urlFilter: StudentsEnrollmentsFilterType,
      stateFilter: StudentsEnrollmentsFilterType
    ) => {
      let hasChanges = false

      Object.keys(stateFilter).every(key => {
        if (
          stateFilter[key as keyof typeof stateFilter].join(',') !==
          urlFilter[key as keyof typeof urlFilter].join(',')
        ) {
          hasChanges = true

          return false
        }

        return true
      })

      return hasChanges
    }

    const urlStudentsEnrollmentsFilter = getFiltersFromUrl()

    const hasChangesOnFilter = checkIfHasChanges(
      urlStudentsEnrollmentsFilter,
      studentsEnrollmentsFilter
    )

    hasChangesOnFilter && setStudentsEnrollmentsFilter(urlStudentsEnrollmentsFilter)
  }, [query])

  const updateFilter = useCallback(
    (
      updatedFilter: StudentsEnrollmentsFilterType = {
        enrollmentStatus: [],
        enrollmentSubStatus: [],
      }
    ) => {
      const data: Array<{
        name: StudentEnrollmentsFilterQueryParams
        value: string
      }> = [
        {
          name: 'enrollment_status',
          value: updatedFilter.enrollmentStatus.join(','),
        },
        {
          name: 'enrollment_sub_status',
          value: updatedFilter.enrollmentSubStatus.join(','),
        },
      ]

      setMultipleQueryParams(data)
    },
    [setMultipleQueryParams]
  )

  const clearFilter = useCallback(() => {
    const data: Array<{
      name: StudentEnrollmentsFilterQueryParams
      value: null
    }> = [
      { name: 'enrollment_status', value: null },
      {
        name: 'enrollment_sub_status',
        value: null,
      },
    ]

    setMultipleQueryParams(data)
  }, [setMultipleQueryParams])

  const studentsEnrollmentsFilterCount = useMemo(
    () => countSelectedFilters(studentsEnrollmentsFilter),
    [studentsEnrollmentsFilter]
  )

  return useMemo(
    () => ({
      clearFilter,
      studentsEnrollmentsFilter,
      studentsEnrollmentsFilterCount,
      updateFilter,
    }),
    [studentsEnrollmentsFilter, studentsEnrollmentsFilterCount, updateFilter, clearFilter]
  )
}
