import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import { setToClipboard } from '@/shared/utils'
import {
  UnleashFlags,
  useApi,
  useJWT,
  useSnackbar,
  useUnleashFlag as unleashFlag,
} from '@/shared/hooks'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import useManualLiquidationNavigation from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/hooks/useManualLiquidationNavigation'

import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import {
  InstallmentStatus,
  InstallmentType,
  NegotiationType,
} from '@/modules/guardians/GuardianDetails/constants'
import { LiquidationSource } from '@/shared/interfaces'
import { FeatureFlags, useFeatureFlag } from '@/shared/hooks/useFeatureFlag'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useHotjar, HotjarEvents } from '@/shared/hooks/useHotjar'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { useSendManualLiquidationEvent } from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useSendManualLiquidationEvents'
import { useSendGuardianDetailsInvoiceEvent } from '../../../hooks/useSendGuardianDetailsInvoiceEvent'

interface UseGuardianInvoicesTableActionsProps {
  installment: Installment
}

export const useGuardianInvoicesTableActions = ({
  installment,
}: UseGuardianInvoicesTableActionsProps) => {
  const { api } = useApi()
  const { isAdmin } = useJWT()
  const { school } = useSelectedSchool()
  const { setPageToReturn } = useGuardianNavigation()
  const { search: currentPageQueryParams } = useLocation()
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { sendHotjarEvent } = useHotjar()

  const { goToManualLiquidation, goToEditManualLiquidation } = useManualLiquidationNavigation({
    urn: installment.urn,
    callbackParams: currentPageQueryParams,
  })

  const {
    value: manualLiquidationFeatureFlagValue,
    config: manualLiquidationConfig,
  } = useFeatureFlag(FeatureFlags.MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG)

  const isManualLiquidationFeatureFlagEnabled =
    !isAdmin && manualLiquidationFeatureFlagValue && manualLiquidationConfig.includes(school.id)

  const isDownPayment = installment.type === InstallmentType.DOWN_PAYMENT
  const isNegotiationV2 = installment.negotiation_type === NegotiationType.NEGOTIATION_API_V2

  const isOpenReceivable = ['DUE_TODAY', 'OPEN', 'OVERDUE'].includes(installment.status)
  const isOverdueReceivable = installment.status === 'OVERDUE'
  const isFromCreditCardFlow = installment.is_from_credit_card_flow

  const isManualLiquidationBlockedForSchool = unleashFlag(
    UnleashFlags.B2CB_984_DISABLE_MANUAL_LIQUIDATION
  )

  const sendManualLiquidationEvent = useSendManualLiquidationEvent()
  const sendGuardianDetailsInvoiceEvent = useSendGuardianDetailsInvoiceEvent()

  const isHiddenManualLiquidation =
    !isOpenReceivable ||
    (isDownPayment && isNegotiationV2 && isOverdueReceivable) ||
    (isManualLiquidationBlockedForSchool && !isAdmin) ||
    !isOpenReceivable ||
    isFromCreditCardFlow

  const isManualLiquidation = [
    LiquidationSource.ISAAC_ACCOUNT,
    LiquidationSource.SCHOOL_ACCOUNT,
  ].includes(installment?.paid_payment_source as LiquidationSource)

  const isEditable =
    !isOpenReceivable &&
    !isFromCreditCardFlow &&
    isManualLiquidation &&
    !(isManualLiquidationBlockedForSchool && !isAdmin)

  const canEditManualLiquidation = isManualLiquidationFeatureFlagEnabled
    ? isEditable && installment?.paid_payment_source === LiquidationSource.ISAAC_ACCOUNT
    : isEditable

  const isHiddenEditManualLiquidation = isOpenReceivable || !canEditManualLiquidation

  const handleClickSharePaymentLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendGuardianDetailsInvoiceEvent('row', {
      name: 'Copiar link de cobrança',
      receivable_id: installment.urn,
      contract_id: installment.contract_id,
    })

    setToClipboard(installment.invoice.payment_link)

    setSnackbarIsOpen(true, {
      variation: 'success',
      title: 'Link copiado com sucesso',
      description: '',
    })
  }

  const handleClickManualLiquidation = () => {
    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION,
      undefined,
      {
        name: 'Registrar recebimento',
        receivable_id: installment.urn,
        selected_date: dayjs().format(),
        contract_id: installment.contract_id,
        component: 'row',
      }
    )
    sendHotjarEvent(HotjarEvents.MANUAL_LIQUIDATION)
    setPageToReturn()
    goToManualLiquidation()
  }

  const handleClickPrintReceipt = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendGuardianDetailsInvoiceEvent('row', {
      name: 'Gerar comprovante',
      receivable_id: installment.urn,
      contract_id: installment.contract_id,
    })

    const response = await api.receivables.printReceipt(
      installment.urn.replace('urn:receivable:', '')
    )
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleClickEditManualLiquidation = () => {
    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION,
      undefined,
      {
        name: 'Editar recebimento',
        receivable_id: installment.urn,
        selected_date: dayjs().format(),
        contract_id: installment.contract_id,
        component: 'row',
      }
    )
    sendHotjarEvent(HotjarEvents.EDIT_MANUAL_LIQUIDATION)
    setPageToReturn()
    goToEditManualLiquidation()
  }

  const isPaid = installment.status === InstallmentStatus.PAID

  return {
    showSharePaymentLink: isOpenReceivable,
    showManualLiquidation: !isHiddenManualLiquidation,
    showEditManualLiquidation: !isHiddenEditManualLiquidation,
    showPrintReceipt: isPaid,
    handleClickPrintReceipt,
    handleClickManualLiquidation,
    handleClickEditManualLiquidation,
    handleClickSharePaymentLink,
  }
}
