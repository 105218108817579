import { CollapsibleFilterGroup } from '@/escolas/components/CollapsibleFilterGroup'
import { useStudentsEnrollmentsFilter } from '@/escolas/hooks'
import { StudentsEnrollmentsFilterType } from '@/escolas/hooks/useStudentsEnrollmentsFilter/types'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { Box } from '@material-ui/core'
import {
  ActionDrawerHeaderProps,
  Button,
  ButtonDocker,
  Drawer,
  DrawerProps,
} from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { memo, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import * as Styled from './styles'
import { useFilterOptions } from './hooks/useFilterOptions'

type Props = Pick<DrawerProps, 'open'> & {
  onClose: (
    event?: Parameters<ActionDrawerHeaderProps['onClose']>[0]
  ) => ReturnType<ActionDrawerHeaderProps['onClose']>
  selectedYear: number
}

const FilterDrawer = ({ open, onClose, selectedYear }: Props) => {
  const { studentsEnrollmentsFilter, updateFilter, clearFilter } = useStudentsEnrollmentsFilter()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { statusOptions, subStatusOptions } = useFilterOptions(selectedYear)

  const form = useForm<StudentsEnrollmentsFilterType>({
    defaultValues: {
      enrollmentStatus: [],
      enrollmentSubStatus: [],
    },
  })

  const { handleSubmit, setValue, reset, watch, getValues } = form
  const [isFilterApplied, setIsFilterApplied] = useState(true)
  const hasActiveFilters =
    getValues('enrollmentStatus').length > 0 || getValues('enrollmentSubStatus').length > 0

  const enableStudentSubStatusFilterFF = useUnleashFlag(
    UnleashFlags.ENABLE_STUDENT_SUBSTATUS_FILTER
  )

  useEffect(() => {
    setValue('enrollmentStatus', studentsEnrollmentsFilter.enrollmentStatus)
    setValue('enrollmentSubStatus', studentsEnrollmentsFilter.enrollmentSubStatus)
  }, [open])

  useEffect(() => {
    const subscription = watch(() => setIsFilterApplied(false))

    return () => subscription.unsubscribe()
  }, [watch])

  const handleApplyFilters: SubmitHandler<StudentsEnrollmentsFilterType> = ({
    enrollmentStatus,
    enrollmentSubStatus,
  }) => {
    setIsFilterApplied(true)
    updateFilter({ enrollmentStatus, enrollmentSubStatus })
    onClose()
  }

  const handleClearFilter = () => {
    reset()
    setIsFilterApplied(true)
    clearFilter()
  }

  return (
    <FormProvider {...form}>
      <Drawer open={open} data-testid="filter_drawer">
        <Styled.Header title="Filtrar" onClose={onClose} />
        <Box display="flex" flex="1" flexDirection="column" padding="1.5rem 1.5rem" overflow="auto">
          <Box mb="3rem">
            <CollapsibleFilterGroup
              label="Situação de matrícula"
              filterName="enrollmentStatus"
              options={statusOptions}
              totalOptions={statusOptions.length}
              searchText={null}
              onClearFilter={() => setValue('enrollmentStatus', [])}
              pageSize={statusOptions.length}
            />
          </Box>
          {enableStudentSubStatusFilterFF && (
            <Box mb="3rem">
              <CollapsibleFilterGroup
                label="Detalhamento"
                filterName="enrollmentSubStatus"
                options={subStatusOptions}
                totalOptions={subStatusOptions.length}
                searchText={null}
                onClearFilter={() => setValue('enrollmentSubStatus', [])}
                pageSize={subStatusOptions.length}
              />
            </Box>
          )}
        </Box>

        <ButtonDocker>
          <Button
            fullWidth
            disabled={isFilterApplied}
            onClick={() => {
              handleSubmit(handleApplyFilters)()
              isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                  name: EnrollmentEventDispatcherEvents.CLICKED,
                  action: 'click',
                  customProperties: {
                    $Button_name: 'FILTRAR_GAVETA_DE_FILTROS',
                    $modal_name: 'FILTROS',
                    $value: [
                      ...form.getValues('enrollmentStatus'),
                      ...form.getValues('enrollmentSubStatus'),
                    ],
                  },
                })
            }}
          >
            Filtrar
          </Button>

          <Button
            fullWidth
            variation="ghost"
            disabled={!hasActiveFilters}
            onClick={() => {
              handleClearFilter()
              isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                  name: EnrollmentEventDispatcherEvents.CLICKED,
                  action: 'click',
                  customProperties: {
                    $Button_name: 'LIMPAR_TUDO_GAVETA_DE_FILTROS',
                    $modal_name: 'FILTROS',
                  },
                })
            }}
          >
            Limpar tudo
          </Button>
        </ButtonDocker>
      </Drawer>
    </FormProvider>
  )
}

export default memo(FilterDrawer)
