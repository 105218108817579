import { DefaultNegotiation } from './DefaultNegotiation'
import { useNegotiationFlow } from './hooks/useNegotiationFlow'
import useDownPaymentFlow from './hooks/useDownPaymentFlow'
import EntryNegotiation from './EntryNegotiation'

export const NegotiationFlow: React.FC = () => {
  const { isDownPaymentEnabled } = useDownPaymentFlow()
  const { isEnableCheckoutWithoutRestrictions } = useNegotiationFlow()

  if (isEnableCheckoutWithoutRestrictions) return <DefaultNegotiation />

  return <>{isDownPaymentEnabled ? <EntryNegotiation /> : <DefaultNegotiation />}</>
}
