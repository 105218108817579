import { CommonMetadata } from './metadata'
import { PaymentMethod } from './paymentMethod'
import { Receivable } from './receivable'
import { School } from './school'

export enum CheckoutType {
  PAGARME_CHECKOUT = 'pagarme_checkout',
  STONE_CHECKOUT = 'stone_checkout',
}

export enum CheckoutProvider {
  STONE_CONNECT = 'STONE_CONNECT',
  STONE_PAGARME = 'STONE_PAGARME',
}

export enum CheckoutStatus {
  ABORTED = 'aborted',
  FAILURE_TO_CONNECT = 'FAILURE_TO_CONNECT',
  FINISHED = 'finished',
  ON_HOLD = 'on_hold',
  PROCESSING = 'processing',
}

export type Checkout = CommonMetadata & {
  amount: number
  external_transaction_id: uuid
  installments_count: number
  keep_due_payment_discounts: boolean
  keep_early_payment_discounts: boolean
  keep_fine_and_interest: boolean
  payment_methods: PaymentMethod[]
  receivables?: Receivable[]
  school?: School
  school_id: uuid
  status: CheckoutStatus
  type: CheckoutType
}

export type GetCheckoutParams = {
  include_receivables: boolean
  include_school: boolean
}

export type StartCheckoutRequestBody = {
  checkout_type?: CheckoutType
  down_payment_conversion: boolean
  information_title: string
  installments_count: number
  keep_due_payment_discounts: boolean
  keep_early_payment_discounts: boolean
  keep_fine_and_interest: boolean
  payment_methods: string[]
  pos_id: uuid
  receivables_ids: uuid[]
}

export type Pos = {
  pdv_name: string
  pos_id: uuid
  pos_serial_number: string
  provider?: CheckoutProvider
}
