import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { DialogPrimitives } from '@gravity/dialog'
import { CircularProgress } from '@material-ui/core'

import * as Styled from './styles'

type Props = {
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

export const CancelProposalDialog = ({ open, onConfirm, onClose, isLoading }: Props) => {
  const handleDialogOpenChange = (visible: boolean) => {
    if (!visible && open) onClose()
  }
  return (
    <DialogPrimitives.Root open={open} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={1}
        title="Deseja cancelar a proposta?"
        actionButton={
          <Button disabled={isLoading} color="error" variant="solid" onClick={onConfirm}>
            Sim, cancelar
          </Button>
        }
        cancelButton={
          <Button disabled={isLoading} variant="ghost" onClick={onClose}>
            Não, sair
          </Button>
        }
      >
        <div>
          <Text variant="body-2-regular">
            Ao cancelar a proposta, este aluno sairá da campanha. Ela não estará mais disponível
            para o responsável através do meu isaac, e ele não poderá avançar no processo de
            matrícula.
          </Text>
          {isLoading && (
            <Styled.LoadingContentWrapper>
              <CircularProgress />
            </Styled.LoadingContentWrapper>
          )}
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
