export const separateUserAccessRoles = (
  roles: (
    | 'atribuir_acesso_secretaria'
    | 'atribuir_acesso_mantenedor'
    | 'atribuir_acesso_gestor'
    | 'remover_acesso_mantenedor'
    | 'remover_acesso_secretaria'
  )[]
) => {
  return roles.reduce<
    [
      ('atribuir_acesso_secretaria' | 'atribuir_acesso_mantenedor' | 'atribuir_acesso_gestor')[],
      ('remover_acesso_mantenedor' | 'remover_acesso_secretaria')[]
    ]
  >(
    (acc, role) => {
      if (
        role === 'atribuir_acesso_secretaria' ||
        role === 'atribuir_acesso_mantenedor' ||
        role === 'atribuir_acesso_gestor'
      ) {
        return [[...acc[0], role], acc[1]]
      }

      if (role === 'remover_acesso_mantenedor' || role === 'remover_acesso_secretaria') {
        return [acc[0], [...acc[1], role]]
      }

      return acc
    },
    [[], []]
  )
}
