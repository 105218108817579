import useAddContractEnabled from '@/escolas/hooks/useAddContractEnabled'
import { Table } from '@olaisaac/design-system'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useNavigation } from '@/escolas/hooks'
import { EnrollmentCycleItem } from '@/escolas/services/enrollment/types'
import { Guardian } from '@/shared/interfaces'
import { ENROLLMENT_PAGE_BASE_PATH } from '../../constants/enrollment'
import { useRefreshSituation } from '../../hooks/useRefreshSituation'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Rows, StudentTableProps } from './types'
import * as Styled from './styles'
import { Situation } from './Situation'
import { Product } from './Product'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

const mapDataToRows = (rawData: EnrollmentCycleItem[]): Rows => {
  return rawData.map(item => ({
    ...item,
    key: item.student_id,
    actions: {
      studentID: item.student_id,
      startedEnrollment: item.started_enrollment,
      isReenrollable: item.is_reenrollable,
      studentName: item.student_name,
    },
    statusAndDetails: {
      status: item.student_status,
      details: item.student_status_details,
      studentID: item.student_id,
      studentName: item.student_name,
    },
    guardians: [item.main_guardian, ...(item.other_guardians || [])],
    products: {
      mainProductName: item.main_product?.name,
      otherProductsNames: item.other_products?.map(product => product.name),
    },
  }))
}

export const getFirstAndLastName = (fullName: string): string => {
  const names = fullName.split(' ')

  if (names.length > 2) {
    return `${names[0]} ${names[names.length - 1]}`
  }

  return fullName
}

export const formatGuardians = (guardians: Pick<Guardian, 'name'>[]): string => {
  return guardians.map(g => getFirstAndLastName(g.name)).join(', ')
}

export const StudentTable = ({ schoolId, data, isLoading, enrollmentYear }: StudentTableProps) => {
  const { getNavigationUrl, newTabNavigateTo } = useNavigation()
  const history = useHistory()
  const [updatedData, setUpdatedData] = useState(data)
  const addContractEnabled = useAddContractEnabled()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { isCollege } = useSelectedSchool()

  const refreshSituation = useRefreshSituation(data => {
    const { status, sub_status, is_reenrollable, studentID } = data

    setUpdatedData(
      updatedData.map(student => {
        if (student.student_id === studentID) {
          return {
            ...student,
            student_status: status,
            student_status_details: sub_status,
            is_reenrollable,
          }
        }

        return student
      })
    )

    return []
  })

  useEffect(() => {
    setUpdatedData(data)
  }, [data])

  return (
    <Styled.TableContainer>
      <Table
        columns={[
          {
            headerName: 'Aluno',
            field: 'student_name',
          },
          {
            headerName: 'Responsável',
            field: 'guardians',
            valueFormatter: formatGuardians,
          },
          {
            headerName: 'Produto',
            field: 'products',
            renderCell: value => <Product {...value} />,
          },
          ...(!isCollege
            ? [
                {
                  headerName: `Situação matrícula ${enrollmentYear}`,
                  field: 'statusAndDetails',
                  small: true,
                  renderCell: (value: Rows[0]['statusAndDetails']) => {
                    return (
                      <Situation
                        {...value}
                        enrollmentCycle={enrollmentYear}
                        refreshSituation={() =>
                          refreshSituation(
                            schoolId,
                            value.studentID,
                            enrollmentYear,
                            value.status,
                            value.studentName
                          )
                        }
                      />
                    )
                  },
                },
              ]
            : []),
          addContractEnabled && {
            headerName: 'Ações',
            field: 'actions',
            small: true,
            renderCell: value => {
              return (
                <Styled.ActionsColumnContainer>
                  <Styled.StyledLink
                    disabled={!value.isReenrollable}
                    href="#"
                    onClick={(e: React.SyntheticEvent) => {
                      e.stopPropagation()

                      if (!value.isReenrollable) {
                        return null
                      }

                      isInitialized &&
                        eventDispatcherClient.sendEvent({
                          scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                          name: EventDispatcherEvents.LINK_CLICK,
                          action: 'click',
                          customProperties: {
                            $Link_name: 'ADICIONAR_NOVO_CONTRATO_OU_REMATRICULAR',
                            $table_name: 'LISTA_DE_ALUNOS',
                            $value: value.startedEnrollment
                              ? 'Adicionar novo contrato'
                              : 'Rematricular',
                            $situation: value.status,
                          },
                        })
                      history.push(
                        getNavigationUrl({
                          path: `/contratos/${enrollmentYear}/novo`,
                        }),
                        {
                          studentID: value.studentID,
                          studentName: value.studentName,
                        }
                      )
                    }}
                  >
                    {value.startedEnrollment ? 'Adicionar novo contrato' : 'Rematricular'}
                  </Styled.StyledLink>
                </Styled.ActionsColumnContainer>
              )
            },
          },
        ]}
        rows={mapDataToRows(updatedData)}
        onRowClick={e => {
          newTabNavigateTo({ path: `/${ENROLLMENT_PAGE_BASE_PATH}/${e.row.student_id}` })
          isInitialized &&
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
              name: EventDispatcherEvents.BUTTON_CLICKED,
              action: 'click',
              customProperties: {
                $Button_name: 'LINHA_DA_TABELA_DE_ALUNOS',
                $table_name: 'LISTA_DE_ALUNOS',
                $situation: e.row.student_status,
              },
            })
        }}
        isLoading={isLoading}
      />
    </Styled.TableContainer>
  )
}
