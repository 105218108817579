import { useHistory, useParams } from 'react-router-dom'

import { UnleashFlags, useApi, useJWT, useUnleashFlag } from '@/shared/hooks'
import { Contract, ErrorDialog } from '@/modules/guardians/GuardianDetails/types'
import {
  Contract as ContractData,
  ContractStatus,
  DebtStatus,
  PreContractStatuses,
  ReceivableStatuses,
} from '@/shared/interfaces'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { MATRICULAS_API_FIRST_YEAR } from '@/escolas/components/contract/constants'
import { GuardiansInstallmentsQueryParamsNameEnum } from '@/modules/guardians/GuardianDetailsInstallmentsTab/constants'
import { downloadFile } from '@/shared/utils'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'
import {
  getAgglutinatedInstallment,
  getDueTodayInstallment,
  getOpenInstallments,
  getOverdueInstallment,
  getPaidEnrollmentInstallment,
  getRenegotiatedInstallment,
  getTuitionInstallmentsPaid,
} from '@/escolas/components/contract/utils'
import { ENROLLMENT_PAGE_BASE_PATH } from '@/modules/enrollment/constants/enrollment'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { processInstallments } from '@/escolas/components/contract/ContractDetails'

type UseGuardianDetailsContractsTableActionsProps = {
  contract: Contract
  contractData?: ContractData
  setErrorDialog: (errorDialog: ErrorDialog) => void
}

export const useGuardianDetailsContractsTableActions = ({
  contract,
  contractData,
  setErrorDialog,
}: UseGuardianDetailsContractsTableActionsProps) => {
  const history = useHistory()

  const { isAdmin } = useJWT()
  const { api } = useApi()
  const { guardianId } = useParams<{ guardianId: string }>()

  const { schoolSlug, school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const { sendHotjarEvent } = useHotjar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const editPreContractFeatureToggle = useUnleashFlag(
    UnleashFlags.ENGAJAMENTO_PAS444_EDIT_PRECONTRACT
  )

  const enableUnifiedContractEditing = useUnleashFlag(UnleashFlags.ENABLE_UNIFIED_CONTRACT_EDITING)

  const isPreContract = contract?.pre_contract_status !== PreContractStatuses.EFFECTED_PCS

  const isDebtPending = contract?.debt_status === DebtStatus.PENDING

  const isEnableEditContractOption =
    editPreContractFeatureToggle &&
    (enableUnifiedContractEditing || isPreContract) &&
    !isDebtPending

  const isEnableDownloadCarneOption =
    !isDebtPending && enableUnifiedContractEditing && !isOutsourcedProvider

  const cancelPreContractFeatureFlagValue = useUnleashFlag(
    UnleashFlags.ENGAJAMENTO_PAS392_CANCELLATION_PRECONTRACT
  )

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const processedInstallments = processInstallments(contractData)

  const openInstallments = getOpenInstallments(processedInstallments)
  const tuitionInstallmentsPaid = getTuitionInstallmentsPaid(processedInstallments)

  const hasRenegotiatedInstallment = Boolean(getRenegotiatedInstallment(processedInstallments))
  const hasAgglutinatedInstallment = Boolean(getAgglutinatedInstallment(processedInstallments))
  const hasOverdueInstallment = Boolean(getOverdueInstallment(processedInstallments))
  const hasDueTodayInstallment = Boolean(getDueTodayInstallment(processedInstallments))

  const hasDebt = hasRenegotiatedInstallment || hasAgglutinatedInstallment || hasOverdueInstallment
  const hasInstallments = contractData?.installments?.length > 0
  const hasOpenInstallments = openInstallments?.length > 0
  const hasInstallmentsTuitionPaid = tuitionInstallmentsPaid?.length > 0

  const hasPaidEnrollmentInstallments = hasInstallments
    ? getPaidEnrollmentInstallment(processedInstallments)
    : false

  const hasPaidReceivables = contractData?.installments?.some(({ receivables }) =>
    receivables?.some(({ status }) => status === ReceivableStatuses.PAID)
  )

  const isCancelPreContractFeatureFlagEnabled = !isAdmin && cancelPreContractFeatureFlagValue

  const canCancelPreContract =
    isPreContract && !hasPaidEnrollmentInstallments && isCancelPreContractFeatureFlagEnabled

  const canCancelPreContractPaid =
    !hasInstallmentsTuitionPaid &&
    hasPaidEnrollmentInstallments &&
    isCancelPreContractFeatureFlagEnabled

  /**
   * Caso o usuário não seja admin mas a escola tem permissão para cancelar contratos.
   */
  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  const canCancelContract = !isPreContract && hasOpenInstallments && isCancelContractEnabled

  const isOpenContract = contract?.status === ContractStatus.OPEN
  const isPendingContract = contract?.status === ContractStatus.PENDING
  const isCanceledContract = contract?.status === ContractStatus.CANCELED

  const isCanceledPreContract = isPreContract && isCanceledContract

  const canEnableCancellationOption =
    isAdmin || canCancelPreContract || canCancelContract || canCancelPreContractPaid

  const showCancellationOption =
    isAdmin || isCancelContractEnabled || isCancelPreContractFeatureFlagEnabled

  const isEnableCancelContractOption =
    (isOpenContract || isPendingContract) &&
    canEnableCancellationOption &&
    showCancellationOption &&
    hasOpenInstallments

  const isEnableGeneratePaymentStatementOption = hasPaidReceivables || isCanceledPreContract

  const isEnableChangeContractOwnershipOption =
    hasOpenInstallments &&
    !hasDueTodayInstallment &&
    !hasDebt &&
    !isPreContract &&
    !isIntegratedSchool

  const sendEvent = (
    name: EventDispatcherEvents,
    scope: EventDispatcherEventScopes,
    entity?: EventDispatcherEntities,
    options?: Record<string, unknown>
  ) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        entity,
        action: 'click',
        customProperties: {
          $component: 'row',
          $contract_id: contract?.id,
          ...options,
        },
      })
  }

  const handleClickGoToContractEdit = () => {
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_DRAWER,
      null,
      {
        $button_name: 'Editar contrato',
        $contract_type: isPreContract ? 'PRE_CONTRACT' : 'EFFECTED_CONTRACT',
      }
    )

    const isMatriculasPreContract =
      Number(contract?.reference_year) >= MATRICULAS_API_FIRST_YEAR && isPreContract

    const path =
      isMatriculasPreContract || enableUnifiedContractEditing
        ? `/contratos/${contract?.id}/editar`
        : `/contratos/${contract?.reference_year}/${contract?.id}/editar-contrato`

    history.push(`/${schoolSlug}${path}`)
  }

  const handleClickGoToInvoicePage = () => {
    sendEvent(
      EventDispatcherEvents.GUARDIAN_CONTRACT_INSTALLMENTS_LIST,
      EventDispatcherEventScopes.GUARDIANS,
      null,
      {
        $button_name: 'Ver faturas',
      }
    )

    const params = new URLSearchParams()
    params.append(GuardiansInstallmentsQueryParamsNameEnum.YEARS, contract.reference_year)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS, contract?.product.id)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS, contract?.student.id)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_STATUS, contract?.status)

    history.push(`/${schoolSlug}/responsaveis/${guardianId}/faturas?${params.toString()}`)
  }

  const handleClickGoToDownloadCarne = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const carneFileDownload = async () => {
      await api.contracts.downloadCarne(contract.id).then(async data => {
        if (data.status > 200) {
          const error = await data.data.text()
          setErrorDialog({ visible: true, message: error })
          return
        }
        downloadFile(`carne-${contract.id}`, 'pdf', async () => await data.data)
      })
    }

    try {
      await carneFileDownload()
    } catch (error) {
      setErrorDialog({
        visible: true,
        message: 'Ocorreu um erro ao tentar baixar o carnê. Tente novamente.',
      })
    }
  }

  const handleClickGoToEnrollmentPage = () => {
    sendEvent(EventDispatcherEvents.VIEW_STUDENT_PAGE, EventDispatcherEventScopes.GUARDIANS, null, {
      $button_name: 'Ver situação de matrícula',
    })

    history.push(`/${schoolSlug}/${ENROLLMENT_PAGE_BASE_PATH}/${contract.student.id}`)
  }

  const sendGenerateStatementEvents = () => {
    sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT_CHECKOUT)
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_PAGE,
      EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
      {
        $button_name: 'Gerar demonstrativo de pagamento',
      }
    )
  }

  const sendChangeContractOwnershipEvents = () => {
    sendEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEventScopes.CONTRACT_PAGE,
      null,
      {
        $button_name: 'Alterar titularidade do contrato',
      }
    )
  }

  return {
    showEditContractOption: isEnableEditContractOption,
    showDownloadCarneOption: isEnableDownloadCarneOption,
    showChangeContractOwnershipOption: isEnableChangeContractOwnershipOption,
    showCancelContractOption: isEnableCancelContractOption,
    showGeneratePaymentStatement: isEnableGeneratePaymentStatementOption,
    handleClickGoToContractEdit,
    handleClickGoToInvoicePage,
    handleClickGoToDownloadCarne,
    handleClickGoToEnrollmentPage,
    sendGenerateStatementEvents,
    sendChangeContractOwnershipEvents,
  }
}
