import { useEffect } from 'react'

import { useGuardiansQuery } from './hooks/useGuardiansQuery'
import { useNavigation } from '@/escolas/hooks'
import { useQuery } from '@/shared/hooks/useQuery'
import { useGuardiansQueryParams } from '../hooks/useGuardiansQueryParams'
import { UserGuidingGuidesIds, useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'
import { useLayout } from '@/shared/hooks/useLayout'
import { SortOrder } from '@/shared/hooks/useOrdering/types'

import { ListGuardiansTable, ListGuardiansFilter } from './components'
import { Search } from './components/Search'

import { getFilterSearchParam, formatSearchTerm } from './utils'
import { SearchContainer } from './styles'
import { GuardiansQueryParamsNameEnum } from '../constants'

const ListGuardians = () => {
  const { schoolId } = useNavigation()
  const { setOnQueryParam } = useQuery()
  const { canUseAutoTrigger } = useUserGuidingTrigger()

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Responsáveis' })

  const {
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    searchTerm,
    filters,
    updateFilters,
    updateOrderingValue,
    setSearchTerm,
  } = useGuardiansQueryParams({})

  const formattedSearchTerm = formatSearchTerm(searchTerm)

  const { guardians, isFetchGuardiansLoading } = useGuardiansQuery({
    schoolId,
    pagination: { page, per_page: itemsPerPage, sort_by: sortBy, sort_order: sortOrder },
    filter: { [getFilterSearchParam(searchTerm)]: formattedSearchTerm, ...filters },
  })

  useEffect(() => {
    if (canUseAutoTrigger) {
      setOnQueryParam(UserGuidingGuidesIds.LIST_GUARDIANS_PAGE, GuardiansQueryParamsNameEnum.guide)
    }
  }, [])

  return (
    <>
      <SearchContainer>
        <Search setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
        <ListGuardiansFilter filters={filters} updateFilters={updateFilters} />
      </SearchContainer>
      <ListGuardiansTable
        guardians={guardians?.data}
        isLoading={isFetchGuardiansLoading}
        total={guardians?.pagination?.total}
        sortOrder={sortOrder}
        setSortOrder={(sortOrder: SortOrder) => updateOrderingValue('sortOrder', sortOrder)}
      />
    </>
  )
}

export { ListGuardians }
