import { FC } from 'react'
import { Box } from '@material-ui/core'
import { Notification } from '@olaisaac/design-system'

import {
  FailureFeedbackDialog,
  FailureFeedbackContent,
} from '@/escolas/components/modal/ConfirmationDialog'
import StatusDialog from '../../components/StatusDialog'

const variantStatus = {
  starting_duplicate: {
    title: 'Carregando informações de pagamento da entrada',
  },
  starting_checkout: {
    title: 'Comunicando com a maquininha',
  },
}

interface EntryStatusDialogProps {
  closeErrorDialog: () => void
  guardianName: string
  isOpen: boolean
  posName: string
  shortId: string
  showErrorDialog: boolean
  totalAmount: number
  variant: keyof typeof variantStatus
}

const EntryStatusDialog: FC<EntryStatusDialogProps> = ({
  showErrorDialog,
  closeErrorDialog,
  shortId,
  variant,
  ...statusDialogProps
}) => {
  const isStartingCheckout = variant === 'starting_checkout'
  return (
    <>
      <StatusDialog
        {...statusDialogProps}
        isTextVisible={isStartingCheckout}
        title={variantStatus[variant]?.title}
      >
        {isStartingCheckout && shortId ? (
          <Box width="100%" paddingX={2}>
            <Notification
              variation="information"
              title="O código identificador da negociação mudou"
              description={`A negociação agora é a #${shortId}`}
            />
          </Box>
        ) : null}
      </StatusDialog>
      <FailureFeedbackDialog
        isVisible={showErrorDialog}
        buttonLabel="Tentar novamente"
        onClose={() => closeErrorDialog()}
        submitHandler={() => closeErrorDialog()}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
    </>
  )
}

export default EntryStatusDialog
