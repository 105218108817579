import { Box, Grid } from '@material-ui/core'
import { DefaultTemplateProps } from './types'
import { Button } from '@gravity/button'
import { ArrowBack } from '@material-ui/icons'
import { RowContainer, TitleText } from './styles'

const DefaultTemplate = ({ title, child, handleBackPreviousRoute }: DefaultTemplateProps) => {
  return (
    <RowContainer>
      <Box mt={5}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Button
              iconStart={<ArrowBack />}
              size={1}
              variant="ghost"
              onClick={handleBackPreviousRoute}
            >
              Voltar
            </Button>
            <TitleText> {title}</TitleText>
            {child}
          </Grid>
        </Grid>
      </Box>
    </RowContainer>
  )
}

export default DefaultTemplate
