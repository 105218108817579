import { useEffect, useMemo, useState } from 'react'

import type { AgreementContextData } from 'src/escolas/contexts/agreementContext/types'
import type { CheckoutCombinableReceivable } from 'src/escolas/contexts/checkoutContext'
import useNegotiationPaymentFormsAgreement from '@/modules/guardians/Negotiation/hooks/useNegotiationPaymentFormsAgreement'

import type {
  AgreementSimulation,
  AgreementSimulationCombined,
  AgreementStatus,
  Checkout,
  FormOfPayment,
  SimulationPaymentMethod,
} from 'src/shared/interfaces'

export default function useDeclareAgreement(): AgreementContextData {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [agreementSimulations, setAgreementSimulations] = useState<AgreementSimulation[]>([])
  const [receivables, setReceivables] = useState<CheckoutCombinableReceivable[]>([])
  const [selectedFormOfPayment, setSelectedFormOfPayment] = useState<FormOfPayment | undefined>()
  const [positionAgreement, setPositionAgreement] = useState<number>(0)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [selectedSimulationPaymentMethod, setSelectedSimulationPaymentMethod] = useState<
    SimulationPaymentMethod | undefined
  >()
  const [agreementStatus, setAgreementStatus] = useState<Record<number, AgreementStatus>>({})

  const handlerAgreementStatus = (position: number, value: AgreementStatus) => {
    setAgreementStatus({ ...agreementStatus, [position]: value })
  }

  const updateAgreementSimulation = (position: number, value: AgreementSimulationCombined) => {
    setAgreementSimulations(
      agreementSimulations.map((agreement, index) => (index === position ? value : agreement))
    )
  }

  const { form, validator } = useNegotiationPaymentFormsAgreement(selectedFormOfPayment)

  // O estado de checkout abaixo so eh utilizado no fluxo v0 e deve ser removido junto com essa flag
  const [checkoutData, setCheckoutData] = useState<Checkout | undefined>()

  const totalAmount = useMemo(
    () => agreementSimulations.reduce((acc, curr) => acc + curr.total_amount, 0),
    [agreementSimulations]
  )

  const totalCreditCardFee = useMemo(
    () => agreementSimulations.reduce((acc, curr) => acc + curr.credit_card_fee, 0),
    [agreementSimulations]
  )

  const totalAmountWithFee = totalAmount + totalCreditCardFee

  const agreementSimulationsCombined = useMemo(() => {
    return agreementSimulations.map(agreement => ({
      ...agreement,
      receivables: receivables?.filter(receivable =>
        agreement.receivable_ids.includes(receivable.id)
      ),
    }))
  }, [agreementSimulations, receivables])

  const hasPaidAgreement = agreementSimulations.some(
    (_, index) => !!agreementStatus[index]?.finished
  )

  function initAgreementSimulationFlow(
    agreements: AgreementSimulation[],
    receivables: CheckoutCombinableReceivable[]
  ) {
    setSelectedFormOfPayment(undefined)
    setIsValid(false)
    setPositionAgreement(0)
    setAgreementStatus({})

    setReceivables(receivables)

    agreements.sort((a, b) => {
      if (a.total_amount > b.total_amount) return -1
      if (a.total_amount < b.total_amount) return 1
      return 0
    })

    setAgreementSimulations(agreements)
  }

  useEffect(() => {
    setSelectedSimulationPaymentMethod(undefined)
  }, [selectedFormOfPayment])

  return {
    openDrawer,
    setOpenDrawer,
    initAgreementSimulationFlow,
    agreementSimulations: agreementSimulationsCombined,
    totalAmount,
    totalAmountWithFee,
    totalCreditCardFee,
    receivables,
    selectedFormOfPayment,
    setSelectedFormOfPayment,
    checkoutData,
    setCheckoutData,
    selectedSimulationPaymentMethod,
    setSelectedSimulationPaymentMethod,
    positionAgreement,
    setPositionAgreement,
    form,
    validator,
    isValid,
    setIsValid,
    handlerAgreementStatus,
    agreementStatus,
    hasPaidAgreement,
    updateAgreementSimulation,
  }
}
