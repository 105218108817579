import { Table } from '@gravity/table'
import { Box, CircularProgress } from '@material-ui/core'
import { mdiCheckCircle } from '@mdi/js'
import Icon from '@mdi/react'
import { Pagination } from '@olaisaac/design-system'

import { useFetchCampaignStudents } from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import { usePagination } from '@/shared/hooks/usePagination'
import { statusParamsDict } from '@/modules/enrollment/components/StatusBadge'

import { useTableData } from './hooks/useTableData'
import { useTableActions } from './hooks/useTableActions'
import * as Styled from './styles'
import { TableRowProps } from './types'

const DEFAULT_ITEMS_PER_PAGE = 25

interface StudentsTableProps {
  campaignId: string
  noDataMessage?: string
}

export const StudentsTable = ({ campaignId, noDataMessage }: StudentsTableProps) => {
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)

  const campaignStudentsQuery = useFetchCampaignStudents({
    campaignId,
    pagination: {
      page: pagination.page,
      per_page: DEFAULT_ITEMS_PER_PAGE,
    },
  })
  const campaignStudents = campaignStudentsQuery?.data?.data?.students ?? []

  const { rows, columnHeaders } = useTableData(campaignStudents)

  const { handleRowClick } = useTableActions()

  if (campaignStudentsQuery.isLoading) {
    return (
      <Styled.LoadingContainer>
        <CircularProgress />
      </Styled.LoadingContainer>
    )
  }

  if (!rows?.length) {
    return <Styled.EmptyStateContainer>{noDataMessage}</Styled.EmptyStateContainer>
  }

  const getRowStatus = (row: TableRowProps) => {
    if (row.isCampaignProposalCanceled) return 'Proposta cancelada'
    return statusParamsDict[row.reenrollmentStatus]?.text ?? ''
  }

  return (
    <>
      <Styled.TableWrapper>
        <Table.Root>
          <Table.Head>
            {columnHeaders.map(({ label, key, minWidth }) => (
              <Styled.TableHeaderCell key={key} name={key} style={{ minWidth }}>
                {label}
              </Styled.TableHeaderCell>
            ))}
          </Table.Head>
          <Table.Body>
            {rows.map(row => {
              return (
                <Styled.TableRow
                  disabled={row.isCampaignProposalCanceled}
                  key={row.key}
                  onClick={handleRowClick(row.key)}
                >
                  <Table.TextCell
                    disabled={row.isCampaignProposalCanceled}
                    subtext={getRowStatus(row)}
                  >
                    {row.columns.student_name}
                  </Table.TextCell>
                  <Table.TextCell
                    disabled={row.isCampaignProposalCanceled}
                    subtext={row.guardianEmail}
                  >
                    {row.columns.guardian_name}
                  </Table.TextCell>
                  <Table.CustomCell>
                    {row.columns.has_open_email === '1' ? (
                      <Icon size={1} path={mdiCheckCircle} />
                    ) : null}
                  </Table.CustomCell>
                  <Table.CustomCell>
                    {row.columns.is_engaged === '1' ? (
                      <Icon size={1} path={mdiCheckCircle} />
                    ) : null}
                  </Table.CustomCell>
                </Styled.TableRow>
              )
            })}
          </Table.Body>
        </Table.Root>
      </Styled.TableWrapper>
      {campaignStudentsQuery.data && (
        <Box my={2} px={2}>
          <Pagination
            currentPage={pagination.page}
            itensPerPage={pagination.itemsPerPage}
            itensPerPageOptions={[10, 15, 25, 50]}
            totalItens={campaignStudentsQuery.data?.pagination?.total ?? 0}
            onPageChange={newPage => {
              updatePaginationValue('page', newPage, true)
              window.scrollTo(0, 0)
            }}
            onItensPerChangeChange={newItensPerPage => {
              updatePaginationValue('itemsPerPage', newItensPerPage, true)
            }}
          />
        </Box>
      )}
    </>
  )
}
