import theme from '@/shared/theme'
import { Info } from '@material-ui/icons'
import styled from 'styled-components'

const { colors, spacing } = theme.primitiveTokens

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  background-color: #ecedfb;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
`

export const Icon = styled(Info)`
  margin-right: ${spacing[2]};
  height: ${spacing[6]};
  width: ${spacing[6]};
  color: ${colors.isaacBlue[60]};
`
