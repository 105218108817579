import { FC, useState } from 'react'
import { Notification } from '@olaisaac/design-system'

import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'

import { useInstallmentsByStudents } from './hooks/useInstallmentsByStudents'

import { useSelectPos } from 'src/escolas/hooks'
import InstallmentsDetail from '../../components/InstallmentDetail/InstallmentDetail'

import * as Styled from './styles'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const InstallmentsOverview: FC<StepComponentProps> = ({ id, onFinish, goBack }) => {
  const [showDiscountBanner, setShowDiscountBanner] = useState<boolean>(true)
  const { installmentsByStudents, totalAmount, hasDiscounts } = useInstallmentsByStudents()

  const showBanner = showDiscountBanner && hasDiscounts

  const { isLoading } = useSelectPos()

  const sendCheckoutEvent = useSendCheckoutEvent()

  function handleGoBack() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_CONTINUE, {
      name: 'Parcelas selecionadas - Continuar',
      location: id,
    })
    goBack()
  }

  return (
    <Styled.Container>
      {showBanner && (
        <Notification
          description="Os descontos de negociação concedidos aqui não impactam o repasse da escola."
          variation="information"
          onClose={() => setShowDiscountBanner(false)}
        />
      )}
      <InstallmentsDetail
        goBack={handleGoBack}
        installmentsByStudents={installmentsByStudents}
        isLoading={isLoading}
        onFinish={onFinish}
        totalAmount={totalAmount}
        stepName={id}
      />
    </Styled.Container>
  )
}

export default InstallmentsOverview
