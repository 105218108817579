import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Box, Tooltip } from '@material-ui/core'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const EachWorkingDayTypography = styled(StyledTypography)`
  color: #6d6d6d;
`
export const HelperTextTypography = styled(StyledTypography)`
  color: #ef1a0a;
  margin-top: 4px;
`
export const RadioBox = styled(Box)`
  display: flex;
`

export const DueDayTooltip = styled(Tooltip)`
  margin-left: -10px;
  margin-top: 4px;
  font-size: 16px;
  color: #838383;
`
