import styled from 'styled-components'
import { Grid, Select, Typography } from '@material-ui/core'

export const SelectYear = styled(Select).attrs(() => ({
  disableUnderline: true,
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
}))`
  border-bottom: none;
  border-radius: ${({ theme }) => theme.primitiveTokens.spacing[2]};
  padding: 2px ${({ theme }) => theme.primitiveTokens.spacing[2]};
  margin-left: ${({ theme }) => theme.primitiveTokens.spacing[1]};

  &.Mui-focused,
  &:hover {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]}14;
  }

  .MuiInput-input {
    font-size: 2rem;
    line-height: 2.35rem;
    font-weight: 500;
    color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  }

  .MuiSelect-selectMenu {
    display: flex;
  }
`

export const SelectYearTitle = styled(Typography)`
  font-weight: 500;
  font-size: 2rem;
`

export const PageContainer = styled.div`
  padding-top: ${({ theme }) => theme.primitiveTokens.spacing[8]};
  padding-bottom: ${({ theme }) => theme.primitiveTokens.spacing[10]};
`

export const HeaderButtonsWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.primitiveTokens.spacing[1]};

  button {
    flex: 1;
    max-width: 294px;
  }
`

/**
 * @deprecated Should be removed after ENABLE_MATRICULAS_SELECT_CYCLES rollout
 */
export const HeaderContainer = styled.header`
  margin: 36px 0 24px;
  display: flex;
  align-items: baseline;
  gap: 24px;
`

export const ActionsContainer = styled.div`
  margin: 36px 0 24px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 24px;
`

export const SearchContainer = styled.div`
  margin: 24px 0 48px;
  max-width: 480px;
`
