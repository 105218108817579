import {
  ActionDrawerHeader,
  ActionDrawerHeaderProps,
  Drawer,
  DrawerProps,
} from '@olaisaac/design-system'
import { ChangeEvent, ReactFragment, memo, useState } from 'react'
import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { ReenrollmentStatus } from '@/shared/interfaces'
import mainTheme from '@/shared/theme'
import { withStyles } from '@material-ui/core'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  getIsaacPayInstallmentMessage,
  isIsaacPaySchool,
} from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'

const Accordion = withStyles({
  root: {
    borderBottom: '1px solid #f0f0f0',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      backgroundColor: mainTheme.primitiveTokens.colors.gray[3],
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    margin: '0 24px 0 0',
  },
  content: {
    margin: '24px 0 24px 24px',
    padding: 0,
    '&$expanded': {
      margin: '24px 0 24px 24px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles(() => ({
  root: {
    margin: '0 24px 24px 24px',
    padding: 0,
  },
}))(MuiAccordionDetails)

type Props = Pick<DrawerProps, 'open'> & {
  onClose: (
    event?: Parameters<ActionDrawerHeaderProps['onClose']>[0]
  ) => ReturnType<ActionDrawerHeaderProps['onClose']>
}

const StatusDrawer = ({ open, onClose }: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const { school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const handleChange = (panel: string) => (event: ChangeEvent<any>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const enrollmentMessage = isIsaacPaySchool(school)
    ? getIsaacPayInstallmentMessage(isOutsourcedProvider)
    : 'Tudo certo com a matrícula do aluno. Suas mensalidades estão garantidas e serão repassadas pelo isaac!'

  const statusList: { description: ReactFragment; status: ReenrollmentStatus }[] = [
    {
      status: ReenrollmentStatus.NOT_REENROLLABLE,
      description: (
        <>
          Para iniciar a rematrícula, é necessário regularizar o pagamento das parcelas vencidas em
          contratos anteriores.
        </>
      ),
    },
    {
      status: ReenrollmentStatus.REENROLLMENT_AVAILABLE,
      description: (
        <>A rematrícula do aluno pode ser iniciada, pois todos os pagamentos estão em dia.</>
      ),
    },
    {
      status: ReenrollmentStatus.ENROLLMENT_IN_PROGRESS,
      description: (
        <>
          A matrícula do aluno será confirmada assim que todas as parcelas em aberto em contratos
          anteriores forem quitados e a primeira parcela de pré-matricula do contrato atual for paga
          (se aplicável).
        </>
      ),
    },
    {
      status: ReenrollmentStatus.ENROLLMENT_AT_RISK,
      description: (
        <>
          O aluno deu início ao processo de rematrícula. No entanto, surgiram pendências financeiras
          relacionadas a contratos anteriores. Se a dívida não for quitada, a matrícula será
          cancelada.
        </>
      ),
    },
    {
      status: ReenrollmentStatus.ENROLLED,
      description: <>{enrollmentMessage}</>,
    },
  ]

  return (
    <Drawer open={open}>
      <ActionDrawerHeader
        onClose={() => {
          onClose()
          isInitialized &&
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
              name: EnrollmentEventDispatcherEvents.CLICKED,
              action: 'click',
              customProperties: {
                $Button_name: 'FECHAR_GAVETA_DE_FILTROS',
                $modal_name: 'ENTENDA_A_SITUACAO_DE_MATRICULAS',
              },
            })
        }}
        title="Situação de matrícula"
        subtitle="A situação de matrícula oferece mais controle e facilidade para acompanhar o processo e diminuir a inadimplência."
      />

      {statusList.map(value => {
        return (
          <Accordion
            key={value.status}
            expanded={expanded === value.status}
            onChange={handleChange(value.status)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={value.status}
              id={value.status}
              onClick={() =>
                isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                  name: EnrollmentEventDispatcherEvents.CLICKED,
                  action: 'click',
                  customProperties: {
                    $Accordion_name: 'SUBSTATUS_GAVETA_DE_STATUS',
                    $modal_name: 'ENTENDA_A_SITUACAO_DE_MATRICULAS',
                    $value: value.status,
                  },
                })
              }
            >
              <StatusBadge status={value.status} />
            </AccordionSummary>
            <AccordionDetails>{value.description}</AccordionDetails>
          </Accordion>
        )
      })}
    </Drawer>
  )
}

export default memo(StatusDrawer)
