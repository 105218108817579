import dayjs from 'dayjs'

import {
  AgreementSimulationCombined,
  PaymentPlan,
  ReceivableStatuses,
  SimulationPaymentMethod,
} from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'
import { getReceivableStatus } from '@/modules/guardians/Negotiation/helpers/getReceivableStatus'
import { CheckoutCombinableReceivable } from 'src/escolas/contexts/checkoutContext'
import getReceivableMonth from '@/modules/guardians/Negotiation/steps/InstallmentsOverview/helpers/getReceivableMonth'
import { formatBankSlipInfoText } from '../../../helpers/textBankSlip'

function useShareMessage(
  totalAmount: number,
  agreementSimulations: AgreementSimulationCombined[],
  paymentFee?: {
    enabled: boolean
    percentage: number
    value: number
  }
) {
  const isPaymentFeeEnabled = paymentFee?.enabled && paymentFee?.value > 0

  function getCreditCardPaymentText(paymentPlan: PaymentPlan, totalAmount: number) {
    const { max_installments_allowed } = paymentPlan
    const numberOfInstallments = max_installments_allowed

    const suffixMsg = isPaymentFeeEnabled ? '' : ' sem juros'

    const infoText =
      numberOfInstallments > 1
        ? `até ${numberOfInstallments}x de ${formatCentsToReal(
            totalAmount / numberOfInstallments
          )}${suffixMsg}`
        : `à vista de ${formatCentsToReal(totalAmount)}${suffixMsg}`

    return `Cartão de crédito${isPaymentFeeEnabled ? ' (com taxa)' : ''}: ${infoText}`
  }

  function getBankSlipPaymentText(
    paymentPlan: PaymentPlan,
    totalAmount: number,
    creditCardFee: number
  ) {
    const { max_installments_allowed, min_down_payment } = paymentPlan
    const numberOfInstallments = max_installments_allowed

    const infoText = formatBankSlipInfoText(
      numberOfInstallments,
      totalAmount,
      min_down_payment,
      paymentFee?.enabled && creditCardFee > 0
    )

    return `Boleto/PIX: ${infoText}`
  }

  function getFormOfPaymentText(agreement: AgreementSimulationCombined) {
    const payments = agreement.payment_plans.map(paymentPlan => {
      switch (paymentPlan.payment_method) {
        case SimulationPaymentMethod.CREDIT_CARD:
          return getCreditCardPaymentText(
            paymentPlan,
            paymentFee?.enabled ? agreement.total_amount + paymentFee.value : agreement.total_amount
          )

        case SimulationPaymentMethod.POS:
        case SimulationPaymentMethod.DEBIT_CARD:
          return `Cartão de débito: à vista de ${formatCentsToReal(agreement.total_amount)}${
            isPaymentFeeEnabled ? '' : ' sem juros'
          }`

        case SimulationPaymentMethod.PIX:
        case SimulationPaymentMethod.BANK_SLIP:
          return getBankSlipPaymentText(
            paymentPlan,
            agreement.total_amount,
            agreement.credit_card_fee
          )

        default:
          return ''
      }
    })
    return payments.join('\n')
  }

  function getInstallmentText(numOfInstallments: number, isOverdue: boolean) {
    const needPlural = numOfInstallments === 1 ? '' : 's'
    return isOverdue
      ? `${numOfInstallments} parcela${needPlural} vencida${needPlural}`
      : `${numOfInstallments} parcela${needPlural} a vencer`
  }

  function getOriginalInstallmentsText(receivables: CheckoutCombinableReceivable[]) {
    return receivables
      .map(receivable => {
        const installmentLabel = getReceivableMonth(receivable.due_date, receivable.competenceDate)
        return `Parcela de ${installmentLabel}: ${formatCentsToReal(receivable.amount)}`
      })
      .join('\n')
  }

  const agreementsText = agreementSimulations
    .map((agreement, index) => {
      const isOverdue = agreement.receivables.every(
        receivable => getReceivableStatus(receivable) === ReceivableStatuses.OVERDUE
      )
      const paymentTitle = agreementSimulations.length > 1 ? `\nPagamento ${index + 1}` : ''

      return (
        paymentTitle +
        `\nAluno: ${agreement.receivables[0].student}` +
        `\nProduto: ${agreement.receivables[0].product}` +
        `\n${getInstallmentText(agreement.receivables.length, isOverdue)}` +
        `\n${getOriginalInstallmentsText(agreement.receivables)}` +
        `\n\nTotal: ${formatCentsToReal(agreement.total_amount)}` +
        `\n\nPode ser pago em:` +
        `\n${getFormOfPaymentText(agreement)}`
      )
    })
    .join('\n\n')

  const introductionFirstPart = `O valor total de ${formatCentsToReal(totalAmount)}`

  const introductionLastPart =
    agreementSimulations.length === 1
      ? 'será pago em um único pagamento:'
      : `será dividido nesses ${agreementSimulations.length} pagamentos:`

  const warningText = `!IMPORTANTE: valores válidos para pagamento hoje ${dayjs().format(
    'DD/MM/YYYY'
  )}`

  return `${introductionFirstPart} ${introductionLastPart}\n${agreementsText}\n\n${warningText}`
}

export default useShareMessage
