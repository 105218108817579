import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const LoadingState = () => {
  return (
    <>
      <Box display="flex" gridGap="1.5rem">
        <Skeleton
          variant="rect"
          width="6.375rem"
          height="2.375rem"
          style={{ borderRadius: '0.25rem' }}
        />

        <Skeleton
          variant="rect"
          width="17.5rem"
          height="2.375rem"
          style={{ borderRadius: '0.25rem' }}
        />
      </Box>

      <Box display="flex" alignItems="center">
        <Skeleton variant="rect" width="1.5rem" height="1.5rem" style={{ borderRadius: '50%' }} />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gridGap="0.5rem"
          margin="0 0.5rem 0 1rem"
        >
          <Skeleton
            variant="rect"
            width="7.5rem"
            height="1rem"
            style={{ borderRadius: '0.25rem' }}
          />

          <Skeleton
            variant="rect"
            width="8.5625rem"
            height="1rem"
            style={{ borderRadius: '0.25rem' }}
          />
        </Box>

        <Skeleton variant="rect" width="2.5rem" height="2.5rem" style={{ borderRadius: '50%' }} />
      </Box>
    </>
  )
}
