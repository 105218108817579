import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useContextEligibility } from '../contexts/CreditDataContext'
import { GracePeriodDate } from '../services/types'
import { dateToFormattedString } from './formatDate'

export const useGetGracePeriodDates = (): GracePeriodDate[] => {
  const { eligibility } = useContextEligibility()
  const isCcpCrEnabled = useUnleashFlag(UnleashFlags.CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE)

  let dates: Date[] = []

  if (isCcpCrEnabled) {
    const cpDates = eligibility.gracePeriod.CP || []
    const cpcrDates = eligibility.gracePeriod.CPCR || []
    dates = Array.from(new Set([...cpDates, ...cpcrDates]))
  } else {
    dates = eligibility.gracePeriod.CP || []
  }

  const gracePeriodDates: GracePeriodDate[] = dates.map(date => ({
    label: dateToFormattedString(date),
    value: date.toString(),
  }))

  return gracePeriodDates
}
