import { useMemo } from 'react'

import { CampaignStudent } from '@/escolas/services/enrollment/types'

import { columnNames, ColumnProps, TableRowProps } from '../types'

export const useTableData = (campaignStudents: CampaignStudent[]) => {
  const rows = useMemo((): TableRowProps[] => {
    return campaignStudents.map(student => {
      return {
        columns: {
          [columnNames.STUDENT_NAME]: student.student_name,
          [columnNames.GUARDIAN_NAME]: student.guardian_name,
          [columnNames.HAS_OPENED_EMAIL]: student.has_open_email ? '1' : '0',
          [columnNames.IS_ENGAGED]: student.is_engaged ? '1' : '0',
          [columnNames.PAYMENT_CONFIRMED]: student.paid_payment_plan ? '1' : '0',
        },
        key: student.student_id,
        reenrollmentStatus: student.enrollment_status,
        guardianEmail: student.guardian_email,
        isCampaignProposalCanceled: student.is_campaign_proposal_canceled,
      }
    })
  }, [campaignStudents])

  const columnHeaders: Array<ColumnProps> = [
    { key: columnNames.STUDENT_NAME, label: 'Aluno', sortable: false, minWidth: '190px' },
    { key: columnNames.GUARDIAN_NAME, label: 'Responsável', sortable: false, minWidth: '190px' },
    {
      key: columnNames.HAS_OPENED_EMAIL,
      label: 'Abriu email?',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.IS_ENGAGED,
      label: 'Demonstrou interesse?',
      sortable: false,
      minWidth: '136px',
    },
  ]

  return {
    rows,
    columnHeaders,
  }
}
