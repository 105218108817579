import { Typography } from '@olaisaac/design-system'

import { formatCurrencyAmount } from '@/modules/report/utils/formatCurrencyAmount'

type InfoCardValueProps = {
  children: number
}

export const InfoCardValue = ({ children }: InfoCardValueProps) => {
  const formattedValue = formatCurrencyAmount(children)

  return <Typography variation="bodyLarge">{formattedValue}</Typography>
}
