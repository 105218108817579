import type { SectionProps, SectionPlaceholderProps } from './types'

import { Container, Description, DescriptionPlaceholder, Title, TitlePlaceholder } from './styles'

export const Section = (props: SectionProps) => {
  const { title, description, variation = 'primary', children } = props

  return (
    <Container $variation={variation}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {children}
    </Container>
  )
}

export const SectionPlaceholder = (props: SectionPlaceholderProps) => {
  const { variation = 'primary', children } = props

  return (
    <Container $variation={variation}>
      <TitlePlaceholder role="heading" />
      <DescriptionPlaceholder role="text" />
      {children}
    </Container>
  )
}
