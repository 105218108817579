import { Button, ButtonGroup, Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

import { Footer } from '@/modules/guardians/Negotiation/components/Footer'
import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { formatCentsToReal } from 'src/shared/utils'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'
import { SharePaymentOptionsButton } from '@/modules/guardians/Negotiation/components/SharePaymentOptionsButton'

import * as S from './styles'
import { HelpButton } from '../../components/HelpButton'
import AgreementList from './components/AgreementList'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const AgreementListStep = ({ id, onFinish, goBack }: StepComponentProps) => {
  const { agreementSimulations, totalAmount } = useAgreement()

  const sendCheckoutEvent = useSendCheckoutEvent()

  function handleGoBack() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_BACK, {
      name: 'Parcelas selecionadas - Voltar',
      location: id,
    })
    goBack()
  }

  function handleOnClickContinue() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_CONTINUE, {
      name: 'Parcelas selecionadas - Continuar',
    })
    onFinish()
  }
  return (
    <S.Container>
      <Box marginTop={8} marginBottom={5}>
        <Typography
          style={{ textAlign: 'center' }}
          color="primary"
          variation="subtitleDesktopLarge"
        >
          O valor total de <b>{formatCentsToReal(totalAmount)}</b> será divido nesses{' '}
          {agreementSimulations.length} pagamentos e<br /> serão pagos em sequência:
        </Typography>
      </Box>

      <AgreementList agreements={agreementSimulations} />

      <Footer
        button={
          <Box width="100%" display="flex">
            <HelpButton eventLocationName={id} />
            <SharePaymentOptionsButton />
          </Box>
        }
      >
        <ButtonGroup>
          <Button variation="ghost" onClick={handleGoBack}>
            Voltar
          </Button>
          <Button variation="primary" onClick={handleOnClickContinue}>
            Começar o 1º pagamento
          </Button>
        </ButtonGroup>
      </Footer>
    </S.Container>
  )
}

export default AgreementListStep
