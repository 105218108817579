import { useState } from 'react'
import { SaveAlt } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { Drawer, ActionDrawerHeader, ButtonDocker, Button } from '@olaisaac/design-system'

import { useApi, useSnackbar } from '@/shared/hooks'

import { downloadFile } from '@/shared/utils'

import {
  BankTransfer,
  FinancialDetails,
  PayoutVersion,
  TransactionEventType,
} from '@/shared/interfaces'

import { ButtonContainer } from './styles'
import { GetAggregatedProvidersReportResponseDTO } from '@/escolas/services/report/types'
import { IsaacPayPayoutDrawerContent } from '../IsaacPayPayoutDrawerContent'
import { IsaacPayExternalPaymentDrawerContent } from '../IsaacPayExternalPaymentDrawerContent'

type IsaacPaySummaryInfo = {
  isaacPay_end_date: Date
  payoutBankTransfers?: BankTransfer[]
  payoutId: string
  payoutVersion: PayoutVersion
}

type SummaryDrawerProps = {
  externalPaymentSummary?: GetAggregatedProvidersReportResponseDTO
  financialDetails?: FinancialDetails
  isButtonDisabled: boolean
  isMonthlyViewEnabled: boolean
  isOpen: boolean
  isOutsourcedProvider?: boolean
  isPayoutOpen: boolean
  onClose: () => void
  payoutData: IsaacPaySummaryInfo
  summaryPeriod?: 'mensal' | 'semanal'
}

/**
 * TODO:
 * - Get colors from useTheme
 * - Receive drawer content from data structure
 * - Review payoutData attributes names
 *
 * Component to display isaacPay payout summary data
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.isPayoutOpen Represents the payout status
 * @param props.onClose Callback function called on request close
 * @param props.payoutData Payout summary data
 * @param props.summaryPeriod IsaacPay summary period type
 */
export const IsaacPaySummaryDrawer = ({
  externalPaymentSummary,
  financialDetails,
  payoutData,
  summaryPeriod = 'semanal',
  isPayoutOpen,
  isMonthlyViewEnabled,
  isOpen,
  isOutsourcedProvider,
  isButtonDisabled,
  onClose,
}: SummaryDrawerProps) => {
  const { api } = useApi()
  const { setMessage, setVariation, setIsOpen } = useSnackbar()

  const showDownloadButtonForIsaacPaySummary = !isButtonDisabled

  const [isLoadingAction, setIsLoadingAction] = useState(false)

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    try {
      setIsLoadingAction(true)
      await downloadFile(filename, 'pdf', () =>
        api.report.fetchPayoutReportFile({ version: 2, id: payoutId, extension: 'pdf' })
      )
    } catch {
      setMessage('Falha ao baixar arquivo. Tente novamente mais tarde.')
      setVariation('error')
      setIsOpen(true)
    } finally {
      setIsLoadingAction(false)
    }
  }

  const getValueColor = (value: number) => (value < 0 ? '#AB1207' : '#156320')

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
  }

  function getTotal(): number {
    if (!isMonthlyViewEnabled) {
      return financialDetails?.total - (values[TransactionEventType.BALANCE_TRANSFER] || 0)
    }

    return externalPaymentSummary?.total || 0
  }

  function getTotalFee(): number {
    if (!isMonthlyViewEnabled) {
      return -1 * financialDetails?.total_fee
    }

    return -1 * externalPaymentSummary?.total_fee
  }

  const bankTransfers = payoutData?.payoutBankTransfers || []

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader title="Resumo" onClose={onClose} />

      {isOutsourcedProvider ? (
        <IsaacPayExternalPaymentDrawerContent
          externalPaymentSummary={externalPaymentSummary}
          bankTransfers={bankTransfers}
          getValueColor={getValueColor}
        />
      ) : (
        <IsaacPayPayoutDrawerContent
          total={getTotal()}
          total_fee={getTotalFee()}
          bankTransfers={bankTransfers}
          getValueColor={getValueColor}
          values={values}
        />
      )}

      {showDownloadButtonForIsaacPaySummary && (
        <ButtonDocker>
          <Tooltip
            title={
              isPayoutOpen
                ? 'Disponível apenas para repasses fechados'
                : 'Baixar o arquivo .pdf do período'
            }
          >
            <ButtonContainer>
              <Button
                fullWidth
                startIcon={<SaveAlt />}
                onClick={() => handleDownloadReport(payoutData.payoutId, 'resumo')}
                loading={isLoadingAction}
                disabled={isPayoutOpen}
                data-testid="download-button"
              >
                {summaryPeriod === 'mensal' ? 'Baixar resumo' : 'Baixar extrato'}
              </Button>
            </ButtonContainer>
          </Tooltip>
        </ButtonDocker>
      )}
    </Drawer>
  )
}
