import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'

import theme from 'src/shared/theme'

const { colors, spacing } = theme.primitiveTokens
const { color, variation } = theme.humanizedTokens.components.typography

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: ${spacing[4]} ${spacing[8]};
  border-radius: 8px;
  border: 1px solid ${colors.gray[10]};
  ${color.primary};
  height: 100%;
`

export const Title = styled.div`
  ${variation.bodySmall};
`
export const TitlePlaceholder = styled(Skeleton)`
  ${variation.bodySmall};
`
