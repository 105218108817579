import styled from 'styled-components'

import { LinkProps, VariantType } from './types'

import theme from 'src/shared/theme'

const { colors, spacing } = theme.primitiveTokens

const getValueByVariant = (variant: VariantType, primaryValue: string, secondaryValue: string) =>
  variant === 'primary' ? primaryValue : secondaryValue

const enabled = ({ $variant }) => {
  const variantColor = getValueByVariant($variant, colors.isaacBlue[60], colors.gray[80])
  const iconVariantColor = getValueByVariant($variant, colors.isaacBlue[60], colors.gray[60])

  return `
  &:link,
  &:visited {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: ${spacing[4]} ${spacing[8]};
    border: 1px solid ${variantColor};
    border-radius: 8px;
    text-decoration: none;
    color: ${variantColor};
    height: 100%;

    &:not(:hover):not(:active) .MuiSvgIcon-root{
      color: ${iconVariantColor};
    }
  }
`
}

const hover = ({ $variant }) => {
  const variantColor = getValueByVariant($variant, colors.isaacBlue[60], colors.gray[70])
  return `
  &:hover {
    color: ${colors.gray[0]};
    background-color: ${variantColor};
    border-color: ${variantColor};
  }
`
}

const active = ({ $variant }) => {
  const variantColor = getValueByVariant($variant, colors.isaacBlue[70], colors.gray[80])
  return `
  &:active {
    background-color: ${variantColor};
    border-color: ${variantColor};
  }
`
}

const focus = () => {
  return `
  &:focus-visible {
    outline: ${colors.isaacBlue[30]} solid 3px;
  }
`
}

export const Anchor = styled.a<LinkProps>`
  ${({ $variant = 'primary' }) => {
    return `
    ${enabled({ $variant })}
    ${hover({ $variant })}
    ${active({ $variant })}
    ${focus()}
  `
  }}
`
