import {
  formatCurrencyValue,
  formatNumberMicroCentsToReal,
  parseCentsToDecimal,
} from '@/shared/utils/numberFormatters'

/**
 * Utility function to parse cents/micro cents payout report value
 *
 * @param amount Payout report value
 * @param useMicroCents Indicates if value is in micro cents or cents
 *
 * @returns Parsed value
 */
export const formatCurrencyAmount = (amount: number, useMicroCents = true) => {
  if (useMicroCents) {
    const decimalAmount = formatNumberMicroCentsToReal(amount)

    return formatCurrencyValue(decimalAmount)
  }

  const decimalAmount = parseCentsToDecimal(amount)

  return formatCurrencyValue(decimalAmount)
}
