import type { User } from '@/modules/access-management/models/User'

export const canRemoveUserAccess = (
  loggedUserId: string,
  removeAccessRoles: ('remover_acesso_mantenedor' | 'remover_acesso_secretaria')[],
  userTobeRemoved: User
) => {
  if (loggedUserId === userTobeRemoved.id) return false

  if (userTobeRemoved.group_name === 'gestor') return false

  if (
    userTobeRemoved.group_name === 'mantenedor' &&
    removeAccessRoles.includes('remover_acesso_mantenedor')
  ) {
    return true
  }

  if (
    userTobeRemoved.group_name === 'secretaria' &&
    removeAccessRoles.includes('remover_acesso_secretaria')
  ) {
    return true
  }

  return false
}
