import { SchoolRounded } from '@material-ui/icons'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
const IconColor = styled(SchoolRounded)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[60]};
  margin-right: 9px;
`

type StudentCardProps = {
  studentName: string
}

export const StudentInfo = ({ studentName }: StudentCardProps) => {
  return (
    <div>
      <TitleContainer>
        <IconColor />
        <Typography variation="bodySmall" color="secondary">
          Aluno(a)
        </Typography>
      </TitleContainer>
      <Typography variation="headlineDesktopMedium">{studentName}</Typography>
    </div>
  )
}
