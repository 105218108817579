import { Box, Typography } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'

import ErrorImage from '../../assets/algo-deu-errado.svg'

type ErrorProps = {
  onRetry: () => void
}

export const GenericError = ({ onRetry }: ErrorProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box maxWidth={552} display="flex" flexDirection="column" alignItems="center">
        <img src={ErrorImage} alt="Imagem de erro" />

        <Typography
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            letterSpacing: '-0.5px',
            lineHeight: '2.25rem',
            textAlign: 'center',
            marginTop: '2.5rem',
          }}
        >
          Algo deu errado
        </Typography>

        <Typography
          style={{
            fontFamily: 'Roboto',
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '0.15px',
            lineHeight: '1.5rem',
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          Por favor, verifique sua conexão com a internet ou aguarde um momento e tente novamente.
        </Typography>

        <Button onClick={onRetry} style={{ padding: '8px 22px', marginTop: '2rem' }}>
          Tentar novamente
        </Button>
      </Box>
    </Box>
  )
}
