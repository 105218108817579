import { RouteDefinition } from '@/modules/core/router'
import { ModuleDefinition } from '@/modules/core/module'
import { useRouteMatch } from 'react-router-dom'
import LegacyRouter from '@/escolas/router'

export const routes: RouteDefinition[] = [
  { key: 'ESCOLAS_LEGACY', path: '', component: LegacyRouter },
]

export const useEscolasModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  module.registerRoutes(routes, { basePath })
}
