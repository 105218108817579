import type { User } from '@/modules/access-management/models/User'

export const canUpdateUserAccess = (
  loggedUserId: string,
  assignAccessRoles: (
    | 'atribuir_acesso_secretaria'
    | 'atribuir_acesso_mantenedor'
    | 'atribuir_acesso_gestor'
  )[],
  userToBeUpdated: User
) => {
  if (loggedUserId === userToBeUpdated.id) return true

  if (userToBeUpdated.group_name === 'gestor') return false

  if (
    userToBeUpdated.group_name === 'mantenedor' &&
    assignAccessRoles.includes('atribuir_acesso_mantenedor')
  ) {
    return true
  }

  if (
    userToBeUpdated.group_name === 'secretaria' &&
    assignAccessRoles.includes('atribuir_acesso_secretaria')
  ) {
    return true
  }

  return false
}
