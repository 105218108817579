import { useForm, UseFormReturn } from 'react-hook-form'
import { FormOfPayment } from 'src/shared/interfaces/agreement'
import { useEffect } from 'react'

export type NegotiationFlowMetaData = {
  credit_card_payment_option: string
  due_date: Date
  min_down_payment: number
  number_of_installments: number
  payment_method: string
  selected_pos: string
}

const defaultValidator = (form: UseFormReturn<NegotiationFlowMetaData>) => {
  return !form.getValues().payment_method
}

const useNegotiationPaymentFormsAgreement = (
  formOfPayment: FormOfPayment
): {
  form: UseFormReturn<NegotiationFlowMetaData>
  validator: (form: UseFormReturn<NegotiationFlowMetaData>) => boolean
} => {
  const form = useForm<NegotiationFlowMetaData>({
    mode: 'onChange',
  })

  useEffect(() => {
    form.reset()
  }, [formOfPayment])

  return { form, validator: defaultValidator }
}

export default useNegotiationPaymentFormsAgreement
