import { useState } from 'react'

export const useDialogVisibility = () => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const closeDialog = () => setDialogVisible(false)
  const openDialog = () => setDialogVisible(true)

  return {
    dialogVisible,
    closeDialog,
    openDialog,
  }
}
