import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import type { NotificationResponse } from '../../services/types'

export const useTrackNoticationTableEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const trackRowClick = (notification: NotificationResponse) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.TABLE_ROW_CLICK,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $page_name: EventDispatcherEventScopes.NOTIFICATIONS,
          $title: notification.title,
          $notification: notification,
        },
      })
    }
  }

  const trackPageChange = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $page_name: EventDispatcherEventScopes.NOTIFICATIONS,
          $title: 'Change page',
          $button: 'Pagination button',
        },
      })
    }
  }

  const trackUnreadFilterClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $page_name: EventDispatcherEventScopes.NOTIFICATIONS,
          $title: 'Apenas não lidos',
          $button: 'Unread filter button',
        },
      })
    }
  }

  return {
    trackPageChange,
    trackRowClick,
    trackUnreadFilterClick,
  }
}
