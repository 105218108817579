import dayjs, { Dayjs } from 'dayjs'

export const SECOND_IN_MILLIS = 1000
export const MINUTE_IN_MILLIS = SECOND_IN_MILLIS * 60
export const HOUR_IN_MILLIS = MINUTE_IN_MILLIS * 60
export const DAY_IN_MILLIS = HOUR_IN_MILLIS * 24

export const getStartOfToday = () => dayjs().set('hour', 0).set('minute', 0).set('second', 0)
export const getEndOfToday = () => dayjs().set('hour', 23).set('minute', 59).set('second', 59)
export const beforeToday = (date: Dayjs) => date && date.isBefore(getStartOfToday())
export const beforeOrEqualsToday = (date: Dayjs) => {
  if (!date) return false
  const today = getEndOfToday()
  return date.isBefore(today) || date.isSame(today)
}

export const makeIsDayAfterDate = (baseDate: Dayjs | string) => (
  currentComparedDate: Dayjs
): boolean => {
  if (!currentComparedDate || !baseDate) return false
  return dayjs(currentComparedDate).isAfter(baseDate, 'day')
}

export const makeIsDayBeforeDate = (baseDate: Dayjs | string) => (
  currentComparedDate: Dayjs | string
): boolean => {
  if (!currentComparedDate || !baseDate) return false
  return dayjs(currentComparedDate).isBefore(baseDate, 'day')
}

export const makeIsDayAfterOrSameDate = (baseDate: Dayjs | string) => (
  currentComparedDate: Dayjs | string
): boolean => {
  if (!currentComparedDate || !baseDate) return false
  return (
    dayjs(currentComparedDate).isSame(baseDate, 'day') ||
    dayjs(currentComparedDate).isAfter(baseDate, 'day')
  )
}

export const makeIsMonthAfterOrSameMonth = (baseDate: Dayjs | string) => (
  currentComparedDate: Dayjs
): boolean => {
  if (!currentComparedDate || !baseDate) return false
  return (
    dayjs(currentComparedDate).isSame(baseDate, 'month') ||
    dayjs(currentComparedDate).isAfter(baseDate, 'month')
  )
}

export const makeIsCurrentCycleOrAfter = (referenceYear: string) => (
  currentCompareYear: Dayjs
): boolean => {
  if (!currentCompareYear || !referenceYear) {
    return false
  }
  return dayjs(currentCompareYear).year() <= Number(referenceYear)
}

export const isDayBeforeToday = makeIsDayBeforeDate(dayjs())

export const isDayAfterToday = makeIsDayAfterDate(dayjs())

export const isDayTodayOrAfter = makeIsDayAfterOrSameDate(dayjs())

export const isCurrentMonthOrAfter = makeIsMonthAfterOrSameMonth(dayjs())

export const isDayToday = (date: Dayjs | string): boolean => {
  if (!date) return false
  return dayjs(date).isSame(dayjs(), 'day')
}

export const calcFixedDate = (baseMonth: Dayjs, nthWorkingDay: number): Dayjs => {
  const lastDayOfChosenMonth = dayjs(baseMonth).daysInMonth()
  const isAfter = nthWorkingDay > lastDayOfChosenMonth

  const day = isAfter ? lastDayOfChosenMonth : nthWorkingDay
  const fixedDate = dayjs(new Date(baseMonth.year(), baseMonth.month(), day))

  return fixedDate
}
