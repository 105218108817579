import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 16px;
  margin-top: ${({ theme }) => theme.primitiveTokens.spacing[4]};
  margin-bottom: ${({ theme }) => theme.primitiveTokens.spacing[8]};
`

export const Skeleton = styled(MuiSkeleton)`
  height: 110px;
  border-radius: 8px;
`
