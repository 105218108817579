import type { SubsectionPlaceholderProps, SubsectionProps } from './types'

import { Container, InfoContainer, Title, TitlePlaceholder } from './styles'

export const Subsection = (props: SubsectionProps) => {
  const { title, icon, columnsCount = 3, children } = props

  return (
    <Container>
      {title && icon && (
        <Title>
          {icon}
          {title}
        </Title>
      )}
      <InfoContainer $columnsCount={columnsCount}>{children}</InfoContainer>
    </Container>
  )
}

export const SubsectionPlaceholder = (props: SubsectionPlaceholderProps) => {
  const { children, columnsCount = 3 } = props

  return (
    <Container>
      <TitlePlaceholder role="heading" />
      <InfoContainer $columnsCount={columnsCount}>{children}</InfoContainer>
    </Container>
  )
}
