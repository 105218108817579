import { UseFormReturn } from 'react-hook-form'
import { useContract } from 'src/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { Installment, PreContractStatuses, ProcessedInstallment } from 'src/shared/interfaces'
import CancellationDrawerDefaultContent, {
  ContractCancellationForm,
} from './CancellationDrawerDefaultContent'
import CancellationDrawerPreContractContent from './CancellationDrawerPreContractContent'

export type CancellationDrawerProps = {
  availableInstallments: Installment[]
  form: UseFormReturn<ContractCancellationForm>
  isOnTheFirstPage: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  processedInstallments: ProcessedInstallment[]
  setIsOnTheFirstPage: (value: boolean) => void
  setShowConfirmCancellationMessage: (value: boolean) => void
}

const CancellationDrawer = ({
  isOpen,
  onClose,
  onConfirm = () => null,
  processedInstallments,
  availableInstallments,
  form,
  isOnTheFirstPage,
  setIsOnTheFirstPage,
  setShowConfirmCancellationMessage,
}: CancellationDrawerProps) => {
  const { contract } = useContract()

  const cancelPreContractFeatureFlagValue = useUnleashFlag(
    UnleashFlags.ENGAJAMENTO_PAS392_CANCELLATION_PRECONTRACT
  )

  const isPreContract = contract?.pre_contract_status !== PreContractStatuses.EFFECTED_PCS

  return isPreContract && cancelPreContractFeatureFlagValue ? (
    <CancellationDrawerPreContractContent
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      form={form}
    />
  ) : (
    <CancellationDrawerDefaultContent
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      processedInstallments={processedInstallments}
      isOnTheFirstPage={isOnTheFirstPage}
      setIsOnTheFirstPage={setIsOnTheFirstPage}
      form={form}
      setShowConfirmCancellationMessage={setShowConfirmCancellationMessage}
      availableInstallments={availableInstallments}
    />
  )
}

export default CancellationDrawer
