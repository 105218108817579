import { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import DiscountsList from 'src/shared/components/DiscountsListForm/DiscountsListForm'
import PreContractEditHeader from './components/PreContractEditHeader'
import { useTuitionFormManager } from './hooks/useTuitionFormManager'
import { usePreContract } from './hooks/usePreContract'
import { PathParams } from 'src/shared/interfaces'
import TuitionInstallmentsForm from 'src/shared/components/TuitionInstallmentsForm'
import { Box } from '@material-ui/core'
import { useSchool } from 'src/escolas/hooks/useSchool'
import { getInstallments } from './utils/getInstallments'
import Accordion from 'src/shared/components/Accordion'
import { createSaveTuitionPayload } from './utils/createSaveTuitionPayload'
import PreContractEditSummary from './components/PreContractEditSummary'
import {
  PreContractEditSectionBox,
  PreContractEditGrid,
  PreContractEditGridItem,
  PreContractEditGridDrawer,
} from './style'
import { NotificationProps } from '@olaisaac/design-system'
import { useRedirection } from './hooks/navigateToContractPage'
import { useParams } from 'react-router-dom'
import { useSnackbar } from '@/shared/hooks'

export const PreContractEdit = () => {
  const { contractId } = useParams<PathParams>()
  const { school } = useSchool()
  const {
    tuitionForm,
    resetTuitionForm,
    appendNewDiscount,
    removeDiscount,
    discountFields,
    discountsDeleted,
    setDiscountsExcluded,
  } = useTuitionFormManager()

  const {
    preContract,
    isFetchPreContractLoading,
    saveTuition,
    isSaveTuitionLoading,
    isSaveTuitionError,
    isSaveTuitionSuccess,
  } = usePreContract(contractId)

  const { navigateToContractsPage } = useRedirection(preContract?.guardian.id)

  const { getValues, trigger } = tuitionForm

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const showSnackbar = (
    variation: NotificationProps['variation'],
    message: string,
    isOpen: boolean
  ): void => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(isOpen)
  }

  const handleSaveTuition = () =>
    saveTuition(
      createSaveTuitionPayload(contractId, {
        ...getValues(),
        discounts_deleted: discountsDeleted,
      })
    )

  const { monthly_amount } = getValues()

  useEffect(() => {
    if (preContract) {
      resetTuitionForm(getInstallments(preContract))
      trigger()
    }
  }, [preContract])

  useEffect(() => {
    if (isSaveTuitionError) {
      showSnackbar('error', 'Erro ao editar contrato', true)
    }
  }, [isSaveTuitionError])

  useEffect(() => {
    if (isSaveTuitionSuccess) {
      showSnackbar('success', 'Contrato editado com sucesso', true)

      setDiscountsExcluded([])

      navigateToContractsPage()
    }
  }, [isSaveTuitionSuccess])

  return (
    <>
      {isFetchPreContractLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="400px">
          <CircularProgress />
        </Box>
      ) : (
        <PreContractEditGrid>
          <PreContractEditGridItem>
            <PreContractEditHeader
              schoolName={school?.name}
              guardianName={preContract?.guardian?.name ?? ''}
              studentName={preContract?.student?.name ?? ''}
              hasDocusign={preContract?.has_docusign}
            />
            <PreContractEditSectionBox>
              <Accordion title="Mensalidade" step={1} disableClosing>
                <TuitionInstallmentsForm form={tuitionForm} />
                <DiscountsList
                  form={tuitionForm}
                  appendDiscount={appendNewDiscount}
                  removeDiscount={removeDiscount}
                  discountFields={discountFields}
                  totalAmount={monthly_amount}
                />
              </Accordion>
            </PreContractEditSectionBox>
          </PreContractEditGridItem>
          <PreContractEditGridDrawer>
            <PreContractEditSummary
              studentName={preContract?.student?.name ?? ''}
              form={tuitionForm}
              discountsDeleted={discountsDeleted}
              productName={preContract?.student?.product_name ?? ''}
              isLoading={isSaveTuitionLoading}
              onFinish={handleSaveTuition}
            />
          </PreContractEditGridDrawer>
        </PreContractEditGrid>
      )}
    </>
  )
}
