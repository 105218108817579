import { cardInfos } from './constants'
import { GuardianFinanceSummaryStatus } from '@/modules/guardians/GuardianDetails/constants'
import { useBigNumber } from './useBigNumber'
import { CURRENT_MONTH_TEXT } from '@/escolas/pages/Home/constants'
import { Text } from '@gravity/text'
import { formatCentsToReal } from '@/shared/utils'
import { Skeleton } from '@gravity/skeleton'
import * as Styled from './styles'

type Props = {
  isLoading: boolean
  quantity?: number
  status?: GuardianFinanceSummaryStatus
  totalAmount?: number
}

export const BigNumber = ({ totalAmount, quantity, status, isLoading = false }: Props) => {
  const { handleBigNumberClick, isSelected } = useBigNumber(status)
  const { badgeText, badgeColor, cardDescription } = cardInfos(quantity, CURRENT_MONTH_TEXT)

  if (isLoading)
    return (
      <Styled.CardSkeleton>
        <Skeleton className="mb-8" width={62} height={24} radius="full" />
        <Skeleton className="mb-8" width={258} height={34} radius="full" />
        <Skeleton className="mb-8" width={143} height={24} radius="full" />
      </Styled.CardSkeleton>
    )
  return (
    <Styled.Card
      role="option"
      aria-selected={isSelected}
      aria-label="text"
      onClick={handleBigNumberClick}
      isSelected={isSelected}
    >
      <Styled.Badge variant="soft" color={badgeColor[status]}>
        {badgeText[status]}
      </Styled.Badge>
      <Styled.CurrencyWrapper>
        <Styled.CurrencySymbol>R$</Styled.CurrencySymbol>
        <Styled.BigNumberText>
          {formatCentsToReal(totalAmount).replace(/[R$]\s?/g, '')}
        </Styled.BigNumberText>
      </Styled.CurrencyWrapper>
      <Text>{cardDescription[status]}</Text>
    </Styled.Card>
  )
}
