import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import AddIcon from '@material-ui/icons/Add'
import EventIcon from '@material-ui/icons/Event'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Delete from '@material-ui/icons/Delete'

import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'

import { Typography } from '@olaisaac/design-system'
import { PageWrapper } from '@/escolas/components/PageWrapper'
import { Installment } from '@/modules/contract/services/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT, useSnackbar } from '@/shared/hooks'

import { PageFooter } from './components/PageFooter/index'
import { SelectedInstallmentsText } from './components/SelectedInstallmentsText'
import {
  calculateHasUnsavedChanges,
  canAddDiscount,
  canEditAmount,
  filterSelectedInstallments,
  canEditDueDates,
  canCancel,
  cancelContract,
} from './utils'
import * as Styled from './styles'
import { useUpdateInstallments } from './hooks/useUpdateInstallments'
import { PageHeader } from './components/PageHeader/index'
import { EditAmountDialog, EditAmountForm } from './components/EditAmountDialog/index'
import { EditDueDateDialog } from './components/EditDueDateDialog/index'
import { AddDiscountDialog, AddDiscountForm } from './components/AddDiscountDialog/index'
import { InstallmentsTable } from './components/InstallmentsTable/index'
import { TableTotals } from './components/TableTotals/index'
import { createUpdateInstallmentsPayload } from './utils/createUpdateInstallmentsPayload'
import { PersonalData } from './components/PersonalData/index'
import { PersonalDataInfo } from './components/PersonalData/types'
import { EditActionButton } from './components/EditActionButton'
import {
  useCancelInstallmentsMutation,
  useContractDetailsQuery,
  useInstallmentsToUpdateQuery,
  useUpdateInstallmentsMutation,
} from './hooks/queries'
import { GoBackButton } from '@/shared/components/GoBackButton'
import { BackButtonWrapper } from './styles'
import { CancellingDialog } from './components/CancellingDialog'
import { contractAPI } from '@/modules/contract/services'
import { ErrorDialog, ErrorDialogNames, selectErrorDialogName } from './components/ErrorDialog'
import { ErrorDialog as GenericErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { EventProvider, useEvents } from './hooks/eventContext'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useQueryClient } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { CancelContractDialog } from './components/CancelContractDialog'
import { useToast } from '@gravity/toast'
import { useUnleashContext } from '@unleash/proxy-client-react'
import { AxiosError } from 'axios'

export enum DialogNames {
  ADD_DISCOUNT = 'ADD_DISCOUNT',
  CANCEL = 'CANCEL',
  CANCEL_CONTRACT = 'CANCEL_CONTRACT',
  EDIT_AMOUNT = 'EDIT_AMOUNT',
  EDIT_DUE_DATE = 'EDIT_DUE_DATE',
}

export const dialogEventHash: Record<DialogNames, string> = {
  [DialogNames.ADD_DISCOUNT]: 'Descontos',
  [DialogNames.CANCEL]: 'Cancelar',
  [DialogNames.CANCEL_CONTRACT]: 'Cancelar contrato',
  [DialogNames.EDIT_AMOUNT]: 'Valor da parcela',
  [DialogNames.EDIT_DUE_DATE]: 'Vencimentos',
}

type EditContractProps = {
  api: contractAPI
  contractId: uuid
  enableCancelContractFF?: boolean
  enableCancelFF?: boolean
  enableEditAmountFF?: boolean
  redirectOnSuccess?: boolean
  schoolBlockedForCancel?: boolean
  schoolId: uuid
}

const PageComponent = ({
  api,
  contractId,
  schoolId,
  redirectOnSuccess = true,
  enableCancelFF = true,
  enableEditAmountFF = false,
  schoolBlockedForCancel = false,
  enableCancelContractFF = true,
}: EditContractProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState(false)
  const [dialogName, setDialogName] = useState<DialogNames | ''>('')
  const [errorDialogName, setErrorDialogName] = useState<ErrorDialogNames>(null)

  const updateContext = useUnleashContext()
  const { toast } = useToast()

  const savedInstallments = useRef<Installment[]>()
  const queryClient = useQueryClient()

  const updateInstallmentsMutation = useUpdateInstallmentsMutation(api)
  const cancelInstallmentsMutation = useCancelInstallmentsMutation(api)
  const { data: response, isLoading, isError } = useInstallmentsToUpdateQuery(api, {
    contractId,
    schoolId,
  })

  const {
    data: contractDetails,
    isLoading: isLoadingContractDetails,
    refetch: refetchContractDetails,
  } = useContractDetailsQuery(api, {
    contractId,
  })

  const contract = response?.data ?? null

  const personalData: PersonalDataInfo = {
    studentName: contract?.student_name,
    productName: contract?.product_name,
    guardianName: contract?.guardian_name,
    referenceYear: contract?.reference_year,
  }

  const {
    setUpdatedInstallments,
    selectedInstallmentIds,
    setSelectedInstallmentIds,
    updateAmounts,
    addDiscounts,
    updateDueDates,
    removeDiscounts,
    updatedInstallments,
  } = useUpdateInstallments(contract?.installments ?? [])

  const events = useEvents()
  useEffect(() => {
    if (!contract) {
      return
    }

    events.setContract(contract)
    events.setSelectedInstallmentIds(selectedInstallmentIds)
  }, [contract, selectedInstallmentIds])

  const { sendHotjarEvent } = useHotjar()
  useEffect(() => {
    sendHotjarEvent(HotjarEvents.EDIT_CONTRACT_PAGE)
  }, [])

  const [installmentIDsToCancel, setInstallmentIDsToCancel] = useState<uuid[]>([])

  useSetPageTitle('Editar Contrato')
  const { setSnackBar } = useSnackbar()

  const { school } = useSelectedSchool()
  const history = useHistory()
  const { isAdmin } = useJWT()

  useEffect(() => {
    if (!isLoading && !isError && contract) {
      setUpdatedInstallments(contract.installments)
      savedInstallments.current = contract.installments
      events.pageViewed()
    }
  }, [isLoading, isError, contract, contractId, schoolId])

  const handleCloseDialog = () => {
    setDialogName('')
  }

  const handleCancel = () => {
    if (dialogName in DialogNames) events.clickCancelBtn(dialogName as DialogNames)
    handleCloseDialog()
  }

  const handleSaveEditAmount = (values: EditAmountForm) => {
    updateAmounts(values)
    if (dialogName in DialogNames) events.editAmount(dialogName as DialogNames)
    handleCloseDialog()
  }

  const handleAddDiscount = (values: AddDiscountForm, percentMode: boolean) => {
    addDiscounts(values, percentMode)
    events.addDiscount(values, percentMode)
    handleCloseDialog()
  }

  const handleEditDueDates = (dueDates: datestring[], workingDayMode: boolean) => {
    updateDueDates(dueDates)
    events.editDueDate(workingDayMode)
    handleCloseDialog()
  }

  const handleDeleteDiscount = (installmentId: uuid, discountIndexToDelete: number) => {
    removeDiscounts(installmentId, discountIndexToDelete)
    events.removeDiscount(installmentId, discountIndexToDelete)
  }

  const handleCancelInstallments = (selectedInstallmentIds: string[]) => {
    setInstallmentIDsToCancel(
      Array.from(new Set([...installmentIDsToCancel, ...selectedInstallmentIds]))
    )
    handleCloseDialog()
  }

  const goToGuardianInstallmentsPage = () => {
    if (redirectOnSuccess) {
      const { guardian_id, product_id, student_id } = contract
      history.push({
        pathname: `/${school.slug}/responsaveis/${guardian_id}/faturas?student_ids=${student_id}&product_ids=${product_id}`,
      })
    }
  }

  const cleanUpOnSuccess = () => {
    setSnackBar('As edições no contrato foram salvas.', 'success')
    setSelectedInstallmentIds([])
    setInstallmentIDsToCancel([])
    refetchContractDetails()
    queryClient.invalidateQueries(['guardian-details-installments'])
    goToGuardianInstallmentsPage()
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    setIsErrorOnSubmit(false)

    const updateInstallmentsPayload = createUpdateInstallmentsPayload(
      contract.installments,
      updatedInstallments
    )
    const hasUpdate = updateInstallmentsPayload.length > 0
    let updateSuccess = false

    const hasCancel = installmentIDsToCancel.length > 0
    let cancelSuccess = false

    try {
      const correlationID = uuidv4()

      const updateInstallmentsPayload = createUpdateInstallmentsPayload(
        contract.installments,
        updatedInstallments
      )

      if (hasUpdate) {
        await updateInstallmentsMutation.mutateAsync({
          schoolId,
          contractId: contract.id,
          updateInstallmentsRequest: updateInstallmentsPayload,
          correlationID,
        })

        updateSuccess = true
      }

      if (hasCancel) {
        await cancelInstallmentsMutation.mutateAsync({
          schoolId,
          contractId: contract.id,
          installmentIDs: installmentIDsToCancel,
          correlationID,
        })

        cancelSuccess = true
      }

      cleanUpOnSuccess()
    } catch (error) {
      events.submitError()
      console.error(error)

      Sentry.captureException(error)

      setIsErrorOnSubmit(true)
      setErrorDialogName(
        selectErrorDialogName({ hasUpdate, updateSuccess, hasCancel, cancelSuccess })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const openDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: DialogNames) => {
    setDialogName(name)
    const buttonText = (e.target as HTMLButtonElement).textContent
    events.clickEditAction(buttonText)
  }

  const isSelected = selectedInstallmentIds.length > 0
  const installments = contract !== null ? updatedInstallments : []
  const selectedInstallments = filterSelectedInstallments(installments, selectedInstallmentIds)

  const dialogComponent =
    {
      [DialogNames.EDIT_AMOUNT]: (
        <EditAmountDialog
          onClose={handleCancel}
          onConfirm={handleSaveEditAmount}
          selectedInstallments={selectedInstallments}
        />
      ),
      [DialogNames.EDIT_DUE_DATE]: (
        <EditDueDateDialog
          api={api}
          onClose={handleCancel}
          onConfirm={handleEditDueDates}
          selectedInstallments={selectedInstallments}
          allInstallments={installments}
        />
      ),
      [DialogNames.ADD_DISCOUNT]: (
        <AddDiscountDialog
          onClose={handleCancel}
          onConfirm={handleAddDiscount}
          selectedInstallments={selectedInstallments}
        />
      ),
      [DialogNames.CANCEL]: (
        <CancellingDialog
          onClose={handleCancel}
          onConfirm={handleCancelInstallments}
          selectedInstallments={selectedInstallments}
          installments={installments}
        />
      ),
      [DialogNames.CANCEL_CONTRACT]: (
        <CancelContractDialog
          api={api}
          contractId={contractId}
          onClose={handleCancel}
          onError={(error: unknown) => {
            events.submitError()
            console.error(error)

            if (
              (error as AxiosError)?.response?.status === 403 &&
              (error as AxiosError)?.response?.data?.errors[0]?.message ===
                'forbidden: school has reached the contract cancellation limit'
            ) {
              toast({
                style: { width: '440px' },
                type: 'error',
                title: 'Cancelamento de contrato indisponível',
                description:
                  'Por questões de segurança, esta ação está indisponível. Caso necessite realizar essa operação, procure o time de suporte.',
              })
              setTimeout(() => {
                updateContext({ userId: schoolId })
              }, 5000)
            } else {
              setIsErrorOnSubmit(true)
              setErrorDialogName(
                selectErrorDialogName({
                  hasUpdate: false,
                  updateSuccess: false,
                  hasCancel: true,
                  cancelSuccess: false,
                })
              )
            }

            Sentry.captureException(error)
          }}
          onSuccess={() => cleanUpOnSuccess()}
          schoolId={schoolId}
          selectedInstallments={selectedInstallments}
        />
      ),
      '': null,
    }[dialogName] || null

  const [discountEnabled, discountTooltip] = canAddDiscount(
    selectedInstallments,
    installmentIDsToCancel
  )
  const [dueDateEnabled, dueDateTooltip] = canEditDueDates(
    installments,
    selectedInstallmentIds,
    installmentIDsToCancel
  )
  const [amountEnabled, amountTooltip] = canEditAmount(
    selectedInstallments,
    enableEditAmountFF,
    installmentIDsToCancel
  )
  const [cancelEnabled, cancelTooltip] = canCancel(selectedInstallments, {
    enableCancelFF,
    schoolBlockedForCancel,
    isAdmin,
  })

  const shouldCancelContract = cancelContract(updatedInstallments, selectedInstallmentIds)

  const hasUnsavedChanges = calculateHasUnsavedChanges(
    savedInstallments.current,
    installments,
    installmentIDsToCancel
  )

  return (
    <>
      <PageWrapper>
        <Styled.FooterSpaceClearance>
          <BackButtonWrapper>
            <GoBackButton />
          </BackButtonWrapper>
          <PageHeader isLoading={isLoadingContractDetails} contract={contractDetails} />
          <PersonalData isLoading={isLoading} data={personalData} />
          <Typography variation="headlineDesktopXsmall">Plano de pagamento</Typography>
          <InstallmentsTable
            contract={contract}
            data={installments}
            isLoading={isLoading}
            onDeleteDiscount={handleDeleteDiscount}
            selectedInstallmentIds={selectedInstallmentIds}
            setSelectedInstallmentIds={setSelectedInstallmentIds}
            installmentIDsToCancel={installmentIDsToCancel}
            setInstallmentIDsToCancel={setInstallmentIDsToCancel}
            enableCancelFF={enableCancelFF}
          />

          {isSelected && (
            <Styled.StickyComponent>
              <Styled.ActionBarContainer>
                <Styled.SelectedTableFooterContainer>
                  <SelectedInstallmentsText count={selectedInstallmentIds.length} />
                  <EditActionButton
                    icon={<AddIcon />}
                    text="Aplicar descontos"
                    tooltip={discountTooltip}
                    disabled={!discountEnabled}
                    onClick={e => openDialog(e, DialogNames.ADD_DISCOUNT)}
                  />
                  <EditActionButton
                    icon={<AttachMoneyIcon />}
                    text="Editar valor das parcelas"
                    tooltip={amountTooltip}
                    disabled={!amountEnabled}
                    onClick={e => openDialog(e, DialogNames.EDIT_AMOUNT)}
                  />
                  <EditActionButton
                    icon={<EventIcon />}
                    text="Editar vencimentos"
                    tooltip={dueDateTooltip}
                    disabled={!dueDateEnabled}
                    onClick={e => openDialog(e, DialogNames.EDIT_DUE_DATE)}
                  />
                  {enableCancelContractFF && (
                    <EditActionButton
                      icon={<Delete />}
                      text={shouldCancelContract ? 'Cancelar contrato' : 'Cancelar parcelas'}
                      color="error"
                      tooltip={cancelTooltip}
                      disabled={!cancelEnabled}
                      onClick={e =>
                        openDialog(
                          e,
                          shouldCancelContract ? DialogNames.CANCEL_CONTRACT : DialogNames.CANCEL
                        )
                      }
                    />
                  )}
                </Styled.SelectedTableFooterContainer>
              </Styled.ActionBarContainer>
            </Styled.StickyComponent>
          )}

          {!isSelected && (
            <Styled.ActionBarContainer>
              <TableTotals installments={installments} />
            </Styled.ActionBarContainer>
          )}

          <PageFooter
            hasUnsavedChanges={hasUnsavedChanges}
            isSubmitting={isSubmitting}
            isNotificationOpen={false}
            handleSubmit={handleSubmit}
          />
        </Styled.FooterSpaceClearance>
      </PageWrapper>
      {dialogComponent}
      <GenericErrorDialog isError={isError} />
      <ErrorDialog isError={isErrorOnSubmit} dialogName={errorDialogName} />
    </>
  )
}

export const EditContract = (props: EditContractProps) => (
  <EventProvider>
    <PageComponent {...props} />
  </EventProvider>
)
