import {
  ContainerCard,
  InternalContainerCard,
  TitleText,
  DescriptionText,
  StyledButton,
  ResponsiveContainer,
} from './styles'
import { Box, Grid } from '@material-ui/core'
import { Card } from '@gravity/card'
import { HeaderHomeProps } from './types'

export const HeaderHome = ({ description, isEligible, title, onClickButton }: HeaderHomeProps) => {
  return (
    <ContainerCard isEligible={isEligible}>
      <Box mx={8}>
        <ResponsiveContainer>
          <Grid container justifyContent={isEligible ? 'flex-start' : 'flex-end'}>
            <Grid item xs={6}>
              <InternalContainerCard isEligible={isEligible}>
                <Card style={{ borderRadius: '2rem', border: 'none' }}>
                  <Box my={1} mx={5}>
                    <TitleText>{title}</TitleText>
                    <DescriptionText>{description}</DescriptionText>
                    {isEligible && (
                      <StyledButton onClick={onClickButton}>Simule agora</StyledButton>
                    )}
                  </Box>
                </Card>
              </InternalContainerCard>
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </Box>
    </ContainerCard>
  )
}
