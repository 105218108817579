import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

import { PayoutReportPageHeader } from '@/modules/report/components/PayoutReportPageHeader'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import { TileDisabledFunctionArgs } from '@olaisaac/design-system'
import { DatePicker } from '@/escolas/components/DatePicker'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'
import { getSchoolReportingPeriod } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS } from '@/modules/isaacpay/pages/IsaacPayReport/constants/schoolChargeConfigItems'
import { findIsaacPayPayoutReportByDate } from '../../helpers'

type IsaacPayPayoutReportHeaderProps = {
  currentViewRange?: string
  isaacPayPayoutReportList: UnguaranteedReport[]
  isLoadingIsaacPayPayoutReportList: boolean
  isOnboardingDrawerOpen: boolean
  onChangeReport: (report: UnguaranteedReport) => void
  onChangeViewRange: (view: string) => void
  onRequestOnboarding: () => void
  selectedDate: Date
  setSelectedDate: (date: Date) => void
}

/**
 * Specialized header component for isaacPay payout report page
 *
 * @param props
 * @param props.isaacPayPayoutReportList List of payout reports
 * @param props.isLoadingIsaacPayPayoutReportList Indicates if loading state should be displayed for the picker
 * @param props.isOnboardingDrawerOpen Indicates if onboarding drawer is open
 * @param props.onRequestOnboarding Callback function called on request drawer open
 * @param props.onChangeIsaacPayPayout Callback function called on change payout
 */
export const IsaacPayPayoutReportHeader = ({
  isaacPayPayoutReportList,
  isLoadingIsaacPayPayoutReportList,
  isOnboardingDrawerOpen,
  onRequestOnboarding,
  currentViewRange,
  onChangeViewRange,
  onChangeReport,
  selectedDate,
  setSelectedDate,
}: IsaacPayPayoutReportHeaderProps) => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const isFeatureFlagCalendarToggleEnabled = useUnleashFlag(
    UnleashFlags.ISAAC_PAY_99_ENABLE_CALENDAR_TOGGLE
  )
  const reportingPeriod = getSchoolReportingPeriod(school)

  const isMonthlyViewEnabled = currentViewRange === ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS.monthly.key
  const datePickerTitleTemplate = isMonthlyViewEnabled ? 'MMMM [de] YYYY' : 'DD [de] MMMM [de] YYYY'

  // TODO: Bruno Mello - não mudar o payout aqui -> deixar pro componente de cima
  function handleChangePickerDate(date: Date) {
    setSelectedDate(date)

    if (isMonthlyViewEnabled || isOutsourcedProvider) {
      onChangeReport(null)
      return
    }

    const report = findIsaacPayPayoutReportByDate(isaacPayPayoutReportList, date) ?? null
    onChangeReport(report)
  }

  function checkIfDateShouldBeDisabled({ view, date }: TileDisabledFunctionArgs) {
    if (view !== 'month') return false

    const payout = findIsaacPayPayoutReportByDate(isaacPayPayoutReportList, date)

    return isOutsourcedProvider ? false : !payout
  }

  function getMinPickerDate(): Date {
    const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1)
    const hasPayouts = isaacPayPayoutReportList?.length > 0

    if (isOutsourcedProvider) return firstDayOfYear
    if (isMonthlyViewEnabled && hasPayouts)
      return isaacPayPayoutReportList[0]?.started_at ?? firstDayOfYear

    return firstDayOfYear
  }

  function getMaxPickerDate(): Date {
    const today = new Date()
    const hasPayouts = isaacPayPayoutReportList?.length > 0

    if (isOutsourcedProvider) return today
    if (!isMonthlyViewEnabled && hasPayouts)
      return isaacPayPayoutReportList[isaacPayPayoutReportList.length - 1]?.ended_at ?? today
    return today
  }

  return (
    <PayoutReportPageHeader
      title="Repasse"
      picker={
        <DatePicker
          valueTemplate={datePickerTitleTemplate}
          variation="ghost"
          maxDetail={isMonthlyViewEnabled ? 'year' : 'month'}
          minDetail="decade"
          minDate={getMinPickerDate()}
          maxDate={getMaxPickerDate()}
          onChange={handleChangePickerDate}
          tileDisabled={checkIfDateShouldBeDisabled}
          value={selectedDate}
        />
      }
      backButtonLink={`/${school?.slug}/repasses`}
      isLoading={isLoadingIsaacPayPayoutReportList}
      shouldShowToggle={isFeatureFlagCalendarToggleEnabled}
      isOnboardingDrawerOpen={isOnboardingDrawerOpen}
      defaultReportingPeriod={reportingPeriod}
      currentReportingPeriodView={currentViewRange}
      onChangeReportingPeriod={onChangeViewRange}
      onRequestOnboarding={() => {
        onRequestOnboarding()
        isInitialized &&
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
            name: EventDispatcherEvents.ISAAC_PAY_PAYOUT_HELP,
            action: 'click',
            customProperties: {
              $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
            },
          })
      }}
    />
  )
}
