/**
 * Utility function to get payout amount label
 *
 * @param isNegative If payout value is negative
 * @param isOpen If payout is open
 */
export const getPayoutAmountLabel = (isNegative: boolean, isOpen: boolean) => {
  if (isNegative) {
    return 'Valor total'
  }

  return isOpen ? 'Valor previsto' : 'Valor total a ser transferido'
}
