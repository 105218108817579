import { useMemo, useState } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Typography, Table, TableColumns } from '@olaisaac/design-system'

import { ChangesChip } from '@/escolas/components/report/Chips/ChangesChip'
import { StudentOrderIcon } from './components/StudentOrderIcon'

import { useOrdering } from '@/shared/hooks/useOrdering'

import { formatMicroCentsToReal, formatDate, prettifyFullName } from '@/shared/utils'

import type {
  EventDetail,
  FinancialTransactionProps,
  TransactionEventType,
  TransactionEvents,
} from '@/shared/interfaces/report'

type TableData = {
  events: TransactionEvents
  id: string
  key: string
  productName: string
  referenceMonth: datestring
  studentName: string
  totalAmount: number
}

type TuitionPayoutReportTableProps = {
  isLoading?: boolean
  isNewVersionPayout: boolean
  onSelectInstallment: (installmentId: string) => void
  transactions: FinancialTransactionProps[]
}

/**
 * Tuition payout report installments table component
 *
 * @param props
 * @param props.transactions Table data
 * @param props.isNewVersionPayout Indicates if payout is in new version
 * @param props.isLoading Indicates if loading state should be displayed
 */
export const TuitionPayoutReportTable = ({
  transactions,
  isNewVersionPayout,
  isLoading = true,
  onSelectInstallment,
}: TuitionPayoutReportTableProps) => {
  const { ordering, updateOrdering } = useOrdering<'student_name'>()

  const [selectedInstallmentId, setSelectedInstallmentId] = useState<string>()

  const handleRowClick = (installmentId: string) => {
    setSelectedInstallmentId(installmentId)
    onSelectInstallment(installmentId)
  }

  const handleSortByStudent = () => {
    const sortOrder =
      ordering.sortBy !== 'student_name' ? 'ASC' : ordering.sortOrder === 'ASC' ? 'DESC' : 'ASC'

    updateOrdering({
      sortBy: 'student_name',
      sortOrder,
    })
  }

  const tableColumns: TableColumns = useMemo(() => {
    return [
      {
        headerName: (
          <Box display="flex" alignItems="center">
            <p>Aluno</p>

            <StudentOrderIcon
              order={ordering?.sortBy === 'student_name' ? ordering.sortOrder : undefined}
            />
          </Box>
        ),
        field: 'studentName',
        enableSorting: true,
        valueFormatter: (value: string) => prettifyFullName(value),
      },
      {
        headerName: 'Produto',
        field: 'productName',
        enableSorting: false,
      },
      {
        headerName: 'Competência',
        field: 'referenceMonth',
        enableSorting: false,
        valueFormatter: (value: string) => formatDate(value, 'MMM YYYY'),
      },
      {
        headerName: (
          <Box display="flex" alignItems="center">
            <p>Valor final</p>

            <Tooltip title="Valor calculado que já considera descontos, mudanças e taxa isaac.">
              <InfoOutlined style={{ fontSize: '1rem', marginLeft: '0.25rem' }} />
            </Tooltip>
          </Box>
        ),
        field: 'totalAmount',
        enableSorting: false,
        valueFormatter: (value: number) => formatMicroCentsToReal(value),
      },
      {
        headerName: (
          <Box display="flex" alignItems="center">
            <p>Mudanças</p>

            <Tooltip title="Alterações feitas em parcelas de mensalidade que afetam o valor final.">
              <InfoOutlined style={{ fontSize: '1rem', marginLeft: '0.25rem' }} />
            </Tooltip>
          </Box>
        ),
        field: 'events',
        enableSorting: false,
        renderCell: (values: TransactionEvents = {}) => (
          // FIXME: Refactor changes chip component
          <ChangesChip
            tags={Object.keys(values) as TransactionEventType[]}
            limit={2}
            style={{ margin: 4 }}
          />
        ),
      },
    ]
  }, [ordering])

  const rows: TableData[] = useMemo(() => {
    const parseEvents = (events: EventDetail[]) => {
      return events.reduce<TransactionEvents>((acc, { event, total }) => {
        return {
          ...acc,
          [event]: total,
        }
      }, {})
    }

    const formattedRows = transactions.map(item => ({
      id: item.referrals.installment_id,
      key: item.referrals.installment_id,
      studentName: prettifyFullName(item.referrals.student_name),
      productName: item.referrals.product_name,
      referenceMonth: item.reference_month,
      totalAmount: item.financial_detail.total,
      events: parseEvents(item.financial_detail?.events_details ?? []),
    }))

    return formattedRows
  }, [transactions])

  return (
    <Table<TableData>
      columns={tableColumns}
      rows={rows}
      rowsSelected={selectedInstallmentId ? [selectedInstallmentId] : undefined}
      isLoading={isLoading}
      emptyRender={
        <Typography>
          {isNewVersionPayout
            ? 'Nenhum resultado encontrado'
            : 'Esse repasse só está disponível via planilha'}
        </Typography>
      }
      sortingHandler={field => field === 'studentName' && handleSortByStudent()}
      onRowClick={event => handleRowClick(event.row.id)}
    />
  )
}
