import { ErrorOutline } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { Chip, TooltipButton } from '@olaisaac/design-system'

import { InstallmentType, InstallmentTypeLabel, TransactionEventType } from 'src/shared/interfaces'
import { VariationColors } from './colors'

type ChangesChipProps = {
  limit: number
  style?: React.CSSProperties
  tags: Array<TransactionEventType | InstallmentType>
  tooltipDisclaimers?: Partial<Record<TransactionEventType | InstallmentType, string>>
}

export const paramsInstallmentDict: PartialRecord<
  InstallmentType,
  { color: VariationColors; text: string }
> = {
  [InstallmentType.TUITION]: {
    color: VariationColors.GRAY,
    text: InstallmentTypeLabel.TUITION,
  },
  [InstallmentType.ENROLLMENT]: {
    color: VariationColors.GRAY,
    text: InstallmentTypeLabel.ENROLLMENT,
  },
}

export const paramsEventDict: PartialRecord<
  TransactionEventType,
  { color: VariationColors; text: string }
> = {
  [TransactionEventType.NEW_CONTRACT]: {
    color: VariationColors.GREEN,
    text: 'Novo aluno',
  },
  [TransactionEventType.LOST_DISCOUNT]: {
    color: VariationColors.GREEN,
    text: 'Adicional desc. antecipação',
  },
  [TransactionEventType.APPLIED_DISCOUNT]: {
    color: VariationColors.YELLOW,
    text: 'Novo desconto',
  },
  [TransactionEventType.CANCEL]: {
    color: VariationColors.CORAL,
    text: 'Cancelado',
  },
  [TransactionEventType.SCHOOL_LIQUIDATION]: {
    color: VariationColors.YELLOW,
    text: 'Recebido na escola',
  },
  [TransactionEventType.LIQUIDATION_EDITION]: {
    color: VariationColors.YELLOW,
    text: 'Edição de recebido na escola',
  },
  [TransactionEventType.CORRECTION]: {
    color: VariationColors.YELLOW,
    text: 'Correção pontual',
  },
  [TransactionEventType.AGGLUTINATION_DISCOUNT]: {
    color: VariationColors.YELLOW,
    text: 'Desconto por aglutinação de parcelas',
  },
  [TransactionEventType.ORIGINAL_AMOUNT_CHANGED]: {
    color: VariationColors.YELLOW,
    text: 'Edição de valor',
  },
}

function ChipWithDisclaimer(props: {
  disclaimer: string
  id: string
  index: number
  label: string
  style: React.CSSProperties
  variation: VariationColors
}) {
  const { style, label, variation, id, index, disclaimer } = props

  return (
    <>
      <Chip key={index} data-id={id} variation={variation} label={label} style={style} />
      <Tooltip title={disclaimer}>
        <ErrorOutline
          style={{
            width: '1.4rem',
            height: '1.4rem',
            color: '#919191',
          }}
        />
      </Tooltip>
    </>
  )
}

export const ChangesChip = ({ tags, limit, style, tooltipDisclaimers = {} }: ChangesChipProps) => {
  const updatedParamEventDict = Object.entries(paramsEventDict).reduce<
    PartialRecord<TransactionEventType, { color: VariationColors; text: string }>
  >((acc, [key, event]) => {
    if (key === TransactionEventType.NEW_CONTRACT) {
      return {
        ...acc,
        [key]: {
          color: event.color,
          text: 'Novo contrato',
        },
      }
    }

    if (key === TransactionEventType.APPLIED_DISCOUNT) {
      return {
        ...acc,
        [key]: {
          color: event.color,
          text: 'Edição de desconto',
        },
      }
    }

    return {
      ...acc,
      [key]: event,
    }
  }, {})

  const paramsDict = { ...updatedParamEventDict, ...paramsInstallmentDict }
  const available = tags?.filter(type => paramsDict[type])
  const plus = available.slice(limit).map(type => paramsDict[type].text)

  return (
    <>
      {available?.slice(0, limit)?.map((type, index) => {
        const disclaimer = tooltipDisclaimers[type]
        return disclaimer ? (
          <ChipWithDisclaimer
            disclaimer={disclaimer}
            id={paramsDict[type].text}
            index={index}
            label={paramsDict[type].text}
            style={style}
            variation={paramsDict[type].color}
          />
        ) : (
          <Chip
            key={index}
            data-id={paramsDict[type].text}
            variation={paramsDict[type].color}
            label={paramsDict[type].text}
            style={style}
          />
        )
      })}

      {plus.length > 0 && (
        <Tooltip title={plus.join(', ')} style={{ cursor: 'pointer' }}>
          <TooltipButton>
            <Chip
              style={{ margin: 4, cursor: 'pointer' }}
              variation={VariationColors.GRAY}
              label={`+${plus.length}`}
            />
          </TooltipButton>
        </Tooltip>
      )}
    </>
  )
}
