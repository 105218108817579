import { useCampaignStepper } from './hooks/useCampaignStepper'
import { StepItem } from './components/StepItem'
import { Heading } from '@gravity/heading'
import { ChevronLeft } from '@material-ui/icons'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useHistory } from 'react-router-dom'
import * as Styled from './styles'
import { StepperProps } from './types'
import { Status } from './components/StepItem/types'

export const Stepper = ({ steps, title }: StepperProps) => {
  const { handleStepClick, activeStep, stepsNavHistory } = useCampaignStepper(steps)

  const stepStatus = (stepIndex: number) => {
    switch (true) {
      case stepsNavHistory[stepIndex].completed:
        return Status.COMPLETED
      case stepIndex === activeStep:
        return Status.ACTIVE
      case stepsNavHistory[stepIndex].visited:
        return Status.ENABLED
      default:
        return Status.DISABLED
    }
  }

  const isStepActive = (stepIndex: number) => stepIndex === activeStep

  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()

  const campaignsListRoute = `/${schoolSlug}/campanhas`
  const handleBack = () => push(campaignsListRoute)

  return (
    <Styled.Container>
      <Styled.ContainerWrapper>
        <Styled.ReturnButton
          iconStart={<ChevronLeft />}
          size={1}
          variant="ghost"
          onClick={handleBack}
        >
          Voltar
        </Styled.ReturnButton>
        <Heading variant="heading-h3-medium">{title}</Heading>
        {steps.map((step, index) => (
          <StepItem
            key={index}
            label={step.label}
            status={stepStatus(index)}
            active={isStepActive(index)}
            onClick={() => handleStepClick(index)}
          />
        ))}
      </Styled.ContainerWrapper>
    </Styled.Container>
  )
}
