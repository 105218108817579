import { useEffect, useState } from 'react'
import { Box, Tabs } from '@material-ui/core'
import dayjs from 'dayjs'

import { useDrawerWidth } from '@/shared/hooks/useDrawerWidth'
import { usePagination } from '@/shared/hooks/usePagination'
import { useQuery } from '@/shared/hooks/useQuery'
import { Container, Content } from '../TuitionPayoutReport/styles'
import { IsaacPayPayoutReportHeader } from './components/IsaacPayPayoutReportHeader'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  getPayoutSubtitle,
  getSchoolReportingPeriod,
} from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { PayoutReportSubHeader } from '../../components/PayoutReportSubHeader'
import { useIsaacPayPayoutReportData } from './hooks/useIsaacPayPayoutReportData'
import { ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS } from '@/modules/isaacpay/pages/IsaacPayReport/constants/schoolChargeConfigItems'
import { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import { findIsaacPayPayoutReportByDate } from './helpers'
import { useIsaacPayAggregatedProvidersSummary } from '../../hooks/queries/useIsaacPayAggregatedProvidersSummary'
import { DAY_IN_MILLIS, formatNumberMicroCentsToReal } from '@/shared/utils'
import { DrawerType, useIsaacPayPayoutReportDrawers } from './hooks/useIsaacPayPayoutReportDrawers'
import { IsaacPayPayoutReportOnboardingDrawer } from './components/IsaacPayPayoutReportOnboardingDrawer'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { REPORT_PAGE_NAMES } from '../../constants/REPORT_PAGE_NAMES'
import { IsaacPaySummaryDrawer } from '@/modules/isaacpay/pages/IsaacPayReport/components/IsaacPaySummaryDrawer'
import { ReportPageTableHeader } from '@/escolas/components/ReportPageTableHeader'
import { StyledTab } from '@/modules/isaacpay/pages/IsaacPayReport/styles'
import { IsaacPayPayoutReportFilterDrawer } from './components/IsaacPayPayoutReportFilterDrawer'
import {
  OUTSOURCED_PROVIDER_TABS,
  IsaacPayTabs,
} from '../../constants/PAYOUT_OUTSOURCED_PROVIDER_TABS'
import { IsaacPayReportTable } from '@/modules/isaacpay/pages/IsaacPayReport/components/IsaacPayReportTable'
import { useIsaacPayExternalPayoutReports } from '../../hooks/queries/useIsaacPayExternalPayoutReports'
import { ErrorState } from '../../components/ErrorState'
import { FinancialTransactionProps } from '@/shared/interfaces'

import { formatPayoutFromExternalPayments } from '@/modules/isaacpay/pages/IsaacPayReport/utils/formatPayoutFromExternalPayments'
import { IsaacPayPayoutReportFooter } from './components/IsaacPayPayoutReportFooter'
import { PAYOUT_REPORT_STATUS } from '../../constants/PAYOUT_REPORT_STATUS'
import { buildIsaacPayPayoutReportFilterOptions } from './utils/buildEnrollmentPayoutReportFilterOptions'
import { buildExternalPaymentsReportFilterOptions } from './utils/buildExternalPaymentsReportFilterOptions'
import { mergePayoutAndExternalPaymentFilters } from './utils/mergePayoutAndExternalPaymentFilters'
import { IsaacPayReportTableRowDetailDrawer } from '@/modules/isaacpay/pages/IsaacPayReport/components/IsaacPayReportTableRowDetailDrawer'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'

export const IsaacPayPayoutReport = () => {
  const { drawerWidth } = useDrawerWidth()
  const { query } = useQuery()
  const {
    isInitialized: isEventDispatcherInitialized,
    eventDispatcherClient,
  } = useEventDispatcher()
  const { pagination } = usePagination(1, 25)

  const { school } = useSelectedSchool()
  const schoolReportingPeriod = getSchoolReportingPeriod(school)
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const initialView = query.get('view') ?? schoolReportingPeriod
  const [currentViewRange, setCurrentViewRange] = useState(initialView)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedReport, setSelectedReport] = useState<UnguaranteedReport | null>(null)
  const [activeTab, setActiveTab] = useState<IsaacPayTabs>(IsaacPayTabs.EXTERNAL_PAYMENTS)
  const [selectedInstallment, setSelectedInstallment] = useState<FinancialTransactionProps>()

  const isMonthlyViewEnabled = currentViewRange === ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS.monthly.key

  const [startDate, endDate] = (() => {
    if (isMonthlyViewEnabled) {
      const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
      const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)
      return [startDate, endDate]
    }

    if (!isMonthlyViewEnabled && !isOutsourcedProvider && !!selectedReport) {
      const startDate = selectedReport?.started_at ?? new Date()
      const endDate = selectedReport?.ended_at ?? new Date()
      return [startDate, endDate]
    }

    return [new Date(selectedDate.getTime() - DAY_IN_MILLIS), new Date(selectedDate)]
  })()

  const {
    payoutReportList,
    isFetching: isFetchingPayoutData,
    payoutMonthAggregation,
    hasError: hasErrorOnFetchPayoutData,
    detailedPayoutReport,
  } = useIsaacPayPayoutReportData({
    payoutId: selectedReport?.payout_id ?? null,
    startDate,
    endDate,
    isMonthlyViewEnabled,
    activeTab,
  })

  const {
    data: aggregatedProvidersSummary,
    isFetching: isFetchingAggregatedProvidersSummary,
  } = useIsaacPayAggregatedProvidersSummary({
    schoolId: school.id,
    startDate: startDate.toLocaleDateString('en-CA'),
    endDate: endDate.toLocaleDateString('en-CA'),
    options: { enabled: isOutsourcedProvider || isMonthlyViewEnabled },
  })

  // TODO: refactor this to a separate hook
  //
  // this runs when the user changes from a monthly view to a daily view and school is not kobana, so that it
  // maintains a valid date selected on the url
  // -
  // when the payout list changes, update selected date to use the last valid payout report date.
  // first, try to use a payout with the same date as the previous selected date. if there isn't any,
  // use the most recent payout
  useEffect(() => {
    if (isFetchingPayoutData || !payoutReportList || payoutReportList.length === 0) return
    if (isMonthlyViewEnabled || isOutsourcedProvider) {
      setSelectedReport(null)
      return
    }

    let report = findIsaacPayPayoutReportByDate(payoutReportList, selectedDate)
    if (!report) {
      report = payoutReportList[payoutReportList.length - 1]
    }

    setSelectedDate(new Date(report.ended_at))
    setSelectedReport(report)
  }, [isMonthlyViewEnabled, payoutReportList, isOutsourcedProvider, isFetchingPayoutData])

  // TODO: Bruno Mello - fechar gavetas ao mudar período de reporting ou mudar payout
  const {
    hasOpenDrawer,
    isPayoutFilterDrawerOpen,
    isPayoutOnboardingDrawerOpen,
    isPayoutSummaryDrawerOpen,
    isStudentDetailsDrawerOpen,
    setDrawerOpen,
    closeAllDrawers,
  } = useIsaacPayPayoutReportDrawers()

  useEffect(() => {
    closeAllDrawers()
  }, [selectedDate, currentViewRange])

  const {
    data: externalPaymentsData,
    isFetching: isFetchingExternalPayments,
    isError: hasErrorOnFetchExternalPaymentsData,
    refetch: refetchExternalPayments,
  } = useIsaacPayExternalPayoutReports({
    schoolId: school.id,
    startDate: startDate.toLocaleDateString('en-CA'),
    endDate: endDate.toLocaleDateString('en-CA'),
    options: {
      enabled: isOutsourcedProvider && activeTab === IsaacPayTabs.EXTERNAL_PAYMENTS,
    },
    pagination: {
      page: pagination?.page,
      per_page: pagination?.itemsPerPage,
    },
  })

  const includedFilters = buildIsaacPayPayoutReportFilterOptions(
    detailedPayoutReport?.data?.filters_included
  )
  const includedExternalPaymentFilters = buildExternalPaymentsReportFilterOptions(
    externalPaymentsData?.data
  )
  const mergedFilters = mergePayoutAndExternalPaymentFilters(
    includedFilters,
    includedExternalPaymentFilters
  )

  function handleOpenDrawer(name: DrawerType) {
    closeAllDrawers()

    setDrawerOpen(name, true)
  }

  function getReportTableInfo(): [FinancialTransactionProps[], number, boolean] {
    if (isOutsourcedProvider && activeTab === IsaacPayTabs.EXTERNAL_PAYMENTS) {
      const data = externalPaymentsData?.data?.map(formatPayoutFromExternalPayments) ?? []
      const total = externalPaymentsData?.pagination?.total ?? 0
      return [data, total, isFetchingExternalPayments]
    }

    if (isOutsourcedProvider && activeTab === IsaacPayTabs.OTHER_PAYMENTS) {
      const data = payoutMonthAggregation?.data?.financial_transactions ?? []
      const total = payoutMonthAggregation?.pagination?.total ?? 0
      return [data, total, isFetchingPayoutData]
    }

    if (!isOutsourcedProvider && !isMonthlyViewEnabled) {
      const data = detailedPayoutReport?.data?.financial_transactions ?? []
      const total = detailedPayoutReport?.pagination?.total ?? 0
      return [data, total, isFetchingPayoutData]
    }

    if (!isOutsourcedProvider && isMonthlyViewEnabled) {
      const data = payoutMonthAggregation?.data?.financial_transactions ?? []
      const total = payoutMonthAggregation?.pagination?.total ?? 0
      return [data, total, isFetchingPayoutData]
    }

    return [[], 0, false]
  }

  // TODO: Bruno Mello - ajustar esse método para só precisar usar o endpoint de valor total
  // TODO: Bruno Mello - conferir valor total com baixa manual para escolas iugu e escolas kobana
  const [payoutSubHeaderValue, isPayoutSubHeaderValueLoading] = (() => {
    if (!isOutsourcedProvider && !isMonthlyViewEnabled) {
      return [
        formatNumberMicroCentsToReal(selectedReport?.agregations?.financial_details?.total ?? 0),
        isFetchingPayoutData,
      ]
    }

    return [
      formatNumberMicroCentsToReal(aggregatedProvidersSummary?.total ?? 0),
      isFetchingAggregatedProvidersSummary,
    ]
  })()

  function isPayoutOpen(): boolean {
    if (!isOutsourcedProvider && !isMonthlyViewEnabled) {
      return selectedReport?.payout?.status === PAYOUT_REPORT_STATUS.OPEN
    }

    if (isOutsourcedProvider && !isMonthlyViewEnabled) {
      return dayjs(selectedDate).isSame(dayjs(), 'day')
    }

    return dayjs(selectedDate).isSame(dayjs(), 'month')
  }

  function handleTableRowClick(id: string) {
    if (!id) return

    let transaction: FinancialTransactionProps

    if (!isOutsourcedProvider && !isMonthlyViewEnabled) {
      transaction = detailedPayoutReport?.data?.financial_transactions?.find(
        transaction => transaction.referrals.installment_id === id
      )
    } else if (
      (!isOutsourcedProvider && isMonthlyViewEnabled) ||
      (isOutsourcedProvider && activeTab === IsaacPayTabs.OTHER_PAYMENTS)
    ) {
      transaction = payoutMonthAggregation?.data?.financial_transactions?.find(
        transaction => transaction.referrals.installment_id === id
      )
    } else if (isOutsourcedProvider && activeTab === IsaacPayTabs.EXTERNAL_PAYMENTS) {
      transaction = externalPaymentsData?.data
        ?.map(formatPayoutFromExternalPayments)
        .find(transaction => transaction.referrals.installment_id === id)
    }

    if (!transaction) return

    setSelectedInstallment(transaction)
    setDrawerOpen('studentDetails', true)

    isEventDispatcherInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
        name: EventDispatcherEvents.ISAAC_PAY_PAYOUT_REPORT_DETAILS,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
        },
      })
  }

  const shouldShowFiltersButton = !isOutsourcedProvider && !isMonthlyViewEnabled
  const shouldShowOrderingButton = !isOutsourcedProvider && !isMonthlyViewEnabled

  const hasError = hasErrorOnFetchExternalPaymentsData || hasErrorOnFetchPayoutData
  const isFetching = isFetchingExternalPayments || isFetchingPayoutData

  if (hasError) {
    return (
      <ErrorState
        title="Repasse"
        description="Ocorreu um erro inesperado ao carregar as informações do seu repasse. Tente novamente mais tarde."
        onRetry={() => {
          refetchExternalPayments()
        }}
      />
    )
  }

  const [tableData, tableTotal, isTableDataLoading] = getReportTableInfo()

  return (
    <Container isCollapsed={hasOpenDrawer} drawerWidth={drawerWidth}>
      <Content>
        <IsaacPayPayoutReportHeader
          isaacPayPayoutReportList={payoutReportList}
          isLoadingIsaacPayPayoutReportList={isFetchingPayoutData}
          isOnboardingDrawerOpen={isPayoutOnboardingDrawerOpen}
          onRequestOnboarding={() => handleOpenDrawer('onboarding')}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onChangeReport={setSelectedReport}
          currentViewRange={currentViewRange}
          onChangeViewRange={setCurrentViewRange}
        />

        <Box mt="2rem">
          <PayoutReportSubHeader
            payoutValue={payoutSubHeaderValue}
            isLoading={isPayoutSubHeaderValueLoading}
            isPayoutOpen={isPayoutOpen()}
            isNewVersionPayout
            payoutReportPeriod={{
              endDate,
              startDate,
            }}
            linkButtonText="Ver resumo"
            onClickAtLinkButton={() => {
              handleOpenDrawer('summary')
              isEventDispatcherInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
                  name: EventDispatcherEvents.ISAAC_PAY_PAYOUT_SUMMARY,
                  action: 'click',
                  customProperties: {
                    $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
                  },
                })
            }}
            isHourIncluded={false}
            payoutSubtitleLabel={getPayoutSubtitle(false)}
          />
        </Box>

        <Box mt="4rem" mb="2rem">
          {/* TODO: Move component to report module */}
          {/* TODO: USE REPLACE STRATEGY FOR NAME FILTER */}
          <ReportPageTableHeader
            filterOptions={mergedFilters}
            reportType="enrollment"
            isFilterReportDrawerOpen={isPayoutFilterDrawerOpen}
            disableActions={isFetching || tableData.length === 0}
            onRequestFilterDrawerOpen={() => handleOpenDrawer('filter')}
            shouldShowFilterButton={shouldShowFiltersButton}
            shouldShowOrderingButton={shouldShowOrderingButton}
          />
        </Box>

        {isOutsourcedProvider && (
          <Box mb="2rem">
            <Tabs variant="standard" value={activeTab} aria-label="Escolha a visualização">
              {OUTSOURCED_PROVIDER_TABS.map(tab => (
                <StyledTab
                  key={tab.label}
                  label={tab.label}
                  value={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        )}

        <IsaacPayReportTable
          isNewVersion
          isLoading={isTableDataLoading}
          transactions={tableData}
          onRowClick={params => handleTableRowClick(params.row.id)}
        />
      </Content>

      <IsaacPayPayoutReportFooter
        isMonthlyViewEnabled={isMonthlyViewEnabled}
        isLoading={isTableDataLoading}
        totalItems={tableTotal}
        isaacPayPayoutReportId={selectedReport?.payout_id ?? ''}
        isaacPayPayoutReportStartDate={selectedReport?.started_at ?? new Date()}
        isaacPayPayoutReportEndDate={selectedReport?.ended_at ?? new Date()}
      />

      <IsaacPayPayoutReportOnboardingDrawer
        isOpen={isPayoutOnboardingDrawerOpen}
        onClose={() => setDrawerOpen('onboarding', false)}
      />

      {/* TODO: Bruno Mello - ajustar dados da gaveta de resumo */}
      <IsaacPaySummaryDrawer
        isMonthlyViewEnabled={isMonthlyViewEnabled}
        financialDetails={selectedReport?.agregations?.financial_details}
        payoutData={{
          payoutId: selectedReport?.payout_id ?? '',
          payoutVersion: selectedReport?.payout.version ?? 2,
          payoutBankTransfers: selectedReport?.bank_transfers ?? [],
          isaacPay_end_date: dayjs(selectedReport?.ended_at ?? undefined).toDate(),
        }}
        externalPaymentSummary={aggregatedProvidersSummary ?? undefined}
        isOutsourcedProvider={isOutsourcedProvider}
        isPayoutOpen={false}
        isOpen={isPayoutSummaryDrawerOpen}
        isButtonDisabled
        onClose={() => setDrawerOpen('summary', false)}
      />

      <IsaacPayPayoutReportFilterDrawer
        filterOptions={mergedFilters}
        isOpen={isPayoutFilterDrawerOpen}
        onClose={() => setDrawerOpen('filter', false)}
      />

      <IsaacPayReportTableRowDetailDrawer
        guardianId={selectedInstallment?.referrals.guardian_id}
        contractReferenceYear={selectedInstallment?.referrals.contract_reference_year}
        contractId={selectedInstallment?.referrals.contract_id ?? ''}
        studentId={selectedInstallment?.referrals.student_id}
        studentName={selectedInstallment?.referrals.student_name ?? ''}
        productId={selectedInstallment?.referrals.product_id}
        productName={selectedInstallment?.referrals.product_name ?? ''}
        financialDetails={selectedInstallment?.financial_detail}
        receiptDate={dayjs(selectedInstallment?.referrals.last_receivable_paid_date).toDate()}
        isOpen={isStudentDetailsDrawerOpen}
        onClose={() => setDrawerOpen('studentDetails', false)}
      />
    </Container>
  )
}
