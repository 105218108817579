import { Link } from '@olaisaac/design-system'

type InfoCardActionProps = {
  children: string
  onClick: () => void
}

export const InfoCardAction = ({ children, onClick }: InfoCardActionProps) => {
  return <Link onClick={onClick}>{children}</Link>
}
