import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import type { NotificationResponse } from '../../services/types'

export const useTrackNotificationMenuEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const trackModalButtonClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $modal: 'Pop-up modal',
          $title: 'Visualizar avisos na pop-up',
          $button: 'Notifications pop-up open button',
        },
      })
    }
  }

  const trackModalClose = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.MODAL_CLOSE,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $modal: 'Pop-up modal',
        },
      })
    }
  }

  const trackTitleLinkClick = (notification: NotificationResponse) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.LINK_CLICK,
        action: 'link_click',
        entity: 'Aviso',
        customProperties: {
          $modal: 'Pop-up modal',
          $title: notification.title,
          $link: 'Notification title link',
          $notification: notification,
        },
      })
    }
  }

  const trackActionButtonClick = (notificationTitle: string) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $modal: 'Pop-up modal',
          $title: notificationTitle,
          $button: 'Internal redirect click',
        },
      })
    }
  }

  const trackFooterLinkClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.LINK_CLICK,
        action: 'link_click',
        entity: 'Aviso',
        customProperties: {
          $modal: 'Pop-up modal',
          $title: 'Acessar Quadro de Avisos',
          $link: 'Notifications board link',
        },
      })
    }
  }

  return {
    trackModalButtonClick,
    trackModalClose,
    trackTitleLinkClick,
    trackActionButtonClick,
    trackFooterLinkClick,
  }
}
