import { useMutation } from '@tanstack/react-query'

import { useApi } from 'src/shared/hooks'
import type { MakeAgreementBody } from 'src/shared/interfaces'

export const useMakeAgreementMutation = () => {
  const { api } = useApi()

  return useMutation((body: MakeAgreementBody[]) => api.agreement.makeAgreement(body))
}
