import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@olaisaac/design-system'
import { Dialog } from '@material-ui/core'
import { useState } from 'react'
import { useNavigation } from '@/escolas/hooks'
import styled from 'styled-components'
import { Guardian } from '@/shared/interfaces'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'

const Container = styled.aside`
  flex: 1;
  border-left: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  padding-left: 32px;
`

const List = styled.ul`
  margin-top: 24px;
`

const ListItem = styled.li`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  &:last-child {
    border-bottom: none;
  }
`

const MainGuardianLink = styled(Link)`
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: 8px 0 4px;
`

type GuardianCardProps = {
  mainGuardian: Guardian
  otherGuardians: Guardian[]
  studentName: string
}

type OtherGuardiansDialogProps = {
  guardians: Guardian[]
  open: boolean
  setOpen: (value: boolean) => void
  studentName: string
}

const OtherGuardiansDialog = ({
  open,
  setOpen,
  studentName,
  guardians,
}: OtherGuardiansDialogProps) => {
  const handleClose = () => setOpen(false)
  const { getNavigationUrl } = useNavigation()
  const { defaultGuardianEntryPage } = useGuardianNavigation()

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variation="headlineDesktopMedium">Responsáveis financeiros</Typography>
      </DialogTitle>
      <DialogContent>
        <p>
          {guardians.length} responsáveis associados ao aluno(a) <strong>{studentName}</strong>
        </p>
        <List>
          {guardians.map(guardian => (
            <ListItem key={guardian.name}>
              <Typography variation="bodyLarge">{guardian.name}</Typography>
              <Link
                href={getNavigationUrl({
                  path: `/responsaveis/${guardian.id}/${defaultGuardianEntryPage}`,
                })}
                rel="noreferrer"
              >
                Ver página do responsável
              </Link>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variation="primary" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const GuardianInfo = ({ mainGuardian, otherGuardians, studentName }: GuardianCardProps) => {
  const { getNavigationUrl } = useNavigation()
  const [open, setOpen] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { defaultGuardianEntryPage } = useGuardianNavigation()

  const hasOtherGuardians = otherGuardians?.length > 0

  return (
    <>
      {hasOtherGuardians && (
        <OtherGuardiansDialog
          open={open}
          setOpen={setOpen}
          guardians={[mainGuardian, ...otherGuardians]}
          studentName={studentName}
        />
      )}
      <Container>
        <Typography variation="bodySmall" color="secondary">
          Responsável financeiro(a)
        </Typography>
        <MainGuardianLink
          onClick={() =>
            isInitialized &&
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
              name: EnrollmentEventDispatcherEvents.CLICKED,
              action: 'click',
              customProperties: { $Link_name: 'RESPONSÁVEL_FINANCEIRO' },
            })
          }
          href={getNavigationUrl({
            path: `/responsaveis/${mainGuardian.id}/${defaultGuardianEntryPage}`,
          })}
          rel="noreferrer"
        >
          {mainGuardian.name}
        </MainGuardianLink>
        {hasOtherGuardians && (
          <Link onClick={() => setOpen(true)}>{`e mais ${otherGuardians.length}`}</Link>
        )}
      </Container>
    </>
  )
}
