import { useRouteMatch } from 'react-router-dom'
import { routes } from './routes'
import type { ModuleDefinition } from '../core/module'
import { useJWT } from '@/shared/hooks'
import { CREDIT_SERVICE_WORKER_URL, useServiceWorker } from './hooks/useServiceWorker'
import { useCreditAccessStore } from './creditAccessStore'

export const useRegisterCreditModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  const { hasPayoutReportAccess } = useJWT()
  const { hasCreditAccess } = useCreditAccessStore()

  const isCreditAccessEnabled = hasCreditAccess && hasPayoutReportAccess

  useServiceWorker(CREDIT_SERVICE_WORKER_URL)

  module.registerRoutes(isCreditAccessEnabled ? routes : [], { basePath })
}
