import type { FinancialInfo, Info } from '../types'

import { NEXT_MONTH_TEXT } from '../constants'

const CURRENT_YEAR = new Date().getFullYear()

export const FINANCIAL_INFO = {
  accumulatedPayoutInfo: {
    title: `Total repassado em ${CURRENT_YEAR}`,
    path: 'repasses',
  },
  nextPayoutInfo: {
    title: `Previsão das mensalidades de ${NEXT_MONTH_TEXT}`,
    path: `repasses/mensalidades`,
  },
  lastEnrollmentInfo: {
    title: `Seu último repasse de matrículas`,
    path: 'repasses/matriculas',
  },
} as const

export const getFinancialInfo = (
  accumulatedPayoutInfo: Info,
  nextPayoutInfo: Info,
  enrollmentInfo: Info
): FinancialInfo => {
  const payoutInfo = {
    accumulatedPayoutInfo: {
      title: FINANCIAL_INFO.accumulatedPayoutInfo.title,
      value: accumulatedPayoutInfo?.value,
      path: FINANCIAL_INFO.accumulatedPayoutInfo.path,
    },
    nextPayoutInfo: {
      title: FINANCIAL_INFO.nextPayoutInfo.title,
      value: nextPayoutInfo?.value,
      path: `${FINANCIAL_INFO.nextPayoutInfo.path}${nextPayoutInfo?.query}`,
    },
  }

  const lastEnrollmentInfo = {
    title: FINANCIAL_INFO.lastEnrollmentInfo.title,
    value: enrollmentInfo?.value,
    path: `${FINANCIAL_INFO.lastEnrollmentInfo.path}${enrollmentInfo?.query}`,
  }

  return {
    ...payoutInfo,
    lastEnrollmentInfo,
  }
}
