import type { ReactNode } from 'react'
import { View } from './view'

type Props = {
  description: string
  onClick?: () => void
  title: string | ReactNode
  value: string
}
export const DetailsBigNumber = ({ title, value, description, onClick }: Props) => {
  return <View title={title} value={value} description={description} onClick={onClick} />
}
