import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { useEffect } from 'react'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'

type CancelContractMenuItemProps = {
  disabled: boolean
  onClick: () => void
  openCancellationDrawer: () => void
}

const CancelContractMenuItem = ({
  onClick,
  openCancellationDrawer,
  disabled,
}: CancelContractMenuItemProps) => {
  const { sendHotjarEvent } = useHotjar()
  const handleMenuItemClick = () => {
    openCancellationDrawer()
    if (typeof onClick === 'function') {
      onClick()
    }
  }

  useEffect(() => {
    sendHotjarEvent(HotjarEvents.CANCEL_CONTRACT)
  }, [])

  const tooltipContent =
    'Contrato sem parcelas abertas não podem ser cancelados. Entre em contato com o atendimento.'

  return (
    <>
      {disabled ? (
        <Tooltip title={tooltipContent}>
          <Box>
            <MenuItem disabled onClick={handleMenuItemClick} component="a" target="blank">
              Cancelar contrato
            </MenuItem>
          </Box>
        </Tooltip>
      ) : (
        <MenuItem onClick={handleMenuItemClick}>Cancelar contrato</MenuItem>
      )}
    </>
  )
}

export default CancelContractMenuItem
