import { SchoolSelection } from '../pages/SchoolSelection'
import { NotFound } from '../pages/NotFound'

import { SchoolUnitModule } from './SchoolUnitModule'

import type { RouteDefinition } from '@/modules/core/router'

export const routes: RouteDefinition[] = [
  { key: 'SCHOOL_UNIT', path: '/:schoolSlug', component: SchoolUnitModule },
  { key: 'NOT_FOUND', path: '/404', component: NotFound, exact: true },
  { key: 'SCHOOL_SELECTION', path: '/', component: SchoolSelection, exact: true },
]
