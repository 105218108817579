import { Box } from '@material-ui/core'
import { Notification } from '@olaisaac/design-system'

import { EmptyState } from './components/EmptyState'
import { TuitionReportDashboardCard } from './components/TuitionReportDashboardCard'
import { EnrollmentReportDashboardCard } from './components/EnrollmentReportDashboardCard'
import { IsaacPayReportDashboardCard } from './components/IsaacPayReportDashboardCard'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePayoutReportDashboardData } from './hooks/usePayoutReportDashboardData'

import { Container, Content } from './styles'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

/**
 * Payout report dashboard page
 */
export const PayoutReportDashboard = () => {
  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Repasses' })
  const {
    tuitionPayoutReportList,
    currentTuitionPayoutReport,
    tuitionLoading,
    tuitionPayoutDetailsLoading,
    tuitionError,
    refetchTuition,
    updateCurrentTuitionPayoutReport,
    enrollmentPayoutReportList,
    currentEnrollmentPayoutReport,
    enrollmentLoading,
    enrollmentError,
    refetchEnrollment,
    updateCurrentEnrollmentPayoutReport,
    isaacPayPayoutReportList,
    currentIsaacPayPayoutReport,
    isaacPayLoading,
    isaacPayError,
    refetchIsaacPay,
    updateCurrentIsaacPayPayoutReport,
  } = usePayoutReportDashboardData()

  const { school } = useSelectedSchool()

  const handleChangeTuitionPayoutReport = (id: string) => {
    const payoutReport = tuitionPayoutReportList.find(item => item.id === id)

    if (!payoutReport) return

    updateCurrentTuitionPayoutReport(payoutReport)
  }

  const handleChangeEnrollmentPayoutReport = (id: string) => {
    const payoutReport = enrollmentPayoutReportList.find(item => item.id === id)

    if (!payoutReport) return

    updateCurrentEnrollmentPayoutReport(payoutReport)
  }

  const handleChangeIsaacPayPayoutReport = (id: string) => {
    const payoutReport = isaacPayPayoutReportList.find(item => item.id === id)

    if (!payoutReport) return

    updateCurrentIsaacPayPayoutReport(payoutReport)
  }

  const isLoading = tuitionLoading || enrollmentLoading || isaacPayLoading
  const hasError = tuitionError || enrollmentError || isaacPayError

  const hasTuitionPayoutReport = tuitionPayoutReportList.length > 0
  const hasEnrollmentPayoutReport = enrollmentPayoutReportList.length > 0
  const hasIsaacPayPayoutReport = isaacPayPayoutReportList.length > 0
  const hasPayoutReports =
    hasTuitionPayoutReport || hasEnrollmentPayoutReport || hasIsaacPayPayoutReport

  const isSchoolIsaacPay = isIsaacPaySchool(school)

  return (
    <Container>
      {!hasPayoutReports && !isLoading && !hasError ? (
        <EmptyState />
      ) : (
        <Box p="2rem 4rem">
          <Box mb="2rem" borderRadius="0.5rem" overflow="hidden">
            <Notification
              title="Essas informações estão disponíveis para o seu usuário."
              description="Você pode solicitar a liberação dessas informações para outros usuários através do suporte."
              variation="information"
            />
          </Box>

          <Content>
            {(hasTuitionPayoutReport || tuitionLoading || tuitionError) && !isSchoolIsaacPay && (
              <TuitionReportDashboardCard
                currentPayout={currentTuitionPayoutReport}
                tuitionPayouts={tuitionPayoutReportList}
                isLoading={tuitionLoading}
                isLoadingPayoutAmount={tuitionPayoutDetailsLoading}
                hasError={tuitionError}
                onChange={handleChangeTuitionPayoutReport}
                onRetry={refetchTuition}
              />
            )}

            {(hasIsaacPayPayoutReport || isaacPayLoading || isaacPayError) && isSchoolIsaacPay && (
              <IsaacPayReportDashboardCard
                currentPayout={currentIsaacPayPayoutReport}
                isaacPayPayouts={isaacPayPayoutReportList}
                isLoading={isaacPayLoading}
                hasError={isaacPayError}
                onChange={handleChangeIsaacPayPayoutReport}
                onRetry={refetchIsaacPay}
              />
            )}

            {(hasEnrollmentPayoutReport || enrollmentLoading || enrollmentError) &&
              isIsaacPaySchool && (
                <EnrollmentReportDashboardCard
                  currentPayout={currentEnrollmentPayoutReport}
                  enrollmentPayouts={enrollmentPayoutReportList}
                  isLoading={enrollmentLoading}
                  hasError={enrollmentError}
                  onChange={handleChangeEnrollmentPayoutReport}
                  onRetry={refetchEnrollment}
                />
              )}
          </Content>
        </Box>
      )}
    </Container>
  )
}
