import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getIdFromUrn,
  getInformationForPaymentDetailByInstallment,
  getReceivableStatusByInstallment,
  sendEvent,
} from '../../helper'
import * as S from '../../styles'
import { useApi, useJWT } from '@/shared/hooks'
import { useNavigation } from '@/escolas/hooks'
import {
  LiquidationPaymentMethod,
  LiquidationPaymentMethodAdminLabel,
  LiquidationSource,
  Receivable,
  ReceivableStatuses,
} from '@/shared/interfaces'
import { REALMS } from '@/shared/constants'
import { UnleashFlags, useUnleashFlag as unleashFlag } from 'src/shared/hooks/useUnleashFlag'
import useManageFields from '../../hooks/manualLiquidation/useManageFields'
import useManualLiquidationForm from '../../hooks/manualLiquidation/useManualLiquidationForm'
import { getStatus } from '@/escolas/components/contract/utils'
import useLiquidationInfo from '../../hooks/manualLiquidation/useLiquidationInfo'
import { formatCentsToReal, getEndOfToday, validateEditReason } from '@/shared/utils'
import { MixpanelEventsEnum } from '@/shared/integrations'
import dayjs from 'dayjs'
import { sendEditManualLiquidationEventsByInstallment } from '../helper'
import usePaymentDetail from '../../hooks/manualLiquidation/usePaymentDetail'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/ConfirmationDialog'
import { Box, Grid } from '@material-ui/core'
import Header from '../../components/Header'
import { InputBox } from '../../components/InputBox'
import { Controller } from 'react-hook-form'
import { EditReasonField } from '../../components/Fields/EditReasonField'
import { AccountField } from '../../components/Fields/AccountField'
import { PaymentMethodField } from '../../components/Fields/PaymentMethodField'
import { PaidDateField } from '../../components/Fields/PaidDateField'
import { AddRounded } from '@material-ui/icons'
import { DiscountsField } from '../../components/Fields/DiscountsField'
import Footer from '../../components/Footer'
import PaymentDetail from '../../components/PaymentDetail'
import Conclusion from '../../components/Conclusion'
import { InstallmentV2 } from '@/modules/guardians/services/types'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import {
  ManualLiquidationFields,
  ManualLiquidationDiscountPageForm,
} from '@/modules/guardians/ManualLiquidation/types'

export interface Props {
  installment: InstallmentV2
  isFetchGuardiansInstallmentsLoading: boolean
}

const ManualLiquidationEdit: FC<Props> = ({ installment, isFetchGuardiansInstallmentsLoading }) => {
  const history = useHistory()
  const { isAdmin } = useJWT()
  const { api } = useApi()
  const { schoolId } = useNavigation()
  const { pageToReturn } = useGuardianNavigation()

  const [openDiscount, setOpenDiscount] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [receivableLiquidated, setReceivableLiquidated] = useState<Receivable>()

  const receivableId = getIdFromUrn(installment?.urn)

  const realm = isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM

  const isValidatePaidDateBefore7Days = unleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE
  )

  const isManualLiquidationBlockedForSchool = unleashFlag(
    UnleashFlags.B2CB_984_DISABLE_MANUAL_LIQUIDATION
  )

  const isEdition = installment?.status === ReceivableStatuses.PAID

  const { toggleField, fieldsStatus, isFieldDisabled } = useManageFields(realm, isEdition)
  const { handleSubmit, control, watch, getValues, setValue } = useManualLiquidationForm(
    isAdmin,
    installment
  )

  const receivableStatus = getStatus(getReceivableStatusByInstallment(installment))

  watch(['source', 'payment_method', 'paid_date'])
  const { source, payment_method, paid_date, edit_reason } = getValues()

  const {
    isLoading,
    discountInfo,
    form: discountForm,
    clearDiscounts,
    liquidationInfo,
  } = useLiquidationInfo({
    installment: installment,
    paidDate: paid_date,
    installmentIsLoading: isFetchGuardiansInstallmentsLoading,
  })

  const initialDueDateValue =
    installment?.lost_due_payment_discount > 0 ? installment?.current_due_payment_discount : 0
  const initialEarlyDiscountValue =
    installment?.lost_early_payment_discount > 0 ? installment?.current_early_payment_discount : 0
  const initialAdditionalDiscountValue = installment?.current_additional_discount

  const {
    additional_discount_amount,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
    apply_previous_fines_and_interest,
    apply_fines_and_interest_change,
  } = discountForm.getValues()

  discountForm.watch(['additional_discount_amount'])

  const formValidate = () => {
    if (!source) return false
    if (!validateEditReason(edit_reason)) return false
    if (source !== LiquidationSource.ISAAC_ACCOUNT && !payment_method) return false
    if (!paid_date) return false
    if (!discountInfo.validateTotalDiscount) return false
    if (Object.values(fieldsStatus).some(fieldStatus => fieldStatus.isOpen)) return false
    return true
  }

  const isFormValid = formValidate()

  const isSourceIsaacAccount = source === LiquidationSource.ISAAC_ACCOUNT

  const handleGoBack = () => {
    history.push(pageToReturn)
  }

  const handleGoBackWithoutSaving = () => {
    handleGoBack()
    sendEvent(
      receivableId,
      receivableStatus,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAGE_FLOW_BACK
    )
  }

  const submitHandler = async () => {
    setLoading(true)

    const resource = api.receivables.patchManualLiquidation

    return await resource(
      receivableId,
      {
        additional_discount_amount:
          discountInfo.isAdditionalDiscountEnabled && additional_discount_amount
            ? +additional_discount_amount
            : 0,
        apply_lost_due_payment_discount: apply_lost_due_payment_discount,
        apply_lost_early_payment_discount: apply_lost_early_payment_discount,
        paid_date: dayjs(paid_date).utc().hour(0).toISOString(),
        remove_fines_and_interest: !apply_previous_fines_and_interest,
        remove_fines_and_interest_change: !apply_fines_and_interest_change,
        source,
        payment_method:
          source === LiquidationSource.SCHOOL_ACCOUNT
            ? LiquidationPaymentMethod[payment_method as keyof typeof LiquidationPaymentMethod]
            : null,
        edit_reason: edit_reason,
        is_edition: isEdition,
      },
      schoolId
    )
      .then(updatedReceivables => {
        const paidReceivable = updatedReceivables.find(
          receivable => receivable.status === ReceivableStatuses.PAID
        )
        setReceivableLiquidated(paidReceivable)
      })
      .catch(() => setShowErrorModal(true))
      .finally(() => setLoading(false))
  }

  const getSourceSubtitle = () => {
    if (fieldsStatus.SELECT_ACCOUNT.isOpen) return 'Selecione em que conta a parcela foi recebida'
    switch (source) {
      case LiquidationSource.ISAAC_ACCOUNT:
        return 'Conta isaac'
      case LiquidationSource.SCHOOL_ACCOUNT:
        return 'Conta escola'
      default:
        return 'Selecione em que conta a parcela foi recebida'
    }
  }

  const getPaymentFormSubtitle = () => {
    if ((!payment_method && !isSourceIsaacAccount) || fieldsStatus.PAYMENT_FORM.isOpen)
      return 'Selecione o meio de pagamento'
    if (isSourceIsaacAccount) return 'Recebimento conta isaac'
    return LiquidationPaymentMethodAdminLabel[
      payment_method as keyof typeof LiquidationPaymentMethodAdminLabel
    ]
  }

  const getPaidDateSubtitle = () => {
    if (!paid_date || fieldsStatus.PAID_DATE.isOpen) return 'Selecione a data'
    return dayjs(paid_date).format('DD/MM/YYYY')
  }

  const endFirstLoading = !!discountInfo?.finalAmount

  const fineAndInterestPrevious = apply_previous_fines_and_interest
    ? discountInfo.previousFinesAndInterest
    : 0

  const changeFineAndInterest = apply_fines_and_interest_change
    ? discountInfo.fineAndInterestChange
    : 0

  const finalFineAndInterest = fineAndInterestPrevious + changeFineAndInterest

  const addicionalDiscount = discountInfo.isAdditionalDiscountEnabled
    ? Number(additional_discount_amount)
    : 0

  const lostDueDiscount = apply_lost_due_payment_discount ? discountInfo.lostDuePaymentDiscount : 0

  const lostEarlyDiscount = apply_lost_early_payment_discount
    ? discountInfo.lostEarlyPaymentDiscount
    : 0

  const totalDiscount = addicionalDiscount + lostDueDiscount + lostEarlyDiscount

  const fineAndDiscountEqualZero = totalDiscount === 0 && finalFineAndInterest <= 0

  const showButtonAddDiscount = !endFirstLoading || (!openDiscount && fineAndDiscountEqualZero)

  const getDiscountSubtitle = () => {
    if (fieldsStatus.DISCOUNTS.isOpen)
      return 'Selecione as multas e descontos que deseja adicionar ou remover'

    let message = ''
    if (finalFineAndInterest > 0) {
      message = `Multas e juros ${formatCentsToReal(finalFineAndInterest)}`
    }
    if (totalDiscount) {
      if (!message) {
        message = `Desconto de ${formatCentsToReal(totalDiscount)}`
      } else {
        message = message + ` e desconto de ${formatCentsToReal(totalDiscount)}`
      }
    }

    return message
  }

  const getEditReasonSubtitle = () => {
    if (!edit_reason || fieldsStatus.EDIT_REASON.isOpen)
      return 'Descreva o motivo da edição do recebimento'
    return edit_reason
  }

  const sendDiscountEvents = (values: ManualLiquidationDiscountPageForm) => {
    if (changeFineAndInterest > 0) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_PENALTY_INTERESTS_CHANGED,
        {
          $changedFinesAndInterest: changeFineAndInterest,
        }
      )
    }

    if (
      discountInfo.lostEarlyPaymentDiscount > 0 &&
      initialEarlyDiscountValue > 0 !== values?.apply_lost_early_payment_discount
    ) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_ANTICIPATION_DISCOUNT_CHANGED,
        {
          $newEarlyValue: lostEarlyDiscount,
        }
      )
    }

    if (
      discountInfo.lostDuePaymentDiscount > 0 &&
      initialDueDateValue > 0 !== values?.apply_lost_due_payment_discount
    ) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_PUNCTUALITY_DISCOUNT_CHANGED,
        {
          $newDueDateValue: lostDueDiscount,
        }
      )
    }

    if (initialAdditionalDiscountValue !== Number(values?.additional_discount_amount)) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_ADDITIONAL_DISCOUNT_CHANGED,
        {
          $newAdditionalDiscountValue: values?.additional_discount_amount,
        }
      )
    }
  }

  const onSubmit = () => {
    const values = discountForm.getValues()
    sendDiscountEvents(values)
    sendEditManualLiquidationEventsByInstallment(values, installment, receivableStatus)
    submitHandler()
  }

  const minDate = isValidatePaidDateBefore7Days
    ? dayjs(installment?.paid_date).subtract(7, 'day')
    : dayjs(installment.contracts?.[0]?.created_at)

  const maxDate = isValidatePaidDateBefore7Days
    ? dayjs(installment?.paid_date).utc()
    : getEndOfToday()

  const isDiscountDisabled = (() => {
    if (discountInfo.isRenegotiated) return true
    return isFieldDisabled(fieldsStatus.DISCOUNTS.name)
  })()

  const { finalTotalDiscount, finalAmount } = getInformationForPaymentDetailByInstallment({
    installment,
    liquidationInfo,
    discountInfo,
    totalDiscount,
    paidDate: paid_date,
  })

  const { openDialog, closeDialog, handleDialogInformation, dialogInformation } = usePaymentDetail(
    installment
  )

  const handlePaymentDetailHeader = () => {
    handleDialogInformation(liquidationInfo, discountForm, discountInfo, paid_date)
  }

  return (
    <S.Wrapper>
      <FailureFeedbackDialog
        isVisible={showErrorModal}
        buttonLabel="Fechar"
        onClose={() => setShowErrorModal(false)}
        submitHandler={() => setShowErrorModal(false)}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>

      <Grid item xs={4} sm={6} style={{ minWidth: '752px' }}>
        <Header
          title="Editar recebimento"
          finalAmount={finalAmount}
          totalDiscount={finalTotalDiscount}
          isLoading={isLoading}
          onClickDetailButton={handlePaymentDetailHeader}
          installment={installment}
        />

        {!receivableLiquidated && (
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <InputBox
              title="Motivo da edição"
              subTitle={getEditReasonSubtitle()}
              name={fieldsStatus.EDIT_REASON.name}
              buttonLabel={source ? 'Editar' : 'Selecionar'}
              isOpen={fieldsStatus.EDIT_REASON.isOpen}
              isDisabled={isFieldDisabled(fieldsStatus.EDIT_REASON.name)}
              onClick={() => {
                toggleField(ManualLiquidationFields.EDIT_REASON)
                if (source) {
                  sendEvent(
                    receivableId,
                    receivableStatus,
                    MixpanelEventsEnum.METHODS_INCOMING_EDIT_REASON_EDITED
                  )
                }
              }}
            >
              <Controller
                control={control}
                name="edit_reason"
                render={({ field: { onChange, value } }) => (
                  <EditReasonField
                    value={value}
                    onCancel={() => {
                      toggleField(ManualLiquidationFields.EDIT_REASON)
                    }}
                    onSave={newValue => {
                      onChange(newValue)
                      const nextField = isAdmin
                        ? ManualLiquidationFields.SELECT_ACCOUNT
                        : ManualLiquidationFields.PAYMENT_FORM
                      toggleField(ManualLiquidationFields.EDIT_REASON, nextField)
                    }}
                  />
                )}
              />
            </InputBox>

            <Box mt={2} />

            {isAdmin && (
              <>
                <InputBox
                  title="Conta de recebimento da parcela"
                  subTitle={getSourceSubtitle()}
                  name={fieldsStatus.SELECT_ACCOUNT.name}
                  buttonLabel={source ? 'Editar' : 'Selecionar'}
                  isOpen={fieldsStatus.SELECT_ACCOUNT.isOpen}
                  isDisabled={isFieldDisabled(fieldsStatus.SELECT_ACCOUNT.name)}
                  onClick={() => {
                    toggleField(ManualLiquidationFields.SELECT_ACCOUNT)
                    if (source) {
                      sendEvent(
                        receivableId,
                        receivableStatus,
                        MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_EDITED
                      )
                    }
                  }}
                >
                  <Controller
                    control={control}
                    name="source"
                    render={({ field: { onChange, value } }) => (
                      <AccountField
                        value={value}
                        onCancel={() => {
                          toggleField(ManualLiquidationFields.SELECT_ACCOUNT)
                        }}
                        onSave={newValue => {
                          onChange(newValue)
                          if (newValue === LiquidationSource.ISAAC_ACCOUNT) {
                            setValue('payment_method', '')
                            toggleField(
                              ManualLiquidationFields.SELECT_ACCOUNT,
                              ManualLiquidationFields.PAID_DATE
                            )
                            return
                          }
                          toggleField(
                            ManualLiquidationFields.SELECT_ACCOUNT,
                            ManualLiquidationFields.PAYMENT_FORM
                          )
                        }}
                      />
                    )}
                  />
                </InputBox>

                <Box mt={2} />
              </>
            )}
            <InputBox
              title="Forma de pagamento usada"
              subTitle={getPaymentFormSubtitle()}
              name={fieldsStatus.PAYMENT_FORM.name}
              buttonLabel={payment_method || isSourceIsaacAccount ? 'Editar' : 'Selecionar'}
              isOpen={fieldsStatus.PAYMENT_FORM.isOpen}
              isDisabled={
                isFieldDisabled(fieldsStatus.PAYMENT_FORM.name) || !source || isSourceIsaacAccount
              }
              onClick={() => {
                toggleField(ManualLiquidationFields.PAYMENT_FORM)
                if (payment_method || isSourceIsaacAccount) {
                  sendEvent(
                    receivableId,
                    receivableStatus,
                    MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_EDITED
                  )
                }
              }}
            >
              <Controller
                control={control}
                name="payment_method"
                render={({ field: { onChange, value } }) => (
                  <PaymentMethodField
                    value={value}
                    source={source}
                    realm={realm}
                    onCancel={() => {
                      toggleField(ManualLiquidationFields.PAYMENT_FORM)
                    }}
                    onSave={newValue => {
                      onChange(newValue)
                      toggleField(
                        ManualLiquidationFields.PAYMENT_FORM,
                        ManualLiquidationFields.PAID_DATE
                      )
                    }}
                  />
                )}
              />
            </InputBox>

            <Box mt={2} />

            <InputBox
              title="Data de recebimento"
              subTitle={getPaidDateSubtitle()}
              name={fieldsStatus.PAID_DATE.name}
              buttonLabel={paid_date ? 'Editar' : 'Selecionar'}
              isOpen={fieldsStatus.PAID_DATE.isOpen}
              isDisabled={isFieldDisabled(fieldsStatus.PAID_DATE.name)}
              onClick={() => {
                toggleField(ManualLiquidationFields.PAID_DATE)
                if (paid_date) {
                  sendEvent(
                    receivableId,
                    receivableStatus,
                    MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_EDITED
                  )
                }
              }}
            >
              <Controller
                control={control}
                name="paid_date"
                render={({ field: { onChange, value } }) => (
                  <PaidDateField
                    contractCreationDate={dayjs(installment.contracts?.[0]?.created_at)}
                    isEdition={isValidatePaidDateBefore7Days}
                    value={value ?? maxDate}
                    maxDate={maxDate}
                    minDate={minDate}
                    onCancel={() => {
                      toggleField(ManualLiquidationFields.PAID_DATE)
                    }}
                    onSave={newDate => {
                      onChange(newDate)
                      toggleField(ManualLiquidationFields.PAID_DATE)
                    }}
                    loading={isLoading}
                    onClickDetailButton={handleDialogInformation}
                    installment={installment}
                  />
                )}
              />
            </InputBox>

            <Box mt={2} />

            {showButtonAddDiscount && (
              <S.StyledButton
                variation="ghost"
                onClick={() => {
                  setOpenDiscount(true)
                  toggleField(ManualLiquidationFields.DISCOUNTS)
                }}
                disabled={isDiscountDisabled}
              >
                <AddRounded />
                <S.TextButton
                  variation="buttonLarge"
                  color={isDiscountDisabled ? 'secondary' : 'accent'}
                >
                  Adicionar desconto
                </S.TextButton>
              </S.StyledButton>
            )}

            {!showButtonAddDiscount ? (
              <InputBox
                title="Multas e Descontos"
                subTitle={getDiscountSubtitle()}
                name={fieldsStatus.DISCOUNTS.name}
                buttonLabel="Editar"
                isOpen={fieldsStatus.DISCOUNTS.isOpen}
                isDisabled={
                  isFieldDisabled(fieldsStatus.DISCOUNTS.name) ||
                  isLoading ||
                  !discountInfo.validateTotalDiscount
                }
                onClick={() => {
                  toggleField(ManualLiquidationFields.DISCOUNTS)
                  setOpenDiscount(!fineAndDiscountEqualZero)
                }}
              >
                <DiscountsField
                  discountsInfo={discountInfo}
                  form={discountForm}
                  isLoading={isLoading}
                  onCancel={() => {
                    toggleField(ManualLiquidationFields.DISCOUNTS)
                    clearDiscounts()
                    setOpenDiscount(false)
                  }}
                  onSave={() => {
                    toggleField(ManualLiquidationFields.DISCOUNTS)
                    setOpenDiscount(!fineAndDiscountEqualZero)
                  }}
                />
              </InputBox>
            ) : null}

            <Footer
              isLoading={isLoading || loading}
              enableSubmit={isFormValid}
              onClickGoBack={handleGoBackWithoutSaving}
              onClick={handleSubmit(onSubmit)}
              label="Salvar edições"
            />
          </S.Form>
        )}
        {openDialog && (
          <PaymentDetail
            open={openDialog}
            paidDate={dialogInformation?.paidDate}
            onClose={closeDialog}
            info={dialogInformation}
          />
        )}
        {receivableLiquidated && (
          <>
            <Conclusion
              liquidationInfo={{
                amount: receivableLiquidated.total_amount_paid,
                orderReference: 'Parcela 1 de 1',
                paidDate: receivableLiquidated.paid_date,
                paymentMethod: receivableLiquidated.payment_method,
                receivableId: receivableLiquidated.id,
              }}
              onSuccess={() => {
                sendEvent(
                  receivableId,
                  receivableStatus,
                  MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYMENT_RECEIPT_DOWNLOAD
                )
              }}
            />
            <Footer
              isLoading={isLoading || loading}
              enableSubmit={isFormValid && !(isManualLiquidationBlockedForSchool && !isAdmin)}
              onClickGoBack={handleGoBackWithoutSaving}
              onClick={handleGoBack}
              type="button"
              label="Finalizar edições"
              hideBackButton
            />
          </>
        )}
      </Grid>
    </S.Wrapper>
  )
}

export default ManualLiquidationEdit
