import { FC } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as Styled from './styles'

export const Loader: FC = () => {
  return (
    <Styled.Container>
      <CircularProgress />
    </Styled.Container>
  )
}
