import styled from 'styled-components'

import theme from 'src/shared/theme'

const { spacing } = theme.primitiveTokens
const { variation } = theme.humanizedTokens.components.typography

export const Title = styled.p`
  ${variation.buttonLarge};
  text-transform: none;
`

export const Icon = styled.div`
  height: 24px;
  margin-bottom: ${spacing[1]};
`
