import { Box, FormControl, RadioGroup } from '@material-ui/core'
import { TextField, FormControlLabel, Radio } from '@olaisaac/design-system'
import HelpIcon from '@material-ui/icons/Help'
import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { EachWorkingDayTypography, HelperTextTypography, RadioBox, DueDayTooltip } from './style'
import { path } from 'ramda'

export type SelectDueDayProps = {
  form: UseFormReturn<any>
  inputName: string
  radioName: string
}

const SelectDueDay = ({ form, radioName, inputName }: SelectDueDayProps) => {
  const { control, getValues, setValue, formState } = form
  const { [radioName]: useWorkingDates } = getValues()

  const hasInputError = Boolean(path(inputName.split('.'), formState.errors))
  const isWorkingDate = useWorkingDates === 'true'
  return (
    <Box display="flex" alignItems="center">
      <FormControl variant="outlined">
        <Controller
          rules={{ required: true }}
          control={control}
          aria-label={radioName}
          name={radioName}
          render={({ field, formState, fieldState }) => (
            <RadioGroup {...formState} {...fieldState} {...field}>
              <RadioBox>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Vencimento por dia útil"
                  onChange={() => {
                    setValue(inputName, null)
                  }}
                />
                <DueDayTooltip title="Vencimento sempre no xº dia útil do mês">
                  <HelpIcon />
                </DueDayTooltip>
              </RadioBox>
              {isWorkingDate && (
                <Controller
                  rules={{
                    required: true,
                    validate: value => value > 0 && value <= 25,
                  }}
                  name={inputName}
                  control={control}
                  render={({ formState, fieldState, field: { onChange, value, ...rest } }) => {
                    const invalid = value && !(value > 0 && value <= 25)
                    return (
                      <Box mb="26px" pl="30px">
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box width="104px" mr="12px">
                            <NumberFormat
                              {...formState}
                              {...fieldState}
                              {...rest}
                              value={value}
                              label="Dia"
                              data-testid={`working-${inputName}-input`}
                              customInput={TextField}
                              variation="outlined"
                              onValueChange={currentValue => {
                                onChange(currentValue.value)
                              }}
                              error={invalid || hasInputError}
                            />
                          </Box>
                          <EachWorkingDayTypography>dia útil cada mês</EachWorkingDayTypography>
                        </Box>
                        {(invalid || hasInputError) && (
                          <HelperTextTypography>
                            O dia útil precisa ser menor ou igual a 25
                          </HelperTextTypography>
                        )}
                      </Box>
                    )
                  }}
                />
              )}
              <RadioBox>
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Vencimento por dia fixo"
                  onChange={() => {
                    setValue(inputName, null)
                  }}
                />
                <DueDayTooltip title="Vencimento sempre no mesmo dia">
                  <HelpIcon />
                </DueDayTooltip>
              </RadioBox>
              {!isWorkingDate && (
                <Controller
                  rules={{ required: true, validate: value => value > 0 && value <= 31 }}
                  name={inputName}
                  control={control}
                  render={({ formState, fieldState, field: { onChange, value, ...rest } }) => {
                    const invalid = value && !(value > 0 && value <= 31)
                    return (
                      <Box mb="26px" pl="30px">
                        <Box width="104px">
                          <NumberFormat
                            {...formState}
                            {...fieldState}
                            {...rest}
                            value={value}
                            label="Dia"
                            data-testid={`fixed-${inputName}-input`}
                            customInput={TextField}
                            variation="outlined"
                            onValueChange={currentValue => {
                              onChange(currentValue.value)
                            }}
                            error={invalid || hasInputError}
                          />
                        </Box>
                        {(invalid || hasInputError) && (
                          <HelperTextTypography>
                            O dia fixo precisa ser menor ou igual a 31
                          </HelperTextTypography>
                        )}
                      </Box>
                    )
                  }}
                />
              )}
            </RadioGroup>
          )}
        />
      </FormControl>
    </Box>
  )
}

export default SelectDueDay
