import { Select } from '@gravity/select'
import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { spacing, colors, fontWeight, fontSize, lineHeight, letterSpacing } from '@gravity/tokens'
import { Skeleton } from '@gravity/skeleton'

// Containers
export const ContainerCard = styled.div`
  margin: ${spacing[4]};
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[2]};
`

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

// Texts
const BaseText = styled(Typography)`
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
`

export const KeyText = styled(BaseText)`
  font-size: ${fontSize.base};
  margin-bottom: ${spacing[2]};
`

export const ValueText = styled(BaseText)`
  font-size: ${fontSize.lg};
`

export const SmallValueText = styled(BaseText)`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
`

export const CommentText = styled(BaseText)`
  color: ${colors['colors-text-main-3']};
  font-size: ${fontSize.xs};
  margin-top: 20px;
`

// Components
export const StyledSelect = styled(Select)<{ $fullWith?: boolean }>`
  width: ${props => (props.$fullWith ? '100%' : '300px')};
  margin-bottom: ${spacing[3]};
`

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${spacing[2]};
`
