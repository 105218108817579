import { StatusSummaryQuery } from '@/modules/enrollment/hooks/queries/enrollment'
import { ENROLLMENT_CYCLE_QUERY_KEY } from '@/modules/enrollment/constants/enrollment'
import { Situation } from '@/escolas/services/enrollment/types'
import { useApi, useSnackbar } from '@/shared/hooks'
import { ReenrollmentStatus } from '@/shared/interfaces'
import { useQueryClient } from '@tanstack/react-query'
import { studentEnrollmentCycleKey } from './queries/useGetStudentEnrollmentCycle'

export const useRefreshSituation = (
  callback: (
    data: Situation & {
      studentID: uuid
    }
  ) => void
) => {
  const { api } = useApi()
  const { setIsOpen } = useSnackbar()
  const queryClient = useQueryClient()

  const refreshSituation = async (
    schoolID: uuid,
    studentID: uuid,
    enrollmentCycle: number,
    currentStatus: ReenrollmentStatus,
    studentName: string
  ) => {
    try {
      const data = (
        await api.enrollment.updateEnrollmentSituation(schoolID, studentID, enrollmentCycle)
      ).data
      const statusHasChanged = data.status !== currentStatus

      const dataWithStudentID = { ...data, studentID }
      callback(dataWithStudentID)

      queryClient.invalidateQueries([ENROLLMENT_CYCLE_QUERY_KEY])
      queryClient.invalidateQueries([StatusSummaryQuery])
      queryClient.invalidateQueries([studentEnrollmentCycleKey])

      setIsOpen(true, {
        description: statusHasChanged
          ? `${studentName} teve sua situação de matrícula atualizada!`
          : `${studentName} não teve mudanças na situação de matrícula. Se houve algum pagamento pode ser que ele ainda não tenha sido compensado, tente novamente mais tarde.`,
        variation: statusHasChanged ? 'success' : 'information',
      })
    } catch (e) {
      setIsOpen(true, {
        description: 'Erro ao tentar atualizar situação de matrícula.',
        variation: 'error',
      })
    }
  }

  return refreshSituation
}
