import { Table } from '@gravity/table'

import { Loading } from './components/Loading'
import { Empty } from './components/Empty'
import { RowActions } from './components/RowActions'

import { canUpdateUserAccess } from './utils/canUpdateUserAccess'
import { canRemoveUserAccess } from './utils/canRemoveUserAccess'

import { USER_ACCESS_LEVEL_LABEL } from '../../constants/USER_ACCESS_LEVEL_LABEL'
import { USER_ACTIVATION_STATUSES_LABEL } from '../../constants/USER_ACTIVATION_STATUSES_LABEL'
import type { SortOrder } from '@/shared/hooks/useOrdering/types'
import type { Columns, SortableColumns, UsersTableProps } from './types'

export const COLUMN_HEADERS: Array<{
  label: string
  name: Columns
  sortable: boolean
}> = [
  { name: 'first_name', label: 'Nome e e-mail', sortable: true },
  { name: 'group_name', label: 'Nível de acesso', sortable: true },
  { name: 'email_verified', label: 'Status do usuário', sortable: true },
]

export const UsersTable = ({
  loggedUserId,
  users,
  ordering,
  isLoading = true,
  onChangeOrdering,
  assignAccessRoles,
  removeAccessRoles,
  onEdit,
  onRemove,
}: UsersTableProps) => (
  <Table.Root>
    <Table.Head>
      {COLUMN_HEADERS.map(({ label, name, sortable }) => (
        <Table.HeaderCell
          key={name}
          name={name}
          sortable={!isLoading && users.length > 0 && sortable}
          sortOrder={
            ordering.sortBy === name && ordering.sortOrder
              ? (ordering.sortOrder.toLowerCase() as Lowercase<SortOrder>)
              : undefined
          }
          onSort={(sortBy, sortOrder) =>
            onChangeOrdering(
              sortBy as SortableColumns,
              sortOrder.toLocaleUpperCase() as Uppercase<'asc' | 'desc'>
            )
          }
        >
          {label}
        </Table.HeaderCell>
      ))}
    </Table.Head>

    <Table.Body>
      {isLoading && <Loading />}

      {!isLoading && users?.length === 0 && <Empty />}

      {!isLoading && users?.length > 0 && (
        <>
          {users.map(user => (
            <Table.Row key={user.id} data-testid="table-row">
              <Table.TextCell subtext={user.email}>
                {`${user.first_name} ${user.last_name}`}
              </Table.TextCell>

              <Table.TextCell>{USER_ACCESS_LEVEL_LABEL[user.group_name]}</Table.TextCell>

              {user.email_verified ? (
                <Table.TextCell>{USER_ACTIVATION_STATUSES_LABEL.VERIFIED}</Table.TextCell>
              ) : (
                <Table.BadgeCell badgeVariant="soft" badgeColor="neutral">
                  {USER_ACTIVATION_STATUSES_LABEL.UNVERIFIED}
                </Table.BadgeCell>
              )}

              <Table.ActionCell
                actions={
                  <RowActions
                    enableEdit={canUpdateUserAccess(loggedUserId, assignAccessRoles, user)}
                    enableRemove={canRemoveUserAccess(loggedUserId, removeAccessRoles, user)}
                    onRequestEdit={() => onEdit(user)}
                    onRequestRemove={() => onRemove(user)}
                  />
                }
              />
            </Table.Row>
          ))}
        </>
      )}
    </Table.Body>
  </Table.Root>
)
