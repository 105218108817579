import { useEffect } from 'react'
import { Table } from '@gravity/table'
import { spacing } from '@gravity/tokens'

import { useGuardianDetailsFinancial } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/hooks/useGuardianDetailsFinancial'
import { formatter } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/utils/formatter'

import { GuardianFinancialSummaryTableProps } from './types'
import { Wrapper, TableRow } from './styles'

export const GuardianFinancialSummaryTable = ({
  allCheckable,
  installments,
  loading,
  onRowClick,
}: GuardianFinancialSummaryTableProps) => {
  const {
    rows,
    batchSelection,
    enableBatchSelection,
    handleRowSelect,
    handleSelectAll,
  } = useGuardianDetailsFinancial({ installments })

  const checkboxStyle = { maxWidth: spacing[8], width: spacing[8] }

  useEffect(() => {
    if (allCheckable && !loading && rows?.length > 0) {
      handleSelectAll(true)
    }
  }, [loading, rows?.length])

  return (
    <Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell
            style={checkboxStyle}
            name="checkbox"
            enableCheckbox={allCheckable && enableBatchSelection}
            onChange={handleSelectAll}
            checked={batchSelection}
          />
          <Table.HeaderCell minWidth={80} name="due_date">
            Vencimento
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="installment_order">
            Fatura
          </Table.HeaderCell>
          <Table.HeaderCell name="student">Aluno</Table.HeaderCell>
          <Table.HeaderCell name="product_name">Produto</Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="amount">
            Valor
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="installment_status">
            Situação
          </Table.HeaderCell>
        </Table.Head>

        <Table.Body>
          {loading ? (
            <>
              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
            </>
          ) : (
            rows?.map(row => (
              <TableRow key={row.urn} onClick={() => onRowClick(row)} disabled={row.disabled}>
                <Table.CustomCell
                  style={checkboxStyle}
                  enableCheckbox={row.enableCheckbox}
                  onChange={() => handleRowSelect(row.urn)}
                  checked={row.checked}
                />
                <Table.TextCell>{formatter.fullDate(row.due_date)}</Table.TextCell>
                <Table.TextCell
                  subtext={formatter.orderReference(row.installment_order?.order_reference)}
                >
                  {formatter.installmentType(row.installment_order?.row_type)}
                </Table.TextCell>
                <Table.TextCell>{row.student?.name}</Table.TextCell>
                <Table.TextCell>{row.product_name}</Table.TextCell>
                <Table.TextCell>{formatter.amount(row.amount)}</Table.TextCell>
                <Table.BadgeCell badgeVariant="soft" badgeColor={row.installment_status?.color}>
                  {row.installment_status?.text}
                </Table.BadgeCell>
              </TableRow>
            ))
          )}
        </Table.Body>
      </Table.Root>
    </Wrapper>
  )
}
