import { useMutation } from '@tanstack/react-query'
import { creditService } from '../../services'
import { useApi } from '@/shared/hooks'

export const getLockQueryKey = 'get-lock'

export const useGetLock = (schoolId: string) => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  const mutation = useMutation([getLockQueryKey, schoolId], async () => service.getLock(schoolId), {
    retry: 3,
    useErrorBoundary: true,
  })
  return mutation
}
