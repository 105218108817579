import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { HelpOutline } from '@material-ui/icons'
import { Button } from '@olaisaac/design-system'

export const StatusHelpButton = ({ onClick }: { onClick: () => void }) => {
  const { isCollege } = useSelectedSchool()

  if (isCollege) {
    return null
  }

  return (
    <Button
      style={{ maxWidth: 'fit-content' }}
      variation="ghost"
      startIcon={<HelpOutline />}
      onClick={onClick}
    >
      Entenda a situação de matrícula
    </Button>
  )
}
