import { useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { Badge } from '@gravity/badge'
import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Heading } from '@gravity/heading'
import { Link } from '@gravity/link'
import { Tooltip } from '@gravity/tooltip'
import { Skeleton } from '@gravity/skeleton'

import Icon from '@mdi/react'
import {
  mdiAccountSchool,
  mdiBlockHelper,
  mdiBookOpenPageVariant,
  mdiChevronLeft,
  mdiEmailOpen,
} from '@mdi/js'

import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { formatDate } from '@/shared/utils'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'

import { CloseCampaignDialog } from '@/modules/enrollment/components/CloseCampaignDialog'
import { DetailsBigNumber } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/DetailsBigNumber'
import { PaymentPlansDialog } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/PaymentPlansDialog'
import { useEnrollmentCampaignRedirect } from '@/modules/enrollment/hooks/useEnrollmentCampaignRedirect'
import { useEnrollmentMixpanelEvents } from '@/modules/enrollment/hooks/useEnrollmentMixpanelEvents'
import { useCampaignsList } from '@/modules/enrollment/pages/Campaigns/CampaignsList/hooks/useCampaignsList'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { StudentsTable } from './components/StudentsTable'

import { campaignStatusToTextMapper, CampaignStatus } from '@/escolas/services/enrollment/types'

import { PageWrapper } from '@/escolas/components/PageWrapper'

import * as Styled from './styles'

type URLParams = {
  campaignId: string
}

export const CampaignDetails = () => {
  useEnrollmentCampaignRedirect()
  useSetPageTitle('Campanha de rematrícula: - isaac')

  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const [showCloseCampaignDialog, setShowCloseCampaignDialog] = useState(false)
  const [showCampaignPaymentPlansDialog, setShowCampaignPaymentPlansDialog] = useState(false)

  const history = useHistory()

  const { campaignId } = useParams<URLParams>()

  const { school } = useSelectedSchool()

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()

  const isCampaignDetailsEnabled = useUnleashFlag(UnleashFlags.MAT_343_ENABLE_CAMPAIGN_DETAILS)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const schoolId = selectedEnrollmentCycle?.school_id ?? school.id

  const schoolEnrollmentCycleId =
    selectedEnrollmentCycle?.school_enrollment_cycle_id ??
    searchParams.get('school_enrollment_cycle_id')

  const { campaigns, isLoading } = useCampaignsList({
    campaignId,
    schoolId,
    schoolEnrollmentCycleId,
    pagination: {
      page: 1,
      per_page: 1,
    },
  })

  const campaignDetails = useMemo(() => {
    if (!isCampaignDetailsEnabled) {
      return {
        name: searchParams.get('name'),
        productName: searchParams.get('product_name'),
        status: searchParams.get('status') as keyof typeof campaignStatusToTextMapper,
        endDate: searchParams.get('end_date'),
        hasPaymentPlan: searchParams.get('has_payment_plan') === '1',
      }
    }

    return {
      name: campaigns?.[0]?.name,
      productName: campaigns?.[0]?.product_name,
      status: campaigns?.[0]?.status,
      endDate: campaigns?.[0]?.end_date,
      hasPaymentPlan: campaigns?.[0]?.has_payment_plan,
      paymentPlans: campaigns?.[0]?.payment_plans,
      engagedStudentsCount: campaigns?.[0]?.engaged_students_count,
      emailRead: campaigns?.[0]?.total_of_campaign_emails_read,
      studentsCount: campaigns?.[0]?.students_count,
      paidPaymentPlans: campaigns?.[0]?.paid_payment_plans_count,
      closedType: campaigns?.[0]?.closed_type,
    }
  }, [isCampaignDetailsEnabled, campaigns, searchParams])

  const statusText = campaignDetails.status
    ? campaignStatusToTextMapper[campaignDetails.status]
    : ''
  const statusColor = campaignDetails.status === CampaignStatus.OPEN ? 'accent' : 'neutral'

  const formattedDate = formatDate(campaignDetails.endDate, 'DD/MM/YYYY')

  const handleGoBackClick = history.goBack

  const handleCampaignClose = (success: boolean) => {
    if (!success) return

    if (isCampaignDetailsEnabled) {
      searchParams.set('status', CampaignStatus.CLOSED)
      history.replace({ search: searchParams.toString() })
    }
  }

  const calloutText = () => {
    const closedCampaignText =
      campaignDetails.closedType === 'MANUALLY'
        ? `Esta campanha foi encerrada manualmente pela escola em ${formattedDate}.`
        : 'Esta campanha está encerrada.'

    const hasPaymentPlansText = campaignDetails.hasPaymentPlan
      ? 'nem efetuar o pagamento da pré-matrícula'
      : ''

    if (campaignDetails.status === CampaignStatus.CLOSED)
      return `${closedCampaignText} Os dados abaixo refletem o estado da campanha no momento do encerramento. Os responsáveis já não podem mais demonstrar interesse ${hasPaymentPlansText} através do meuisaac.`
    return 'Atenção: A adição de mensalidades para alunos em campanha está em desenvolvimento. Em breve, será possível adicioná-las em lote ou individualmente através do botão acima.'
  }
  if (isLoading && isCampaignDetailsEnabled)
    return (
      <PageWrapper>
        <Styled.PageContainer>
          <Button
            variant="ghost"
            iconStart={<Icon path={mdiChevronLeft} />}
            onClick={handleGoBackClick}
          >
            Voltar
          </Button>
          <Styled.PageContainerSkeleton>
            <Styled.HeaderSkeleton>
              <Styled.InfoHeaderSkeleton>
                <Skeleton fullWidth height={24} radius={8} />
                <Skeleton fullWidth height={24} radius={8} />
                <Skeleton fullWidth height={24} radius={8} />
              </Styled.InfoHeaderSkeleton>
              <Styled.ActionButtonsSkeleton>
                <Skeleton fullWidth height={24} radius={8} />
              </Styled.ActionButtonsSkeleton>
            </Styled.HeaderSkeleton>

            <Skeleton fullWidth height={24} radius={8} />
            <Styled.BigNumbersWrapper>
              <Styled.BigNumberSkeleton>
                <Skeleton fullWidth height={24} radius={8} />
                <Skeleton fullWidth height={24} radius={8} />
              </Styled.BigNumberSkeleton>
              <Styled.BigNumberSkeleton>
                <Skeleton fullWidth height={24} radius={8} />
                <Skeleton fullWidth height={24} radius={8} />
              </Styled.BigNumberSkeleton>
              <Styled.BigNumberSkeleton>
                <Skeleton fullWidth height={24} radius={8} />
                <Skeleton fullWidth height={24} radius={8} />
              </Styled.BigNumberSkeleton>
              <Styled.BigNumberSkeleton>
                <Skeleton fullWidth height={24} radius={8} />
                <Skeleton fullWidth height={24} radius={8} />
              </Styled.BigNumberSkeleton>
            </Styled.BigNumbersWrapper>
          </Styled.PageContainerSkeleton>
        </Styled.PageContainer>
      </PageWrapper>
    )

  return (
    <PageWrapper>
      <Styled.PageContainer>
        <Button
          variant="ghost"
          iconStart={<Icon path={mdiChevronLeft} />}
          onClick={handleGoBackClick}
        >
          Voltar
        </Button>
        <Styled.ActionsContainer>
          <Box flexDirection="column">
            <Box display="flex" flexDirection="column">
              <Styled.ActionNameWrapper>
                <Heading variant="heading-h3-medium">{campaignDetails.name}</Heading>
                <Badge variant="soft" color={statusColor}>
                  {statusText}
                </Badge>
              </Styled.ActionNameWrapper>

              <Heading variant="heading-h4-regular">{`${campaignDetails.productName} | Válida até ${formattedDate}`}</Heading>
              {isCampaignDetailsEnabled &&
                campaignDetails.hasPaymentPlan &&
                (campaignDetails?.paymentPlans?.length ?? 0) > 0 && (
                  <Link
                    onClick={() => {
                      setShowCampaignPaymentPlansDialog(true)
                    }}
                  >
                    Ver condições de pagamento
                  </Link>
                )}
            </Box>
          </Box>
          <Styled.ActionButtonsWrapper>
            <Button
              disabled={campaignDetails.status !== CampaignStatus.OPEN}
              iconStart={<Icon path={mdiBlockHelper} />}
              onClick={() => {
                sendButtonClickEvent('encerrar_campanha_acompanhamento')
                setShowCloseCampaignDialog(true)
              }}
              variant="outline"
            >
              Encerrar campanha
            </Button>
            <Tooltip
              text="Em breve, você poderá adicionar mensalidades à sua campanha."
              position="bottom"
            >
              <Button disabled>Adicionar mensalidades</Button>
            </Tooltip>
          </Styled.ActionButtonsWrapper>
        </Styled.ActionsContainer>
        {isCampaignDetailsEnabled ? (
          <>
            <Callout text={calloutText()} />
            <Styled.BigNumbersWrapper>
              <DetailsBigNumber
                title={<Icon path={mdiAccountSchool} size="24px" />}
                value={campaignDetails.studentsCount.toString()}
                description="Alunos incluídos"
              />
              <DetailsBigNumber
                title={<Icon path={mdiEmailOpen} size="24px" />}
                value={campaignDetails.emailRead.toString()}
                description="Emails abertos"
              />
              <DetailsBigNumber
                title={<Icon path={mdiBookOpenPageVariant} size="24px" />}
                value={campaignDetails.engagedStudentsCount.toString()}
                description="Alunos interessados"
              />
            </Styled.BigNumbersWrapper>
            <StudentsTable campaignId={campaignId} />
          </>
        ) : (
          <Styled.ComingSoonTextWrapper>
            <Heading variant="heading-h3-regular" color="red">
              Em breve, você poderá visualizar mais detalhes de sua campanha por aluno.
            </Heading>
          </Styled.ComingSoonTextWrapper>
        )}
      </Styled.PageContainer>

      {isCampaignDetailsEnabled && (
        <PaymentPlansDialog
          visible={showCampaignPaymentPlansDialog}
          closeDialog={() => setShowCampaignPaymentPlansDialog(false)}
          paymentPlans={campaignDetails.paymentPlans}
        />
      )}
      <CloseCampaignDialog
        visible={showCloseCampaignDialog}
        closeDialog={() => setShowCloseCampaignDialog(false)}
        campaignId={campaignId}
        hasPaymentPlan={campaignDetails.hasPaymentPlan}
        onFinish={handleCampaignClose}
      />
    </PageWrapper>
  )
}
