import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const Form = styled.form`
  padding-top: ${spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ChipsWrapper = styled(Box)`
  margin-top: ${({ theme }) => `${theme.gravity.spacing['2']}`};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > :last-child {
    margin-right: 0;
  }
`
export const ChipContainer = styled(Box)`
  margin-top: ${({ theme }) => `${theme.gravity.spacing['2']}`};
  margin-right: ${({ theme }) => `${theme.gravity.spacing['2']}`};
`

export const AlertBox = styled(Alert).attrs({
  severity: 'warning',
})`
  background-color: ${({ theme }) =>
    `${theme.gravity.colors['colors-background-semantic-colors-warning-2']}`};

  .MuiAlert-message {
    color: ${({ theme }) => `${theme.gravity.colors['colors-text-semantic-colors-warning-1']}`};
  }

  .MuiAlert-icon,
  .MuiAlert-icon > svg {
    width: 16px;
    color: ${({ theme }) => `${theme.gravity.colors['colors-text-semantic-colors-warning-1']}`};
  }
`
