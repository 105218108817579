import { Button } from '@gravity/button'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { colors, spacing } from '@gravity/tokens'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'

export const Form = styled.form`
  padding-top: ${spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const DatePickerWrapper = styled.div`
  .MuiTextField-root {
    width: 100%;
    .MuiInputBase-root {
      height: 40px;

      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
      }
    }
  }
`

export const TextFieldWrapper = styled.div`
  input {
    width: 100%;
  }
`

export const SelectWrapper = styled.div`
  button {
    width: 100%;
  }
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
`

export const OfferInfoWrapper = styled.div`
  border-top: 1px solid ${colors['colors-border-neutral-2']};
  margin: ${spacing[8]} 0;
  padding-top: ${spacing[8]};
  width: auto;
`

export const AlertBox = styled(Alert).attrs({
  severity: 'warning',
})`
  background-color: ${colors['colors-background-semantic-colors-warning-2']};

  .MuiAlert-message {
    color: ${colors['colors-text-semantic-colors-warning-1']};
  }

  .MuiAlert-icon,
  .MuiAlert-icon > svg {
    width: 16px;
    color: ${colors['colors-text-semantic-colors-warning-1']};
  }
`

export const OfferButton = styled(Button)`
  width: 100%;
`

export const Footer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: end;
`
