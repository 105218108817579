import { View } from './view'
import type { EnrollmentPaymentPlan } from '@/escolas/services/enrollment/types'

type Props = {
  closeDialog: () => void
  paymentPlans: EnrollmentPaymentPlan[]
  visible: boolean
}

export const PaymentPlansDialog = ({ visible, closeDialog, paymentPlans }: Props) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }

  return (
    <View
      visible={visible}
      paymentPlans={paymentPlans}
      handleDialogOpenChange={handleDialogOpenChange}
    />
  )
}
