import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@olaisaac/design-system'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { useEnrollmentCampaignRedirect } from '@/modules/enrollment/hooks/useEnrollmentCampaignRedirect'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { useGetEnrollmentReportCampaignReport } from '../../hooks/queries/enrollmentReport'
import { Loader } from '../../components/Loader'
import { PageWrapper } from '../../components/PageWrapper'
import * as Styled from './styles'

export const EnrollmentCampaignReport: FC = () => {
  const { goBack, replace } = useHistory()
  const reportQuery = useGetEnrollmentReportCampaignReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  const { schoolSlug } = useSelectedSchool()

  useEnrollmentCampaignRedirect()

  const isCampaignReportEnabled = useUnleashFlag(
    UnleashFlags.MAT_367_ENABLE_CAMPAIGN_METABASE_REPORT
  )

  useEffect(() => {
    if (!isCampaignReportEnabled) {
      replace(`/${schoolSlug}/matriculas`)
    }
  }, [isCampaignReportEnabled])

  return (
    <PageWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button variation="ghost" startIcon={<ArrowBackIcon />} onClick={goBack}>
          Voltar
        </Button>
        <Styled.HeaderTextWrapper>
          <Heading variant="heading-h3-medium">Relatório de campanha de rematrícula</Heading>
          <Text variant="body-1-regular">
            Acompanhe os detalhes de todas as campanhas por aluno. Este relatório é atualizado
            diariamente a partir das 08:00, no horário de Brasília.
          </Text>
        </Styled.HeaderTextWrapper>
      </Styled.Header>

      {iframeStatus === 'loading' ? <Loader /> : null}

      <Styled.Iframe
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageWrapper>
  )
}
