import { CheckoutCombinableReceivable } from '@/escolas/contexts/checkoutContext'
import { InstallmentV2 } from '@/modules/guardians/services/types'
import { getIdFromUrn } from '@/modules/guardians/ManualLiquidation/helper'
import { ReceivableStatuses } from '@/shared/interfaces'
import { InstallmentStatus } from '@/modules/guardians/GuardianDetails/constants'

const adaptInstallmentV2ToCombinedReceivables = (
  guardiansInstallments: InstallmentV2[]
): CheckoutCombinableReceivable[] => {
  return guardiansInstallments.reduce((acc, curr) => {
    const status =
      curr.overdue && curr.status === InstallmentStatus.OPEN
        ? ReceivableStatuses.OVERDUE
        : curr.status
    const parsedCombinedReceivable: CheckoutCombinableReceivable = {
      amount: curr.current_amount,
      contract_id: curr.contracts[0].id,
      credit_card_fee: curr.credit_card_fee ?? 0,
      due_date: curr.due_date,
      id: getIdFromUrn(curr.urn),
      lost_due_payment_discount: curr.lost_due_payment_discount,
      lost_early_payment_discount: curr.lost_early_payment_discount,
      orderReference: curr.order_reference,
      product: curr.products[0].name,
      product_id: curr.products[0].id,
      status,
      student: curr.student.name,
      student_id: curr.student.source_id,
      current_fine: curr.current_fine,
      current_interest: curr.current_interest,
      discounts: curr.discounts?.map(discount => ({
        amount: discount.amount,
        description: discount.description,
        type: discount.type,
        days_before_due_date: discount.days_before_due_date,
      })),
      invoice_link: curr.invoice.payment_link,
      original_amount: curr.original_amount,
      type: curr.type,
    }

    acc.push(parsedCombinedReceivable)
    return acc
  }, [])
}

export default adaptInstallmentV2ToCombinedReceivables
