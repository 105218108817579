import styled from 'styled-components'

type AmountProps = {
  color: string
}

export const CurrencySign = styled.span`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 700;
  color: inherit;
  padding-right: 0.5rem;
`

export const Amount = styled.p<AmountProps>`
  font-family: 'Roboto';
  font-size: 2rem;
  font-weight: 700;
  color: ${props => props.color};
`
