import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { path } from 'ramda'
import { formatCentsToReal } from 'src/shared/utils'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { typography } from '@material-ui/system'

type DiscountFormFieldsProps = {
  amountDefaultValue?: cents
  amountFieldName?: string
  disableForm: boolean
  form: UseFormReturn<any>
  totalAmountPaid: cents
}
const StyledTypography = styled(Typography)`
  ${typography}
`
function AgglutinationDiscount({
  amountDefaultValue,
  amountFieldName = 'discount',
  form,
  totalAmountPaid,
  disableForm,
}: DiscountFormFieldsProps) {
  const { control, formState } = form
  const invalidAmount = Boolean(path(amountFieldName.split('.'), formState.errors))
  return (
    <>
      <Box pb={1} display="flex" justifyContent="space-between" paddingTop="15px">
        <StyledTypography variant="body2" fontSize="18px">
          Desconto
        </StyledTypography>
      </Box>
      <Box mb={3} mt={1}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{ required: true, validate: v => v && v < totalAmountPaid }}
                control={control}
                name={amountFieldName}
                defaultValue={amountDefaultValue}
                render={({ field: { onChange, value } }) => (
                  <NumberFormat
                    onValueChange={value => {
                      onChange(value.floatValue)
                    }}
                    id={amountFieldName}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={value}
                    error={invalidAmount}
                    helperText={invalidAmount ? 'Desconto não pode ser superior a 100%' : ''}
                    disabled={disableForm}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AgglutinationDiscount
