import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SelectedSchoolContext } from '@/modules/app/contexts/SchoolRoutesProviders/SelectedSchoolContext'

import { EducationalStage, School } from '@/shared/models/School'

type SelectedSchoolHookProps = {
  onError?: (error: unknown) => void
}

type SelectedSchoolHookValue = {
  hasErrorOnFetchSchool: boolean
  isCollege: boolean
  isLoadingSchool: boolean
  retryFetchSchool: () => Promise<void>
  school?: School
  schoolSlug?: string
}

/**
 * Custom hook to access the selected school data. This hook returns `schoolSlug`
 * collected from URL and the school data collected from API
 */
export const useSelectedSchool = ({
  onError,
}: SelectedSchoolHookProps = {}): SelectedSchoolHookValue => {
  const { schoolSlug } = useParams<{ schoolSlug?: string }>()

  const { updateSchoolSlug, hasErrorOnFetchSchool, error, school, ...rest } = useContext(
    SelectedSchoolContext
  )

  useEffect(() => {
    schoolSlug && updateSchoolSlug(schoolSlug)
  }, [schoolSlug])

  useEffect(() => {
    if (hasErrorOnFetchSchool) {
      onError?.(error)
    }
  }, [hasErrorOnFetchSchool])

  return {
    ...rest,
    school,
    schoolSlug,
    hasErrorOnFetchSchool,
    isCollege: school?.educational_stage === EducationalStage.COLLEGE,
  }
}
