import styled from 'styled-components'

import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { IconButton } from '@gravity/icon-button'
import { colors } from '@gravity/tokens'

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
  :focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: ${colors['colors-border-accent-1']};
  }
`

export const TextFieldWrapper = styled.div`
  .MuiTextField-root {
    width: 100%;

    .MuiInputBase-root {
      height: 41.8px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

export const DangerIconButton = styled(IconButton)`
  --tw-ring-shadow: 0 0 #000 !important;
  color: ${colors['colors-interaction-dangerous-1']};
  z-index: 2;
  :not([disabled]) {
    border: 1px solid ${colors['colors-interaction-dangerous-4']};
  }
  :hover {
    background-color: ${colors['colors-interaction-dangerous-4']}20;
  }
`
