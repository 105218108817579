import { convertToUTCDate, formatNumberMicroCentsToReal } from 'src/shared/utils'

import { CURRENT_MONTH_NUMBER, CURRENT_YEAR } from '../constants'

import type { PayoutSummary } from '@/modules/report/models/PayoutSummary'
import type { FetchPayoutDetailsResponseDTO } from '@/escolas/services/report/types'

export const getElapsedPayouts = (
  payouts: PayoutSummary[],
  targetMonth = CURRENT_MONTH_NUMBER,
  targetYear = CURRENT_YEAR
) => {
  return payouts?.filter(payout => {
    const dueDate = new Date(payout.payout_due_date)

    return (
      convertToUTCDate(dueDate).format('YYYY-MM') >= `${targetYear}-01` &&
      convertToUTCDate(dueDate).format('YYYY-MM') <= `${targetYear}-${targetMonth}`
    )
  })
}

export const getFormattedPayoutDate = (date: string) => convertToUTCDate(date).format('YYYY-MM')

export const getPayoutByMonth = (
  payouts: PayoutSummary[],
  targetMonth = CURRENT_MONTH_NUMBER,
  targetYear = CURRENT_YEAR
) => {
  return payouts?.find(
    payout => getFormattedPayoutDate(payout.payout_due_date) === `${targetYear}-${targetMonth}`
  )
}

export const getPayoutsAccumulatedValue = (payouts: PayoutSummary[]) =>
  payouts?.reduce(
    (accumulator, currentPayout) => accumulator + (currentPayout?.final_amount ?? 0),
    0
  )

export const getPayoutValue = (payout: FetchPayoutDetailsResponseDTO<'tuition'>['data']) => {
  const { total } = payout.agregations.financial_details

  return formatNumberMicroCentsToReal(total)
}
