import { useNavigation } from '@/escolas/hooks'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useApi } from 'src/shared/hooks'
import { Checkout, CheckoutStatus, CheckoutType, CheckoutProvider } from 'src/shared/interfaces'
import { useSendCheckoutEvent } from './useSendCheckoutEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const initCheckoutStatusPolling = (
  getCheckout: (id: uuid) => Promise<Checkout>,
  checkoutObject: Checkout,
  setCheckoutObject: Dispatch<SetStateAction<Checkout>>,
  setIsProcessingCheckoutPayment: Dispatch<SetStateAction<boolean>>,
  setCheckoutPollingInterval: Dispatch<SetStateAction<NodeJS.Timeout>>
) => {
  setIsProcessingCheckoutPayment(true)

  const checkoutId = checkoutObject.id

  let asking = false

  const ask = () => {
    if (asking) {
      return
    }

    asking = true

    getCheckout(checkoutId)
      .then(data => {
        setCheckoutObject(data)
      })
      .catch(error => {
        console.log('checkout polling error ', error)
        setIsProcessingCheckoutPayment(false)
      })
      .finally(() => {
        asking = false
      })
  }

  const interval = setInterval(ask, 4000)
  setCheckoutPollingInterval(interval)
}

const useFlowCheckout = (onSuccessfullyConcluded: (data: Checkout) => void) => {
  const sendCheckoutEvent = useSendCheckoutEvent()

  const { api } = useApi()

  const [checkoutObject, setCheckoutObject] = useState<Checkout>(null)
  const [checkoutInformationTitle, setCheckoutInformationTitle] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const [isProcessingCheckoutPayment, setIsProcessingCheckoutPayment] = useState<boolean>(false)
  const [isCheckoutFeedbackDisabled, setIsCheckoutFeedbackDisabled] = useState<boolean>(false)
  const [checkoutPollingInterval, setCheckoutPollingInterval] = useState<NodeJS.Timeout>(null)

  const [waitingApi, setWaitingApi] = useState<boolean>(false)

  const { guardian } = useNavigation()

  const startCheckout = (
    numberOfInstallments: number,
    paymentMethod: string,
    posId: uuid,
    receivablesIds: string[],
    checkoutProvider: CheckoutProvider,
    downPaymentConversion = false
  ) => {
    setWaitingApi(true)
    const code = (Math.random() + 1).toString(36).substring(7).toUpperCase()
    setCheckoutInformationTitle(code)

    const checkoutType =
      checkoutProvider === CheckoutProvider.STONE_PAGARME
        ? CheckoutType.PAGARME_CHECKOUT
        : CheckoutType.STONE_CHECKOUT

    const body = {
      checkout_type: checkoutType,
      information_title: code,
      installments_count: numberOfInstallments,
      keep_due_payment_discounts: true,
      keep_early_payment_discounts: false,
      keep_fine_and_interest: true,
      payment_methods: [paymentMethod],
      pos_id: posId,
      receivables_ids: receivablesIds,
      down_payment_conversion: downPaymentConversion,
    }

    api.checkout
      .startCheckout(body)
      .then(data => {
        setCheckoutObject(data)
        setIsCheckoutFeedbackDisabled(false)
        initCheckoutStatusPolling(
          api.checkout.getCheckout,
          data,
          setCheckoutObject,
          setIsProcessingCheckoutPayment,
          setCheckoutPollingInterval
        )
      })
      .catch(() => {
        setShowErrorDialog(true)
      })
      .finally(() => {
        setWaitingApi(false)
      })
  }

  const onCancelCheckout = () => {
    setWaitingApi(true)

    api.checkout
      .cancelCheckout(checkoutObject.id)
      .then(() => {
        sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_CANCEL, {
          name: 'Cancelar transação',
        })
      })
      .catch(() => {
        clearInterval(checkoutPollingInterval)
        setShowErrorDialog(true)
      })
      .finally(() => {
        setWaitingApi(false)
      })
  }

  const onFinishCheckout = () => {
    if (checkoutObject?.status === 'finished') {
      window.location.reload()
    }
    if (checkoutObject?.status === 'aborted') {
      sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_CANCEL, {
        name: 'Tentar transação novamente',
      })
    }
    setIsProcessingCheckoutPayment(false)
  }

  useEffect(() => {
    if (checkoutObject?.status === CheckoutStatus.FINISHED && isProcessingCheckoutPayment) {
      clearInterval(checkoutPollingInterval)
      setIsProcessingCheckoutPayment(false)
      onSuccessfullyConcluded(checkoutObject)
      return
    }

    if (!isProcessingCheckoutPayment || checkoutObject?.status === CheckoutStatus.ABORTED) {
      clearInterval(checkoutPollingInterval)
    }
  }, [checkoutObject?.status, isProcessingCheckoutPayment])

  useEffect(() => {
    return () => {
      clearInterval(checkoutPollingInterval)
    }
  }, [])

  const closeCheckoutFeedback = () => {
    setIsProcessingCheckoutPayment(false)
    setIsCheckoutFeedbackDisabled(true)
  }

  const closeCheckoutErrorDialog = () => {
    setShowErrorDialog(false)
    closeCheckoutFeedback()
  }

  return {
    guardianName: guardian.name,
    checkoutObject,
    checkoutInformationTitle,
    showErrorDialog,
    isProcessingCheckoutPayment,
    isCheckoutFeedbackDisabled,
    checkoutPollingInterval,
    startCheckout,
    waitingApi,
    onCancelCheckout,
    onFinishCheckout,
    closeCheckoutFeedback,
    closeCheckoutErrorDialog,
  }
}

export default useFlowCheckout
