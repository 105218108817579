import { AxiosInstance } from 'axios'
import {
  CancelContractRequest,
  CancelInstallmentsRequest,
  ContractDetailsRequest,
  ContractDetailsResponse,
  FetchInstallmentsToUpdateRequest,
  FetchInstallmentsToUpdateResponse,
  UpdateInstallmentsRequest,
  UpdateInstallmentsResponse,
} from './types'
import { APIResponse } from '@/shared/interfaces'

export type contractAPI = {
  cancelContract: (params: CancelContractRequest) => Promise<null>
  cancelInstallments: (params: CancelInstallmentsRequest) => Promise<null>
  contractDetails: (params: ContractDetailsRequest) => Promise<ContractDetailsResponse>
  fetchInstallmentsToUpdate: ({
    contractId,
    schoolId,
  }: FetchInstallmentsToUpdateRequest) => Promise<FetchInstallmentsToUpdateResponse>
  getWorkingDateFromNthDay: (params?: {
    date: datestring
    nth_working_day: number
  }) => Promise<APIResponse<datestring>>
  updateInstallments: (
    schoolId: string,
    contractId: uuid,
    params: UpdateInstallmentsRequest,
    correlationID: uuid
  ) => Promise<UpdateInstallmentsResponse>
}

const correlationIDKey = 'Correlation-Id'

export const newContractAPI = (fetcher: AxiosInstance): contractAPI => {
  return {
    /**
     * Function responsible for fetching all installments from a specific contract.
     *
     * @param data.contractId Contract ID
     * @param data.schoolId School ID as query param
     *
     * @returns A contract with its installments to update.
     */
    fetchInstallmentsToUpdate: async ({ contractId, schoolId }) => {
      const { data } = await fetcher.get<FetchInstallmentsToUpdateResponse>(
        `contract/${contractId}/installments-to-edit`,
        {
          params: {
            school_id: schoolId,
          },
        }
      )
      return data
    },
    updateInstallments: async (
      schoolId: string,
      contractId: uuid,
      params: UpdateInstallmentsRequest,
      correlationID: uuid
    ) => {
      const { data } = await fetcher.patch<UpdateInstallmentsResponse>(
        `/contract/${contractId}/installments?school_id=${schoolId}`,
        params,
        {
          headers: {
            [correlationIDKey]: correlationID,
          },
        }
      )
      return data
    },
    cancelInstallments: async (params: CancelInstallmentsRequest) => {
      return await fetcher.post(
        `/contract/${params.contractId}/installments/cancel?school_id=${params.schoolId}`,
        {
          installment_ids: params.installmentIDs,
        },
        {
          headers: {
            [correlationIDKey]: params.correlationID,
          },
        }
      )
    },
    cancelContract: async (params: CancelContractRequest) => {
      return await fetcher.patch(
        `/contract/${params.contractId}/revoke?school_id=${params.schoolId}`,
        {
          installment_id: params.firstInstallmentId,
          cancellation_description: params.description,
          cancellation_reason: params.reason,
          triggered_by: params.triggeredBy,
        },
        {
          headers: {
            [correlationIDKey]: params.correlationID,
          },
        }
      )
    },
    contractDetails: async ({ contractId }) => {
      const response = await fetcher.get<ContractDetailsResponse>(`/contract/${contractId}`)

      return response.data
    },
    getWorkingDateFromNthDay: async params =>
      (
        await fetcher.get<APIResponse<datestring>>(
          `/date/working-date-from-nth-day/${params.date}/${params.nth_working_day}`
        )
      ).data,
  }
}
