import { PaymentPlan, SimulationPaymentMethod } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'
import { formatBankSlipTextOptionCard } from '../../../helpers/textBankSlip'

const formatDebitText = (totalAmount: number, isPaymentFeeEnabled?: boolean) =>
  `${formatCentsToReal(totalAmount)} à vista${isPaymentFeeEnabled ? '' : ' sem juros'}`

const formatCreditText = (
  totalAmount: number,
  { max_installments_allowed: maxInstallment }: PaymentPlan,
  isPaymentFeeEnabled?: boolean
) =>
  maxInstallment === 1
    ? `${formatCentsToReal(totalAmount)} à vista${isPaymentFeeEnabled ? '' : ' sem juros'}`
    : `Até ${maxInstallment}x de ${formatCentsToReal(totalAmount / maxInstallment)}${
        isPaymentFeeEnabled ? '' : ' sem juros'
      }`

export const getOptionInformations = (
  type: SimulationPaymentMethod,
  totalAmount: number,
  plan: PaymentPlan,
  paymentFee?: {
    enabled: boolean
    percentage: number
    value: number
  }
) => {
  const isEnabledCreditCardFee = paymentFee?.enabled && paymentFee?.value > 0
  const base = isEnabledCreditCardFee
    ? {
        bottomLeft: `com taxa de ${paymentFee?.percentage}%`,
        bottomRight: `${formatCentsToReal(totalAmount - paymentFee?.value)} + ${formatCentsToReal(
          paymentFee?.value
        )} de taxa`,
      }
    : {}

  return {
    [SimulationPaymentMethod.BANK_SLIP]: {
      info: formatBankSlipTextOptionCard(
        plan.max_installments_allowed,
        totalAmount,
        plan.min_down_payment,
        isEnabledCreditCardFee
      ),
    },
    [SimulationPaymentMethod.CREDIT_CARD]: {
      ...base,
      info: formatCreditText(totalAmount, plan, isEnabledCreditCardFee),
    },
    [SimulationPaymentMethod.DEBIT_CARD]: {
      info: formatDebitText(totalAmount, isEnabledCreditCardFee),
    },
    [SimulationPaymentMethod.PIX]: {
      info: '',
    },
    [SimulationPaymentMethod.POS]: {
      info: '',
    },
  }[type]
}
