import { ElementType } from 'react'

import type { InfoPlaceholderProps, InfoProps } from './types'

import { Box, Title, TitlePlaceholder } from './styles'
import { Link } from '../Link'

export const Info = (props: InfoProps) => {
  const { children, title, href = '' } = props

  const Container = (href ? Link : Box) as ElementType

  const containerProps = href
    ? {
        $variant: 'secondary',
        href,
        'aria-label': title,
        onClick: props.onClick,
      }
    : {}

  return (
    <Container {...containerProps}>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}

export const InfoPlaceholder = (props: InfoPlaceholderProps) => {
  const { children } = props

  return (
    <Box>
      <TitlePlaceholder role="text" />
      {children}
    </Box>
  )
}
