import type { SvgIconComponent } from '@material-ui/icons'

type InfoCardIconProps = {
  color: string
  icon: SvgIconComponent
}

export const InfoCardIcon = ({ icon: Icon, color }: InfoCardIconProps) => {
  return <Icon style={{ color, fontSize: '1.25rem' }} data-testid="icon" />
}
