import { Button } from '@olaisaac/design-system'

import type { BannerProps } from './types'

import {
  Container,
  Explanation,
  ExplanationPlaceholder,
  InfoContainer,
  Title,
  TitlePlaceholder,
} from './styles'
import { Skeleton } from '@material-ui/lab'

export const Banner = (props: BannerProps) => {
  const { title, explanation, buttonLabel, imageSrc, buttonOnClick, backgroundColor, color } = props
  return (
    <Container backgroundColor={backgroundColor}>
      <img src={imageSrc} alt="" width={262} height={174.92} />
      <InfoContainer>
        <Title color={color}>{title}</Title>
        <Explanation $color={color}>{explanation}</Explanation>
        <Button onClick={buttonOnClick}>{buttonLabel}</Button>
      </InfoContainer>
    </Container>
  )
}

export const BannerPlaceholder = () => (
  <Container>
    <Skeleton width={262} height={174.92} variant="rect" role="img" />
    <InfoContainer>
      <TitlePlaceholder role="heading" />
      <Skeleton />
      <ExplanationPlaceholder role="text" />
      <Skeleton width={132} height={48} variant="rect" role="button" />
    </InfoContainer>
  </Container>
)
