import { CheckoutStatus, CheckoutType, PaymentMethod } from '../interfaces'
import { UnifiedInvoice } from '../interfaces/unifiedInvoice'

export const mockUnifiedInvoice: UnifiedInvoice = {
  amount: 100,
  checkout: {
    amount: 100,
    external_transaction_id: '123',
    id: '123',
    installments_count: 1,
    payment_method: PaymentMethod.CREDIT_CARD_PAYMENT_METHOD,
    status: CheckoutStatus.ON_HOLD,
    type: CheckoutType.PAGARME_CHECKOUT,
    created_at: new Date().toDateString(),
    updated_at: new Date().toDateString(),
  },
  id: '123',
}
