import { FC, useCallback, useEffect } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Grid } from '@material-ui/core'
import Icon from '@mdi/react'
import { useHistory } from 'react-router-dom'
import { mdiAccountSchool, mdiBookOpenVariant, mdiCalendar, mdiReceiptTextOutline } from '@mdi/js'
import { debounce } from 'throttle-debounce'
import { DialogPrimitives } from '@gravity/dialog'

import { formatCentsToReal, formatDate } from '@/shared/utils'
import { useCampaignContext } from '@/modules/enrollment/contexts/CampaignContext'
import { StepPageProps } from '@/modules/enrollment/components/Stepper/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSnackbar } from '@/shared/hooks'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'

import { useDialogVisibility } from './hooks/useDialogVisibilty'
import { useCreateCampaign } from './hooks/useCreateCampaign'
import * as Styled from './styles'
import { useEnrollmentMixpanelEvents } from '@/modules/enrollment/hooks/useEnrollmentMixpanelEvents'

export const ReviewStep: FC<StepPageProps> = ({ enrollmentCycle }) => {
  const { conditions, chosenProducts, totalParticipatingStudents } = useCampaignContext()
  const offers = conditions?.offers ? conditions.offers : []

  const { closeDialog, dialogVisible, openDialog } = useDialogVisibility()
  const { createCampaign, isLoading } = useCreateCampaign(enrollmentCycle)
  const { replace } = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { setSnackBar } = useSnackbar()
  const { sendHotjarEvent } = useHotjar()
  const {
    sendPageViewEvent,
    sendButtonClickEvent,
    sendModalViewEvent,
  } = useEnrollmentMixpanelEvents()

  const createCampaignFlow = useCallback(async () => {
    try {
      await createCampaign()
      setSnackBar('Campanha criada com sucesso!', 'success')
      sendHotjarEvent(HotjarEvents.CAMPAIGN_CREATED)
      replace(`/${schoolSlug}/campanhas/${enrollmentCycle.reference_year}`)
    } catch (_) {}
  }, [schoolSlug, enrollmentCycle])

  const debouncedCreateCampaignFlow = useCallback(debounce(500, createCampaignFlow), [
    createCampaignFlow,
  ])

  const handleDialogSubmit = () => {
    sendButtonClickEvent('confirmar_criacao')
    closeDialog()
    debouncedCreateCampaignFlow()
  }

  const handleDialogOpenChange = (open: boolean) => {
    sendModalViewEvent('deseja_iniciar')
    if (!open && dialogVisible) closeDialog()
  }

  useEffect(() => {
    sendPageViewEvent('REVISAO_CAMPANHA')
  }, [])

  return (
    <Styled.Wrapper>
      <Heading variant="heading-h3-medium">Revisão</Heading>
      <Styled.SubtitleWrapper>
        <Text variant="body-1-regular">
          Confira as configurações gerais da sua campanha e, se necessário, edite antes de iniciar.
        </Text>
      </Styled.SubtitleWrapper>

      <Grid container spacing={3}>
        <Grid item sm={6}>
          <Styled.Box>
            <Icon path={mdiBookOpenVariant} size={1} />
            <Text variant="body-2-regular">{`Produto ${enrollmentCycle.reference_year} da campanha`}</Text>
            <Heading variant="heading-h4-medium">{conditions.product.name}</Heading>
          </Styled.Box>
        </Grid>
        <Grid item sm={3}>
          <Styled.Box>
            <Icon path={mdiCalendar} size={1} />
            <Text variant="body-2-regular">Data de validade</Text>
            <Heading variant="heading-h4-medium">
              {formatDate(conditions.end_date, 'DD/MM/YYYY')}
            </Heading>
          </Styled.Box>
        </Grid>
        <Grid item sm={3}>
          <Styled.Box>
            <Icon path={mdiAccountSchool} size={1} />
            <Text variant="body-2-regular">Alunos</Text>
            <Heading variant="heading-h4-medium">{totalParticipatingStudents.toString()}</Heading>
          </Styled.Box>
        </Grid>

        <Grid item sm={12}>
          <Styled.Box>
            <Icon path={mdiCalendar} size={1} />
            <Text variant="body-2-regular">Produtos de origem</Text>
            {chosenProducts.map((product, index) => (
              <Heading key={index} variant="heading-h4-medium">
                {product.name}
              </Heading>
            ))}
          </Styled.Box>
        </Grid>

        {offers.map((offer, index) => (
          <Grid item sm={4} key={index}>
            <Styled.Box>
              <Icon path={mdiReceiptTextOutline} size={1} />
              <Text variant="body-2-regular">{`Condição de pagamento ${index + 1}:`}</Text>
              <Text variant="body-2-regular">Valor da pré-matrícula</Text>
              <Heading variant="heading-h4-medium">{formatCentsToReal(offer.amount)}</Heading>
              <Text variant="body-2-regular">Para pagamento até:</Text>
              <Heading variant="heading-h4-medium">
                {formatDate(offer.expirationDate, 'DD/MM/YYYY')}
              </Heading>
            </Styled.Box>
          </Grid>
        ))}
      </Grid>

      <Styled.Footer>
        <Button
          onClick={() => {
            openDialog()
            sendButtonClickEvent('iniciar_campanha')
          }}
          iconEnd={isLoading && <Styled.CircularProgress color="inherit" />}
        >
          Iniciar campanha
        </Button>
      </Styled.Footer>

      <DialogPrimitives.Root open={dialogVisible} onOpenChange={handleDialogOpenChange}>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          size={1}
          description="Ao confirmar, o isaac enviará os e-mails, e as condições estarão disponíveis no meu isaac para os responsáveis participantes da campanha."
          title="Deseja iniciar a campanha?"
          actionButton={
            <Button variant="solid" onClick={handleDialogSubmit}>
              Sim
            </Button>
          }
          cancelButton={
            <Button
              variant="ghost"
              onClick={() => {
                closeDialog()
                sendButtonClickEvent('cancelar_criacao')
              }}
            >
              Cancelar
            </Button>
          }
        />
      </DialogPrimitives.Root>
    </Styled.Wrapper>
  )
}
