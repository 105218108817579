import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import {
  CloseCampaignRequest,
  CreateCampaignRequest,
  GetCampaignsRequest,
  GetCampaignsResponse,
  GetCampaignStudentRequest,
  GetCampaignStudentResponse,
  HasCampaignEnrollmentRequest,
  HasCampaignEnrollmentResponse,
} from '@/escolas/services/enrollment/types'
import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'

const getCampaignsKey = 'get-campaigns'

type UseCreateCampaignMutationParams = CreateCampaignRequest & {
  schoolId: uuid
}
export const useCreateCampaignMutation = () => {
  const { api } = useApi()

  return useMutation(({ schoolId, ...params }: UseCreateCampaignMutationParams) => {
    return api.enrollment.createCampaign(schoolId, params)
  })
}

type UseCloseCampaignMutationParams = CloseCampaignRequest & {
  campaignId: uuid
}
export const useCloseCampaignMutation = () => {
  const { api } = useApi()

  return useMutation(
    ({ campaignId, ...params }: UseCloseCampaignMutationParams) => {
      return api.enrollment.closeCampaign(campaignId, params)
    },
    { onSuccess: () => queryClient.invalidateQueries([getCampaignsKey]) }
  )
}

export const useFetchCampaigns = (
  params: GetCampaignsRequest,
  options?: UseQueryOptions<GetCampaignsResponse>
) => {
  const { api } = useApi()

  return useQuery<GetCampaignsResponse>(
    [getCampaignsKey, params],
    () => api.enrollment.fetchCampaigns(params),
    options
  )
}

export const useFetchStudentHasCampaign = (
  params: HasCampaignEnrollmentRequest,
  options?: UseQueryOptions<HasCampaignEnrollmentResponse['data']>
) => {
  const { api } = useApi()

  return useQuery<HasCampaignEnrollmentResponse['data']>(
    ['has-campaign-enrollment', params],
    () => api.enrollment.hasCampaignEnrollment(params),
    options
  )
}

export const useFetchCampaignStudents = (
  params: GetCampaignStudentRequest,
  options?: UseQueryOptions<GetCampaignStudentResponse>
) => {
  const { api } = useApi()

  return useQuery<GetCampaignStudentResponse>(
    ['fetch-campaign-students', params],
    () => api.enrollment.fetchCampaignStudents(params),
    options
  )
}
