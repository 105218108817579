import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { dateToOnlyDateISO, isDayBeforeToday, isDayToday } from 'src/shared/utils'
import { paramsDict } from 'src/escolas/components/contract/utils'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

type ManualLiquidationEventPayload = {
  component?: string
  contract_id: string
  name: string
  receivable_id?: string
  selected_date?: string
  status?: string
}

export const useSendManualLiquidationEvent = () => {
  const { schoolSlug } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  if (!isInitialized) return null

  return (
    event: EventDispatcherEvents,
    entity: EventDispatcherEntities,
    due_date?: string,
    metadata?: ManualLiquidationEventPayload
  ) => {
    if (due_date) {
      metadata.status =
        isDayBeforeToday(dateToOnlyDateISO(due_date)) && !isDayToday(dateToOnlyDateISO(due_date))
          ? paramsDict.OVERDUE
          : paramsDict.OPEN
    }
    const completeMetadata = {
      ...metadata,
      school_slug: schoolSlug,
    }

    eventDispatcherClient.sendEvent({
      name: event,
      action: 'click',
      scope: EventDispatcherEventScopes.MANUAL_LIQUIDATION_PAGE,
      customProperties: completeMetadata,
      entity,
    })
  }
}
