import styled from 'styled-components'
import { colors, spacing } from '@gravity/tokens'

export const PageContainer = styled.div`
  padding: ${spacing[12]} 0;
`

export const ActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.primitiveTokens.spacing[2]};
  margin-bottom: ${({ theme }) => theme.primitiveTokens.spacing[6]};
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: ${({ theme }) => theme.primitiveTokens.spacing[6]};
`

export const ActionNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[2]};
  margin-bottom: ${spacing[2]};
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: ${spacing[4]};
`
export const ComingSoonTextWrapper = styled.div`
  margin-top: ${spacing[20]};
  h3 {
    color: ${colors['colors-text-main-3']} !important;
  }
`
export const BigNumbersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[6]};
  margin-top: ${spacing[4]};
`
export const BigNumberSkeleton = styled.div`
  flex: 1;
  & > :first-child {
    width: 25%;
    margin-bottom: ${spacing[2]};
  }
  & > :nth-child(2) {
    width: 50%;
  }
`
export const PageContainerSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[20]};
`

export const HeaderSkeleton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${spacing[20]};
  width: 100%;
`
export const InfoHeaderSkeleton = styled.div`
  display: flex;
  gap: ${spacing[2]};
  flex-direction: column;
  width: 100%;

  & > :nth-child(3) {
    width: 50%;
  }
`
export const ActionButtonsSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
