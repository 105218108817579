import styled from 'styled-components'
import { Table } from '@gravity/table'
import { spacing } from '@gravity/tokens'

export const Wrapper = styled.div`
  overflow-x: auto;
  overflow-y: visible;

  margin-bottom: ${spacing[4]};
`

export const TableRow = styled(Table.Row)`
  :hover {
    cursor: pointer;
  }
`
