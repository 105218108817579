import { Box, Grid } from '@material-ui/core'
import {
  ActionDrawerHeader,
  ActionDrawerHeaderProps,
  Button,
  ButtonDocker,
  Drawer,
  DrawerProps,
  Sticky,
} from '@olaisaac/design-system'
import { memo, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import type { Props as ProductFormProps } from './components/ProductForm'
import ProductForm from './components/ProductForm'
import { useSnackbar } from '@/shared/hooks'

type Props = Pick<DrawerProps, 'open'> &
  ProductFormProps & {
    onClose: (
      event?: Parameters<ActionDrawerHeaderProps['onClose']>[0]
    ) => ReturnType<ActionDrawerHeaderProps['onClose']>
  }

const TITLE = {
  create: 'Criar produto',
  edit: 'Editar produto',
}

const SUBTITLE = {
  create: 'Produtos são os serviços que a sua escola oferece.',
  edit: 'As mudanças feitas só serão aplicadas a novos contratos.',
}

const SNACKBAR = {
  create: {
    title: '',
    description: 'Produto salvo com sucesso!',
  },
  edit: {
    title: 'Produto editado com sucesso!',
    description: 'As alterações não afetarão contratos previamente atrelados ao produto.',
  },
}

const GridWhiteBackground = styled(Grid)`
  background: #fff;
`

export const CreateOrEditProductDrawer = memo(({ open, onClose, ...props }: Props) => {
  const form = useRef<HTMLFormElement>()
  const { setIsOpen, setMessage } = useSnackbar()

  const isEditing = useMemo(() => Boolean(props?.product), [props.product])

  const [title, subtitle] = useMemo(() => {
    const key = isEditing ? 'edit' : 'create'
    return [TITLE[key], SUBTITLE[key]]
  }, [isEditing])

  const handleSubmit = useCallback(() => {
    if (form.current) {
      form.current.requestSubmit()
    }
  }, [form.current])

  const handleSuccess = useCallback(() => {
    onClose()

    const method = isEditing ? 'edit' : 'create'

    setIsOpen(true, { title: SNACKBAR[method].title })
    setMessage(SNACKBAR[method].description)
  }, [onClose, setIsOpen, setMessage, isEditing])

  const handleError = useCallback(() => {
    setIsOpen(true, { variation: 'error', title: 'Não foi possível salvar o produto.' })
    setMessage('Por favor, tente novamente ou entre em contato com seu gerente no isaac.')
  }, [setIsOpen, setMessage])

  return (
    <Drawer open={open}>
      <ActionDrawerHeader onClose={onClose} title={title} subtitle={subtitle} />

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ flex: 1 }}
      >
        <Box px={3} py={3}>
          {open && (
            <ProductForm
              {...props}
              onApiError={handleError}
              showEnvelopField={Boolean(props.product)}
              ref={form}
              onSuccess={handleSuccess}
            />
          )}
        </Box>
      </Grid>
      <Sticky position="bottom">
        <GridWhiteBackground item>
          <ButtonDocker>
            <Button fullWidth onClick={handleSubmit}>
              Salvar produto
            </Button>
          </ButtonDocker>
        </GridWhiteBackground>
      </Sticky>
    </Drawer>
  )
})
