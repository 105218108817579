import { ReceivableType } from '@/modules/guardians/GuardianDetails/constants'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { SimulationPaymentMethod } from '@/shared/interfaces'

import { useAgreement } from 'src/escolas/contexts/agreementContext'

export const usePaymentCreditCard = () => {
  const { checkoutCart } = useCheckoutCart()
  const {
    positionAgreement,
    agreementSimulations,
    agreementStatus,
    selectedSimulationPaymentMethod,
    totalCreditCardFee,
  } = useAgreement()

  const openAgreements = agreementSimulations?.filter(
    agreement => !agreement.agreement_simulation_id
  )

  const openAgreement = openAgreements?.[positionAgreement]
  const agreement = agreementSimulations?.[positionAgreement]

  const isReceivableTypePermitted = checkoutCart?.some(
    ({ receivable_type }) => receivable_type !== ReceivableType.ENROLLMENT
  )

  const isCreditCardPaymentEnabled = useUnleashFlag(
    UnleashFlags.REG_2105_ENABLE_CREDIT_CARD_PAYMENT
  )

  const isCreditCardFlow =
    isCreditCardPaymentEnabled &&
    totalCreditCardFee > 0 &&
    (agreementStatus?.[positionAgreement]?.invoices?.[0]?.payment_method ===
      SimulationPaymentMethod.CREDIT_CARD ||
      selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD)

  return {
    isCreditCardFlow,
    paymentFee: {
      value: totalCreditCardFee,
      percentage:
        openAgreement?.credit_card_fee_percentage ?? agreement?.credit_card_fee_percentage ?? 0,
      enabled: isCreditCardPaymentEnabled && isReceivableTypePermitted,
    },
  }
}
