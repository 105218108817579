import type { AgreementSimulationCombined } from 'src/shared/interfaces'
import type { CheckoutCombinableReceivable } from 'src/escolas/contexts/checkoutContext'
import { isDayBeforeToday } from 'src/shared/utils'

import getReceivableMonth from './getReceivableMonth'
import { Product, ProductsByStudents } from '../../../types'

function getConsolidatedMonthNames(receivables: CheckoutCombinableReceivable[]) {
  return receivables.reduce((acc, curr, index) => {
    const monthName = getReceivableMonth(curr.due_date, curr.competenceDate)
    const separator = receivables.length > 1 && index === receivables.length - 1 ? ' e' : ','
    return acc ? `${acc}${separator} ${monthName}` : monthName
  }, '')
}

export function adaptAgreementsToProductsByStudent(
  agreements: AgreementSimulationCombined[]
): ProductsByStudents[] {
  const studentsMap = agreements.reduce((acc, curr) => {
    const mapKey = curr.receivables[0]?.student_id
    if (mapKey && !acc.has(mapKey)) {
      acc.set(mapKey, {
        studentId: curr.receivables[0].student_id,
        studentName: curr.receivables[0].student,
        isOverdue: isDayBeforeToday(curr.receivables[0].due_date),
        products: new Array<Product>(),
      })
    }
    return acc
  }, new Map<string, ProductsByStudents>())

  agreements.forEach(agreement => {
    const mapKey = agreement.receivables[0]?.student_id
    if (!mapKey || !studentsMap.has(mapKey)) return

    const monthNames = getConsolidatedMonthNames(agreement.receivables)
    const consolidatedMonthNames =
      agreement.type === 'down_payment' ? `Acordo ${monthNames} - Entrada` : monthNames

    const product: Product = {
      finalAmount: agreement.total_amount,
      finesAndInterest: agreement.fine_applied + agreement.interest_applied,
      baseAmount: agreement.original_amount,
      discounts:
        agreement.due_payment_discount_applied +
        agreement.early_payment_discount_applied +
        agreement.perpetual_discount_applied,
      productId: agreement.receivables[0].product_id,
      productName: agreement.receivables[0].product,
      consolidatedMonthNames: consolidatedMonthNames,
    }

    studentsMap.get(mapKey).products.push(product)
  })

  return Array.from(studentsMap.values())
}
