import styled from 'styled-components'
import { spacing } from '@gravity/tokens'
export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`

export const TableWrapper = styled.div`
  margin-top: ${spacing[8]};
`
