import { useState, createContext } from 'react'
import api from '@/shared/api'
import { ApiClient } from '@/modules/core/api'

type ApiContext = {
  api: ReturnType<typeof api>
  apiClient: ApiClient
  correlationId: string
  setCorrelationId: (correlationId: string) => void
}

export const ApiContext = createContext<ApiContext>(null)
const apiClient = new ApiClient()

export const ApiProvider = ({ children }) => {
  const [correlationId, setCorrelationId] = useState('')
  return (
    <ApiContext.Provider
      value={{
        correlationId,
        setCorrelationId,
        api: api(apiClient, setCorrelationId),
        apiClient: apiClient,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
