import { ChatBubble, CheckCircle, LibraryBooks, Today } from '@material-ui/icons'
import { Drawer, ActionDrawerHeader } from '@olaisaac/design-system'

import {
  ContactInfo,
  DrawerContent,
  InfoItem,
  Link,
} from '@/modules/report/pages/EnrollmentPayoutReport/components/EnrollmentPayoutReportOnboardingDrawer/styles'

type TuitionPayoutReportOnboardingDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

/**
 * Onboarding drawer for tuition payout report page
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const TuitionPayoutReportOnboardingDrawer = ({
  isOpen,
  onClose,
}: TuitionPayoutReportOnboardingDrawerProps) => {
  const handleNavigateToFaq = () => {
    window.open('https://centraldeajuda.olaisaac.io/repasse', '_blank') // TODO: Move URL to env var
  }

  return (
    <Drawer open={isOpen} appearance="secondary">
      <ActionDrawerHeader
        title="Entenda o repasse de mensalidades"
        appearance="secondary"
        onClose={onClose}
      />

      <DrawerContent>
        <InfoItem>
          <Today />
          <h2>Fechamento mensal</h2>
          <p>
            <strong>Mensalmente, a partir do dia 05*</strong>, o isaac vai repassar os valores
            referentes às mensalidades do mês vigente.
          </p>
          <p>
            *Pagamentos de acordo com o seu contrato com o isaac. Em caso de feriado, o repasse será
            feito no dia útil seguinte.
          </p>
        </InfoItem>

        <InfoItem>
          <CheckCircle />
          <h2>Valores garantidos</h2>
          <p>
            O isaac garante a transferência dos valores de todas as mensalidades, independente do
            pagamento das parcelas pelas famílias.
          </p>
        </InfoItem>

        <InfoItem data-testid="glossary-section">
          <LibraryBooks />
          <h2>Glossário do repasse</h2>
          <p>
            Não entendeu algum termo ou conceito do seu repasse? Não se preocupe,{' '}
            <Link onClick={handleNavigateToFaq}>acesse o glossário do repasse</Link> e veja as
            definições!
          </p>
        </InfoItem>

        <ContactInfo>
          <ChatBubble />
          <h2>Ainda tem dúvidas?</h2>
          <p className="label">Entre em contato com o Apoio isaac</p>
          <p className="contact">WhatsApp: (11) 97876-5797</p>
          <p className="contact">falecom@isaac.com.br</p>
        </ContactInfo>
      </DrawerContent>
    </Drawer>
  )
}
