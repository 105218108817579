import { ReenrollmentStatus } from '@/shared/interfaces'
import { Chip, ChipProps } from '@olaisaac/design-system'

export type Props = {
  disabled?: boolean
  status: string
}
export const statusParamsDict: PartialRecord<
  ReenrollmentStatus,
  { color: ChipProps['variation']; text: string }
> = {
  [ReenrollmentStatus.NOT_REENROLLABLE]: { color: 'coral', text: 'Não rematriculável' },
  [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: { color: 'blue', text: 'Rematrícula disponível' },
  [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: { color: 'yellow', text: 'Matrícula em andamento' },
  [ReenrollmentStatus.ENROLLMENT_AT_RISK]: { color: 'darkCoral', text: 'Matrícula em risco' },
  [ReenrollmentStatus.ENROLLED]: { color: 'green', text: 'Matriculado' },
  [ReenrollmentStatus.GATE_ERROR]: { color: 'gray', text: 'Indisponível no momento' },
  [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: { color: 'gray', text: 'Indisponível no momento' },
}

// Type guard function to check if a string is a key of ReenrollmentStatus
const isReenrollmentStatus = (status: string): status is ReenrollmentStatus =>
  Object.values(ReenrollmentStatus).includes(status as ReenrollmentStatus)

export const StatusBadge = ({ status, disabled }: Props) => {
  if (!isReenrollmentStatus(status) || !statusParamsDict[status]) {
    return null
  }

  return (
    <Chip
      variation={statusParamsDict[status]?.color}
      label={statusParamsDict[status]?.text}
      style={disabled ? { opacity: 0.5 } : {}}
    />
  )
}
