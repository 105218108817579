import { useState } from 'react'
import {
  SideMenu as DsSideMenu,
  SideMenuItem,
  SideMenuLink,
  SideMenuList,
  SideMenuSearchResultLi,
} from '@olaisaac/design-system'
import {
  Home as HomeIcon,
  SearchRounded,
  ViewListRounded,
  BarChartRounded,
  People,
  AssignmentTurnedIn,
  MonetizationOn,
  School as SchoolIcon,
  FolderShared,
  Extension as IrregularitiesIcon,
} from '@material-ui/icons'

import Icon from '@mdi/react'
import { mdiHandCoin, mdiWidgets } from '@mdi/js'

import MuiTypography from '@material-ui/core/Typography'
import { useRouteMatch, Link as RRDLink, useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import styled from 'styled-components'

import ConfirmationDialog from '@/escolas/components/modal/ConfirmationDialog'

import { useJWT } from '@/shared/hooks'
import { useNavigation, useSchools } from '@/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useOnboardingRedirect } from '@/escolas/hooks/useOnboardingRedirect'

import { School } from '@/shared/interfaces/school'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import envConfig from '@/config'
import {
  ENROLLMENT_PAGE_BASE_PATH,
  ENROLLMENT_YEAR,
} from '@/modules/enrollment/constants/enrollment'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useCreditAccessStore } from '@/modules/credit/creditAccessStore'

const Link = styled(RRDLink)({
  textDecoration: 'none',
})

const BottomLinks = styled.div`
  margin-top: auto;
`

const BUTTON_LABELS = {
  REPORT: 'Relatórios',
  CREDIT: 'Menu de crédito',
}

export const SchoolSideMenu = () => {
  const { push } = useHistory()
  const { isAdmin, isSchool, schoolIds, hasPayoutReportAccess } = useJWT()
  const { getNavigationUrl, schoolSlug } = useNavigation()

  const { hasCreditAccess } = useCreditAccessStore()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [showConfirmReonboardingMessage, setShowConfirmReonboardingMessage] = useState(false)

  const { schools, hasFetched } = useSchools()

  const { DEFAULT_CONTRACT_YEAR, SELF_ONBOARDING_REDIRECT_URL } = envConfig
  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)

  const { data: onboardingRedirect } = useOnboardingRedirect()
  const enableSelfOnboarding = onboardingRedirect?.is_school_active
  const schoolPageSelfOnboardingUrl = `${SELF_ONBOARDING_REDIRECT_URL}/escolas/${onboardingRedirect?.self_onboarding_school_id}`

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const openMessageSelfOnboarding = () => {
    setShowConfirmReonboardingMessage(true)
  }

  const handleGoToSelfOnboarding = () => {
    schoolPageSelfOnboardingUrl && window.location.replace(schoolPageSelfOnboardingUrl)
  }

  function escapeStrSpecialChars(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const handleGetOptions = async (inputValue: string) => {
    const escapedInputValue = escapeStrSpecialChars(inputValue)
    // The next code line gives this error: security/detect-non-literal-regexp
    // But, this is not an request, is just runs on a array of Schools already stored
    // So there is no problem running it
    // eslint-disable-next-line security/detect-non-literal-regexp
    return schools.filter(school => school.name.match(new RegExp(escapedInputValue, 'i')))
  }

  const homePath = `/${schoolSlug}/`
  const managementDahsboardPath = `/${schoolSlug}/painel-de-gestao`
  const transfersPath = `/${schoolSlug}/repasses`
  const contractsPathBase = `/contratos/${DEFAULT_CONTRACT_YEAR}`
  const contractsPath = getNavigationUrl({ path: contractsPathBase })
  const guardiansPath = getNavigationUrl({ path: '/responsaveis' })
  const productsPath = getNavigationUrl({ path: '/produtos' })
  const creditPath = getNavigationUrl({ path: '/credito' })
  const reportsPath = getNavigationUrl({ path: '/relatorios' })
  const enrollmentPath = `/${schoolSlug}/${ENROLLMENT_PAGE_BASE_PATH}`
  const irregularitiesPath = `/${schoolSlug}/irregularidades`

  const hasMultipleSchools = schoolIds?.length > 1
  const showSchoolSelection = isAdmin || (isSchool && hasMultipleSchools)

  const handleChangeSchool = (schoolSlug: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SIDE_MENU,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $form_action: 'Troca de escola',
          $selected_school_slug: schoolSlug,
        },
      })

    push(`/${schoolSlug}`)
  }

  const handleCreditButton = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CREDIT,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: { $button_name: BUTTON_LABELS.CREDIT },
      })
  }

  return (
    <>
      <DsSideMenu
        menuList={(setSearchVisibility: (visible: boolean) => void) => (
          <SideMenuList>
            {showSchoolSelection && hasFetched && (
              <SideMenuItem>
                <SideMenuLink
                  onClick={() => setSearchVisibility(true)}
                  icon={<SearchRounded />}
                  label="Pesquisar escolas"
                  wrapperAs="span"
                />
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link to={`/${schoolSlug}`}>
                <SideMenuLink
                  icon={<HomeIcon />}
                  label="Página Inicial"
                  active={Boolean(useRouteMatch({ path: homePath, exact: true }))}
                  wrapperAs="span"
                />
              </Link>
            </SideMenuItem>

            {hasPayoutReportAccess && (
              <SideMenuItem>
                <Link to={managementDahsboardPath}>
                  <SideMenuLink
                    icon={<Icon className="management-icon" path={mdiWidgets} size={1} />}
                    label="Painel de gestão"
                    active={Boolean(useRouteMatch({ path: managementDahsboardPath, exact: true }))}
                    wrapperAs="span"
                    onClick={() => {
                      isInitialized &&
                        eventDispatcherClient.sendEvent({
                          scope: EventDispatcherEventScopes.SIDE_MENU,
                          name: EventDispatcherEvents.BUTTON_CLICK,
                          action: 'click',
                          customProperties: {
                            $Button_name: 'Painel de gestão',
                          },
                        })
                    }}
                  />
                </Link>
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link to={enrollmentPath}>
                <SideMenuLink
                  icon={<SchoolIcon />}
                  label={isSelectCyclesEnabled ? 'Matrículas' : `Matrículas ${ENROLLMENT_YEAR}`}
                  active={Boolean(useRouteMatch({ path: enrollmentPath }))}
                  wrapperAs="span"
                  onClick={() =>
                    isInitialized &&
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.SIDE_MENU,
                      name: EnrollmentEventDispatcherEvents.CLICKED,
                      action: 'click',
                      customProperties: {
                        $Button_name: 'MATRÍCULAS_2024_SIDE_MENU',
                        $Menu_name: 'MENU_LATERAL',
                      },
                    })
                  }
                />
              </Link>
            </SideMenuItem>

            {!isSelectCyclesEnabled && (
              <SideMenuItem>
                <Link to={contractsPath}>
                  <SideMenuLink
                    icon={<FolderShared />}
                    label="Contratos"
                    active={Boolean(useRouteMatch({ path: contractsPath }))}
                    wrapperAs="span"
                  />
                </Link>
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link to={guardiansPath} data-testid="guardiansListButton">
                <SideMenuLink
                  icon={<People />}
                  label="Responsáveis"
                  active={Boolean(useRouteMatch({ path: guardiansPath }))}
                  wrapperAs="span"
                  onClick={() => {
                    isInitialized &&
                      eventDispatcherClient.sendEvent({
                        scope: EventDispatcherEventScopes.SIDE_MENU,
                        name: EventDispatcherEvents.ACCESS_GUARDIANS_LIST,
                        action: 'click',
                      })
                  }}
                />
              </Link>
            </SideMenuItem>

            {isIntegratedSchool && (
              <SideMenuItem>
                <Link to={irregularitiesPath}>
                  <SideMenuLink
                    icon={<IrregularitiesIcon className="integration-icon" />}
                    label="Integrações"
                    active={Boolean(useRouteMatch({ path: irregularitiesPath }))}
                    wrapperAs="span"
                  />
                </Link>
              </SideMenuItem>
            )}

            {hasPayoutReportAccess && (
              <SideMenuItem>
                <Link to={transfersPath}>
                  <SideMenuLink
                    onClick={() => {
                      isInitialized &&
                        eventDispatcherClient.sendEvent({
                          scope: EventDispatcherEventScopes.SIDE_MENU,
                          name: EventDispatcherEvents.NAVIGATION_TO_REPORT_DASHBOARD,
                          action: 'click',
                        })
                    }}
                    icon={<MonetizationOn />}
                    label="Repasses"
                    active={Boolean(useRouteMatch({ path: transfersPath, exact: true }))}
                    wrapperAs="span"
                  />
                </Link>
              </SideMenuItem>
            )}
            {hasCreditAccess && hasPayoutReportAccess && (
              <SideMenuItem>
                <Link to={creditPath} onClick={handleCreditButton}>
                  <SideMenuLink
                    icon={<Icon className="credit-icon" path={mdiHandCoin} size={1} />}
                    label="Crédito"
                    active={Boolean(useRouteMatch({ path: creditPath, exact: true }))}
                    wrapperAs="span"
                  />
                </Link>
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link
                to={reportsPath}
                onClick={() => {
                  isInitialized &&
                    eventDispatcherClient.sendEvent({
                      name: EventDispatcherEvents.BUTTON_CLICK,
                      scope: EventDispatcherEventScopes.SIDE_MENU,
                      customProperties: {
                        $button_label: BUTTON_LABELS.REPORT,
                      },
                      action: 'click',
                    })
                }}
              >
                <SideMenuLink
                  icon={<BarChartRounded />}
                  label="Relatórios"
                  active={Boolean(useRouteMatch({ path: reportsPath }))}
                  wrapperAs="span"
                />
              </Link>
            </SideMenuItem>

            <SideMenuItem>
              <Link to={productsPath}>
                <SideMenuLink
                  icon={<ViewListRounded />}
                  label="Produtos"
                  active={Boolean(useRouteMatch({ path: productsPath }))}
                  wrapperAs="span"
                />
              </Link>
            </SideMenuItem>

            <BottomLinks>
              {enableSelfOnboarding && (
                <SideMenuItem>
                  <SideMenuLink
                    icon={<AssignmentTurnedIn />}
                    label="Ferramenta de migração"
                    wrapperAs="span"
                    onClick={openMessageSelfOnboarding}
                  />
                </SideMenuItem>
              )}
            </BottomLinks>
          </SideMenuList>
        )}
        options={schools}
        getOptions={handleGetOptions}
        renderOptions={(schools: School[], inputValue: string) => {
          const escapedInputValue = escapeStrSpecialChars(inputValue)
          return schools
            .filter(school => school.name.match(new RegExp(escapedInputValue, 'i'))) // eslint-disable-line security/detect-non-literal-regexp
            .map(school => (
              <SideMenuSearchResultLi
                key={school.id}
                onClick={() => handleChangeSchool(school.slug)}
              >
                {school.name}
              </SideMenuSearchResultLi>
            ))
        }}
      />

      <ConfirmationDialog
        maxWidth="md"
        isVisible={showConfirmReonboardingMessage}
        onClose={() => setShowConfirmReonboardingMessage(false)}
        submitHandler={handleGoToSelfOnboarding}
        backButtonLabel="Continuar na plataforma"
        buttonLabel="Fazer migração de contratos"
        title="Gostaria de migrar mais contratos para a plataforma isaac?"
      >
        <MuiTypography>
          É possível fazer o processo de migração de várias planilhas mais uma vez através da
          ferramenta de migração. Você pode acessar a ferramenta no menu lateral.
        </MuiTypography>
      </ConfirmationDialog>
    </>
  )
}
