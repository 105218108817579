import { AxiosInstance } from 'axios'
import qs from 'qs'

import { GetContractParams, ListDebtsFilters } from 'src/shared/api'

import {
  APIResponse,
  Contract,
  ContractCancellationReason,
  ContractNeverPaid,
  ContractNeverPaidDetail,
  ContractNeverPaidInstallment,
  ContractResponse,
  Pagination,
  PreContractStatuses,
} from 'src/shared/interfaces'
import {
  FetchContractsByGuardiansRequestDTO,
  FetchContractsByGuardiansResponseDTO,
  FetchContractsByStudentRequestDTO,
  FetchContractsRequestDTO,
  FetchContractsByStudentResponseDTO,
} from './types'

import { paramsToQueryString } from 'src/shared/utils'
import { MATRICULAS_API_FIRST_YEAR } from '@/escolas/components/contract/constants'

export const contractsService = (fetcher: AxiosInstance) => {
  return {
    get: async (id: uuid, getParams?: GetContractParams) => {
      const { data } = await fetcher.get<APIResponse<Contract>>(
        `/contract/${id}?${paramsToQueryString(getParams)}`
      )

      return data.data
    },
    getList2021: async (p?: Pagination & ListDebtsFilters) => {
      const { data } = await fetcher.get<
        APIResponse<(ContractResponse & { contract_id?: string })[]>
      >(`/contract/2021/?${paramsToQueryString(p)}`)

      return data
    },

    /**
     * Function to fetch contracts by students view
     *
     * @param data.schoolId School ID
     * @param data.referenceYear Contract reference year
     * @param data.filter Object containing filter
     * @param data.filter.name Filter by student name
     * @param data.filter.contractStatus Filter by contract status
     * @param data.filter.debtStatus Filter by debt status
     * @param data.filter.isReenrollable Filter by contract is enrollable
     * @param data.filter.productId Filter by product IDs
     * @param data.filter.signPaymentStatus Filter by enrollment payment status
     * @param data.filter.nextYearSignPaymentStatus Filter by next year contract enrollment payment status
     * @param data.pagination Object containing pagination attributes
     *
     * @returns Promise with array of contracts
     */
    fetchByStudent: async ({
      schoolId,
      referenceYear,
      filter: {
        name = '',
        contractStatus = [],
        debtStatus = [],
        isReenrollable = [],
        productId = [],
        signPaymentStatus = [],
        studentId = '',
        nextYearSignPaymentStatus = [],
      },
      pagination,
    }: FetchContractsByStudentRequestDTO): Promise<FetchContractsByStudentResponseDTO> => {
      const fetchContracts = async ({
        schoolId,
        referenceYear,
        filterId = '',
        fixedFilters,
        pagination,
      }: FetchContractsRequestDTO) => {
        const formattedFixedFilters = {
          ...(!!name && { name }),
          ...(!!studentId && { student_id: studentId }),
          status: fixedFilters.contractStatus,
          debt_status: fixedFilters.debtStatus,
          is_reenrollable: fixedFilters.isReenrollable,
          sign_payment_status: fixedFilters.signPaymentStatus,
          next_year_sign_payment_status: fixedFilters.nextYearSignPaymentStatus,
        }

        const { data } = await fetcher.get<FetchContractsByStudentResponseDTO>('contract', {
          params: {
            school_id: schoolId,
            ...(referenceYear && { reference_year: referenceYear }),
            ...(filterId && { filter_id: filterId }),
            ...formattedFixedFilters,
            ...pagination,
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        })

        return data
      }

      if (productId.length > 0) {
        const {
          data: {
            data: { filter_id },
          },
        } = await fetcher.post<APIResponse<{ filter_id: string }>>(
          'contract/store-filters',
          { product_id: productId },
          { params: { school_id: schoolId } }
        )

        const data = await fetchContracts({
          schoolId,
          referenceYear,
          filterId: filter_id,
          fixedFilters: {
            name,
            contractStatus,
            debtStatus,
            isReenrollable,
            signPaymentStatus,
            studentId,
            nextYearSignPaymentStatus,
          },
          pagination,
        })

        return data
      }

      const data = await fetchContracts({
        schoolId,
        referenceYear,
        fixedFilters: {
          name,
          contractStatus,
          debtStatus,
          isReenrollable,
          signPaymentStatus,
          studentId,
          nextYearSignPaymentStatus,
        },
        pagination,
      })

      return data
    },

    /**
     * Function to fetch contracts by guardians view
     *
     * @param data.schoolId School ID
     * @param data.referenceYear Contract reference year
     * @param data.filter Object containing filter
     * @param data.filter.name Filter by guardian name
     * @param data.filter.taxId Filter by guardian CPF number
     * @param data.filter.status Filter by contract status
     * @param data.pagination Object containing pagination attributes
     *
     * @returns Promise with array of contracts
     */
    fetchByGuardian: async ({
      schoolId,
      referenceYear,
      filter: { name = '', taxId = '', contractStatus = null },
      pagination,
    }: FetchContractsByGuardiansRequestDTO) => {
      const filter = {
        ...(!!name && { name }),
        ...(!!taxId && { tax_id: taxId }),
        ...(!!contractStatus && { status: contractStatus }),
      }

      const { data } = await fetcher.get<FetchContractsByGuardiansResponseDTO>(
        'contract/guardian',
        {
          params: {
            school_id: schoolId,
            reference_year: referenceYear,
            ...filter,
            ...pagination,
          },
        }
      )

      return data
    },

    fetchNeverPaid: async (schoolId: uuid, referenceYear: string, installmentType, pagination) => {
      const { data } = await fetcher.get<APIResponse<ContractNeverPaid>>(
        `/contract/never-paid/${schoolId}`,
        {
          params: {
            school_id: schoolId,
            reference_year: referenceYear,
            installment_type: installmentType,
            ...pagination,
          },
        }
      )

      return data
    },

    fetchNeverPaidDetail: async (schoolId: uuid, referenceYear: string, uniqueKey: string) => {
      const { data } = await fetcher.get<APIResponse<ContractNeverPaidDetail>>(
        `/contract/never-paid/${schoolId}/${uniqueKey}`,
        {
          params: {
            reference_year: referenceYear,
          },
        }
      )

      return data
    },

    confirmNeverPaid: async (schoolId: uuid, uniqueKeys: string[]) => {
      const { data } = await fetcher.patch<ContractNeverPaidInstallment[]>(
        `/installment/confirm-never-paid/unique-key`,
        {
          unique_keys: uniqueKeys,
        },
        {
          params: {
            school_id: schoolId,
            triggered_by: 'NEVER_PAID',
          },
        }
      )

      return data
    },

    confirmNeverPaidBulk: async (schoolId: uuid, installmentIds: string[]) => {
      const { data } = await fetcher.patch<ContractNeverPaidInstallment[]>(
        `/installment/confirm-never-paid/bulk`,
        {
          installment_ids: installmentIds,
        },
        {
          params: {
            school_id: schoolId,
            triggered_by: 'NEVER_PAID',
          },
        }
      )

      return data
    },

    revoke: async (
      id: uuid,
      params: {
        cancellation_description: string
        cancellation_reason: ContractCancellationReason
        installment_id: uuid
        triggered_by?: string
      },
      schoolID: uuid,
      contract: Contract
    ) => {
      const cancelAtMatriculas =
        Number(contract?.reference_year) >= MATRICULAS_API_FIRST_YEAR &&
        contract.pre_contract_status !== PreContractStatuses.EFFECTED_PCS

      if (cancelAtMatriculas) {
        return (
          await fetcher.patch<APIResponse<void>>(`/enrollment/${schoolID}/${id}/cancel`, params)
        ).data.data
      }

      return (
        await fetcher.patch<APIResponse<void>>(
          `/contract/${id}/revoke?school_id=${schoolID}`,
          params
        )
      ).data.data
    },
  }
}
