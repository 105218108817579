import { useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { StyledBox, StyledContainer } from './styles'
import SchoolEnrollmentCycleTransitionImage from '@/modules/enrollment/assets/school-enrollment-cycle-transition.svg'

type SchoolEnrollmentCycleTransitionProps = {
  onComplete?: () => void
}

const INTERVAL_DELAY_MS = 600
const MAX_PROGRESS = 100
const ADD_PROGRESS = 23

export const SchoolEnrollmentCycleTransition = ({
  onComplete,
}: SchoolEnrollmentCycleTransitionProps) => {
  const [progress, setProgress] = useState(10)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(state => {
        if (state === MAX_PROGRESS) {
          clearInterval(timer)
          onComplete?.()

          return MAX_PROGRESS
        }

        const newProgress = state + ADD_PROGRESS

        return newProgress > MAX_PROGRESS ? MAX_PROGRESS : newProgress
      })
    }, INTERVAL_DELAY_MS)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <StyledContainer>
      <img src={SchoolEnrollmentCycleTransitionImage} alt="Trocando de ano letivo" />
      <br />
      <Typography variation="headlineDesktopSmall">Trocando de ano letivo</Typography>
      <Typography variation="caption">
        Evite erros: antes de fazer qualquer ação, verifique em qual ano letivo você está
      </Typography>
      <StyledBox>
        <LinearProgress variant="determinate" value={progress} />
      </StyledBox>
    </StyledContainer>
  )
}
