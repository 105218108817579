import { v4 as uuidv4 } from 'uuid'
import { Text } from '@gravity/text'
import { Link } from '@gravity/link'
import { Heading } from '@gravity/heading'

import { BigNumber } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/components/BigNumber'
import { CURRENT_MONTH_TEXT } from '@/escolas/pages/Home/constants'
import * as Styled from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/styles'
import noPending from '@/modules/guardians/assets/noPending.svg'
import { GuardianDetailsTabs } from '@/modules/guardians/GuardianDetails/constants'
import { InstallmentsDrawerContainerV2 } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { GuardianDetailsFooter } from '@/modules/guardians/GuardianDetails/features/GuardianDetailsFooter/GuardianDetailsFooter'

import { useGuardianDetailsFinancialSummaryTab } from './hooks/useGuardianDetailsFinancialSummaryTab'
import { GuardianFinancialSummaryTable } from './components/GuardianFinancialSummaryTable'

type GuardianDetailsFinancialSummaryTabProps = {
  changeActiveTab: (tab: GuardianDetailsTabs) => void
}
export const GuardianDetailsFinancialSummaryTab = ({
  changeActiveTab,
}: GuardianDetailsFinancialSummaryTabProps) => {
  const {
    guardianSummary,
    installments,
    isLoadingGuardianSummaryData,
    isSuccessGuardianSummaryData,
    isErrorGuardianSummaryData,
    isEmpty,
    handleRowClick,
    selectedInstallment,
    guardianId,
    filtersKey,
    showInstallmentsDetailsDrawer,
    handleCloseDrawer,
    hasOnlyPaidInstallments,
  } = useGuardianDetailsFinancialSummaryTab()

  const isLoading = isLoadingGuardianSummaryData || isErrorGuardianSummaryData

  if (isSuccessGuardianSummaryData && isEmpty)
    return (
      <Styled.NoPendingWrapper>
        <Styled.Image src={noPending} alt="sem pendências" />
        <Text variant="body-1-medium">Este responsável não possui pendências</Text>
        <Text>Se novas pendências surgirem, elas ficarão disponíveis aqui.</Text>
        <Link
          onClick={() => changeActiveTab(GuardianDetailsTabs.FATURAS)}
          underline="none"
          weight="semibold"
        >
          {'Ver faturas >'}
        </Link>
      </Styled.NoPendingWrapper>
    )
  return (
    <>
      <Styled.SummaryPageWrapper>
        <Heading variant="heading-h4-medium">{`Pendências de ${CURRENT_MONTH_TEXT}`}</Heading>
        <Styled.BigNumberWrapper>
          {guardianSummary.map(key => (
            <BigNumber
              key={uuidv4()}
              quantity={key?.quantity}
              status={key?.status}
              totalAmount={key?.total_amount}
              isLoading={isLoading}
            />
          ))}
        </Styled.BigNumberWrapper>

        <GuardianFinancialSummaryTable
          installments={installments}
          loading={isLoading}
          onRowClick={handleRowClick}
          key={filtersKey}
          allCheckable={!hasOnlyPaidInstallments}
        />

        {showInstallmentsDetailsDrawer && (
          <InstallmentsDrawerContainerV2
            onClose={handleCloseDrawer}
            guardianId={guardianId}
            selectedInstallment={selectedInstallment}
          />
        )}
      </Styled.SummaryPageWrapper>
      {!isLoading && !hasOnlyPaidInstallments && <GuardianDetailsFooter />}
    </>
  )
}
