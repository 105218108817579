import { Typography } from '@olaisaac/design-system'
import Skeleton from '@material-ui/lab/Skeleton'

import styled from 'styled-components'
import theme from 'src/shared/theme'

import type { SectionContainerProps } from './types'

const { colors, spacing } = theme.primitiveTokens
const { variation, color } = theme.humanizedTokens.components.typography

export const Container = styled.section<SectionContainerProps>`
  margin: ${({ $variation }) =>
    $variation === 'primary' ? `0 -${spacing[16]}` : `${spacing[8]} -${spacing[16]}`};
  padding: 0 ${spacing[16]} ${spacing[8]} ${spacing[16]};
  background: ${({ $variation }) =>
    $variation === 'primary' ? colors.gray[0] : colors.isaacBlue[10]};

  &:not(:first-of-type) {
    padding: ${spacing[8]} ${spacing[16]};
  }
`

export const Title = styled.h2`
  ${variation.subtitleDesktopLarge};
`
export const TitlePlaceholder = styled(Skeleton)`
  ${variation.subtitleDesktopLarge};
  width: 300px;
`

export const Description = styled(Typography)`
  ${variation.bodyLarge};
  ${color.secondary};
  line-height: 1.5;
  margin: ${spacing[2]} 0 ${spacing[8]} 0;
`

export const DescriptionPlaceholder = styled(Skeleton)`
  ${variation.bodyLarge};
  line-height: 1.5;
  margin: ${spacing[2]} 0 ${spacing[4]} 0;
  width: 500px;
`
