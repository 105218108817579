import { useAgreement } from '@/escolas/contexts/agreementContext'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { SimulationPaymentMethod } from '@/shared/interfaces'

export const useNegotiationV2 = () => {
  const {
    selectedSimulationPaymentMethod,
    agreementSimulations,
    agreementStatus,
    positionAgreement,
  } = useAgreement()

  const isNegotiationV2Enabled = useUnleashFlag(UnleashFlags.REG_125_ENABLED_NEGOTIATIONS_V2)

  const isNegotiationDownPayment =
    isNegotiationV2Enabled &&
    [SimulationPaymentMethod.CREDIT_CARD, SimulationPaymentMethod.BANK_SLIP].includes(
      selectedSimulationPaymentMethod
    ) &&
    Boolean(agreementSimulations[positionAgreement]?.agreement_simulation_id)

  const isNegotiationDownPaymentFinished =
    isNegotiationDownPayment && agreementStatus[positionAgreement]?.finished

  return {
    isNegotiationV2Enabled,
    isNegotiationDownPayment,
    isNegotiationDownPaymentFinished,
  }
}
