import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Typography } from '@olaisaac/design-system'
import { propEq } from 'ramda'
import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { Text } from '@gravity/text'
import type { Product } from '@/modules/products/models/Product'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { FormProps } from '../../types'
import { useAllProductsList } from './hooks/useAllProductsList'
import * as Styled from './styles'
import { InputWrapper } from '../../../styles'

type Props = {
  form: UseFormReturn<FormProps>
  referenceYear: string
}

export const SelectProductInput = ({ form, referenceYear }: Props) => {
  const [isSearchingProduct, setIsSearchingProduct] = useState<boolean>(false)
  const { school } = useSelectedSchool()

  const formValues = form.getValues()

  const productList = useAllProductsList(school.id)

  const noOptionsText = isSearchingProduct
    ? 'Nenhum produto com esse nome foi encontrado'
    : 'Comece a digitar para buscar produtos'

  const onProductSelectChange = (data: Product) => {
    if (!data?.id) {
      return form.reset({ ...formValues, product: null })
    }

    const product = productList.data.find(propEq('id', data.id))
    form.setValue('product', product)
  }

  const debounceMS = 500
  const inputDebounce = debounce(debounceMS, () => setIsSearchingProduct(true))
  const label = `Qual o produto de ${referenceYear} da campanha?`
  return (
    <Box mt={4}>
      <FormControl fullWidth variant="outlined">
        <Controller
          rules={{ required: true, validate: v => Boolean(v) }}
          control={form.control}
          name="product"
          render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
            <InputWrapper hasError={Boolean(error?.type)}>
              <Text id="select-product-input-label" variant="subtitle-medium">
                {label}
              </Text>
              <Autocomplete
                {...rest}
                value={value}
                forcePopupIcon={false}
                getOptionSelected={(option, value) => option?.name === value?.name}
                getOptionLabel={option => option?.name || ''}
                renderOption={option => <Typography>{option?.name}</Typography>}
                options={productList.data}
                onChange={(_, data, reason) => {
                  if (!data || reason === 'clear') {
                    return form.reset(
                      {
                        ...formValues,
                        product: null,
                      },
                      { keepErrors: true }
                    )
                  }
                  onChange(data as Product)
                  onProductSelectChange(data as Product)
                }}
                onInputChange={inputDebounce}
                noOptionsText={noOptionsText}
                renderInput={params => (
                  <Styled.TextFieldWrapper>
                    <TextField
                      {...params}
                      label=""
                      hiddenLabel
                      variant="outlined"
                      error={Boolean(error?.type)}
                      placeholder={`Selecione ou procure um produto ${referenceYear}`}
                      helperText={error?.type && 'Selecione um produto cadastrado'}
                      inputProps={{
                        ...params.inputProps,
                        'aria-labelledby': 'select-product-input-label',
                      }}
                    />
                  </Styled.TextFieldWrapper>
                )}
              />
            </InputWrapper>
          )}
        />
      </FormControl>
    </Box>
  )
}
