import { AgreementEntry } from '@/shared/interfaces'
import { ProductsByStudents } from '../../types'

const formatConsolidatedMonthName = (months: string[]) => {
  const monthsName = months.join(', ')

  return `Acordo ${monthsName} - Entrada`
}

export const adaptEntryDetails = (entryInformation: AgreementEntry): ProductsByStudents[] => {
  if (!entryInformation) return []

  const student = entryInformation.student
  const product = entryInformation.product[0]

  return [
    {
      products: [
        {
          finalAmount: entryInformation.value,
          baseAmount: entryInformation.value,
          consolidatedMonthNames: formatConsolidatedMonthName(entryInformation.competenceMonths),
          finesAndInterest: 0,
          discounts: 0,
          productId: product.id,
          productName: product.name,
        },
      ],
      studentId: student.source_id,
      studentName: student.name,
      isOverdue: false,
    },
  ]
}
