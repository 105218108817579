import { CommonMetadata } from './metadata'

export type School = CommonMetadata & {
  cnpj: cnpj
  name: string
  slug: string
}

export enum SchoolOperationType {
  DEFAULT = 'default',
  ISAACPAY = 'isaac-pay',
}
