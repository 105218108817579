import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

/**
 * Loading state for payout report sub header card component
 */
export const LoadingState = () => {
  return (
    <Box display="flex" justifyContent="space-between" data-testid="loading-state">
      <Box>
        <Skeleton
          variant="rect"
          width="15rem"
          height="2rem"
          style={{ borderRadius: '0.5rem', marginBottom: '1rem' }}
        />

        <Skeleton
          variant="rect"
          width="20rem"
          height="1rem"
          style={{ borderRadius: '0.5rem', marginBottom: '1rem' }}
        />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Skeleton
          variant="rect"
          width="15rem"
          height="2rem"
          style={{ borderRadius: '0.5rem', marginBottom: '1rem' }}
        />

        <Skeleton
          variant="rect"
          width="20rem"
          height="1rem"
          style={{ borderRadius: '0.5rem', marginBottom: '1rem' }}
        />
      </Box>
    </Box>
  )
}
