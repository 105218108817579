import { useRouteMatch } from 'react-router-dom'
import type { ModuleDefinition } from '@/modules/core/module'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { routes } from './routes'

import env from '@/config'

const IS_BACKOFFICE_REALM = env.SORTING_HAT.REALM === 'backoffice'

export const useRegisterAccessManagementModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  const isEnabledAccessManagementPage = useUnleashFlag(
    UnleashFlags.PE_97_ENABLE_ACCESS_MANAGEMENT_PAGE
  )

  if (isEnabledAccessManagementPage && !IS_BACKOFFICE_REALM)
    module.registerRoutes(routes, { basePath })
}
