import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { LoadingBox } from '@/shared/components/LoadingBox'
import ManualLiquidation from './ManualLiquidation'
import useGuardiansInstallmentsByUrnQuery from '../hooks/manualLiquidation/useGuardianInstallmentsQueryByUrn'

const ManualLiquidationEdit: FC = () => {
  const { guardianId, urn } = useParams<{ guardianId: uuid; urn: uuid }>()

  const {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
  } = useGuardiansInstallmentsByUrnQuery({
    guardianId,
    urns: [urn],
  })

  const installment = guardiansInstallments?.data?.[0]
  return (
    <>
      {isFetchGuardiansInstallmentsLoading ? (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      ) : (
        <ManualLiquidation
          installment={installment}
          isFetchGuardiansInstallmentsLoading={isFetchGuardiansInstallmentsLoading}
        />
      )}
    </>
  )
}

export default ManualLiquidationEdit
