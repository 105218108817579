import { AssignAccessRoles } from '@/modules/access-management/types/AssignAccessRoles'
import { OPTIONS_GROUP } from '@/modules/access-management/constants/OPTIONS_GROUP'

import type { GroupNames } from '@/modules/access-management/types/GroupNames'

interface IProps {
  assignAccessRoles?: AssignAccessRoles[]
  userAccessToBeEdit?: GroupNames
}

export const getAvailableOptions = ({ assignAccessRoles = [], userAccessToBeEdit }: IProps) => {
  if (!userAccessToBeEdit) return []

  if (!assignAccessRoles.length) {
    return OPTIONS_GROUP.filter(item => item.value === userAccessToBeEdit)
  }

  if (assignAccessRoles.includes('atribuir_acesso_gestor') && userAccessToBeEdit !== 'gestor') {
    return OPTIONS_GROUP
  }

  const accessRulesByGroup = {
    gestor: () => {
      return OPTIONS_GROUP.filter(item => item.value === 'gestor')
    },
    mantenedor: () => {
      return OPTIONS_GROUP.filter(item => item.value === 'mantenedor')
    },
    secretaria: () => {
      if (assignAccessRoles.includes('atribuir_acesso_mantenedor')) {
        return OPTIONS_GROUP.filter(item => item.value !== 'gestor')
      } else {
        return OPTIONS_GROUP.filter(item => item.value === 'secretaria')
      }
    },
  }

  return accessRulesByGroup[userAccessToBeEdit]()
}
