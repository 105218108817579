import { Text } from '@gravity/text'
import { colors, fontSize, spacing } from '@gravity/tokens'
import styled from 'styled-components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

export const InputErrorText = styled(Text)`
  color: ${colors['colors-interaction-dangerous-1']};
`

export const InputWrapper = styled.div<{ hasError: boolean }>`
  > span {
    margin-bottom: ${spacing[2]};
    line-height: 24px;
  }

  ${({ hasError }) =>
    hasError &&
    `
    button, .MuiOutlinedInput-notchedOutline {
      --tw-ring-shadow: 0 0 #000 !important;
      border: 1px solid ${colors['colors-interaction-dangerous-1']};
    }
    .MuiOutlinedInput-root > fieldset {
      border-width: 1px !important;
       border: 1px solid ${colors['colors-interaction-dangerous-1']} !important;
    }
    span, .MuiFormHelperText-root {
      color: ${colors['colors-interaction-dangerous-1']};
    }
    .MuiFormHelperText-root {
      margin-left: 0px;
      font-size: ${fontSize.sm}
    }
  `}
`

export const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  font-size: 1rem;
  margin-left: ${spacing[1]};
`
