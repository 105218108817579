import type { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { ArrowBack, Help } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { Button } from '@olaisaac/design-system'
import { rgba } from 'polished'
import { useHistory } from 'react-router-dom'

import theme from '@/shared/theme'

import { Title } from './styles'
import { IsaacPayToggleGroup } from '@/modules/isaacpay/pages/IsaacPayReport/components/IsaacPayToggleGroup'
import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'

type PayoutReportPageHeaderProps = {
  backButtonLink: string
  currentReportingPeriodView?: string
  defaultReportingPeriod?: string
  isLoading: boolean
  isOnboardingDrawerOpen: boolean
  onChangeReportingPeriod?: (reportingPeriod: string) => void
  onRequestOnboarding: () => void
  picker: ReactNode
  shouldShowToggle?: boolean
  title: string
}

const BUTTON_COLOR = theme.primitiveTokens.colors.isaacBlue[60]
const BUTTON_BACKGROUND_COLOR = rgba(BUTTON_COLOR, 0.14)

/**
 * Component for payout report page header
 *
 * @param props
 * @param props.picker Custom component to be displayed as a picker
 * @param props.title Header title
 * @param props.backButtonLink Path to be redirected on click at back button
 * @param props.isLoading Indicates if loading state should be displayed for the picker
 * @param props.isOnboardingDrawerOpen Indicates if onboarding drawer is open
 * @param props.onRequestOnboarding Callback function called on request drawer open
 * @param props.shouldShowToggle Boolean value that indicates the rendering of calendar toggle
 * @param props.reportingPeriod School Payout reporting period
 * @param props.onChangeReportingPeriod Callback function called on change payout
 */
export const PayoutReportPageHeader = ({
  title,
  picker,
  backButtonLink,
  isLoading,
  isOnboardingDrawerOpen,
  onRequestOnboarding,
  onChangeReportingPeriod,
  shouldShowToggle,
  defaultReportingPeriod = SchoolReportingPeriod.weekly,
  currentReportingPeriodView,
}: PayoutReportPageHeaderProps) => {
  const { replace } = useHistory()

  return (
    <Box pt="1.25rem">
      <Box display="flex" justifyContent="space-between">
        <Button variation="ghost" startIcon={<ArrowBack />} onClick={() => replace(backButtonLink)}>
          Voltar
        </Button>

        <Button
          variation="ghost"
          startIcon={<Help />}
          style={
            isOnboardingDrawerOpen
              ? {
                  backgroundColor: BUTTON_BACKGROUND_COLOR,
                  color: BUTTON_COLOR,
                }
              : {}
          }
          onClick={onRequestOnboarding}
        >
          Ajuda
        </Button>
      </Box>

      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mt="1rem">
        <Box display="flex" alignItems="flex-end">
          <Title>{title}</Title>

          {isLoading ? (
            <Box ml="1rem" mb="6px">
              <Skeleton
                variant="rect"
                width="11rem"
                height="1rem"
                style={{ borderRadius: '0.5rem' }}
                data-testid="skeleton"
              />
            </Box>
          ) : (
            <Box ml="1rem" mb="5px">
              {picker}
            </Box>
          )}
        </Box>

        {shouldShowToggle ? (
          isLoading ? (
            <Box ml="1rem" mb="6px">
              <Skeleton
                variant="rect"
                width="11rem"
                height="1rem"
                style={{ borderRadius: '0.5rem' }}
                data-testid="skeleton"
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="end">
              <IsaacPayToggleGroup
                value={currentReportingPeriodView}
                defaultReportingPeriod={defaultReportingPeriod}
                onChangeReportingPeriod={onChangeReportingPeriod}
              />
            </Box>
          )
        ) : null}
      </Box>
    </Box>
  )
}
