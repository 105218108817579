import { DialogPrimitives } from '@gravity/dialog'
import type { EnrollmentPaymentPlan } from '@/escolas/services/enrollment/types'
import { formatDate, formatCentsToReal } from '@/shared/utils'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import * as Styled from './styles'
import { Icon } from '@mdi/react'
import { mdiCalendarBlank } from '@mdi/js'

interface Props {
  handleDialogOpenChange: (open: boolean) => void
  paymentPlans: EnrollmentPaymentPlan[]
  visible: boolean
}

export const View = ({ visible, paymentPlans, handleDialogOpenChange }: Props) => {
  return (
    <DialogPrimitives.Root open={visible} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content size={3} title="Ver condições de pagamento">
        <Styled.PaymentPlansWrapper>
          {paymentPlans?.map((paymentPlan, idx) => (
            <div key={paymentPlan?.due_date}>
              <Heading variant="heading-h4-medium">{`Condição de pagamento ${idx + 1}`}</Heading>
              <Styled.RowWrapper>
                <Styled.InfoWrapper>
                  <Text variant="subtitle-medium">Qual o valor da antecipação?</Text>
                  <Styled.Input>
                    {formatCentsToReal(paymentPlan?.amount?.toString() ?? '0')}
                  </Styled.Input>
                </Styled.InfoWrapper>
                <Styled.InfoWrapper>
                  <Text variant="subtitle-medium">Qual a data de validade?</Text>
                  <Styled.Input>
                    <Icon path={mdiCalendarBlank} size="16px" />
                    {paymentPlan.due_date ? formatDate(paymentPlan.due_date, 'DD/MM/YYYY') : null}
                  </Styled.Input>
                </Styled.InfoWrapper>
              </Styled.RowWrapper>
            </div>
          ))}
        </Styled.PaymentPlansWrapper>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
