import { Controller, UseFormReturn } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { typography } from '@material-ui/system'

export const formOfPaymentOptions = [
  { name: 'Boleto ou Pix', value: ['PIX', 'BANK_SLIP'], key: 'PIX_BANKSLIP' },
  { name: 'Cartão de crédito', value: ['CREDIT_CARD'], key: 'CREDIT_CARD' },
]
const StyledTypography = styled(Typography)`
  ${typography}
`
type DiscountAgglutinationProps = {
  defaultValue?: string[]
  disableForm: boolean
  form: UseFormReturn<any>
  paymentMethod?: string
}

function AgglutinationPayment({
  defaultValue = formOfPaymentOptions.find(option => option.key === 'PIX_BANKSLIP').value,
  paymentMethod = 'payment_method',
  form,
  disableForm,
}: DiscountAgglutinationProps) {
  const { control } = form
  return (
    <>
      <Box pb={1} display="flex" justifyContent="space-between" paddingTop="15px">
        <StyledTypography variant="body2" fontSize="18px">
          Forma de Pagamento
        </StyledTypography>
      </Box>
      <Box mb={2} marginTop="18px">
        <FormControl fullWidth variant="outlined" disabled={disableForm}>
          <InputLabel id={paymentMethod}>Forma de pagamento</InputLabel>
          <Controller
            rules={{ required: true, validate: v => v?.length > 0 }}
            control={control}
            name={paymentMethod}
            render={({ field: { value, onChange } }) => (
              <Select
                labelId={paymentMethod}
                label="Forma de pagamento"
                value={value}
                defaultValue={defaultValue}
                onChange={e => {
                  onChange(e.target.value)
                }}
              >
                {formOfPaymentOptions.map(({ name, value, key }) => (
                  <MenuItem key={key} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
    </>
  )
}

export default AgglutinationPayment
