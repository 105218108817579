import { useFetchCampaigns } from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import type { PaginationRequest } from '@/shared/interfaces'

type Props = {
  campaignId?: string
  campaignsStatusFilter?: string[]
  pagination?: PaginationRequest
  schoolEnrollmentCycleId: string
  schoolId: string
}
export function useCampaignsList({
  campaignId,
  campaignsStatusFilter,
  pagination,
  schoolEnrollmentCycleId,
  schoolId,
}: Props) {
  const { data, isSuccess, isLoading, refetch } = useFetchCampaigns({
    campaignId,
    schoolEnrollmentCycleId: schoolEnrollmentCycleId,
    schoolId: schoolId,
    pagination: pagination,
    status: campaignsStatusFilter,
  })

  const campaigns = data?.data?.campaigns || []
  const tablePagination = data?.pagination || {}

  return {
    campaigns,
    tablePagination,
    isSuccess,
    isLoading,
    refetch,
  }
}
