import type { AgreementSimulationCombined } from 'src/shared/interfaces'

export const getIdsForPayment = (
  agreements: AgreementSimulationCombined[],
  position: number,
  needAllIds: boolean
): string[] => {
  if (needAllIds) {
    return agreements.reduce((acc, agreement) => {
      acc.push(...agreement.receivable_ids)
      return acc
    }, [])
  }
  return agreements[position].receivable_ids
}
