import { BadgeProps } from '@gravity/badge'

import { SortOrder } from '@/shared/hooks/useOrdering/types'
import { DebtStatus } from '@/shared/interfaces'

import {
  InstallmentRowType,
  InstallmentStatusDescription,
} from '@/modules/guardians/GuardianDetails/constants'
import { Installment } from '@/modules/guardians/GuardianDetails/types'

export type UseGuardiansQueryParamsProps = {
  filters?: FiltersType
  itemsPerPage?: number
  page?: number
  sortBy?: 'name'
  sortOrder?: SortOrder
}

export type FiltersType = {
  debt_status: DebtStatus[]
}

export type FilterOptionsType = {
  label: string
  value: string
}

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Row = Installment & {
  checked?: boolean
  disabled: boolean
  enableCheckbox?: boolean
  id: string
  installment_info: {
    checkable?: boolean
    competence_date: Installment['competence_date']
    negotiation_id: Installment['negotiation_id']
    row_type: InstallmentRowType
  }
  installment_order: {
    order_reference: Installment['order_reference']
    row_type: InstallmentRowType
  }
  installment_status: {
    color: BadgeProps['color']
    overdue_for_days: Installment['overdue_for_days']
    paid_date: Installment['paid_date']
    row_type: InstallmentRowType
    status: Installment['status']
    status_description: InstallmentStatusDescription
    text: string
  }
  is_from_credit_card_flow: Installment['is_from_credit_card_flow']
  key: string
  row_type: InstallmentRowType
}

export type Rows = Row[]
