import { useEffect, useState } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { RemoveUserAccessDialog } from './components/RemoveAccessDialog'
import { AccessEditDialog } from './components/AccessEditDialog'
import { UsersTable } from '../../components/UsersTable'

import { useJWT } from '@/shared/hooks/useJWT'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { useUserAccess } from '@/modules/access-management/hooks/queries/user-access'

import { separateUserAccessRoles } from './utils/separateUserAccessRoles'

import type { User } from '../../models/User'
import type { SortableColumns } from '../../components/UsersTable/types'

import { Container, HeaderContainer } from './styles'
import { AccessCreateDialog } from './components/AccessCreateDialog'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export const AccessManagement = () => {
  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Gestão de usuários',
  })

  const { userId } = useJWT()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { ordering, updateOrdering } = useOrdering<SortableColumns>({
    sortBy: 'group_name',
    sortOrder: 'ASC',
  })

  const { data, isFetching } = useUserAccess(
    {
      schoolId: school?.id ?? '',
      sortBy: ordering?.sortBy,
      sortOrder: ordering?.sortOrder,
    },
    {
      enabled: !!school?.id,
    }
  )

  const [selectedUser, setSelectedUser] = useState<User>()
  const [isCreateAccessDialogOpen, setIsCreateAccessDialogOpen] = useState(false)
  const [isEditAccessDialogOpen, setIsEditAccessDialogOpen] = useState(false)
  const [isRemoveAccessDialogOpen, setIsRemoveAccessDialogOpen] = useState(false)

  const actionDialogsDispatchers = {
    edit: setIsEditAccessDialogOpen,
    remove: setIsRemoveAccessDialogOpen,
  }

  const handleCreateAccessButtonClick = () => {
    setIsCreateAccessDialogOpen(true)

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $button_name: 'novo_acesso',
        },
      })
    }
  }

  const handleCalloutLinkClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.LINK_CLICKED,
        action: 'link_click',
        customProperties: {
          $page_name: 'saiba_mais_sobre_acessos',
        },
      })
    }
  }

  const handleOpenActionDialog = (dialog: 'edit' | 'remove', user: User) => {
    setSelectedUser(user)
    actionDialogsDispatchers[dialog](true)

    const buttonName = dialog === 'edit' ? 'editar_acesso' : 'excluir_acesso'

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $button_name: buttonName,
        },
      })
    }
  }

  const handleCloseDialog = (dialog: 'edit' | 'remove') => {
    actionDialogsDispatchers[dialog](false)
    setSelectedUser(undefined)
  }

  const userRoles = data?.data.userRoles ?? []
  const [assignAccessRoles, removeAccessRoles] = separateUserAccessRoles(userRoles)

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: 'acessos_de_usuarios',
        },
      })
    }
  }, [])

  return (
    <Container>
      <HeaderContainer>
        <div>
          <Heading variant="heading-h3-medium" className="title">
            Acessos de usuários
          </Heading>
          <Text variant="body-2-regular">
            Gerencie o nível de acesso que os usuários possuem em sua organização.
          </Text>
        </div>

        <div>
          <Button
            variant="outline"
            iconStart={<Icon path={mdiPlus} />}
            onClick={handleCreateAccessButtonClick}
            disabled={assignAccessRoles.length === 0}
          >
            Novo acesso
          </Button>
        </div>
      </HeaderContainer>

      <Callout
        text="Entenda os níveis de acessos e suas permissões"
        href="https://centraldeajuda.olaisaac.io/credito-isaac/entenda-as-condicoes-especiais-do-credito-atrelado-a-renovacao-"
        linkTarget="_blank"
        onLinkClick={handleCalloutLinkClick}
      />

      <UsersTable
        loggedUserId={userId}
        assignAccessRoles={assignAccessRoles}
        removeAccessRoles={removeAccessRoles}
        ordering={ordering}
        onChangeOrdering={(sortBy, sortOrder) => {
          updateOrdering({ sortBy, sortOrder })
        }}
        isLoading={isFetching}
        users={data?.data.users ?? []}
        onEdit={user => handleOpenActionDialog('edit', user)}
        onRemove={user => handleOpenActionDialog('remove', user)}
      />

      <RemoveUserAccessDialog
        currentSchool={school}
        user={selectedUser}
        isOpen={isRemoveAccessDialogOpen}
        onClose={() => handleCloseDialog('remove')}
      />

      <AccessEditDialog
        assignAccessRoles={assignAccessRoles}
        currentSchool={school}
        user={selectedUser}
        actionUserId={userId}
        isOpen={isEditAccessDialogOpen}
        onClose={() => handleCloseDialog('edit')}
      />

      <AccessCreateDialog
        currentSchool={school}
        assignAccessRoles={assignAccessRoles}
        isOpen={isCreateAccessDialogOpen}
        onClose={() => setIsCreateAccessDialogOpen(false)}
      />
    </Container>
  )
}
