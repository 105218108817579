import styled from 'styled-components'
import { Link } from '@olaisaac/design-system'
import { StyledLinkProps } from './types'

export const SituationContainer = styled.div`
  width: max-content;
  padding: 16px 0;
`

export const StatusContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const ActionsColumnContainer = styled.div`
  width: max-content;
`

export const TableContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};
  & tbody tr {
    cursor: pointer;
  }
`

export const StyledLink = styled(Link)<StyledLinkProps>`
  color: ${props => props.disabled && props.theme.primitiveTokens.colors.gray[20]};

  &:hover {
    color: ${props => props.disabled && props.theme.primitiveTokens.colors.gray[20]};
  }
`

export const ProductsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const OtherProductsContainer = styled.div`
  display: flex;
  align-items: center;
`
