import Icon from '@mdi/react'
import { mdiMinusCircleOutline } from '@mdi/js'
import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { CircularProgress } from '@material-ui/core'
import { MouseEvent } from 'react'

import { CampaignClosedType, CampaignStatus } from '@/escolas/services/enrollment/types'
import { formatDate } from '@/shared/utils'
import type { Ordering, SortOrder } from '@/shared/hooks/useOrdering/types'
import { CloseCampaignDialog } from '@/modules/enrollment/components/CloseCampaignDialog'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { useCloseCampaignDialog } from './hooks/useCloseCampaignDialog'
import { TableRowProps, ColumnProps, SortableColumns, columnNames } from './types'
import * as Styled from './styles'
import { useEnrollmentMixpanelEvents } from '@/modules/enrollment/hooks/useEnrollmentMixpanelEvents'

interface CampaignsTableProps {
  isLoading: boolean
  noDataMessage?: string
  onChangeOrdering: (sortBy: SortableColumns, sortOrder: SortOrder) => void
  onClickRow: (rowKey: string) => void
  ordering: Ordering
  rows: TableRowProps[]
}

const setCampaignStatusSubtext = (closureType: CampaignClosedType | ''): string => {
  switch (closureType) {
    case CampaignClosedType.MANUALLY:
      return 'Manualmente'
    case CampaignClosedType.AUTOMATICALLY:
      return 'Por validade'
    default:
      return ''
  }
}

export const CampaignsTable = ({
  rows,
  isLoading = true,
  ordering,
  onChangeOrdering,
  noDataMessage,
  onClickRow,
}: CampaignsTableProps) => {
  const { closeDialog, dialogVisible, openDialog, selectedRow } = useCloseCampaignDialog()

  const isCampaignDetailsEnabled = useUnleashFlag(UnleashFlags.MAT_343_ENABLE_CAMPAIGN_DETAILS)
  const emailColumn: ColumnProps = {
    key: 'total_of_campaign_emails_read',
    label: 'Emails abertos',
    sortable: false,
  }
  const COLUMN_HEADERS: Array<ColumnProps> = [
    { key: columnNames.NAME, label: 'Campanha', sortable: true },
    { key: columnNames.STUDENTS_COUNT, label: 'Alunos incluídos', sortable: false },
    ...(isCampaignDetailsEnabled ? [emailColumn] : []),
    { key: columnNames.ENGAGED_STUDENTS_COUNT, label: 'Alunos interessados', sortable: false },
    { key: columnNames.END_DATE, label: 'Validade', sortable: false },
    { key: columnNames.STATUS, label: 'Situação', sortable: false },
  ]
  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  if (isLoading) {
    return (
      <Styled.LoadingContainer>
        <CircularProgress />
      </Styled.LoadingContainer>
    )
  }

  if (!rows?.length) {
    return <Styled.EmptyStateContainer>{noDataMessage}</Styled.EmptyStateContainer>
  }

  return (
    <Styled.TableWrapper>
      <Table.Root>
        <Table.Head>
          {COLUMN_HEADERS.map(({ label, key, sortable = false }) => (
            <Table.HeaderCell
              key={key}
              name={key}
              sortable={!isLoading && sortable}
              sortOrder={
                ordering.sortBy === key && ordering.sortOrder
                  ? (ordering.sortOrder.toLowerCase() as Lowercase<SortOrder>)
                  : undefined
              }
              onSort={(sortBy, sortOrder) =>
                onChangeOrdering(
                  sortBy as SortableColumns,
                  sortOrder.toLocaleUpperCase() as Uppercase<'asc' | 'desc'>
                )
              }
            >
              {label}
            </Table.HeaderCell>
          ))}
        </Table.Head>
        <Table.Body>
          {rows.map(row => {
            const statusBadgeColor = row.columns[5] === CampaignStatus.OPEN ? 'accent' : 'neutral'
            const statusBadgeLabel =
              row.columns[5] === CampaignStatus.OPEN ? 'Iniciada' : 'Encerrada'
            const statusBadgeSubtext = setCampaignStatusSubtext(
              row.columns[6] as CampaignClosedType | ''
            )

            return (
              <Table.Row key={row.key} onClick={() => onClickRow(row.key)}>
                <Table.TextCell subtext={row.columns[1]}>{row.columns[0]}</Table.TextCell>
                <Table.TextCell>{row.columns[2]}</Table.TextCell>
                {isCampaignDetailsEnabled && <Table.TextCell>{row.columns[7]}</Table.TextCell>}
                <Table.TextCell>{row.columns[3]}</Table.TextCell>
                <Table.TextCell>{formatDate(row.columns[4], 'DD/MM/YYYY')}</Table.TextCell>
                <Table.BadgeCell
                  subtext={statusBadgeSubtext}
                  badgeVariant="soft"
                  badgeColor={statusBadgeColor}
                >
                  {statusBadgeLabel}
                </Table.BadgeCell>

                {row.columns[5] === CampaignStatus.OPEN && (
                  <Table.ActionCell
                    actions={
                      <>
                        <Tooltip text="Encerrar campanha" position="left">
                          <IconButton
                            variant="ghost"
                            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                              event.stopPropagation()
                              sendButtonClickEvent('encerrar_campanha_tabela')
                              openDialog(row)
                            }}
                          >
                            <Icon path={mdiMinusCircleOutline} size={16} />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                )}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>

      {selectedRow && (
        <CloseCampaignDialog
          visible={dialogVisible}
          closeDialog={closeDialog}
          campaignId={selectedRow.key}
          hasPaymentPlan={selectedRow.hasPaymentPlan}
        />
      )}
    </Styled.TableWrapper>
  )
}
