import styled from 'styled-components'
import theme from 'src/shared/theme'

import Skeleton from '@material-ui/lab/Skeleton'

import type { ContainerProps } from './types'

const { spacing } = theme.primitiveTokens
const { variation } = theme.humanizedTokens.components.typography

export const Container = styled.nav`
  & ~ & {
    margin-top: ${spacing[8]};
  }
`

export const Title = styled.h3`
  display: flex;
  align-items: center;
  ${variation.bodyLarge}
  line-height: 1.5;

  svg {
    color: rgba(0, 0, 0, 0.6);
    margin-right: ${spacing[2]};
  }
`

export const TitlePlaceholder = styled(Skeleton)`
  ${variation.bodyLarge};
  line-height: 1.5;
  width: 100px;
`

export const InfoContainer = styled.ul<ContainerProps>`
  display: grid;
  grid-template-columns: ${({ $columnsCount }) => `repeat( ${$columnsCount}, 1fr)`};
  grid-gap: ${spacing[8]};
  margin-top: ${spacing[4]};

  li {
    list-style: none;
  }
`
