import { Box } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSchoolEnrollmentCycles } from '@/modules/enrollment/hooks/useSchoolEnrollmentCycles'

import { Carousel } from '../Carousel'
import { Banner, BannerPlaceholder } from '../Banner'
import { Section } from '../Section'
import { Error } from '../Error'

import { useBannerInfos } from './useBannerInfos'
import { BannerInfo } from './types'

export const CommunicationSection = () => {
  const history = useHistory()
  const route = useRouteMatch()

  const { school } = useSelectedSchool()
  const { activeEnrollmentCycleYear } = useSchoolEnrollmentCycles()

  const {
    isInitialized: isEventDispatcherInitialized,
    eventDispatcherClient,
  } = useEventDispatcher()

  const { data, isLoading, isError, refetch } = useBannerInfos({
    schoolId: school?.id,
    referenceYear: activeEnrollmentCycleYear ?? 0,
  })

  const handleClick = (
    info: Pick<BannerInfo, 'actionPath' | 'actionExternalLink' | 'event'>
  ) => () => {
    const { event, actionPath, actionExternalLink } = info

    if (actionExternalLink) {
      window.open(actionPath, '_blank', 'noopener noreferrer')
    } else {
      history.push(`${route.url}${actionPath}`)
    }

    if (event && isEventDispatcherInitialized) {
      eventDispatcherClient.sendEvent({
        name: event.name,
        scope: event.scope,
        customProperties: event.props,
        action: event.action,
      })
    }
  }

  if (isError) {
    return (
      <Section>
        <Error title="Não foi possível carregar suas informações de cobranças" onRetry={refetch} />
      </Section>
    )
  }

  const banners = isLoading
    ? [<BannerPlaceholder key="placeholder" />]
    : data.map(item => (
        <Banner
          title={item.title}
          key={item.title}
          explanation={item.explanation}
          imageSrc={item.imageSrc}
          buttonLabel={item.actionLabel}
          buttonOnClick={handleClick(item)}
          backgroundColor={item.backgroundColor}
          color={item.color}
        />
      ))

  return (
    <Box maxWidth="calc(100vw - 15rem)">
      {banners.length > 0 ? <Carousel items={banners} /> : null}
    </Box>
  )
}
