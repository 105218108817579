import { Box, Grid } from '@material-ui/core'
import { Footer } from '../../components/Footer'
import { useLayout } from '@/shared/hooks/useLayout'
import { StatusStepper } from '../../components/StatusStepper'
import { ChosenOfferCard } from '../../components/ChosenOfferCard'
import {
  useContextChosenOffer,
  useContextCreditValue,
  useContextStepperMap,
} from '../../contexts/CreditDataContext'
import { StepperMap } from '../../services/types'
import { Redirect } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ResponsiveContainer } from './styles'

const isCancel = (currentStep: StepperMap) => {
  const status = [
    { step: 3, finished: false }, // awaiting_disbursement
    { step: 3, finished: true }, // finished
  ]
  return !status.find(e => e.step === currentStep.step && e.finished === currentStep.finished)
}

export const CreditStatus = () => {
  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Crédito' })
  const { schoolSlug } = useSelectedSchool()

  const { chosenOffer } = useContextChosenOffer()
  const { creditValue } = useContextCreditValue()
  const { stepperMap } = useContextStepperMap()

  // TODO: this is a quick solution to redirect user if there is no data in context
  // we should persist data in browser storage or use useNavigate to avoid this
  if (!chosenOffer || !creditValue || !stepperMap) return <Redirect to={`/${schoolSlug}/credito`} />

  return (
    <ResponsiveContainer>
      <Box pl={8} pt={6} pr={8}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <StatusStepper statusStepper={stepperMap} />
            <Footer />
          </Grid>
          <Grid item xs={5}>
            <ChosenOfferCard
              offer={chosenOffer}
              creditValue={creditValue}
              canCancel={isCancel(stepperMap)}
            />
          </Grid>
        </Grid>
      </Box>
    </ResponsiveContainer>
  )
}
