import { useHistory, useParams } from 'react-router-dom'

import { useNavigation } from '@/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks'

import type { UseManualLiquidationNavigationProps } from './types'
import { CURRENT_YEAR } from '@/escolas/components/contract/constants'

const useManualLiquidationNavigation = ({
  urn,
  callbackParams,
}: Partial<UseManualLiquidationNavigationProps>) => {
  const history = useHistory()

  const { getNavigationUrl } = useNavigation()

  const { referenceYear, guardianId } = useParams<{
    guardianId: uuid
    referenceYear: string
  }>()

  const isManualLiquidationPageEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_MANUAL_LIQUIDATION_PAGE
  )

  const isEditManualLiquidationPageEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_288_ENABLE_EDIT_MANUAL_LIQUIDATION_PAGE
  )

  const year = referenceYear || CURRENT_YEAR

  const redirectManualLiquidation = (url: string) => history.push(url)

  const getCallbackParams = (callbackParams: string) => {
    if (!callbackParams) return ''
    const params = new URLSearchParams()
    params.append('callback', btoa(callbackParams))
    return `?${params.toString()}`
  }

  const goToManualLiquidationPage = () => {
    const path = `/${year}/responsavel/${guardianId}/${urn}/manual-liquidation/add${getCallbackParams(
      callbackParams
    )}`

    const url = getNavigationUrl({
      path,
    })

    redirectManualLiquidation(url)
  }

  const goToEditManualLiquidationPage = () => {
    const path = `/${year}/responsavel/${guardianId}/${urn}/manual-liquidation/edit${getCallbackParams(
      callbackParams
    )}`

    const url = getNavigationUrl({
      path,
    })
    redirectManualLiquidation(url)
  }

  const goToEditManualLiquidation = () => {
    if (isEditManualLiquidationPageEnabled) {
      goToEditManualLiquidationPage()
    }
  }

  const goToManualLiquidation = () => {
    if (isManualLiquidationPageEnabled) {
      goToManualLiquidationPage()
    }
  }

  return { goToManualLiquidation, goToEditManualLiquidation }
}

export default useManualLiquidationNavigation
