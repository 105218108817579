import { BaseSyntheticEvent, useState } from 'react'
import { Table, TableColumns, TableRowParams, Typography } from '@olaisaac/design-system'

import { MoneyTypography } from '@/escolas/components/report/layout/Typography'
import { ChangesChip } from '@/escolas/components/report/Chips/ChangesChip'

import { formatDate, prettifyFullName } from '@/shared/utils'

import type {
  FinancialTransactionProps,
  TransactionEventType,
  TransactionEvents,
} from '@/shared/interfaces'

import { Container } from './styles'

type TableData = {
  contract_reference_year: string
  events?: TransactionEvents
  id: string
  installment: string
  key: string
  product_name: string
  receipt_date: datestring
  student_name: string
  totalAmount: number
}

type EnrollmentReportTableProps = {
  isFilterEmpty?: boolean
  isLoading?: boolean
  isNewVersion: boolean
  onRowClick: (params: TableRowParams<TableData>, event: BaseSyntheticEvent) => void
  transactions: FinancialTransactionProps[]
}

const TABLE_COLUMNS: TableColumns = [
  {
    headerName: 'Aluno',
    field: 'student_name',
    enableSorting: true,
  },
  {
    headerName: 'Produto',
    field: 'product_name',
  },
  {
    headerName: 'Ano letivo',
    field: 'contract_reference_year',
  },
  {
    headerName: 'Parcela',
    field: 'installment',
  },
  {
    headerName: 'Data de recebimento',
    field: 'receipt_date',
    valueFormatter: (value: string) => formatDate(value, 'DD/MM/YYYY'),
  },

  {
    headerName: 'Valor do repasse',
    field: 'totalAmount',
    renderCell: (value: number) => <MoneyTypography amount={value} />,
  },

  {
    headerName: 'Mudanças',
    field: 'events',
    enableSorting: false,
    renderCell: (values: TransactionEvents = {}) => (
      // FIXME: Refactor changes chip component
      <ChangesChip
        tags={Object.keys(values) as TransactionEventType[]}
        limit={2}
        style={{ margin: 4 }}
      />
    ),
  },
]

export const EnrollmentReportTable = ({
  isFilterEmpty = false,
  isLoading,
  onRowClick,
  transactions,
}: EnrollmentReportTableProps) => {
  const [selectedRow, setSelectedRow] = useState<string>()

  const handleRowClick = (params: TableRowParams<TableData>, event: BaseSyntheticEvent) => {
    setSelectedRow(params.row.id)
    onRowClick(params, event)
  }

  const rows = transactions.map(item => {
    return {
      id: item.referrals.installment_id,
      key: item.referrals.installment_id,
      installment: item.referrals.installment_order_reference,
      student_name: prettifyFullName(item.referrals.student_name),
      product_name: item.referrals.product_name,
      contract_reference_year: item.referrals.contract_reference_year || '2023',
      totalAmount: item.financial_detail.total,
      events: item.financial_detail.events,
      receipt_date: item.referrals.last_receivable_paid_date,
    }
  }) as TableData[]

  return (
    <Container>
      <Table<TableData>
        columns={TABLE_COLUMNS}
        rows={rows}
        sorted={false}
        isLoading={isLoading}
        rowsSelected={selectedRow ? [selectedRow] : undefined}
        emptyRender={
          <Typography>
            {isFilterEmpty
              ? 'Não houveram pagamentos de matrículas neste período.'
              : 'Nenhum resultado encontrado'}
          </Typography>
        }
        onRowClick={handleRowClick}
      />
    </Container>
  )
}
