import { FC, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Product } from '@/modules/products/models/Product'
import { useSnackbar } from 'src/shared/hooks'
import { CircularProgress } from '@material-ui/core'
import ConfirmationDialog from '@/escolas/components/modal/ConfirmationDialog'
import { StyledOverlay } from '@/escolas/components/modal/FormDialog'
import { useMutateOnDeleteProduct } from '@/modules/products/hooks/queries/products'

type DeleteProductProps = {
  isVisible: boolean
  selectedProduct: Product
  setIsVisible: (value: boolean) => void
}

export const DeleteProductDialog: FC<DeleteProductProps> = ({
  selectedProduct,
  isVisible,
  setIsVisible,
}) => {
  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>()
  const { mutateAsync } = useMutateOnDeleteProduct()

  const closeModal = () => {
    setIsVisible(false)
  }

  const submitHandler = async () => {
    if (!selectedProduct) return
    setIsLoading(true)

    await mutateAsync(selectedProduct.id, {
      onSuccess: () => {
        setSnackbarVariation('success')
        setSnackbarIsOpen(true)
        setSnackbarMessage('Produto excluído com sucesso!')
      },
      onError: () => {
        setSnackbarVariation('error')
        setSnackbarIsOpen(true)
        setSnackbarMessage(
          'Não foi possível excluir este produto. Ele já está atrelado a um contrato.'
        )
      },
    })

    setIsLoading(false)
    closeModal()
  }

  return (
    <ConfirmationDialog
      isVisible={isVisible}
      onClose={closeModal}
      submitHandler={submitHandler}
      title="Excluir produto"
      buttonLabel="Confirmar"
      backButtonLabel="Cancelar"
    >
      {isLoading && (
        <StyledOverlay>
          <CircularProgress />
        </StyledOverlay>
      )}
      <Typography variant="body2" data-testid="delete-product-content">
        Tem certeza que você quer excluir o produto <strong>{`${selectedProduct?.name}`}</strong>?
        Essa ação não poderá ser desfeita.
      </Typography>
    </ConfirmationDialog>
  )
}
