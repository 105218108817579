import { useLayout } from '@/shared/hooks/useLayout'
import { StyledCard, GrayTitleCard, SubtitleGrayCard } from './styles'
import { Subtitle } from '@/escolas/pages/ChangeGuardian/components/ChangeGuardianHeader/style'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetSchoolEligibility } from '../../hooks/queries/useGetSchoolEligibility'
import { Box, Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingHome } from './LoadingHome'
import {
  useContextChosenOffer,
  useContextCreditOrderId,
  useContextCreditValue,
  useContextEligibility,
  useContextStepperMap,
} from '../../contexts/CreditDataContext'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { NoCreditAvailable } from '../NoCreditAvailable'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { useGetOrderStatus } from '../../hooks/queries/useGetOrderStatus'
import { isObjectEmpty } from '../../utils/validateObject'
import { HomeTemplate } from '../../templates/HomeTemplate'
import { InfoCard } from '../../components/InfoCard'
import Icon from '@mdi/react'
import { mdiArrowTopRight, mdiCalendarMonth, mdiCheck } from '@mdi/js'
import { Typography } from '@olaisaac/design-system'
import { IconButton } from '@gravity/icon-button'
import MonetizationIcon from '@/modules/credit/assets/monetization-on-outline.svg'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import getDataByCPCRFeatureFlag from '../../utils/featureFlag'

export const CreditHome = () => {
  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Crédito' })
  const { push } = useHistory()
  const { school, schoolSlug } = useSelectedSchool()

  const { data: orderStatus, isLoading: isLoadingStatus } = useGetOrderStatus(school.id)

  const shouldCallEligibility = !isLoadingStatus && isObjectEmpty(orderStatus.data)

  const { data: eligibility, isLoading } = useGetSchoolEligibility(school.id, {
    enabled: shouldCallEligibility,
  })

  const [showSimulationPage, setShowSimulationPage] = useState(false)

  const { setEligibility } = useContextEligibility()
  const { setChosenOffer } = useContextChosenOffer()
  const { setCreditValue } = useContextCreditValue()
  const { setStepperMap } = useContextStepperMap()
  const { setCreditOrderId } = useContextCreditOrderId()
  const isCPCREnabled = useUnleashFlag(UnleashFlags.CRED_884_ENABLE_CCP_CR_TO_CREDIT_MODULE)

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.LOADING_HOME_PAGE,
    })
  }, [])

  useEffect(() => {
    if (!isLoading) setEligibility(eligibility)

    if (eligibility?.eligibility.eligible.CP || eligibility?.eligibility.eligible.CPCR) {
      sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
        $page_name: CreditEventComponentNames.HOME_PAGE,
      })
    } else {
      sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
        $page_name: CreditEventComponentNames.NO_CREDIT_HOME,
      })
    }
  }, [eligibility])

  const shouldRenderLoadingHome = shouldCallEligibility && isLoading
  const shouldRenderLoadingStatus = !shouldCallEligibility && isLoadingStatus
  const shouldRenderIneligibleState =
    shouldCallEligibility &&
    !eligibility?.eligibility?.eligible?.CP &&
    !eligibility?.eligibility?.eligible?.CPCR
  const shouldRenderStatusPage = !isLoadingStatus && !isObjectEmpty(orderStatus.data)

  if (shouldRenderLoadingHome || shouldRenderLoadingStatus) {
    return <LoadingHome />
  }

  if (shouldRenderIneligibleState) {
    return <NoCreditAvailable />
  }

  if (showSimulationPage) {
    push(`/${schoolSlug}/credito/simulacao`)
  }

  if (shouldRenderStatusPage) {
    setChosenOffer(orderStatus.data.chosenOffer)
    setCreditValue(orderStatus.data.creditValue)
    setStepperMap(orderStatus.data.stepperMapper)
    setCreditOrderId(orderStatus.data.orderId)
    push(`/${schoolSlug}/credito/estado`)
  }

  const handleSimulateClick = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Simule agora',
    })
    setShowSimulationPage(true)
  }

  const cards = [
    <InfoCard
      key={1}
      icon={<Icon path={mdiCheck} size={1} />}
      title="Sem burocracia"
      description="Solicitamos apenas dois documentos para contratação de créditos pré-aprovados. Escolas parceiras têm acesso a capital sem burocracia."
    />,
    <InfoCard
      key={2}
      icon={<img src={MonetizationIcon} height={24} width={24} />}
      title="Desconto direto no repasse"
      description="Sabemos que lidar com muitos boletos no dia a dia pode ser complicado. Por isso, no isaac, a parcela do seu crédito é descontada diretamente do repasse."
    />,
    <InfoCard
      key={3}
      icon={<Icon path={mdiCalendarMonth} size={1} />}
      title="Pula parcela em janeiro"
      description="O início do ano letivo pode trazer despesas desafiadoras. Por isso, no isaac, não fazemos cobrança de parcelas de crédito em janeiro. O faturamento da escola não é impactado logo no início do ano."
    />,
  ]
  const { subtitle, description } = getDataByCPCRFeatureFlag(isCPCREnabled)

  return (
    <HomeTemplate
      title={'Crédito que impulsiona os melhores planos \n da sua escola'}
      cardDescription={
        'Simule quantas vezes quiser, sem compromisso, \n e contrate o crédito ideal para sua escola.'
      }
      isEligible
      cards={cards}
      mainCardDescription="Pensado e feito para escolas"
      onClickButton={() => handleSimulateClick()}
      bottomInfo={
        <>
          <Grid item xs={9}>
            <StyledCard>
              <Box my={1} mx={5}>
                <GrayTitleCard>Entenda as possibilidades de parcelamento</GrayTitleCard>
                <Subtitle>{subtitle()}</Subtitle>
                <Typography style={{ marginTop: '54px' }}>{description()}</Typography>
              </Box>
            </StyledCard>
          </Grid>
          <Grid item xs={3}>
            <StyledCard>
              <Box m={1}>
                <SubtitleGrayCard>Dúvidas frequentes</SubtitleGrayCard>
                <Subtitle style={{ marginTop: '1rem' }}>
                  Quais documentos são exigidos? Quando o dinheiro estará na minha conta? Consulte
                  aqui suas principais dúvidas sobre o crédito isaac.
                </Subtitle>
                <IconButton
                  size={2}
                  style={{ marginTop: '2rem' }}
                  onClick={() => {
                    sendCreditEventToMixpanel(
                      EventDispatcherEvents.BUTTON_CLICKED,
                      MixpanelEventActions.CLICK,
                      {
                        $page_name: CreditEventComponentNames.FAQ,
                      }
                    )
                    window.open('https://centraldeajuda.olaisaac.io/credito-isaac', '_blank')
                  }}
                >
                  <Icon path={mdiArrowTopRight} />
                </IconButton>
              </Box>
            </StyledCard>
          </Grid>
        </>
      }
    />
  )
}
