import { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { TileDisabledFunctionArgs } from '@olaisaac/design-system'

import { StandardPicker } from './components/StandardPicker'
import { GhostPicker } from './components/GhostPicker'
import { PickerPopOver } from './components/PickerPopOver'

import { Container } from './styles'

type LabelSize = 'small' | 'large'

export type DetailType = 'month' | 'year' | 'decade'

type DatePickerProps = {
  labelSize?: LabelSize
  maxDate?: Date
  maxDetail: DetailType
  minDate?: Date
  minDetail?: DetailType
  onChange: (date: Date) => void
  tileDisabled?: (data: TileDisabledFunctionArgs) => boolean
  value?: Date
  valueTemplate?: string
  variation?: 'standard' | 'ghost'
}

/**
 * TODO: Unity tests
 *
 * Custom component for date picking
 *
 * @param props
 * @param props.variation Picker trigger variation
 * @param props.labelSize Label size
 * @param props.maxDate Max date allowed
 * @param props.minDate Min date allowed
 * @param props.maxDetail Defines the maximum date detail
 * @param props.minDetail Defines the minimum date detail
 * @param props.value Date value
 * @param props.tileDisabled Function that allows disable specific dates
 * @param props.onChange On change callback function
 */
export const DatePicker = ({
  value,
  onChange,
  labelSize,
  valueTemplate = 'MMMM [de] YYYY',
  variation = 'standard',
  ...rest
}: DatePickerProps) => {
  const openPickerButtonRef = useRef<HTMLButtonElement | null>(null)

  const [isPickerVisible, setIsPickerVisible] = useState(false)

  const handleSelectDate = (date: Date) => {
    onChange(date)
    setIsPickerVisible(false)
  }

  const dateText = useMemo(() => {
    if (!value) {
      return 'Selecione uma data'
    }

    return dayjs(value).format(valueTemplate)
  }, [value, valueTemplate])

  return (
    <Container>
      {variation === 'standard' ? (
        <StandardPicker
          ref={openPickerButtonRef}
          label={dateText}
          labelSize={labelSize}
          onClick={() => setIsPickerVisible(true)}
        />
      ) : (
        <GhostPicker
          ref={openPickerButtonRef}
          label={dateText}
          onClick={() => setIsPickerVisible(true)}
        />
      )}

      <PickerPopOver
        value={value}
        onChange={handleSelectDate}
        anchorEl={openPickerButtonRef.current}
        isOpen={isPickerVisible}
        onClose={() => setIsPickerVisible(false)}
        {...rest}
      />
    </Container>
  )
}
