/* eslint-disable no-constant-condition */
import type { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Button, Chip, Typography } from '@olaisaac/design-system'

import { ErrorState } from './components/ErrorState'
import { LoadingState } from './components/LoadingState'

import { formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'
import { getPayoutAmountLabel } from '@/modules/report/utils/getPayoutAmountLabel'

import theme from '@/shared/theme'

import { Amount, CurrencySign } from './styles'

type DashboardCardProps = {
  children: ReactNode
  hasError?: boolean
  isLoading?: boolean
  isLoadingPayoutAmount?: boolean
  isPayoutOpen: boolean
  onRequestDetails: () => void
  onRetry: () => void
  payoutAmountFormat: 'decimal' | 'microcents'
  payoutSubtitleLabel?: string
  payoutValue: number
  title: string
}

const BORDER_COLOR = theme.primitiveTokens.colors.gray[10]
const POSITIVE_VALUE_COLOR = theme.primitiveTokens.colors.green[90]
const NEGATIVE_VALUE_COLOR = theme.primitiveTokens.colors.coral[90]

/**
 * Component for payout report dashboard cards
 *
 * @param props
 * @param props.title Card title
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.isLoadingPayoutAmount Indicates if loading state for payout amount should be displayed
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.payoutValue Payout value
 * @param props.payoutAmountFormat Indicates the payout amount format
 * @param props.children Picker to be used inside the card
 * @param props.hasError Indicates if error state should be displayed
 * @param props.onRequestDetails Callback function triggered on click at details button
 * @param props.onRetry Callback function for retry button
 * @param props.payoutSubtitleLabel Payout label value for open and close instances
 */
export const DashboardCard = ({
  title,
  isLoading = false,
  isLoadingPayoutAmount = false,
  isPayoutOpen,
  payoutValue,
  payoutAmountFormat,
  children,
  hasError = false,
  onRequestDetails,
  onRetry,
  payoutSubtitleLabel,
}: DashboardCardProps) => {
  const parsePayoutValue = (amount: number, format: 'decimal' | 'microcents') => {
    if (format === 'microcents') {
      return formatNumberMicroCentsToReal(amount)
    }

    return amount
  }

  const isNegative = parsePayoutValue(payoutValue, payoutAmountFormat) < 0

  const formattedValue = (() => {
    const parsedValue = parsePayoutValue(Math.abs(payoutValue), payoutAmountFormat)

    const formatter = new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })

    return formatter.format(parsedValue)
  })()

  const valueLabel = payoutSubtitleLabel ?? getPayoutAmountLabel(isNegative, isPayoutOpen)

  if (hasError) {
    return <ErrorState title={title} onRetry={onRetry} />
  }

  if (isLoading) {
    return <LoadingState />
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="18.625rem"
      px="2rem"
      py="3rem"
      border={`1px solid ${BORDER_COLOR}`}
      borderRadius="0.5rem"
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Box mb="0.5rem">
            <Typography
              variation="headlineDesktopXsmall"
              color="primary"
              data-testid="title-element"
            >
              {title}
            </Typography>
          </Box>

          {children}
        </Box>

        {isPayoutOpen ? (
          <Chip variation="yellow" label="Aberto" />
        ) : (
          <Chip variation="green" label="Fechado" />
        )}
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Typography variation="bodyLarge" color="secondary" data-testid="payout-value-label">
            {valueLabel}
          </Typography>

          {isLoadingPayoutAmount ? (
            <Skeleton
              variant="rect"
              height="3rem"
              style={{ borderRadius: '0.5rem' }}
              data-testid="amount-loading"
            />
          ) : (
            <Amount
              color={isNegative ? NEGATIVE_VALUE_COLOR : POSITIVE_VALUE_COLOR}
              data-testid="payout-value-element"
            >
              <CurrencySign>{isNegative ? '- R$' : 'R$'}</CurrencySign>
              {formattedValue}
            </Amount>
          )}
        </Box>

        <Button onClick={onRequestDetails}>Ver detalhes</Button>
      </Box>
    </Box>
  )
}
