import { InfoCardRoot } from './components/InfoCardRoot'
import { InfoCardIcon } from './components/InfoCardIcon'
import { InfoCardTitle } from './components/InfoCardTitle'
import { InfoCardValue } from './components/InfoCardValue'
import { InfoCardAction } from './components/InfoCardAction'

/**
 * Generic card to display simple amount data
 */
export const InfoCard = {
  Root: InfoCardRoot,
  Icon: InfoCardIcon,
  Title: InfoCardTitle,
  Value: InfoCardValue,
  Action: InfoCardAction,
}
