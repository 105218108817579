import { OPTIONS_GROUP } from '@/modules/access-management/constants/OPTIONS_GROUP'
import { AssignAccessRoles } from '@/modules/access-management/types/AssignAccessRoles'
import type { GroupNames } from '@/modules/access-management/types/GroupNames'

export const getAvailableOptions = ({
  assignAccessRoles,
}: {
  assignAccessRoles: AssignAccessRoles[]
}) => {
  return OPTIONS_GROUP.reduce((acc, option: { label: string; value: GroupNames }) => {
    if (assignAccessRoles.includes('atribuir_acesso_gestor')) {
      return [...acc, option]
    }
    if (assignAccessRoles.includes('atribuir_acesso_mantenedor') && option.value !== 'gestor') {
      return [...acc, option]
    }
    return acc
  }, [] as { label: string; value: GroupNames }[])
}
