import config from '@/config'
import { Azp, JWTProps, Realm as Rm } from '@/modules/app/contexts/GlobalProviders/Auth/types'
import {
  AuthorizationClient,
  COOKIE_ACCESS_TOKEN,
  queryTreeBuilder,
  Realm,
  Client,
  SortingHatResponse,
  UserAuthorizationData,
} from '@olaisaac/sorting-hat'
import Cookies, { CookieSetOptions } from 'universal-cookie'

export const loginURL = `${config.SORTING_HAT.HOST}${config.SORTING_HAT.LOGIN_PATH}`
const goToLoginScreen = () => window.location.replace(loginURL)

const cookies = new Cookies()
const cookieDefaults: CookieSetOptions = {
  domain: config.API.COOKIES_DOMAIN,
  path: '/',
  sameSite: 'lax',
}
export enum CookiesKeys {
  AUTH = '__OISA-SH-AT',
  REALM = '__OISA-SH-TR',
  REFRESH = '__OISA-SH-RT',
}

const clearCookies = () => {
  const options: CookieSetOptions = { ...cookieDefaults, expires: new Date(0) }

  cookies.set(CookiesKeys.AUTH, null, options)
  cookies.set(CookiesKeys.REFRESH, null, options)
  cookies.set(CookiesKeys.REALM, null, options)

  document.cookie.split(';').forEach(cookie => {
    const [name] = cookie.split('=')
    if (name) {
      cookies.remove(name)
    }
  })
}

export const logOut = () => {
  clearCookies()
  goToLoginScreen()
}

export const sortingHatClientV3 = new AuthorizationClient({
  baseURL: config.SORTING_HAT.HOST,
  appName: config.SORTING_HAT.APP_AZP,
})

export const checkIfIsAuthenticated = () => {
  const AT = cookies.get(COOKIE_ACCESS_TOKEN)
  if (!AT) {
    goToLoginScreen()
  }
}

export const getCredential = () => {
  return cookies.get(COOKIE_ACCESS_TOKEN)
}

export const isAuthorized = () => {
  const credential = getCredential()

  const requirements = {
    realm: config.SORTING_HAT.REALM as Realm,
    client: config.SORTING_HAT.APP_AZP as Client,
  }
  const tree = queryTreeBuilder(requirements)
  return sortingHatClientV3.isAuthorized(credential, true, tree)
}

export const SHResponseToJWTProps = (shResponse: SortingHatResponse): JWTProps => {
  const response = shResponse
  let jwtProps = {} as JWTProps
  const shData = response.data as UserAuthorizationData
  jwtProps = {
    azp: shData.client as Azp,
    email: shData.user.email,
    exp: shData.expires_at,
    family_name: shData.user.last_name,
    given_name: shData.user.first_name,
    name: shData.user.first_name + ' ' + shData.user.last_name,
    preferred_username: shData.user.username,
    realm: shData.realm as Rm,
    resource_definition: { definition: shData.multi_tenant_roles },
    resource_roles: shData.plain_roles,
    sub: shData.user.id,
  }
  return jwtProps
}

export const isSchoolUserWithoutAccess = (userData: UserAuthorizationData): boolean => {
  const hasNoResources = !userData.resources || !userData.resources.length
  return (
    userData.realm === Realm.isaac && userData.client === Client.portalEscolas && hasNoResources
  )
}
