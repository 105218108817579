import { Box } from '@material-ui/core'
import { Button, Pagination } from '@olaisaac/design-system'
import { Add } from '@material-ui/icons'

import { useJWT } from '@/shared/hooks'

import theme from '@/shared/theme'

type FooterProps = {
  onChangeItemsPerPage: (itemsPerPage: number) => void
  onChangePage: (page: number) => void
  onClickAddProductButton: () => void
  pagination: {
    itemsPerPage: number
    page: number
    total: number
  }
}

const BORDER_COLOR = theme.primitiveTokens.colors.gray[5]

export const Footer = ({
  pagination,
  onChangePage,
  onChangeItemsPerPage,
  onClickAddProductButton,
}: FooterProps) => {
  const { isAdmin } = useJWT()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1.5rem 4rem 2.5rem"
      borderTop={`1px solid ${BORDER_COLOR}`}
    >
      <Pagination
        currentPage={pagination.page}
        itensPerPage={pagination.itemsPerPage}
        totalItens={pagination.total}
        onPageChange={onChangePage}
        onItensPerChangeChange={onChangeItemsPerPage}
      />

      {isAdmin && (
        <Button variation="primary" startIcon={<Add />} onClick={onClickAddProductButton}>
          Novo produto
        </Button>
      )}
    </Box>
  )
}
