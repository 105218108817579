import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

export const useSendGuardianDetailsInvoiceEvent = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  if (!isInitialized) return null

  return (component: 'row' | 'button', options?: Record<string, unknown>) => {
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INSTALLMENT_DRAWER,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      customProperties: {
        $component: component,
        ...options,
      },
    })
  }
}
