import { TabWrapper } from '../TabWrapper'
import { Iframe } from './styles'

type DashboardEmbeddedProps = {
  id: string
  isVisible: boolean
  refetch: () => void
  status: 'error' | 'success' | 'loading'
  style?: React.CSSProperties
  url: string
}

export const DashboardEmbedded = ({
  id,
  url,
  status,
  refetch,
  isVisible,
  style,
}: DashboardEmbeddedProps) => {
  return (
    <>
      <Iframe
        id={id}
        src={url}
        style={{ ...style, display: isVisible && status === 'success' ? 'block' : 'none' }}
      />
      <TabWrapper status={status} onRetry={refetch} isVisible={isVisible} />
    </>
  )
}
