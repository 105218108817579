import CreditSummaryCard from '../../components/CreditSummaryCard'
import { ButtonGroup, GhostButton, StyledGrid, StyledOverlay } from './styles'
import { Button } from '@gravity/button'
import { Tooltip } from '@gravity/tooltip'
import {
  useContextChosenOffer,
  useContextCreditOrderId,
  useContextCreditSimulation,
  useContextRequestSimulation,
  useContextStepperMap,
  useReasonForChosenPolicyType,
} from '../../contexts/CreditDataContext'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useEffect, useState } from 'react'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useChooseOffer } from '../../hooks/queries/useChooseOffer'
import DefaultTemplate from '../../templates/DefaultTemplate'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import PageExitConfirm from '../../components/PageExitConfirm'
import { useGetLock } from '../../hooks/queries/useGetLock'
import { APIResponse } from '@/shared/interfaces/api'
import { GetLockResponse } from '../../services/types'
import { CircularProgress } from '@material-ui/core'

export const CreditSummary = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const { push } = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const { creditSimulation } = useContextCreditSimulation()
  const { chosenOffer, setChosenOffer } = useContextChosenOffer()
  const { reasonForChosenPolicyType } = useReasonForChosenPolicyType()
  const { setCreditOrderId } = useContextCreditOrderId()
  const { setStepperMap } = useContextStepperMap()
  const { requestSimulation } = useContextRequestSimulation()
  const { mutateAsync } = useChooseOffer()
  const { school } = useSelectedSchool()
  const { mutateAsync: mutateAsyncLock } = useGetLock(school.id)
  const [orderLock, setOrderLock] = useState<GetLockResponse>(null)
  const statusPage = `/${schoolSlug}/credito/estado`
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.SIMULATION_RESULT,
    })
  }, [])

  useEffect(() => {
    const checkLock = async () => {
      const lock = await mutateAsyncLock()
      setOrderLock(lock)
    }

    checkLock()
  }, [mutateAsyncLock])

  const handleRequestCredit = async () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.REQUEST_CREDIT,
      $selected: requestSimulation.reason,
      $note: requestSimulation.note,
      $request_value: chosenOffer.totalAmount,
      $installments: chosenOffer.totalInstallments,
      $selected_period: requestSimulation.gracePeriodDate?.label,
      $options_period: requestSimulation.gracePeriodDates?.map(date => date.label),
      $modality_type: chosenOffer.category,
    })

    setIsLoading(true)

    mutateAsync({
      orderId: creditSimulation.orderId,
      chooseOfferId: chosenOffer.id,
    }).then(async (response: APIResponse) => {
      if (response) {
        setCreditOrderId(creditSimulation.orderId)
        setStepperMap({ step: 0, finished: true })
        push(statusPage)
      } else {
        location.href = statusPage
      }
    })
  }

  const handleSimulateNewOffer = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.SIMULATE_NEW_OFFER,
    })
    push(`/${schoolSlug}/credito/simulacao`)
  }

  const isButtonDisabled = orderLock && orderLock.isLocked

  // TODO: this is a quick solution to redirect user if there is no data in context
  // we should persist data in browser storage or use useNavigate to avoid this
  if (!creditSimulation) {
    return <Redirect to={`/${schoolSlug}/credito`} />
  }

  if (isLoading) {
    return (
      <StyledOverlay>
        <CircularProgress />
      </StyledOverlay>
    )
  }

  return (
    <DefaultTemplate
      title="Resumo da sua oferta"
      child={
        <>
          <CreditSummaryCard
            chosenOffer={chosenOffer}
            setChosenOffer={setChosenOffer}
            reasonForChosenPolicyType={reasonForChosenPolicyType}
            {...creditSimulation}
          />
          <ButtonGroup>
            <GhostButton variant="ghost" onClick={handleSimulateNewOffer}>
              Simular nova oferta
            </GhostButton>
            {isButtonDisabled ? (
              <StyledGrid>
                <Tooltip text="Já existe um pedido da mesma modalidade em aberto">
                  <Button fullWidth onClick={handleRequestCredit} disabled={isButtonDisabled}>
                    Solicitar crédito
                  </Button>
                </Tooltip>
              </StyledGrid>
            ) : (
              <Button fullWidth onClick={handleRequestCredit} disabled={isButtonDisabled}>
                Solicitar crédito
              </Button>
            )}
          </ButtonGroup>

          <PageExitConfirm
            title="Nos ajude a construir a oferta de crédito ideal para você"
            labelReason="Quais pontos da oferta simulada não te atenderam?"
            confirmButtonLabel="Sair da oferta"
            backButtonLabel="Continuar na oferta"
          />
        </>
      }
      handleBackPreviousRoute={() => {
        push(`/${schoolSlug}/credito/simulacao`)
      }}
    />
  )
}
