import { PayoutReportDashboard } from '../pages/PayoutReportDashboard'
import { TuitionPayoutReport } from '../pages/TuitionPayoutReport'
import { EnrollmentPayoutReport } from '../pages/EnrollmentPayoutReport'
import { IsaacPayPayoutReport } from '../pages/IsaacPayPayoutReport'

import type { RouteDefinition } from '@/modules/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'PAYOUT_DASHBOARD',
    path: '/repasses',
    component: PayoutReportDashboard,
    exact: true,
  },
  {
    key: 'TUITION_PAYOUT',
    path: '/repasses/mensalidades',
    component: TuitionPayoutReport,
    exact: true,
  },
  {
    key: 'ENROLLMENT_PAYOUT',
    path: '/repasses/matriculas',
    component: EnrollmentPayoutReport,
    exact: true,
  },
  {
    key: 'ISAAC_PAY_PAYOUT',
    path: '/repasses/isaac-pay',
    component: IsaacPayPayoutReport,
    exact: true,
  },
]
