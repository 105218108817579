import { ReactNode, useEffect, useState } from 'react'
import axios from 'axios'
import { identity } from 'ramda'

import { RESPONSE_STATUS } from '@/shared/interfaces'
import {
  SHResponseToJWTProps,
  isAuthorized,
  logOut,
  isSchoolUserWithoutAccess,
} from '@/shared/api/sortingHatClientV3'
import { JWTProvider } from '@/shared/contexts/JWTContext'

import { JWTProps } from './types'

import { CircularProgress } from '@material-ui/core'

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [jwtProps, setJwtProps] = useState(null as JWTProps)
  useEffect(() => {
    const runSyncIsAuthorized = async () => {
      const response = await isAuthorized()
      if (!response.success || isSchoolUserWithoutAccess(response.data)) {
        return logOut()
      }

      const jwtProp = SHResponseToJWTProps(response)
      setJwtProps(jwtProp)
    }
    if (!jwtProps) {
      runSyncIsAuthorized()
    }
  }, [])

  axios.defaults.withCredentials = true

  axios.interceptors.response.use(identity, error => {
    const shouldRedirect =
      error.response.status === RESPONSE_STATUS.UNAUTHORIZED ||
      error.response.status === RESPONSE_STATUS.FORBIDDEN
    if (shouldRedirect) {
      return logOut()
    }

    return error
  })
  if (!jwtProps) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </div>
    )
  }
  return <JWTProvider props={jwtProps}>{children}</JWTProvider>
}
