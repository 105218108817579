import { StatusSummaryResponse } from '@/escolas/services/enrollment/types'
import { ReenrollmentStatus } from '@/shared/interfaces'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { School } from '@/shared/models/School'

import { StatusSummaryCard, StatusSummaryCardProps } from '../StatusSummaryCard'
import * as Styled from './styles'

const statusByPriority = [
  ReenrollmentStatus.ENROLLMENT_AT_RISK,
  ReenrollmentStatus.NOT_REENROLLABLE,
  ReenrollmentStatus.ENROLLMENT_IN_PROGRESS,
  ReenrollmentStatus.REENROLLMENT_AVAILABLE,
  ReenrollmentStatus.ENROLLED,
]

export const mapStatusToProps = (
  status: ReenrollmentStatus,
  school: School
): Pick<StatusSummaryCardProps, 'text' | 'tooltip'> => {
  return {
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: {
      text: 'alunos estão com matrícula em risco',
      tooltip:
        'É necessário regularizar o pagamento das parcelas vencidas, do contrário a rematrícula pode ser cancelada.',
    },
    [ReenrollmentStatus.NOT_REENROLLABLE]: {
      text: 'alunos não podem ser rematriculados',
      tooltip: 'É necessário regularizar o pagamento das parcelas vencidas.',
    },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: {
      text: 'alunos estão com matrícula em andamento',
      tooltip: `A matrícula do aluno será confirmada assim que todas as parcelas em aberto em contratos anteriores forem quitados e a primeira parcela de pré-matricula do contrato atual for paga (se aplicável).`,
    },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: {
      text: 'alunos podem iniciar a rematrícula',
      tooltip: 'A rematrícula do aluno pode ser iniciada, pois todos os pagamentos estão em dia.',
    },
    [ReenrollmentStatus.ENROLLED]: {
      text: 'alunos estão com a matrícula confirmada',
      tooltip: isIsaacPaySchool(school)
        ? 'Tudo certo com a matrícula do aluno. Suas mensalidades serão repassadas pelo isaac!'
        : 'Tudo certo com a matrícula do aluno. Suas mensalidades estão garantidas e serão repassadas pelo isaac!',
    },
    [ReenrollmentStatus.GATE_ERROR]: {
      text: '',
      tooltip: '',
    },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: {
      text: '',
      tooltip: '',
    },
  }[status]
}

type StatusSummaryProps = {
  data: StatusSummaryResponse['data']
  isError: boolean
  isLoading: boolean
}

export const StatusSummary = ({ data, isLoading, isError }: StatusSummaryProps) => {
  const { school, isCollege } = useSelectedSchool()

  if (isCollege) {
    return null
  }

  if (isLoading) {
    return (
      <Styled.Container>
        {[...Array(3)].map((_, index) => (
          <Styled.Skeleton key={index} variant="rect" />
        ))}
      </Styled.Container>
    )
  }

  if (isError) {
    return null
  }

  return (
    <Styled.Container role="listbox" aria-label="Filtro de status" aria-multiselectable="true">
      {statusByPriority.map(status => {
        const value = data.status[status]

        if (!value) {
          return null
        }

        return (
          <StatusSummaryCard
            key={status}
            status={status}
            value={value}
            {...mapStatusToProps(status, school)}
          />
        )
      })}
    </Styled.Container>
  )
}
