import type { ReenrollmentStatus } from '@/shared/interfaces'

export enum columnNames {
  GUARDIAN_NAME = 'guardian_name',
  HAS_OPENED_EMAIL = 'has_open_email',
  IS_ENGAGED = 'is_engaged',
  PAYMENT_CONFIRMED = 'paid_payment_plan',
  STUDENT_NAME = 'student_name',
}
type ColumnName = `${columnNames}`

export type TableRowProps = {
  columns: Record<columnNames, string>
  guardianEmail: string
  isCampaignProposalCanceled: boolean
  key: string
  reenrollmentStatus: ReenrollmentStatus
}

export type ColumnProps = {
  key: ColumnName
  label: string
  minWidth: string
  sortable?: boolean
}
