import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

type EventAction =
  | 'click'
  | 'type'
  | 'link_click'
  | 'scroll'
  | 'hover'
  | 'page_view'
  | 'video_progress'
  | 'file_download'
  | 'callback'
  | 'component_view'

type Event = {
  action: EventAction
  customProperties: Record<string, any>
  name: string
  scope: string
}

const createEvent = (): Event => ({
  name: '',
  scope: EventDispatcherEventScopes.ENROLLMENT,
  action: 'click',
  customProperties: {},
})

const setName = (event: Event, name: string): Event => ({
  ...event,
  name,
})

const setAction = (event: Event, action: EventAction): Event => ({
  ...event,
  action,
})

const addCustomProperty = (event: Event, key: string, value: any): Event => ({
  ...event,
  customProperties: {
    ...event.customProperties,
    [key]: value,
  },
})

const createEventBuilder = () => {
  let event = createEvent()

  const builder = {
    setName: (name: string) => {
      event = setName(event, name)
      return builder
    },
    setAction: (action: EventAction) => {
      event = setAction(event, action)
      return builder
    },
    addCustomProperty: (key: string, value: string) => {
      event = addCustomProperty(event, key, value)
      return builder
    },
    build: () => event,
  }

  return builder
}

export const useEnrollmentMixpanelEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (eventBuilder: ReturnType<typeof createEventBuilder>) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent(eventBuilder.build())
    }
  }

  const sendButtonClickEvent = (name: string, selected_item?: string) => {
    const eventBuilder = createEventBuilder()
      .setName(EventDispatcherEvents.BUTTON_CLICKED)
      .setAction('click')
      .addCustomProperty('$button_name', name)

    if (selected_item) eventBuilder.addCustomProperty('$selected_item', selected_item)
    sendEvent(eventBuilder)
  }

  const sendPageViewEvent = (name: string) => {
    sendEvent(
      createEventBuilder()
        .setName(EventDispatcherEvents.PAGE_VIEWED)
        .setAction('page_view')
        .addCustomProperty('$page_name', name)
    )
  }

  const sendTabClickEvent = (name: string, selected_tab: string) => {
    sendEvent(
      createEventBuilder()
        .setName(EventDispatcherEvents.TAB_CLICKED)
        .setAction('click')
        .addCustomProperty('$tab_name', name)
        .addCustomProperty('$selected_item', selected_tab)
    )
  }

  const sendModalViewEvent = (name: string) => {
    sendEvent(
      createEventBuilder()
        .setName(EventDispatcherEvents.MODAL_VIEWED)
        .setAction('component_view')
        .addCustomProperty('$modal_name', name)
    )
  }

  return {
    sendButtonClickEvent,
    sendPageViewEvent,
    sendTabClickEvent,
    sendModalViewEvent,
  }
}
