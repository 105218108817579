import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useGuardiansInstallmentsQuery } from '@/modules/guardians/GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useParams } from 'react-router-dom'
import { InstallmentsDrawerContainerV2 } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { Grid, NotificationProps } from '@olaisaac/design-system'
import { useForm } from 'react-hook-form'

import { Agglutination } from '@/modules/guardians/GuardianDetails/features/Agglutination/Agglutination'
import { UpdateEnrollmentStatus } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/UpdateEnrollmentStatus/UpdateEnrollmentStatus'
import { GuardianDetailsInstallmentsFilter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsFilter/GuardianDetailsInstallmentsFilter'
import { useGuardiansInstallmentsQueryParams } from '@/modules/guardians/GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsQueryParams'
import { useGuardianFiltersOptionsQuery } from '@/modules/guardians/GuardianDetails/hooks/useGuardianFiltersOptionsQuery'
import { getUniqueBufferInstallmentsByStudent } from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/getUniqueBufferInstallmentsOfStudent'
import { UnleashFlags, useJWT, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import { ReceivablesAgglutinationProvider } from '@/escolas/contexts/receivablesAgglutinationContext'
import { useQueryClient } from '@tanstack/react-query'
import {
  TrackDrawerClickEvents,
  useGuardiansInstallmentsTabEvents,
} from '@/modules/guardians/GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsTabEvents'
import { useQuery } from '@/shared/hooks/useQuery'
import useUserGuiding from '@/escolas/router/responsavel/[guardianId]/contratos/hooks/useUserGuiding'
import { CONTRACT_TABLE_TAB } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'
import {
  getModalShownDate,
  storeModalShownDate,
} from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/infoFromSession'
import {
  InstallmentStatus,
  InstallmentStatusDescription,
  InstallmentType,
} from '@/modules/guardians/GuardianDetails/constants'
import { isTimeExpired } from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/getTimeExpiration'
import { SHOW_MODAL_AGAIN_IN_HOURS } from '@/modules/guardians/GuardianDetailsInstallmentsTab/constants'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { DEFAULT_AWAITING_TIME } from '@/modules/guardians/constants'
import { GuardianDetailsDisclaimerModal } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsDisclaimerModal/GuardianDetailsDisclaimerModal'
import { CloseReason } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsDisclaimerModal/types'
import { Row } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTable/types'
import { GuardianInvoicesTable } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTable/GuardianInvoicesTable'
import AgglutinationDrawer from '@/modules/guardians/InstallmentsDrawerContainer/AgglutinationDrawer/AgglutinationDrawer'
import { AgglutinationMetaData } from '@/escolas/components/contract/ContractDetails'
import { GuardianDetailsFooter } from '@/modules/guardians/GuardianDetails/features/GuardianDetailsFooter/GuardianDetailsFooter'

type GuardianDetailsInvoicesTabProps = {
  setShowPendingEnrollmentProcessingAlert: Dispatch<SetStateAction<boolean>>
}

export const GuardianDetailsInvoicesTab = ({
  setShowPendingEnrollmentProcessingAlert,
}: GuardianDetailsInvoicesTabProps) => {
  const { isAdmin, userId, name, email } = useJWT()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { school } = useSelectedSchool()
  const { query, setOnQueryParam } = useQuery()
  const queryClient = useQueryClient()
  const { selectedInstallment, setSelectedInstallment } = useInstallmentDrawerContext()

  const [showInstallmentsDetailsDrawer, setShowInstallmentsDetailsDrawer] = useState(false)
  const [isProcessingAgglutination, setProcessingAgglutination] = useState<boolean>(false)
  const [refetchCount, setRefetchCount] = useState(0)
  const [isFirstInstallmentsFetch, setIsFirstInstallmentsFetch] = useState(true)
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
  const [showAgglutinationDrawer, setShowAgglutinationDrawer] = useState<boolean>(false)

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const showSnackbar = (variation: NotificationProps['variation'], message: string) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  const agglutinationForm = useForm<AgglutinationMetaData>({
    mode: 'onChange',
  })

  const { trackRowClick, trackDrawerClick } = useGuardiansInstallmentsTabEvents()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const {
    filters: installmentsFilters,
    updateFilters,
    clearFilters,
  } = useGuardiansInstallmentsQueryParams(true)

  const {
    filterOptions,
    isFiltersOptionsFetched,
    refetchFiltersOptions,
  } = useGuardianFiltersOptionsQuery({
    guardianId,
    schoolId: school.id,
  })

  const {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
    refetchGuardiansInstallments,
    isRefetchingGuardiansInstallments,
    isFetchGuardiansInstallmentsFetched,
  } = useGuardiansInstallmentsQuery({
    filter: installmentsFilters,
    guardianId,
    schoolId: school.id,
  })

  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )

  const uniqueBufferInstallmentsByStudent = getUniqueBufferInstallmentsByStudent(
    guardiansInstallments?.data
  )

  const studentIds = uniqueBufferInstallmentsByStudent?.map(installment => installment.student.id)

  const isLoadingInstallments =
    isFetchGuardiansInstallmentsLoading || isRefetchingGuardiansInstallments

  const hasEnabledCheckbox = guardiansInstallments?.data?.some(installment =>
    [InstallmentStatus.DUE_TODAY, InstallmentStatus.OPEN, InstallmentStatus.OVERDUE].includes(
      installment.status
    )
  )

  const handleCloseInstallmentsDetailsDrawer = () => {
    setSelectedInstallment(null)
    setShowInstallmentsDetailsDrawer(false)
  }

  const handleRowClick = (row: Row) => {
    trackRowClick(row.row_type, row.contract_id)
    setSelectedInstallment(row)
    trackDrawerClick(row.urn, TrackDrawerClickEvents.OPEN)
  }

  const handleDisclaimerModalClose = (
    _: React.SyntheticEvent<Element, Event>,
    reason: CloseReason
  ) => {
    if (reason !== 'backdropClick') {
      setShowDisclaimerModal(false)
      storeModalShownDate(guardianId)
    }
  }

  const checkPendingEnrollProcessing = (data: Installment[]) => {
    const pendingEnrollProcessing = data?.reduce((result, installment) => {
      return (
        result ||
        installment.status_description === InstallmentStatusDescription.PENDING_ENROLL_PROCESSING
      )
    }, false)
    if (pendingEnrollProcessing && refetchCount < 3) {
      setTimeout(() => {
        queryClient.invalidateQueries(['guardian-details-installments'])
        setRefetchCount(refetchCount + 1)
        refetchGuardiansInstallments()
      }, DEFAULT_AWAITING_TIME)
    } else if (pendingEnrollProcessing && refetchCount === 3) {
      setShowPendingEnrollmentProcessingAlert(true)
    }
  }

  const handleAgglutinationClose = () => {
    setShowAgglutinationDrawer(false)
  }

  const callbackAgglutination = () => {
    showSnackbar('success', 'Aglutinação realizada com sucesso')
    setOnQueryParam('true', 'refetch')
    setShowAgglutinationDrawer(false)
  }

  useUserGuiding({
    userId,
    email,
    name,
    isAdmin,
    isLoading: isFetchGuardiansInstallmentsLoading,
    activeTab: CONTRACT_TABLE_TAB.INSTALLMENTS,
  })

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (isFetchGuardiansInstallmentsFetched && isFirstInstallmentsFetch) {
      setIsFirstInstallmentsFetch(false)

      if (guardiansInstallments?.data.length === 0) {
        clearFilters()
      }
    }
  }, [isFetchGuardiansInstallmentsFetched, isFirstInstallmentsFetch])

  useEffect(() => {
    if (shouldRefetch) {
      setOnQueryParam(null, 'refetch')
      refetchFiltersOptions()
      refetchGuardiansInstallments()
    }
  }, [shouldRefetch])

  useEffect(() => {
    if (selectedInstallment) {
      setShowInstallmentsDetailsDrawer(true)
    }
  }, [selectedInstallment])

  useEffect(() => {
    const whenModalBeenShown = getModalShownDate(guardianId)
    if (!guardiansInstallments?.data && !isFetchGuardiansInstallmentsFetched) return
    checkPendingEnrollProcessing(guardiansInstallments?.data)
    const hasDownPaymentInstallment = guardiansInstallments?.data.some(
      installment => installment.type === InstallmentType.DOWN_PAYMENT
    )

    if (
      hasDownPaymentInstallment &&
      isTimeExpired(whenModalBeenShown, SHOW_MODAL_AGAIN_IN_HOURS) &&
      !isAdmin
    ) {
      setShowDisclaimerModal(true)
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
          entity: EventDispatcherEntities.OPEN_NEGOTIATION_MODAL,
          customProperties: {
            $name: 'Responsável com negociação em andamento',
          },
          name: EventDispatcherEvents.MODAL_VIEWED,
          action: 'component_view',
        })
    }
  }, [guardiansInstallments, isFetchGuardiansInstallmentsFetched, refetchCount])

  return (
    <>
      <Grid item xs={4}>
        <GuardianDetailsDisclaimerModal
          isOpen={showDisclaimerModal}
          onClose={handleDisclaimerModalClose}
        />
        <GuardianDetailsInstallmentsFilter
          filters={isFirstInstallmentsFetch ? {} : installmentsFilters}
          updateFilters={updateFilters}
          filtersOptions={filterOptions?.data}
          isFiltersOptionsFetched={isFiltersOptionsFetched}
          agglutinationButton={
            isAdmin && isRenegotiateAndAgglutinateButtonEnabled ? (
              <Agglutination
                setShowAgglutinationDrawer={() => {
                  setShowAgglutinationDrawer(true)
                }}
              />
            ) : null
          }
          updateEnrollmentStatusButton={
            <UpdateEnrollmentStatus
              schoolId={school.id}
              studentIds={studentIds}
              isLoadingInstallments={isLoadingInstallments}
            />
          }
        />

        <GuardianInvoicesTable
          loading={isLoadingInstallments}
          installments={guardiansInstallments?.data || []}
          onRowClick={handleRowClick}
        />

        {showInstallmentsDetailsDrawer && (
          <InstallmentsDrawerContainerV2
            onClose={handleCloseInstallmentsDetailsDrawer}
            guardianId={guardianId}
            selectedInstallment={selectedInstallment}
          />
        )}

        {showAgglutinationDrawer && (
          <ReceivablesAgglutinationProvider>
            <AgglutinationDrawer
              isOpen={showAgglutinationDrawer}
              onClose={handleAgglutinationClose}
              form={agglutinationForm}
              isProcessingAgglutination={isProcessingAgglutination}
              disableChecks={() => setProcessingAgglutination(true)}
              enableChecks={() => setProcessingAgglutination(false)}
              callbackAgglutination={callbackAgglutination}
            />
          </ReceivablesAgglutinationProvider>
        )}
      </Grid>
      {hasEnabledCheckbox && <GuardianDetailsFooter />}
    </>
  )
}
