import styled from 'styled-components'
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@material-ui/core'

const HEADER_HEIGHT = '96px'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT});
  padding: ${({ theme }) => `${theme.gravity.spacing[8]} ${theme.gravity.spacing[16]} 0`};
`

export const Tabs = styled(MaterialTabs)`
  width: 100%;
  border-bottom: 1px solid #dadada;
  margin-bottom: ${({ theme }) => theme.gravity.spacing[8]};
`

export const Tab = styled(MaterialTab)`
  color: #999999;
  font-size: ${({ theme }) => theme.gravity.fontSize.base};
  text-transform: none;
  width: max-content;
  height: 40px;
  font-weight: ${({ theme }) => theme.gravity.fontWeight.normal};
  border-bottom: 2px solid transparent;
  transition: border 0.25s ease-in-out;
  white-space: nowrap;
  padding: 0 ${({ theme }) => theme.gravity.spacing[4]};

  &.Mui-selected {
    color: ${({ theme }) => theme.gravity.colors['colors-text-main-2']};
  }

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.gravity.colors['colors-background-accent-1']};
  }
`
