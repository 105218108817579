export enum IrregularStudentStatus {
  FINISHED = 'finished',
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
}

export enum IrregularStudentStatusFilter {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export enum IrregularStudentStatusPayloadResponse {
  finished = 'finished',
  in_progress = 'in_progress',
  not_started = 'not_started',
}

export const irregularStatusList: Array<IrregularStudentStatus> = [
  IrregularStudentStatus.NOT_STARTED,
  IrregularStudentStatus.IN_PROGRESS,
  IrregularStudentStatus.FINISHED,
]

export enum GuardianIrregularityType {
  ADDRESS_CITY = 'ADDRESS_CITY',
  ADDRESS_STATE_CODE = 'ADDRESS_STATE_CODE',
  ADDRESS_STREET = 'ADDRESS_STREET',
  ADDRESS_ZIP_CODE = 'ADDRESS_ZIP_CODE',
  GUARDIAN_EMAIL = 'GUARDIAN_EMAIL',
  GUARDIAN_NAME = 'GUARDIAN_NAME',
  GUARDIAN_PHONE_NUMBER = 'GUARDIAN_PHONE_NUMBER',
}

export const irregularStatusTranslationDict: Record<IrregularStudentStatus, string> = {
  [IrregularStudentStatus.FINISHED]: 'Correção concluída',
  [IrregularStudentStatus.IN_PROGRESS]: 'Correção iniciada',
  [IrregularStudentStatus.NOT_STARTED]: 'Correção pendente',
}

export const guardianIrregularityParamsDict: PartialRecord<
  GuardianIrregularityType,
  { action: string; name: string }
> = {
  [GuardianIrregularityType.ADDRESS_CITY]: {
    name: 'Endereço do responsável',
    action: 'Informar cidade do endereço',
  },
  [GuardianIrregularityType.ADDRESS_STATE_CODE]: {
    name: 'Endereço do responsável',
    action: 'Informar UF válido para o endereço',
  },
  [GuardianIrregularityType.ADDRESS_STREET]: {
    name: 'Endereço do responsável',
    action: 'Informar logradouro do endereço',
  },
  [GuardianIrregularityType.ADDRESS_ZIP_CODE]: {
    name: 'Endereço do responsável',
    action: 'Informar CEP válido para o endereço',
  },
  [GuardianIrregularityType.GUARDIAN_EMAIL]: {
    name: 'Contato do responsável',
    action: 'Informar e-mail válido',
  },
  [GuardianIrregularityType.GUARDIAN_NAME]: {
    name: 'Contato do responsável',
    action: 'Informar nome do responsável',
  },
  [GuardianIrregularityType.GUARDIAN_PHONE_NUMBER]: {
    name: 'Contato do responsável',
    action: 'Informar celular válido, que deve ter de 10 a 13 dígitos',
  },
}

export enum InstallmentIrregularityType {
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_DAYS_BEFORE_DUE_DATE = 'DISCOUNT_DAYS_BEFORE_DUE_DATE',
  DISCOUNT_DESCRIPTION = 'DISCOUNT_DESCRIPTION',
  DISCOUNTS_LENGTH = 'DISCOUNTS_LENGTH',
  DISCOUNTS_TOTAL_AMOUNT = 'DISCOUNTS_TOTAL_AMOUNT',
  DUPLICATED_ACCRUAL_DATE = 'DUPLICATED_ACCRUAL_DATE',
  INSTALLMENT_AMOUNT = 'INSTALLMENT_AMOUNT',
  INSTALLMENT_COMPETENCE_DATE = 'INSTALLMENT_COMPETENCE_DATE',
  INSTALLMENT_DUE_DATE_EXPIRED = 'INSTALLMENT_DUE_DATE_EXPIRED',
  INSTALLMENT_DUE_DATE_MISSING = 'INSTALLMENT_DUE_DATE_MISSING',
  INSTALLMENT_TYPE = 'INSTALLMENT_TYPE',
  INVALID_FORMAT_ACCRUAL_DATE = 'INVALID_FORMAT_ACCRUAL_DATE',
}

export const installmentIrregularityParamsDict: PartialRecord<
  InstallmentIrregularityType,
  { action: string; name: string }
> = {
  [InstallmentIrregularityType.INSTALLMENT_DUE_DATE_MISSING]: {
    name: 'Vencimento da parcela',
    action: 'Informar vencimento da parcela, que não pode ser anterior a hoje',
  },
  [InstallmentIrregularityType.INSTALLMENT_DUE_DATE_EXPIRED]: {
    name: 'Vencimento da parcela',
    action: 'Corrigir vencimento da parcela, que não pode ser anterior a hoje',
  },
  [InstallmentIrregularityType.INSTALLMENT_COMPETENCE_DATE]: {
    name: 'Competência da parcela',
    action: 'É permitido somente uma parcela por mês de competência neste produto',
  },
  [InstallmentIrregularityType.INVALID_FORMAT_ACCRUAL_DATE]: {
    name: 'Competência da parcela',
    action: 'Corrigir competência da parcela, que deve estar no formato ano-mês',
  },
  [InstallmentIrregularityType.DUPLICATED_ACCRUAL_DATE]: {
    name: 'Duplicação de parcela',
    action: 'É permitido somente uma parcela por mês de competência neste produto',
  },
  [InstallmentIrregularityType.INSTALLMENT_AMOUNT]: {
    name: 'Valor padrão da parcela',
    action: 'Corrigir valor da parcela, que deve ser maior do que 0',
  },
  [InstallmentIrregularityType.INSTALLMENT_TYPE]: {
    name: 'Tipo da parcela',
    action: 'Corrigir tipo da parcela, que deve ser Pré-matrícula ou Mensalidade',
  },
  [InstallmentIrregularityType.DISCOUNTS_TOTAL_AMOUNT]: {
    name: 'Desconto total da parcela',
    action: 'Corrigir valor total de desconto, que não pode ser maior que da parcela',
  },
  [InstallmentIrregularityType.DISCOUNTS_LENGTH]: {
    name: 'Desconto total da parcela',
    action: 'Corrigir quantidade de descontos, que não pode se maior que 3 por parcela',
  },
  [InstallmentIrregularityType.DISCOUNT_AMOUNT]: {
    name: 'Configuração do desconto',
    action: 'Corrigir valor do desconto, que deve ser maior do que 0',
  },
  [InstallmentIrregularityType.DISCOUNT_DESCRIPTION]: {
    name: 'Configuração do desconto',
    action: 'Corrigir descrição do desconto, que deve ter entre 5 e 100 caracteres',
  },
  [InstallmentIrregularityType.DISCOUNT_DAYS_BEFORE_DUE_DATE]: {
    name: 'Configuração do desconto',
    action: 'Corrigir validade do desconto, que não pode ser depois do vencimento',
  },
}

export enum InstallmentType {
  ENROLLMENT = 'ENROLLMENT',
  TUITION = 'TUITION',
  UNKNOWN = 'UNKNOWN',
}

export const installmentTypeParamsDict: PartialRecord<InstallmentType, string> = {
  [InstallmentType.ENROLLMENT]: 'Matricula',
  [InstallmentType.TUITION]: 'Mensalidade',
  [InstallmentType.UNKNOWN]: 'Tipo não indicado',
}
