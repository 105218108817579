import type { RouteDefinition } from '@/modules/core/router'
import { Router } from '@/modules/core/router'
import { CreditHome } from '../pages/CreditHome'
import { Simulation } from '../pages/Simulation'
import { useRouteMatch } from 'react-router-dom'
import { ModuleRegister } from '@/modules/core/module'
import { CreditProvider } from '../contexts/CreditDataContext'
import { CreditSummary } from '../pages/CreditSummary'
import { CreditStatus } from '../pages/CreditStatus'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'

export const internalRoutes: RouteDefinition[] = [
  {
    key: 'CREDIT_HOME',
    path: '/',
    component: CreditHome,
    exact: true,
  },

  {
    key: 'SIMULATION',
    path: '/simulacao',
    component: Simulation,
    exact: true,
  },
  {
    key: 'SUMMARY',
    path: '/resumo',
    component: CreditSummary,
    exact: true,
  },
  {
    key: 'STATUS',
    path: '/estado',
    component: CreditStatus,
    exact: true,
  },
]

const ModuleInternalRoutesWrapper = () => {
  const { path: basePath } = useRouteMatch()

  const moduleRegister = new ModuleRegister()
  moduleRegister.registerRoutes(internalRoutes, { basePath })

  return (
    <ErrorBoundary>
      <CreditProvider>
        <Router routes={moduleRegister.getRoutes()} loadingComponent={<p>loading</p>} />
      </CreditProvider>
    </ErrorBoundary>
  )
}

export const routes: RouteDefinition[] = [
  {
    key: 'CREDIT_ROUTES',
    path: '/credito',
    component: ModuleInternalRoutesWrapper,
    exact: false,
  },
]
