import { convertToUTCDate, formatNumberMicroCentsToReal } from 'src/shared/utils'

import { TransactionEventType } from 'src/shared/interfaces'
import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'

export const getLastEnrollment = (enrollments: UnguaranteedReport[]) =>
  enrollments[enrollments.length - 1]

export const getEnrollmentValue = (enrollment: UnguaranteedReport) => {
  const { total, events } = enrollment?.agregations?.financial_details

  const value = total - (events?.[TransactionEventType.BALANCE_TRANSFER] || 0)

  return formatNumberMicroCentsToReal(value)
}

export const getFormattedEnrollmentDate = (date: Date) =>
  convertToUTCDate(date).format('YYYY-MM-DD')

export const getEnrollmentDates = (enrollment: UnguaranteedReport) => {
  const { started_at, ended_at } = enrollment
  const startDate = getFormattedEnrollmentDate(started_at)
  const endDate = getFormattedEnrollmentDate(ended_at)

  return { startDate, endDate }
}
