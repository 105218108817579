import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

/**
 * Loading state for dashboard card component
 */
export const LoadingState = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="18.625rem"
      px="2rem"
      py="3rem"
      border="1px solid #DADADA"
      borderRadius="0.5rem"
      data-testid="loading-state"
    >
      <Box>
        <Skeleton
          variant="rect"
          width="14.8rem"
          height="2rem"
          style={{ borderRadius: '0.5rem', marginBottom: '1rem' }}
        />
        <Skeleton variant="rect" width="100%" height="1rem" style={{ borderRadius: '0.5rem' }} />
      </Box>

      <Skeleton variant="rect" width="100%" height="2rem" style={{ borderRadius: '0.5rem' }} />
    </Box>
  )
}
