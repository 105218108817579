import { useApi } from '@/shared/hooks'
import { ContractCancellationReason } from '@/shared/interfaces'
import * as Sentry from '@sentry/react'
import { useMutation } from '@tanstack/react-query'

type Params = {
  enrollmentID: uuid
  schoolID: uuid
}

export const useCancelProposal = () => {
  const { api } = useApi()

  return useMutation({
    mutationFn: async (params: Params) => {
      await api.enrollment.cancel(params.enrollmentID, params.schoolID, {
        cancellation_description: 'PROPOSAL_CANCELED',
        cancellation_reason: ContractCancellationReason.OTHER,
      })
    },
    onError: error => {
      Sentry.captureException(error)
    },
  })
}
