import { FC, Fragment, ReactNode } from 'react'
import { Box, CircularProgress } from '@material-ui/core'

import { formatCentsToReal } from 'src/shared/utils'

import { Message, StyledBoxLoading, StyledDialog, StyledDialogContent, Title } from './styles'

interface StatusDialogProps {
  children?: ReactNode
  guardianName: string
  isOpen: boolean
  isTextVisible?: boolean
  posName: string
  title?: string
  totalAmount: number
}

const StatusDialog: FC<StatusDialogProps> = ({
  children,
  guardianName,
  isOpen,
  isTextVisible,
  posName,
  totalAmount,
  title = 'Comunicando com a maquininha',
}) => {
  const createListMessage = () => {
    if (isTextVisible) {
      return [
        <Fragment key="code">
          <b>Nome do responsável: </b>
          {guardianName}
        </Fragment>,
        <Fragment key="total-amount">
          <b>Valor a pagar: </b>
          {formatCentsToReal(totalAmount)}
        </Fragment>,
        <Fragment key="pos">
          <b>Maquininha: </b>
          {posName}
        </Fragment>,
      ]
    }
  }

  return (
    <StyledDialog open={isOpen}>
      <StyledDialogContent>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingX={2}
        >
          <StyledBoxLoading>
            <CircularProgress style={{ width: '120px', height: 'auto' }} />
          </StyledBoxLoading>
          <Box marginBottom={1} paddingX={5}>
            <Title>{title}</Title>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="start"
            marginBottom={2}
          >
            {createListMessage()?.map(message => (
              <Message key={`option-message-${message.key}`} withoutMargin>
                {message}
              </Message>
            ))}
          </Box>
        </Box>
        {children}
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default StatusDialog
