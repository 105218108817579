import { Table } from '@gravity/table'

import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { useGuardianDetailsInvoice } from '@/modules/guardians/GuardianDetailsInvoicesTab/hooks/useGuardianDetailsInvoice'
import { formatter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/formatter'

import { Row } from './types'
import { Wrapper, TableRow } from './styles'
import { GuardianInvoicesTableActions } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTableActions'
import { spacing } from '@gravity/tokens'

interface GuardianInvoicesTableProps {
  installments: Installment[]
  loading?: boolean
  onRowClick: (row: Row) => void
}

export const GuardianInvoicesTable = ({
  installments,
  onRowClick,
  loading,
}: GuardianInvoicesTableProps) => {
  const {
    rows,
    batchSelection,
    enableBatchSelection,
    handleRowSelect,
    handleSelectAll,
  } = useGuardianDetailsInvoice({ installments })

  const checkboxStyle = { maxWidth: spacing[8], width: spacing[8] }

  return (
    <Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell
            style={checkboxStyle}
            name="checkbox"
            enableCheckbox={enableBatchSelection}
            onChange={handleSelectAll}
            checked={batchSelection}
          />
          <Table.HeaderCell minWidth={80} name="vencimento">
            Vencimento
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="fatura">
            Fatura
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="competencia">
            Competência
          </Table.HeaderCell>
          <Table.HeaderCell name="aluno">Aluno</Table.HeaderCell>
          <Table.HeaderCell name="produto">Produto</Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="valor-a-pagar">
            Valor a pagar
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="situacao">
            Situação
          </Table.HeaderCell>
        </Table.Head>

        <Table.Body>
          {loading ? (
            <>
              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
            </>
          ) : (
            rows?.map(row => (
              <TableRow key={row.urn} onClick={() => onRowClick(row)} disabled={row.data.disabled}>
                <Table.CustomCell
                  style={checkboxStyle}
                  enableCheckbox={row.data.enableCheckbox}
                  onChange={() => handleRowSelect(row.urn)}
                  checked={row.data.checked}
                />
                <Table.TextCell>{row.data.due_date}</Table.TextCell>
                <Table.TextCell subtext={row.data.order_reference}>{row.data.type}</Table.TextCell>
                <Table.TextCell>{row.data.competence_date}</Table.TextCell>
                <Table.TextCell>{row.student.name}</Table.TextCell>
                <Table.TextCell subtext={row.data.negotiation_id}>
                  {row.product_name}
                </Table.TextCell>
                <Table.TextCell>{formatter.amount(row.amount)}</Table.TextCell>
                <Table.BadgeCell badgeVariant="soft" badgeColor={row.data.status.color}>
                  {row.data.status.text}
                </Table.BadgeCell>

                <GuardianInvoicesTableActions installment={row} />
              </TableRow>
            ))
          )}
        </Table.Body>
      </Table.Root>
    </Wrapper>
  )
}
