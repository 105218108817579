import { useNavigation } from '@/escolas/hooks'
import { newContractAPI } from '@/modules/contract/services'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useApi } from '@/shared/hooks/useApi'
import { useParams } from 'react-router-dom'

import { EditContract } from './EditContract'

export const EditContractPage = () => {
  const { contractId } = useParams<{ contractId: uuid }>()
  const { schoolId } = useNavigation()
  const enableCancelFF = useUnleashFlag(UnleashFlags.FII_321_ENABLE_CANCEL_INSTALLMENTS)
  const enableCancelContractFF = useUnleashFlag(
    UnleashFlags.FII_394_ENABLE_CANCEL_CONTRACT_VIA_INSTALLMENTS
  )
  const schoolBlockedForCancel = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)
  const enableEditInstallmentAmount = useUnleashFlag(
    UnleashFlags.FII_329_ENABLE_EDIT_INSTALLMENT_AMOUNT
  )

  const { apiClient } = useApi()
  const api = newContractAPI(apiClient.privateApi)

  return (
    <EditContract
      api={api}
      contractId={contractId}
      schoolId={schoolId}
      enableCancelFF={enableCancelFF}
      enableEditAmountFF={enableEditInstallmentAmount}
      schoolBlockedForCancel={schoolBlockedForCancel}
      enableCancelContractFF={enableCancelContractFF}
    />
  )
}
