import { FC, useState } from 'react'
import { LabelReason, StyledDialogContent, StyledTextArea } from './styled'
import { modalCustomProps } from './types'
import { Button } from '@gravity/button'
import { DialogPrimitives } from '@gravity/dialog'

const ModalCustomDialog: FC<modalCustomProps> = ({
  isVisible,
  setIsVisible,
  onClose,
  submitHandler,
  title,
  isConfirmDisabled,
  description,
  labelReason,
  backButton,
  confirmButton,
  size,
  children,
  style,
}) => {
  const [note, setNote] = useState<string>('')

  return (
    <DialogPrimitives.Root
      open={children ? undefined : isVisible}
      onOpenChange={
        children
          ? () => null
          : (open: boolean) => {
              if (!open) {
                setIsVisible(false)
              }
            }
      }
    >
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Trigger asChild>{children || ''}</DialogPrimitives.Trigger>
      <DialogPrimitives.Content
        style={style}
        size={size}
        title={title}
        description={description}
        actionButton={
          <Button
            color={confirmButton.color}
            variant={confirmButton.variation}
            onClick={() => {
              submitHandler(note)
            }}
            disabled={isConfirmDisabled?.()}
          >
            {confirmButton.label}
          </Button>
        }
        cancelButton={
          <Button variant={backButton.variation} onClick={onClose}>
            {backButton.label}
          </Button>
        }
      >
        <StyledDialogContent>
          <LabelReason>{labelReason}</LabelReason>
          <StyledTextArea
            placeholder="Preencha as informações (opcional)"
            size={3}
            onChange={v => setNote(v.target.value)}
          />
        </StyledDialogContent>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}

export default ModalCustomDialog
