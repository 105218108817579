import { ReactNode, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Autocomplete, Option } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Heading } from '@gravity/heading'

import { NotificationMenu } from '@/modules/notification/components/NotificationMenu'
import { UserMenu } from './components/UserMenu'
import { LoadingState } from './components/LoadingState'

import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useUserSchoolGroup } from '@/shared/hooks/queries/schoolGroups'
import { useUnleashFlag, UnleashFlags } from '@/shared/hooks/useUnleashFlag'

import { ROLES, ROLES_ISAAC } from '@/shared/constants'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { DSHighlighter } from '../DSHighlighter'

import { Container, NotificationContainer } from './styles'

type HeaderProps = {
  title: string | ReactNode
}

/**
 * Standard platform header
 *
 * @param props.title Header title
 */
export const Header = ({ title }: HeaderProps) => {
  const { push, location } = useHistory()
  const { hasPayoutReportAccess, isGroupManager, schoolId } = useJWT()
  const { schoolSlug, school } = useSelectedSchool()

  const isSchoolGroupsEnabled = useUnleashFlag(UnleashFlags.PE_ENABLE_SCHOOL_GROUPS)
  const isSchoolGroupModuleEnabled = useUnleashFlag(UnleashFlags.PE_ENABLE_SCHOOL_GROUPS_MODULES)

  const isSchoolGroupFeatureEnabled = isSchoolGroupsEnabled && isSchoolGroupModuleEnabled

  // TODO: handle error
  const { data, isLoading: isFetching } = useUserSchoolGroup(schoolId ?? '', {
    enabled: !!schoolId && isGroupManager && isSchoolGroupFeatureEnabled,
  })

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendChangeSchoolEvent = (selectedSchool: string) => {
    isInitialized &&
      eventDispatcherClient?.sendEvent({
        scope: EventDispatcherEventScopes.SCHOOL_GROUP,
        name: EventDispatcherEvents.OPTION_SELECTED,
        action: 'click',
        customProperties: {
          $form_action: 'Troca de unidade do grupo escolar',
          $selected_school: selectedSchool,
        },
      })
  }

  const handleChangeSchool = (option: Option | null) => {
    if (!option) return

    sendChangeSchoolEvent(option.label)

    push({
      pathname: schoolSlug
        ? location.pathname.replace(schoolSlug, String(option.value))
        : `/${String(option.value)}`,
    })
  }

  const schoolsOptions =
    data?.data.schools.map(school => ({
      label: school.name,
      value: school.slug,
    })) ?? []

  const currentOption = useMemo(
    () => schoolsOptions.find(item => item.value === schoolSlug) ?? null,
    [schoolSlug, schoolsOptions]
  )

  const userRole = (() => {
    if (isGroupManager) {
      return ROLES_ISAAC[ROLES.GROUP_MANAGER]
    }

    if (hasPayoutReportAccess) {
      return ROLES_ISAAC[ROLES.PROFILE_MANTAINER]
    }

    return ROLES_ISAAC[ROLES.PROFILE_SECRETARY]
  })()

  const schoolGroup = data?.data.school_group

  const isLoading = !schoolId || !isGroupManager ? false : isFetching

  const userMenuVariation = hasPayoutReportAccess ? 'primary' : 'secondary'

  return (
    <Container>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {typeof title === 'string' ? (
            <Heading variant="heading-h4-medium">{title}</Heading>
          ) : (
            title
          )}

          {isGroupManager && (
            <Box width={386} marginLeft="1.5rem">
              <Autocomplete
                label="Nome da unidade"
                noOptionsText="Nenhuma unidade encontrada"
                value={currentOption}
                options={schoolsOptions}
                onChange={handleChangeSchool}
              />
            </Box>
          )}

          <Box display="flex" alignItems="center" marginLeft="auto" gridGap="1rem">
            <DSHighlighter />
            {/* TODO: Rever acoplamento com SchoolsHeader */}
            <NotificationContainer hideDivider iconSize={20}>
              <NotificationMenu />
            </NotificationContainer>

            <UserMenu
              title={(isGroupManager ? schoolGroup?.name : school?.name) ?? ''}
              subtitle={userRole}
              variation={userMenuVariation}
            />
          </Box>
        </>
      )}
    </Container>
  )
}
