import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import type { TileDisabledFunctionArgs } from '@olaisaac/design-system'

import { DashboardCard } from '../DashboardCard'
import { DatePicker } from '@/escolas/components/DatePicker'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export type TuitionPayoutReportData = {
  id: string
  isOpen: boolean
  payoutDueDate: Date
  payoutValue: number
}

type TuitionReportDashboardCardProps = {
  currentPayout?: TuitionPayoutReportData
  hasError: boolean
  isLoading: boolean
  isLoadingPayoutAmount: boolean
  onChange: (tuitionPayoutReportId: string) => void
  onRetry: () => void
  tuitionPayouts: TuitionPayoutReportData[]
}

/**
 * Specialized dashboard card for tuition payout report
 *
 * @param props
 * @param props.currenPayout Current selected tuition payout report
 * @param props.tuitionPayouts List of available tuition payout reports
 * @param props.isLoading Indicates if the loading state should be displayed
 * @param props.isLoadingPayoutAmount Indicates if loading state for payout amount should be displayed
 * @param props.hasError Indicates if the error state should be displayed
 * @param props.onChange On change callback function
 * @param props.onRetry On retry callback function
 */
export const TuitionReportDashboardCard = ({
  currentPayout,
  tuitionPayouts,
  isLoading,
  isLoadingPayoutAmount,
  hasError,
  onChange,
  onRetry,
}: TuitionReportDashboardCardProps) => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleNavigateToTuitionPayoutReport = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.NAVIGATION_TO_PAYOUT_REPORT,
        action: 'click',
        customProperties: { $page_name: 'Dashboard de repasses' },
      })

    push(`repasses/mensalidades${currentPayout && `?id=${currentPayout.id}`}`)
  }

  const findTuitionPayoutByDate = (date: Date) => {
    const payout = tuitionPayouts.find(
      item => dayjs(item.payoutDueDate).format('YYYY-MM') === dayjs(date).format('YYYY-MM')
    )

    return payout
  }

  const handleChangeSelectedPayout = (date: Date) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.TUITION_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD,
        action: 'click',
        customProperties: {
          $page_name: 'Dashboard de repasse',
        },
      })

    const payout = findTuitionPayoutByDate(date)

    if (!payout) return

    onChange(payout.id)
  }

  const checkIfDateShouldBeDisabled = ({ view, date }: TileDisabledFunctionArgs) => {
    if (view !== 'year') return false

    const payout = findTuitionPayoutByDate(date)

    return !payout
  }

  const tuitionMinDate = tuitionPayouts[tuitionPayouts.length - 1]?.payoutDueDate

  const tuitionMaxDate = tuitionPayouts[0]?.payoutDueDate

  return (
    <DashboardCard
      title="Repasse de mensalidades"
      isPayoutOpen={currentPayout?.isOpen ?? true}
      payoutAmountFormat="decimal"
      payoutValue={currentPayout?.payoutValue ?? 0}
      isLoading={isLoading}
      isLoadingPayoutAmount={isLoadingPayoutAmount}
      hasError={hasError}
      onRequestDetails={handleNavigateToTuitionPayoutReport}
      onRetry={onRetry}
    >
      <DatePicker
        variation="ghost"
        maxDetail="year"
        minDate={tuitionMinDate}
        maxDate={tuitionMaxDate}
        tileDisabled={checkIfDateShouldBeDisabled}
        value={dayjs(currentPayout?.payoutDueDate).toDate()}
        onChange={handleChangeSelectedPayout}
      />
    </DashboardCard>
  )
}
